import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { NotifyUtil } from "../../utils";
import {
  DELETE_DEAL_REQUEST,
  FETCH_DEALS_REQUEST,
  FETCH_DEAL_BY_SLUG_REQUEST,
  TOGGLE_DEALS_FEATURED_STATUS_REQUEST,
  TOGGLE_DEALS_STATUS_REQUEST,
  UPDATE_DEAL_FEATURED_DATE_REQUEST,
} from "./dealActionType";
import {
  deleteDealFailure,
  deleteDealSuccess,
  fetchDealBySlugFailure,
  fetchDealBySlugSuccess,
  fetchDealsFailure,
  fetchDealsSuccess,
  toggleDealsFeaturedStatusFailure,
  toggleDealsFeaturedStatusSuccess,
  toggleDealsStatusFailure,
  toggleDealsStatusSuccess,
  updateDealFeaturedDateFailure,
  updateDealFeaturedDateSuccess,
} from "./dealAction";
import { DealService } from "../../services";

function* fetchDealsSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      DealService.getDeals,
      skip,
      limit,
      action.searchQuery,
      action.statusFilter,
      action.featuredFilter
    );
    yield put(fetchDealsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchDealsFailure(error));
  }
}

function* fetchDealBySlugSaga(action) {
  try {
    const { data } = yield call(DealService.getDealBySlug, action.slug);
    yield put(fetchDealBySlugSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    action.meta.onError();
    yield put(fetchDealBySlugFailure(error));
  }
}

function* deleteDealSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(DealService.deleteDeal, payload);
    yield put(deleteDealSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteDealFailure(error));
  }
}

function* toggleDealsStatusSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(DealService.toggleDealsStatus, payload);
    yield put(toggleDealsStatusSuccess());
    toast.success("Successfully status updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(toggleDealsStatusFailure(error));
  }
}

function* toggleDealsFeaturedStatusSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(DealService.toggleDealsFeaturedStatus, payload);
    yield put(toggleDealsFeaturedStatusSuccess());
    toast.success("Successfully featured status updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(toggleDealsFeaturedStatusFailure(error));
  }
}

function* updateDealFeaturedDateSaga(action) {
  try {
    const {
      payload,
      dealId,
      meta: { onSuccess },
    } = action;
    yield call(DealService.updateDealFeaturedDate, dealId, payload);
    yield put(updateDealFeaturedDateSuccess());
    toast.success("Successfully featured date updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateDealFeaturedDateFailure(error));
  }
}

export function* dealWatcherSaga() {
  yield takeEvery(FETCH_DEALS_REQUEST, fetchDealsSaga);
  yield takeEvery(FETCH_DEAL_BY_SLUG_REQUEST, fetchDealBySlugSaga);
  yield takeEvery(DELETE_DEAL_REQUEST, deleteDealSaga);
  yield takeEvery(TOGGLE_DEALS_STATUS_REQUEST, toggleDealsStatusSaga);
  yield takeEvery(
    TOGGLE_DEALS_FEATURED_STATUS_REQUEST,
    toggleDealsFeaturedStatusSaga
  );
  yield takeEvery(
    UPDATE_DEAL_FEATURED_DATE_REQUEST,
    updateDealFeaturedDateSaga
  );
}
