import {
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  FETCH_CLIENTS_FAILURE,
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
} from "./clientActionType";

export const fetchClientsRequest = (offset, searchQuery) => {
  return {
    type: FETCH_CLIENTS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchClientsSuccess = (data) => {
  return {
    type: FETCH_CLIENTS_SUCCESS,
    payload: data,
  };
};

export const fetchClientsFailure = (error) => {
  return {
    type: FETCH_CLIENTS_FAILURE,
    error,
  };
};

export const fetchClientRequest = (email) => {
  return {
    type: FETCH_CLIENT_REQUEST,
    email,
  };
};
export const fetchClientSuccess = (data) => {
  return {
    type: FETCH_CLIENT_SUCCESS,
    payload: data,
  };
};
export const fetchClientFailure = (error) => {
  return {
    type: FETCH_CLIENT_FAILURE,
    error,
  };
};

export const createClientRequest = (payload, onSuccess) => {
  return {
    type: CREATE_CLIENT_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const createClientSuccess = (data) => {
  return {
    type: CREATE_CLIENT_SUCCESS,
    payload: data,
  };
};

export const createClientFailure = (error) => {
  return {
    type: CREATE_CLIENT_FAILURE,
    error,
  };
};

export const updateClientRequest = (email, payload, onSuccess) => {
  return {
    type: UPDATE_CLIENT_REQUEST,
    email,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateClientSuccess = () => {
  return {
    type: UPDATE_CLIENT_SUCCESS,
  };
};

export const updateClientFailure = (error) => {
  return {
    type: UPDATE_CLIENT_FAILURE,
    error,
  };
};

export const deleteClientRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_CLIENT_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteClientSuccess = () => {
  return {
    type: DELETE_CLIENT_SUCCESS,
  };
};

export const deleteClientFailure = (error) => {
  return {
    type: DELETE_CLIENT_FAILURE,
    error,
  };
};
