import {
  DELETE_REPORT_FAILURE,
  DELETE_REPORT_REQUEST,
  DELETE_REPORT_SUCCESS,
  FETCH_REPORTS_FAILURE,
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_STATUS_FAILURE,
  FETCH_REPORT_STATUS_REQUEST,
  FETCH_REPORT_STATUS_SUCCESS,
  FETCH_REPORT_SUCCESS,
  UPDATE_REPORT_FAILURE,
  UPDATE_REPORT_REQUEST,
  UPDATE_REPORT_SUCCESS,
} from "./reportActionType";

export const fetchReportsRequest = (offset, searchQuery) => {
  return {
    type: FETCH_REPORTS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchReportsSuccess = (data) => {
  return {
    type: FETCH_REPORTS_SUCCESS,
    payload: data,
  };
};

export const fetchReportsFailure = (error) => {
  return {
    type: FETCH_REPORTS_FAILURE,
    error,
  };
};

export const fetchReportRequest = (reportId) => {
  return {
    type: FETCH_REPORT_REQUEST,
    reportId,
  };
};

export const fetchReportSuccess = (data) => {
  return {
    type: FETCH_REPORT_SUCCESS,
    payload: data,
  };
};

export const fetchReportFailure = (error) => {
  return {
    type: FETCH_REPORT_FAILURE,
    error,
  };
};

export const deleteReportRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_REPORT_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteReportSuccess = () => {
  return {
    type: DELETE_REPORT_SUCCESS,
  };
};

export const deleteReportFailure = (error) => {
  return {
    type: DELETE_REPORT_FAILURE,
    error,
  };
};

export const fetchReportStatusRequest = () => {
  return {
    type: FETCH_REPORT_STATUS_REQUEST,
  };
};

export const fetchReportStatusSuccess = (data) => {
  return {
    type: FETCH_REPORT_STATUS_SUCCESS,
    payload: data,
  };
};

export const fetchReportStatusFailure = (error) => {
  return {
    type: FETCH_REPORT_STATUS_FAILURE,
    error,
  };
};

export const updateReportRequest = (id, payload, onSuccess) => {
  return {
    type: UPDATE_REPORT_REQUEST,
    id,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateReportSuccess = () => {
  return {
    type: UPDATE_REPORT_SUCCESS,
  };
};

export const updateReportFailure = (error) => {
  return {
    type: UPDATE_REPORT_FAILURE,
    error,
  };
};
