export const FETCH_TOP_CONTRIBUTORS_DASHBOARD_REQUEST =
  "FETCH_TOP_CONTRIBUTORS_DASHBOARD_REQUEST";
export const FETCH_TOP_CONTRIBUTORS_DASHBOARD_SUCCESS =
  "FETCH_TOP_CONTRIBUTORS_DASHBOARD_SUCCESS";
export const FETCH_TOP_CONTRIBUTORS_DASHBOARD_FAILURE =
  "FETCH_TOP_CONTRIBUTORS_DASHBOARD_FAILURE";

export const FETCH_MONTHLY_DEALS_SUMMARY_REQUEST =
  "FETCH_MONTHLY_DEALS_SUMMARY_REQUEST";
export const FETCH_MONTHLY_DEALS_SUMMARY_SUCCESS =
  "FETCH_MONTHLY_DEALS_SUMMARY_SUCCESS";
export const FETCH_MONTHLY_DEALS_SUMMARY_FAILURE =
  "FETCH_MONTHLY_DEALS_SUMMARY_FAILURE";

export const FETCH_MONTHLY_USERS_SUMMARY_REQUEST =
  "FETCH_MONTHLY_USERS_SUMMARY_REQUEST";
export const FETCH_MONTHLY_USERS_SUMMARY_SUCCESS =
  "FETCH_MONTHLY_USERS_SUMMARY_SUCCESS";
export const FETCH_MONTHLY_USERS_SUMMARY_FAILURE =
  "FETCH_MONTHLY_USERS_SUMMARY_FAILURE";

export const FETCH_MONTHLY_REPORTS_SUMMARY_REQUEST =
  "FETCH_MONTHLY_REPORTS_SUMMARY_REQUEST";
export const FETCH_MONTHLY_REPORTS_SUMMARY_SUCCESS =
  "FETCH_MONTHLY_REPORTS_SUMMARY_SUCCESS";
export const FETCH_MONTHLY_REPORTS_SUMMARY_FAILURE =
  "FETCH_MONTHLY_REPORTS_SUMMARY_FAILURE";

export const FETCH_SUMMARY_REQUEST = "FETCH_SUMMARY_REQUEST";
export const FETCH_SUMMARY_SUCCESS = "FETCH_SUMMARY_SUCCESS";
export const FETCH_SUMMARY_FAILURE = "FETCH_SUMMARY_FAILURE";

export const FETCH_TOP_MOST_VIEWED_DEALS_REQUEST =
  "FETCH_TOP_MOST_VIEWED_DEALS_REQUEST";
export const FETCH_TOP_MOST_VIEWED_DEALS_SUCCESS =
  "FETCH_TOP_MOST_VIEWED_DEALS_SUCCESS";
export const FETCH_TOP_MOST_VIEWED_DEALS_FAILURE =
  "FETCH_TOP_MOST_VIEWED_DEALS_FAILURE";

export const FETCH_TOP_MOST_REPORTED_DEALS_REQUEST =
  "FETCH_TOP_MOST_REPORTED_DEALS_REQUEST";
export const FETCH_TOP_MOST_REPORTED_DEALS_SUCCESS =
  "FETCH_TOP_MOST_REPORTED_DEALS_SUCCESS";
export const FETCH_TOP_MOST_REPORTED_DEALS_FAILURE =
  "FETCH_TOP_MOST_REPORTED_DEALS_FAILURE";

export const FETCH_TOP_MOST_SEARCHED_KEYWORDS_REQUEST =
  "FETCH_TOP_MOST_SEARCHED_KEYWORDS_REQUEST";
export const FETCH_TOP_MOST_SEARCHED_KEYWORDS_SUCCESS =
  "FETCH_TOP_MOST_SEARCHED_KEYWORDS_SUCCESS";
export const FETCH_TOP_MOST_SEARCHED_KEYWORDS_FAILURE =
  "FETCH_TOP_MOST_SEARCHED_KEYWORDS_FAILURE";

export const FETCH_CATEGORY_BRAND_STORE_SUMMARY_REQUEST =
  "FETCH_CATEGORY_BRAND_STORE_SUMMARY_REQUEST";
export const FETCH_CATEGORY_BRAND_STORE_SUMMARY_SUCCESS =
  "FETCH_CATEGORY_BRAND_STORE_SUMMARY_SUCCESS";
export const FETCH_CATEGORY_BRAND_STORE_SUMMARY_FAILURE =
  "FETCH_CATEGORY_BRAND_STORE_SUMMARY_FAILURE";

export const FETCH_ACTIVE_USER_SUMMARY_REQUEST =
  "FETCH_ACTIVE_USER_SUMMARY_REQUEST";
export const FETCH_ACTIVE_USER_SUMMARY_SUCCESS =
  "FETCH_ACTIVE_USER_SUMMARY_SUCCESS";
export const FETCH_ACTIVE_USER_SUMMARY_FAILURE =
  "FETCH_ACTIVE_USER_SUMMARY_FAILURE";
