import { api } from "../config";

export const CategoryService = {
  getCategories: async (page, limit, searchQuery, featuredFilter) => {
    try {
      const { data } = await api.get(
        `api/admin/categories/?query=${searchQuery}&page=${page}&limit=${limit}&isFeatured=${featuredFilter}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getCategory: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/categories/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getCategoryOptions: async () => {
    try {
      const { data } = await api.get("api/admin/categories/category-options");
      return data;
    } catch (error) {
      throw error;
    }
  },
  getSubCategoryOptions: async () => {
    try {
      const { data } = await api.get(
        "api/admin/categories/sub-category-options"
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  createCategory: async (payload) => {
    try {
      const { data } = await api.post("api/admin/categories/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateCategory: async (slug, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/categories/update/${slug}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteCategory: async (payload) => {
    try {
      const { data } = await api.post("api/admin/categories/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
