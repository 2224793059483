export const FETCH_DEALS_REQUEST = "FETCH_DEALS_REQUEST";
export const FETCH_DEALS_SUCCESS = "FETCH_DEALS_SUCCESS";
export const FETCH_DEALS_FAILURE = "FETCH_DEALS_FAILURE";

export const FETCH_DEAL_BY_SLUG_REQUEST = "FETCH_DEAL_BY_SLUG_REQUEST";
export const FETCH_DEAL_BY_SLUG_SUCCESS = "FETCH_DEAL_BY_SLUG_SUCCESS";
export const FETCH_DEAL_BY_SLUG_FAILURE = "FETCH_DEAL_BY_SLUG_FAILURE";

export const DELETE_DEAL_REQUEST = "DELETE_DEAL_REQUEST";
export const DELETE_DEAL_SUCCESS = "DELETE_DEAL_SUCCESS";
export const DELETE_DEAL_FAILURE = "DELETE_DEAL_FAILURE";

export const TOGGLE_DEALS_STATUS_REQUEST = "TOGGLE_DEALS_STATUS_REQUEST";
export const TOGGLE_DEALS_STATUS_SUCCESS = "TOGGLE_DEALS_STATUS_SUCCESS";
export const TOGGLE_DEALS_STATUS_FAILURE = "TOGGLE_DEALS_STATUS_FAILURE";

export const UPDATE_DEAL_FEATURED_DATE_REQUEST =
  "UPDATE_DEAL_FEATURED_DATE_REQUEST";
export const UPDATE_DEAL_FEATURED_DATE_SUCCESS =
  "UPDATE_DEAL_FEATURED_DATE_SUCCESS";
export const UPDATE_DEAL_FEATURED_DATE_FAILURE =
  "UPDATE_DEAL_FEATURED_DATE_FAILURE";

export const TOGGLE_DEALS_FEATURED_STATUS_REQUEST =
  "TOGGLE_DEALS_FEATURED_STATUS_REQUEST";
export const TOGGLE_DEALS_FEATURED_STATUS_SUCCESS =
  "TOGGLE_DEALS_FEATURED_STATUS_SUCCESS";
export const TOGGLE_DEALS_FEATURED_STATUS_FAILURE =
  "TOGGLE_DEALS_FEATURED_STATUS_FAILURE";
