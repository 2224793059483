import { api } from "../config";

export const DealService = {
  getDeals: async (skip, limit, searchQuery, statusFilter, featuredFilter) => {
    try {
      const { data } = await api.get(
        `api/admin/deals/?query=${searchQuery}&skip=${skip}&limit=${limit}&isActive=${statusFilter}&isFeatured=${featuredFilter}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getDealBySlug: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/deals/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteDeal: async (payload) => {
    try {
      const { data } = await api.post("api/admin/deals/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
  toggleDealsStatus: async (payload) => {
    try {
      const { data } = await api.post("api/admin/deals/toggle-status", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
  toggleDealsFeaturedStatus: async (payload) => {
    try {
      const { data } = await api.post(
        "api/admin/deals/toggleFeatureStatus",
        payload
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateDealFeaturedDate: async (dealId, payload) => {
    try {
      const { data } = await api.post(
        `api/admin/deals/updateFeaturedDate/${dealId}`,
        payload
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
