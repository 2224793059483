import Axios from "axios";
import store from "../store/storeConfig";

export const api = Axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
});

// Add a request interceptor
api.interceptors.request.use(function (config) {
  const { employee } = store.getState();
  const { token } = employee.loggedEmployee;
  config.headers.Authorization = token
    ? `Bearer ${process.env.REACT_APP_API_KEY},Bearer ${token}`
    : `Bearer ${process.env.REACT_APP_API_KEY}`;

  return config;
});
