import React from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVert from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { Action, CanPerformAction } from "../const";
import { Authenticate } from "../hoc/Authenticate";

export const ColumnOption = ({
  children,
  model,
  row,
  open,
  anchorEl,
  handleClick,
  handleClose,
  onClickMenuItem,
}) => {
  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: {
            //width: "150px",
            backgroundColor: "#EFFFFD",
          },
        }}
      >
        {children ?? [
          <Authenticate
            key={Action.EDIT}
            model={model}
            action={CanPerformAction.CAN_EDIT}
          >
            <MenuItem onClick={() => onClickMenuItem(Action.EDIT, row)}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </Authenticate>,

          <Authenticate
            key={Action.DELETE}
            model={model}
            action={CanPerformAction.CAN_DELETE}
          >
            <MenuItem onClick={() => onClickMenuItem(Action.DELETE, row)}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Authenticate>,
          <MenuItem
            key={Action.VIEW}
            onClick={() => onClickMenuItem(Action.VIEW, row)}
          >
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText>View</ListItemText>
          </MenuItem>,
        ]}
      </Menu>
    </>
  );
};
