import { Box } from "@mui/material";
import React from "react";
import { ReportForm } from "../forms";

export const ReportModal = ({ report, onCancel, onSuccess }) => {
  return (
    <Box mt="1rem" minWidth="450px">
      <ReportForm report={report} onCancel={onCancel} onSuccess={onSuccess} />
    </Box>
  );
};
