import { combineReducers } from "redux";
import { EmployeeReducer } from "./employee/employeeReducer";
import { BannerReducer } from "./banner/bannerReducer";
import { BadgeReducer } from "./badge/badgeReducer";
import { BrandReducer } from "./brand/brandReducer";
import { CategoryReducer } from "./category/categoryReducer";
import { CountryReducer } from "./country/countryReducer";
import { UiReducer } from "./ui/uiReducer";
import { ClientReducer } from "./client/clientReducer";
import { CommentReducer } from "./comment/commentReducer";
import { DealReducer } from "./deal/dealReducer";
import { ReportReducer } from "./report/reportReducer";
import { RoleReducer } from "./role/roleReducer";
import { ResourceReducer } from "./resource/resourceReducer";
import { StoreReducer } from "./store/storeReducer";
import { UserReducer } from "./user/userReducer";
import { DashboardReducer } from "./dashboard/dashboardReducer";
import { SearchLogReducer } from "./searchLog/searchLogReducer";
import { FacebookPageReducer } from "./facebookPage/facebookPageReducer";
import { EmailHistoryReducer } from "./emailHistory/emailHistoryReducer";
import { NotificationReducer } from "./notification/notificationReducer";
import { RewardRuleReducer } from "./rewardRule/rewardRuleReducer";
import { RewardReducer } from "./reward/rewardReducer";
import { RewardValidationLogReducer } from "./rewardValidationLog/rewardValidationLogReducer";
import { RedeemRewardReducer } from "./redeemReward/redeemRewardReducer";
import { DealAlertReducer } from "./dealAlert/dealAlertReducer";
import { StoreReviewReducer } from "./storeReview/storeReviewReducer";

const rootReducer = combineReducers({
  employee: EmployeeReducer,
  dashboard: DashboardReducer,
  banner: BannerReducer,
  badge: BadgeReducer,
  brand: BrandReducer,
  category: CategoryReducer,
  country: CountryReducer,
  client: ClientReducer,
  comment: CommentReducer,
  deal: DealReducer,
  report: ReportReducer,
  role: RoleReducer,
  resource: ResourceReducer,
  store: StoreReducer,
  user: UserReducer,
  searchLog: SearchLogReducer,
  facebookPage: FacebookPageReducer,
  emailHistory: EmailHistoryReducer,
  ui: UiReducer,
  notification: NotificationReducer,
  rewardRule: RewardRuleReducer,
  reward: RewardReducer,
  rewardValidationLog: RewardValidationLogReducer,
  redeemReward: RedeemRewardReducer,
  dealAlert: DealAlertReducer,
  storeReview: StoreReviewReducer,
});

export default rootReducer;
