export const FETCH_REWARD_VALIDATION_LOGS_REQUEST =
  "FETCH_REWARD_VALIDATION_LOGS_REQUEST";
export const FETCH_REWARD_VALIDATION_LOGS_SUCCESS =
  "FETCH_REWARD_VALIDATION_LOGS_SUCCESS";
export const FETCH_REWARD_VALIDATION_LOGS_FAILURE =
  "FETCH_REWARD_VALIDATION_LOGS_FAILURE";

export const DELETE_REWARD_VALIDATION_LOG_REQUEST =
  "DELETE_REWARD_VALIDATION_LOG_REQUEST";
export const DELETE_REWARD_VALIDATION_LOG_SUCCESS =
  "DELETE_REWARD_VALIDATION_LOG_SUCCESS";
export const DELETE_REWARD_VALIDATION_LOG_FAILURE =
  "DELETE_REWARD_VALIDATION_LOG_FAILURE";
