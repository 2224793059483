import {
  CREATE_REWARD_FAILURE,
  CREATE_REWARD_REQUEST,
  CREATE_REWARD_SUCCESS,
  DELETE_REWARD_FAILURE,
  DELETE_REWARD_REQUEST,
  DELETE_REWARD_SUCCESS,
  FETCH_REWARDS_FAILURE,
  FETCH_REWARDS_REQUEST,
  FETCH_REWARDS_SUCCESS,
  FETCH_REWARD_FAILURE,
  FETCH_REWARD_REQUEST,
  FETCH_REWARD_SUCCESS,
  UPDATE_REWARD_FAILURE,
  UPDATE_REWARD_REQUEST,
  UPDATE_REWARD_SUCCESS,
} from "./rewardActionType";

export const fetchRewardsRequest = (offset, searchQuery) => {
  return {
    type: FETCH_REWARDS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchRewardsSuccess = (data) => {
  return {
    type: FETCH_REWARDS_SUCCESS,
    payload: data,
  };
};

export const fetchRewardsFailure = (error) => {
  return {
    type: FETCH_REWARDS_FAILURE,
    error,
  };
};

export const fetchRewardRequest = (slug) => {
  return {
    type: FETCH_REWARD_REQUEST,
    slug,
  };
};

export const fetchRewardSuccess = (data) => {
  return {
    type: FETCH_REWARD_SUCCESS,
    payload: data,
  };
};

export const fetchRewardFailure = (error) => {
  return {
    type: FETCH_REWARD_FAILURE,
    error,
  };
};

export const createRewardRequest = (payload, onSuccess) => {
  return {
    type: CREATE_REWARD_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};
export const createRewardSuccess = (data) => {
  return {
    type: CREATE_REWARD_SUCCESS,
    payload: data,
  };
};
export const createRewardFailure = (error) => {
  return {
    type: CREATE_REWARD_FAILURE,
    error,
  };
};

export const updateRewardRequest = (slug, payload, onSuccess) => {
  return {
    type: UPDATE_REWARD_REQUEST,
    slug,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateRewardSuccess = () => {
  return {
    type: UPDATE_REWARD_SUCCESS,
  };
};

export const updateRewardFailure = (error) => {
  return {
    type: UPDATE_REWARD_FAILURE,
    error,
  };
};

export const deleteRewardRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_REWARD_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteRewardSuccess = () => {
  return {
    type: DELETE_REWARD_SUCCESS,
  };
};

export const deleteRewardFailure = (error) => {
  return {
    type: DELETE_REWARD_FAILURE,
    error,
  };
};
