import {
  DELETE_DEAL_FAILURE,
  DELETE_DEAL_REQUEST,
  DELETE_DEAL_SUCCESS,
  FETCH_DEALS_FAILURE,
  FETCH_DEALS_REQUEST,
  FETCH_DEALS_SUCCESS,
  FETCH_DEAL_BY_SLUG_FAILURE,
  FETCH_DEAL_BY_SLUG_REQUEST,
  FETCH_DEAL_BY_SLUG_SUCCESS,
  TOGGLE_DEALS_FEATURED_STATUS_FAILURE,
  TOGGLE_DEALS_FEATURED_STATUS_REQUEST,
  TOGGLE_DEALS_FEATURED_STATUS_SUCCESS,
  TOGGLE_DEALS_STATUS_FAILURE,
  TOGGLE_DEALS_STATUS_REQUEST,
  TOGGLE_DEALS_STATUS_SUCCESS,
  UPDATE_DEAL_FEATURED_DATE_FAILURE,
  UPDATE_DEAL_FEATURED_DATE_REQUEST,
  UPDATE_DEAL_FEATURED_DATE_SUCCESS,
} from "./dealActionType";

export const fetchDealsRequest = (
  offset,
  searchQuery,
  statusFilter,
  featuredFilter
) => {
  return {
    type: FETCH_DEALS_REQUEST,
    offset,
    searchQuery,
    statusFilter,
    featuredFilter,
  };
};

export const fetchDealsSuccess = (data) => {
  return {
    type: FETCH_DEALS_SUCCESS,
    payload: data,
  };
};

export const fetchDealsFailure = (error) => {
  return {
    type: FETCH_DEALS_FAILURE,
    error,
  };
};
export const fetchDealBySlugRequest = (slug, onError) => {
  return {
    type: FETCH_DEAL_BY_SLUG_REQUEST,
    slug,
    meta: {
      onError,
    },
  };
};

export const fetchDealBySlugSuccess = (data) => {
  return {
    type: FETCH_DEAL_BY_SLUG_SUCCESS,
    payload: data,
  };
};

export const fetchDealBySlugFailure = (error) => {
  return {
    type: FETCH_DEAL_BY_SLUG_FAILURE,
    error,
  };
};

export const deleteDealRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_DEAL_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteDealSuccess = () => {
  return {
    type: DELETE_DEAL_SUCCESS,
  };
};

export const deleteDealFailure = (error) => {
  return {
    type: DELETE_DEAL_FAILURE,
    error,
  };
};

export const toggleDealsStatusRequest = (payload, onSuccess) => {
  return {
    type: TOGGLE_DEALS_STATUS_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const toggleDealsStatusSuccess = () => {
  return {
    type: TOGGLE_DEALS_STATUS_SUCCESS,
  };
};

export const toggleDealsStatusFailure = (error) => {
  return {
    type: TOGGLE_DEALS_STATUS_FAILURE,
    error,
  };
};

export const toggleDealsFeaturedStatusRequest = (payload, onSuccess) => {
  return {
    type: TOGGLE_DEALS_FEATURED_STATUS_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const toggleDealsFeaturedStatusSuccess = () => {
  return {
    type: TOGGLE_DEALS_FEATURED_STATUS_SUCCESS,
  };
};

export const toggleDealsFeaturedStatusFailure = (error) => {
  return {
    type: TOGGLE_DEALS_FEATURED_STATUS_FAILURE,
    error,
  };
};

export const updateDealFeaturedDateRequest = (dealId, payload, onSuccess) => {
  return {
    type: UPDATE_DEAL_FEATURED_DATE_REQUEST,
    dealId,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateDealFeaturedDateSuccess = () => {
  return {
    type: UPDATE_DEAL_FEATURED_DATE_SUCCESS,
  };
};

export const updateDealFeaturedDateFailure = (error) => {
  return {
    type: UPDATE_DEAL_FEATURED_DATE_FAILURE,
    error,
  };
};
