export const FETCH_REWARDS_REQUEST = "FETCH_REWARDS_REQUEST";
export const FETCH_REWARDS_SUCCESS = "FETCH_REWARDS_SUCCESS";
export const FETCH_REWARDS_FAILURE = "FETCH_REWARDS_FAILURE";

export const FETCH_REWARD_REQUEST = "FETCH_REWARD_REQUEST";
export const FETCH_REWARD_SUCCESS = "FETCH_REWARD_SUCCESS";
export const FETCH_REWARD_FAILURE = "FETCH_REWARD_FAILURE";

export const CREATE_REWARD_REQUEST = "CREATE_REWARD_REQUEST";
export const CREATE_REWARD_SUCCESS = "CREATE_REWARD_SUCCESS";
export const CREATE_REWARD_FAILURE = "CREATE_REWARD_FAILURE";

export const UPDATE_REWARD_REQUEST = "UPDATE_REWARD_REQUEST";
export const UPDATE_REWARD_SUCCESS = "UPDATE_REWARD_SUCCESS";
export const UPDATE_REWARD_FAILURE = "UPDATE_REWARD_FAILURE";

export const DELETE_REWARD_REQUEST = "DELETE_REWARD_REQUEST";
export const DELETE_REWARD_SUCCESS = "DELETE_REWARD_SUCCESS";
export const DELETE_REWARD_FAILURE = "DELETE_REWARD_FAILURE";
