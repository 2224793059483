import { Box } from "@mui/material";
import React from "react";
import { RedeemRewardStatusForm } from "../forms";

export const RedeemRewardStatusModal = ({
  redeemReward,
  onCancel,
  onSuccess,
}) => {
  return (
    <Box mt="1rem" minWidth="550px">
      <RedeemRewardStatusForm
        redeemReward={redeemReward}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
    </Box>
  );
};
