import {
  DELETE_DEAL_ALERT_FAILURE,
  DELETE_DEAL_ALERT_REQUEST,
  DELETE_DEAL_ALERT_SUCCESS,
  FETCH_DEAL_ALERTS_FAILURE,
  FETCH_DEAL_ALERTS_REQUEST,
  FETCH_DEAL_ALERTS_SUCCESS,
} from "./dealAlertActionType";

export const fetchDealAlertsRequest = (offset, searchQuery) => {
  return {
    type: FETCH_DEAL_ALERTS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchDealAlertsSuccess = (data) => {
  return {
    type: FETCH_DEAL_ALERTS_SUCCESS,
    payload: data,
  };
};

export const fetchDealAlertsFailure = (error) => {
  return {
    type: FETCH_DEAL_ALERTS_FAILURE,
    error,
  };
};

export const deleteDealAlertRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_DEAL_ALERT_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteDealAlertSuccess = () => {
  return {
    type: DELETE_DEAL_ALERT_SUCCESS,
  };
};

export const deleteDealAlertFailure = (error) => {
  return {
    type: DELETE_DEAL_ALERT_FAILURE,
    error,
  };
};
