import moment from "moment";

export const DateUtil = {
  compareTodayWithDate: (date) => {
    const now = moment(new Date()); //todays date
    const duration = moment.duration(now.diff(date));
    const months = Math.round(duration.asMonths());
    const days = Math.round(duration.asDays());
    const hours = Math.round(duration.asHours());
    const minutes = Math.round(duration.asMinutes());
    let value = 0;
    if (months > 0) {
      const monthOrMonths = months > 1 ? "months" : "month";
      value = `${months} ${monthOrMonths}`;
    } else if (days > 0) {
      const dayOrDays = days > 1 ? "days" : "day";
      value = `${days} ${dayOrDays}`;
    } else if (hours > 0) {
      const hourOrHours = hours > 1 ? "hours" : "hour";
      value = `${hours} ${hourOrHours}`;
    } else if (minutes > 0) {
      const minuteOrMinutes = minutes > 1 ? "minutes" : "minute";
      value = `${minutes} ${minuteOrMinutes}`;
    } else {
      value = "Few sec";
    }
    return `${value} ago`;
  },
  getTime: (date) => {
    return moment(date).format("hh:mm A");
  },
  isDealExpired: (date) => {
    return moment(date).isBefore(new Date(), "day");
  },
};
