import { api } from "../config";

export const ClientService = {
  getClients: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/clients/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getClient: async (email) => {
    try {
      const { data } = await api.get(`api/admin/clients/${email}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  createClient: async (payload) => {
    try {
      const { data } = await api.post(
        "api/admin/clients/",
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateClient: async (email, payload) => {
    try {
      const { data } = await api.post(
        `api/admin/clients/update/${email}`,
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteClient: async (payload) => {
    try {
      const { data } = await api.post("api/admin/clients/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
