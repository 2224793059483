import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import {
  ColumnOption,
  ColumnTitle,
  DmBox,
  DmTable,
  StatusChip,
} from "../../components";
import {
  deleteCategoryRequest,
  fetchCategoriesRequest,
} from "../../store/category/categoryAction";
import { searchQuerySelector } from "../../store/selector";
import { UrlUtil } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Action, CanPerformAction, DATE_FORMAT, Models } from "../../const";
import { ConfirmModal, DmModal } from "../../components/Modal";
import { useToggle } from "../../hooks";
import _ from "lodash";
import moment from "moment";
import { Authenticate } from "../../hoc/Authenticate";

export const CategoryListPage = () => {
  const categoryState = useSelector((state) => state.category);
  const searchQuery = useSelector(searchQuerySelector);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRow, setActiveRow] = useState({});
  const [activeRowId, setActiveRowId] = useState(); // this is used only for hide and show ... options
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [openModal, setOpenModal] = useToggle();
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [featuredFilter, setFeaturedFilter] = React.useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentPageFromURL = parseInt(queryParams.get("page")) || 1;
  const limitFromURL = parseInt(queryParams.get("limit")) || 10;
  const [offset, setOffset] = useState({
    limit: limitFromURL,
    page: currentPageFromURL - 1,
  });

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickMenuItem = (action, row) => {
    switch (action) {
      case Action.DELETE:
        setSelectedRows([row]);
        if (selectedRows.length === 1) setActiveRow(row);
        setOpenModal();
        break;
      case Action.EDIT:
        navigate(
          `/categories/edit/${row?.slug}?page=${offset.page + 1}&limit=${
            offset.limit
          }`
        );
        break;
      case Action.VIEW:
        navigate(
          `/categories/details/${row?.slug}?page=${offset.page + 1}&limit=${
            offset.limit
          }`
        );
        break;
      default:
        break;
    }
  };

  const onMouseEnterRowHandler = (row) => {
    if (selectedRows.length === 0) setActiveRow(row);
    setActiveRowId(row.id);
  };

  const onMouseLeaveRowHandler = () => {
    setAnchorEl(null);
    setActiveRowId(null);
  };

  useEffect(() => {
    dispatch(fetchCategoriesRequest(offset, searchQuery, featuredFilter ?? ""));
  }, [dispatch, offset, searchQuery, featuredFilter]);

  const onChangePage = (page) => {
    setOffset({ ...offset, page: page - 1 });
    navigate(`?page=${page}&limit=${offset.limit}`);
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setOffset({
      limit: currentRowsPerPage,
      page: currentPage - 1,
    });
    navigate(`?page=${currentPage}&limit=${currentRowsPerPage}`);
  };

  const onDelete = () => {
    dispatch(
      deleteCategoryRequest(
        { categoryIds: _.map(selectedRows, "id") },
        onSuccessfullyDelete
      )
    );
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const onSuccessfullyDelete = () => {
    setOffset({ page: offset.page, limit: offset.limit });
    navigate(`?page=${offset.page + 1}&limit=${offset.limit}`);
  };

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
    if (state.selectedRows.length === 1) setActiveRow(state.selectedRows[0]);
  }, []);

  const columns = [
    {
      name: <ColumnTitle title={"Id"} />,
      selector: (row) => row.id,
      minWidth: "210px",
    },

    {
      name: <ColumnTitle title={"Name"} />,
      selector: (row) => (
        <Button
          variant="link"
          onClick={() =>
            navigate(
              `/categories/details/${row?.slug}?page=${offset.page + 1}&limit=${
                offset.limit
              }`
            )
          }
        >
          {row.name}
        </Button>
      ),
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Display Name"} />,
      selector: (row) => row.displayName,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Slug"} />,
      selector: (row) => row.slug,
    },
    {
      name: <ColumnTitle title={"Parent"} />,
      selector: (row) => row?.parent?.displayName ?? row?.parent?.name,
    },
    {
      name: <ColumnTitle title={"Is Root"} />,
      selector: (row) => <StatusChip status={row.isRoot} />,
    },
    {
      name: <ColumnTitle title={"Is Featured"} />,
      selector: (row) => <StatusChip status={row.isFeatured} />,
    },
    {
      name: <ColumnTitle title={"Enabled"} />,
      selector: (row) => <StatusChip status={row.isActive} />,
    },
    {
      name: <ColumnTitle title={"Image"} />,
      maxWidth: "150px",
      selector: (row) => (
        <Box
          component="img"
          sx={{ width: "50px", height: "50px", objectFit: "contain" }}
          src={
            row.image?.path
              ? UrlUtil.getFullImagePath(row.image.path)
              : "/images/default-thumbnail-image.gif"
          }
        />
      ),
    },
    {
      name: <ColumnTitle title={"Created"} />,
      selector: (row) => moment(row.createdAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Updated"} />,
      selector: (row) => moment(row.updatedAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={""} />,
      cell: (row) => (
        <>
          {activeRowId === row.id && (
            <ColumnOption
              row={row}
              open={open}
              model={Models.CATEGORY}
              anchorEl={anchorEl}
              handleClick={handleOptionClick}
              handleClose={handleOptionClose}
              onClickMenuItem={onClickMenuItem}
            />
          )}
        </>
      ),
    },
  ];

  const onChangeFeaturedHandler = (isFeatured) => {
    setFeaturedFilter(isFeatured);
  };

  return (
    <DmBox>
      <DmTable
        columns={columns}
        data={categoryState.categories}
        title={"Category List"}
        actions={
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Featured</InputLabel>
              <Select
                value={featuredFilter}
                label="Featured"
                onChange={(e) => onChangeFeaturedHandler(e.target.value)}
                sx={{ fontSize: ".9rem", width: 150 }}
              >
                <MenuItem value={""} sx={{ fontSize: ".9rem" }}>
                  Select
                </MenuItem>
                <MenuItem value={true} sx={{ fontSize: ".9rem" }}>
                  Featured
                </MenuItem>
                <MenuItem value={false} sx={{ fontSize: ".9rem" }}>
                  Not Featured
                </MenuItem>
              </Select>
            </FormControl>
            <Authenticate
              model={Models.CATEGORY}
              action={CanPerformAction.CAN_ADD}
            >
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate("/categories/add")}
              >
                Add
              </Button>
            </Authenticate>
          </Stack>
        }
        pending={categoryState.loading}
        totalRow={categoryState.totalCategories}
        paginationDefaultPage={currentPageFromURL}
        paginationPerPage={limitFromURL}
        onRowMouseEnter={onMouseEnterRowHandler}
        onRowMouseLeave={onMouseLeaveRowHandler}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        contextActions={
          <Authenticate
            model={Models.CATEGORY}
            action={CanPerformAction.CAN_DELETE}
          >
            <Button
              onClick={setOpenModal}
              sx={{
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
            >
              Delete
            </Button>
          </Authenticate>
        }
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
      <DmModal open={openModal} closeModal={setOpenModal}>
        <ConfirmModal
          title={
            selectedRows.length > 1
              ? `Are you sure want to delete ${selectedRows.length} categories ?`
              : `Are you sure you want to delete ${activeRow.name} ?`
          }
          subTitle="This action cannot be undone"
          btnConfirmText={"Delete"}
          btnCancelText="Cancel"
          onCancel={setOpenModal}
          onConfirm={onDelete}
        />
      </DmModal>
    </DmBox>
  );
};
