import { api } from "../config";

export const BannerService = {
  getBanners: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/banners/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getBanner: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/banners/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  createBanner: async (payload) => {
    try {
      const { data } = await api.post("api/admin/banners/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateBanner: async (slug, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/banners/update/${slug}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteBanner: async (payload) => {
    try {
      const { data } = await api.post("api/admin/banners/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
