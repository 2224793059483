import {
  Box,
  Button,
  ListItem,
  ListItemText,
  List,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import { Action, DATE_TIME_FORMAT, Models } from "../../const";
import { StringUtil, UrlUtil } from "../../utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { Authenticate } from "../../hoc/Authenticate";

export const ViewBannerPage = () => {
  const { slug } = useParams();
  const banners = useSelector((state) => state.banner.banners);
  const banner = banners.find((eachBanner) => eachBanner.slug === slug);
  const navigate = useNavigate();

  return (
    <DmBox>
      <Stack direction="row" spacing={5} mb="1rem">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate("/banners")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Authenticate model={Models.BANNER} action={Action.EDIT}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(`/banners/edit/${banner.slug}`)}
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Authenticate>
      </Stack>
      <Typography sx={{ fontWeight: 600, pl: "1.3rem", mb: "1rem" }}>
        Details
      </Typography>
      <Divider />
      <Box pl="1.3rem">
        <Box
          component="img"
          src={
            banner?.image?.path
              ? UrlUtil.getFullImagePath(banner.image.path)
              : "/images/default-thumbnail-image.gif"
          }
          sx={{
            width: "250px",
            height: "250px",
            objectFit: "contain",
            border: "1px solid",
            borderColor: "divider",
            marginTop: "1.3rem",
            padding: ".5rem",
          }}
        />
      </Box>
      <List sx={{ pt: "0px" }}>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Id
            </Typography>
            <Box>
              <Typography variant="value">{banner.id}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Name
            </Typography>
            <Box>
              <Typography variant="value">{banner.name}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Slug
            </Typography>
            <Box>
              <Typography variant="value">{banner.slug}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Enabled
            </Typography>
            <Box>
              <Typography variant="value">
                {StringUtil.getYesOrNo(banner.isActive)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Start Date
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(banner.startDate).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              End Date
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(banner.endDate).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Created
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(banner.createdAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Updated
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(banner.updatedAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </DmBox>
  );
};
