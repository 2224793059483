import { api } from "../config";

export const EmployeeService = {
  login: async (email, password) => {
    try {
      const { data } = await api.post(
        "api/admin/employees/login/",
        JSON.stringify({ email, password })
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  verifyToken: async (token) => {
    try {
      const { data } = await api.post(
        "api/admin/employees/verify-token",
        JSON.stringify({ token })
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getEmployees: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/employees/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getEmployee: async (email) => {
    try {
      const { data } = await api.get(`api/admin/employees/${email}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getEmployeeOptions: async () => {
    try {
      const { data } = await api.get("api/admin/roles/role-options");
      return data;
    } catch (error) {
      throw error;
    }
  },
  createEmployee: async (payload) => {
    try {
      const { data } = await api.post("api/admin/employees/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateEmployee: async (email, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/employees/update/${email}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async (email, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/employees/reset-password/${email}`,
        payload
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateEmployeeProfile: async (email, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/employees/update/profile/${email}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteEmployee: async (payload) => {
    try {
      const { data } = await api.post("api/admin/employees/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateEmployeeNotificationSummarySeen: async (payload) => {
    try {
      const { data } = await api.put(
        "api/admin/employees//update-notification-summary",
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateMarkEmployeeNotificationRead: async (payload) => {
    try {
      const { data } = api.put(
        "api/admin/notifications/mark-as-read",
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getUpdatedEmployee: async (email) => {
    try {
      const { data } = await api.get(
        `api/admin/employees/get-updated-employee/${email}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
