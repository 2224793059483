import React from "react";
import Chart from "react-apexcharts";

export const BarChart = ({
  seriesName,
  title,
  data,
  categories = [],
  height,
}) => {
  const state = {
    series: [
      {
        name: seriesName,
        data: data,
      },
    ],
    options: {
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          vertical: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      title: {
        text: title,
        align: "left",
      },
      xaxis: {
        categories: categories,
      },
    },
  };
  return (
    <Chart
      height={height}
      options={state.options}
      series={state.series}
      type="bar"
    />
  );
};
