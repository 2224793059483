import { api } from "../config";

export const StoreReviewService = {
  getStoreReviews: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/store-reviews/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  toggleStoreReviewsStatus: async (payload) => {
    try {
      const { data } = await api.post(
        "api/admin/store-reviews/toggle-status",
        payload
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteStoreReview: async (payload) => {
    try {
      const { data } = await api.post(
        "api/admin/store-reviews/delete",
        payload
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
