import { Stack, Typography } from "@mui/material";
import React from "react";
import { ResetPasswordForm } from "../forms";

export const ResetPasswordModal = ({ employee, onSuccess = undefined }) => {
  return (
    <Stack alignItems="center" spacing={3} mt="1rem">
      <Typography variant="h5">Reset Password</Typography>
      <ResetPasswordForm
        employee={employee}
        onSuccess={onSuccess}
        isResetPasswordFromProfile={false}
      />
    </Stack>
  );
};
