import { Box } from "@mui/material";
import React from "react";
import { FeaturedSettingForm } from "../forms";

export const DealFeaturedSettingModal = ({ deal, onCancel, onSuccess }) => {
  return (
    <Box mt="1rem" minWidth="450px">
      <FeaturedSettingForm
        deal={deal}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
    </Box>
  );
};
