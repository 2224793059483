import { api } from "../config";

export const DealAlertService = {
  getDealAlerts: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/deal-alerts/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteDealAlert: async (payload) => {
    try {
      const { data } = await api.post("api/admin/deal-alerts/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
