import {
  FETCH_CATEGORY_BRAND_STORE_SUMMARY_FAILURE,
  FETCH_CATEGORY_BRAND_STORE_SUMMARY_REQUEST,
  FETCH_CATEGORY_BRAND_STORE_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAILURE,
  FETCH_SUMMARY_REQUEST,
  FETCH_SUMMARY_SUCCESS,
  FETCH_TOP_CONTRIBUTORS_DASHBOARD_FAILURE,
  FETCH_TOP_CONTRIBUTORS_DASHBOARD_REQUEST,
  FETCH_TOP_CONTRIBUTORS_DASHBOARD_SUCCESS,
  FETCH_TOP_MOST_REPORTED_DEALS_FAILURE,
  FETCH_TOP_MOST_REPORTED_DEALS_REQUEST,
  FETCH_TOP_MOST_REPORTED_DEALS_SUCCESS,
  FETCH_TOP_MOST_SEARCHED_KEYWORDS_FAILURE,
  FETCH_TOP_MOST_SEARCHED_KEYWORDS_REQUEST,
  FETCH_TOP_MOST_SEARCHED_KEYWORDS_SUCCESS,
  FETCH_TOP_MOST_VIEWED_DEALS_FAILURE,
  FETCH_TOP_MOST_VIEWED_DEALS_REQUEST,
  FETCH_TOP_MOST_VIEWED_DEALS_SUCCESS,
  FETCH_MONTHLY_DEALS_SUMMARY_FAILURE,
  FETCH_MONTHLY_DEALS_SUMMARY_REQUEST,
  FETCH_MONTHLY_DEALS_SUMMARY_SUCCESS,
  FETCH_MONTHLY_REPORTS_SUMMARY_FAILURE,
  FETCH_MONTHLY_REPORTS_SUMMARY_REQUEST,
  FETCH_MONTHLY_REPORTS_SUMMARY_SUCCESS,
  FETCH_MONTHLY_USERS_SUMMARY_REQUEST,
  FETCH_MONTHLY_USERS_SUMMARY_SUCCESS,
  FETCH_MONTHLY_USERS_SUMMARY_FAILURE,
  FETCH_ACTIVE_USER_SUMMARY_REQUEST,
  FETCH_ACTIVE_USER_SUMMARY_FAILURE,
  FETCH_ACTIVE_USER_SUMMARY_SUCCESS,
} from "./dashboardActionType";

const initialState = {
  loading: false,
  contributors: [], // table data
  contributorsLoading: false,
  monthlyDealsSummary: [], // chart data
  monthlyUsersSummary: [], // chart data
  monthlyReportsSummary: [], // chart data
  summary: {}, // chart data
  topMostViewedDeals: [], // table data
  topMostViewedDealsLoading: false,
  topMostReportedDeals: [], // table data
  topMostReportedDealsLoading: false,
  topMostSearchedKeywords: [],
  topMostSearchedKeywordsLoading: false,
  categoryBrandStoreSummary: {},
  activeUserSummary: {},
  error: "",
};

export const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MONTHLY_DEALS_SUMMARY_REQUEST:
    case FETCH_MONTHLY_USERS_SUMMARY_REQUEST:
    case FETCH_MONTHLY_REPORTS_SUMMARY_REQUEST:
    case FETCH_SUMMARY_REQUEST:
    case FETCH_CATEGORY_BRAND_STORE_SUMMARY_REQUEST:
    case FETCH_ACTIVE_USER_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TOP_CONTRIBUTORS_DASHBOARD_REQUEST:
      return {
        ...state,
        contributorsLoading: true,
      };
    case FETCH_TOP_MOST_VIEWED_DEALS_REQUEST:
      return {
        ...state,
        topMostViewedDealsLoading: true,
      };
    case FETCH_TOP_MOST_REPORTED_DEALS_REQUEST:
      return {
        ...state,
        topMostReportedDealsLoading: true,
      };
    case FETCH_TOP_MOST_SEARCHED_KEYWORDS_REQUEST:
      return {
        ...state,
        topMostSearchedKeywordsLoading: true,
      };
    // success
    case FETCH_TOP_CONTRIBUTORS_DASHBOARD_SUCCESS:
      return {
        ...state,
        contributorsLoading: false,
        contributors: action.payload,
      };
    case FETCH_MONTHLY_DEALS_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        monthlyDealsSummary: action.payload,
      };
    case FETCH_MONTHLY_USERS_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        monthlyUsersSummary: action.payload,
      };
    case FETCH_MONTHLY_REPORTS_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        monthlyReportsSummary: action.payload,
      };
    case FETCH_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        summary: action.payload,
      };
    case FETCH_TOP_MOST_VIEWED_DEALS_SUCCESS:
      return {
        ...state,
        topMostViewedDealsLoading: false,
        topMostViewedDeals: action.payload,
      };
    case FETCH_TOP_MOST_REPORTED_DEALS_SUCCESS:
      return {
        ...state,
        topMostReportedDealsLoading: false,
        topMostReportedDeals: action.payload,
      };
    case FETCH_TOP_MOST_SEARCHED_KEYWORDS_SUCCESS:
      return {
        ...state,
        topMostSearchedKeywordsLoading: false,
        topMostSearchedKeywords: action.payload,
      };
    case FETCH_CATEGORY_BRAND_STORE_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryBrandStoreSummary: action.payload,
      };
    case FETCH_ACTIVE_USER_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        activeUserSummary: action.payload,
      };

    // failure
    case FETCH_TOP_MOST_VIEWED_DEALS_FAILURE:
      return {
        ...state,
        topMostViewedDealsLoading: false,
        error: action.error,
      };
    case FETCH_TOP_CONTRIBUTORS_DASHBOARD_FAILURE:
      return {
        ...state,
        contributorsLoading: false,
        error: action.error,
      };
    case FETCH_TOP_MOST_REPORTED_DEALS_FAILURE:
      return {
        ...state,
        topMostReportedDealsLoading: false,
        error: action.error,
      };
    case FETCH_TOP_MOST_SEARCHED_KEYWORDS_FAILURE:
      return {
        ...state,
        topMostSearchedKeywordsLoading: false,
        error: action.error,
      };
    case FETCH_MONTHLY_DEALS_SUMMARY_FAILURE:
    case FETCH_MONTHLY_USERS_SUMMARY_FAILURE:
    case FETCH_MONTHLY_REPORTS_SUMMARY_FAILURE:
    case FETCH_SUMMARY_FAILURE:
    case FETCH_CATEGORY_BRAND_STORE_SUMMARY_FAILURE:
    case FETCH_ACTIVE_USER_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
