import {
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
  TOGGLE_COMMENTS_STATUS_FAILURE,
  TOGGLE_COMMENTS_STATUS_REQUEST,
  TOGGLE_COMMENTS_STATUS_SUCCESS,
} from "./commentActionType";

export const fetchCommentsRequest = (offset, searchQuery) => {
  return {
    type: FETCH_COMMENTS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchCommentsSuccess = (data) => {
  return {
    type: FETCH_COMMENTS_SUCCESS,
    payload: data,
  };
};

export const fetchCommentsFailure = (error) => {
  return {
    type: FETCH_COMMENTS_FAILURE,
    error,
  };
};

export const toggleCommentsStatusRequest = (payload, onSuccess) => {
  return {
    type: TOGGLE_COMMENTS_STATUS_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const toggleCommentsStatusSuccess = () => {
  return {
    type: TOGGLE_COMMENTS_STATUS_SUCCESS,
  };
};

export const toggleCommentsStatusFailure = (error) => {
  return {
    type: TOGGLE_COMMENTS_STATUS_FAILURE,
    error,
  };
};

export const deleteCommentRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_COMMENT_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteCommentSuccess = () => {
  return {
    type: DELETE_COMMENT_SUCCESS,
  };
};

export const deleteCommentFailure = (error) => {
  return {
    type: DELETE_COMMENT_FAILURE,
    error,
  };
};
