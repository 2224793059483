import {
  CREATE_RESOURCE_FAILURE,
  CREATE_RESOURCE_REQUEST,
  DELETE_RESOURCE_FAILURE,
  DELETE_RESOURCE_REQUEST,
  FETCH_RESOURCES_FAILURE,
  FETCH_RESOURCES_REQUEST,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCE_FAILURE,
  FETCH_RESOURCE_OPTIONS_FAILURE,
  FETCH_RESOURCE_OPTIONS_REQUEST,
  FETCH_RESOURCE_OPTIONS_SUCCESS,
  FETCH_RESOURCE_REQUEST,
  FETCH_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_FAILURE,
  UPDATE_RESOURCE_REQUEST,
} from "./resourceActionType";

const initialState = {
  loading: false,
  resourceOptionLoading: false,
  resourceOptions: [],
  resources: [],
  resource: {},
  totalResources: 0,
  error: "",
};

export const ResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESOURCES_REQUEST:
    case FETCH_RESOURCE_REQUEST:
    case CREATE_RESOURCE_REQUEST:
    case UPDATE_RESOURCE_REQUEST:
    case DELETE_RESOURCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RESOURCE_OPTIONS_REQUEST:
      return {
        ...state,
        resourceOptionLoading: true,
      };
    case FETCH_RESOURCES_SUCCESS:
      const { resources, totalResources } = action.payload;
      return {
        ...state,
        loading: false,
        resources: resources,
        totalResources: totalResources,
      };
    case FETCH_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        resource: action.payload,
      };
    case FETCH_RESOURCE_OPTIONS_SUCCESS:
      return {
        ...state,
        resourceOptionLoading: false,
        resourceOptions: action.payload,
      };
    case FETCH_RESOURCE_OPTIONS_FAILURE:
      return {
        ...state,
        resourceOptionLoading: false,
      };
    case FETCH_RESOURCES_FAILURE:
    case FETCH_RESOURCE_FAILURE:
    case CREATE_RESOURCE_FAILURE:
    case UPDATE_RESOURCE_FAILURE:
    case DELETE_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
