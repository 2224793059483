import {
  DELETE_REWARD_VALIDATION_LOG_FAILURE,
  DELETE_REWARD_VALIDATION_LOG_REQUEST,
  FETCH_REWARD_VALIDATION_LOGS_FAILURE,
  FETCH_REWARD_VALIDATION_LOGS_REQUEST,
  FETCH_REWARD_VALIDATION_LOGS_SUCCESS,
} from "./rewardValidationLogActionType";

const initialState = {
  loading: false,
  rewardValidationLogs: [],
  totalRewardValidationLogs: 0,
  error: "",
};

export const RewardValidationLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REWARD_VALIDATION_LOGS_REQUEST:
    case DELETE_REWARD_VALIDATION_LOG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REWARD_VALIDATION_LOGS_SUCCESS:
      const { rewardValidationLogs, totalRewardValidationLogs } =
        action.payload;
      return {
        ...state,
        loading: false,
        rewardValidationLogs,
        totalRewardValidationLogs,
      };
    case FETCH_REWARD_VALIDATION_LOGS_FAILURE:
    case DELETE_REWARD_VALIDATION_LOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
