import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { verifyEmployeeTokenRequest } from "../store";
import { loggedEmployeeSelector } from "../store/selector";

export const ProtectedRoute = ({ children, action, model }) => {
  const { token, id } = useSelector(loggedEmployeeSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyEmployeeTokenRequest(token));
  }, [dispatch, token]);

  const isLoggedIn = () => {
    return id !== (null || undefined);
  };

  return isLoggedIn() ? children : <Navigate to="/login" />;
};
