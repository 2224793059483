import { api } from "../config";

export const FacebookPageService = {
  getFacebookPages: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/facebook-pages/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getFacebookPage: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/facebook-pages/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  exportFacebookPages: async (slug) => {
    try {
      const { data } = await api.get("api/admin/facebook-pages/export", {
        responseType: "blob",
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  createFacebookPage: async (payload) => {
    try {
      const { data } = await api.post("api/admin/facebook-pages/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateFacebookPage: async (slug, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/facebook-pages/update/${slug}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteFacebookPage: async (payload) => {
    try {
      const { data } = await api.post(
        "api/admin/facebook-pages/delete",
        payload
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
