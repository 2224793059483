import {
  ListItem,
  ListItemAvatar,
  Avatar,
  Box,
  ListItemText,
  Typography,
} from "@mui/material";
import _ from "lodash";

import { DateUtil, UrlUtil } from "../utils";
import { NotificationActions } from "../const";

export const NotificationItem = ({ notification }) => {
  const getIconPath = (action) => {
    switch (action) {
      case NotificationActions.NEW_DEAL:
        return "/icons/deal.png";
      case NotificationActions.NEW_DEAL_REPORT:
        return "/icons/report.png";
      default:
        return "/icons/reward.png";
    }
  };

  return (
    <ListItem alignItems="flex-start" sx={{ p: "0px 10px" }}>
      <ListItemAvatar sx={{ position: "relative" }}>
        <Avatar
          alt={notification.details.name}
          src={UrlUtil.getFullImagePath(notification.details?.image?.path)}
        >
          {!notification.details?.image?.path &&
            _.upperCase(notification.details.name.substring(0, 2))}
        </Avatar>
        <Box
          component="img"
          alt="notification icon"
          src={getIconPath(notification.action)}
          sx={{
            width: "25px",
            height: "25px",
            position: "absolute",
            right: 8,
            bottom: -5,
          }}
        />
        {!notification.readAt && (
          <Box
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              bgcolor: "#1F75FE",
              position: "absolute",
              left: -18,
              top: 18,
            }}
          />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={
          notification.readAt ? (
            _.startCase(notification.details.name)
          ) : (
            <Typography component="span" sx={{ fontWeight: 600 }}>
              {_.startCase(notification.details.name)}
            </Typography>
          )
        }
        secondary={
          <>
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {notification.details.message}
            </Typography>
            {` — "${notification.details.title}"`}
            <Typography component="span" sx={{ display: "block", mt: ".3rem" }}>
              {DateUtil.compareTodayWithDate(notification.createdAt)}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};
