import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DmBox } from "../../components";
import { BrandForm } from "../../components/forms";
import { fetchBrandRequest } from "../../store";

export const AddBrandPage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const brandState = useSelector((state) => state.brand);

  useEffect(() => {
    if (slug) dispatch(fetchBrandRequest(slug));
  }, [dispatch, slug]);

  return (
    <DmBox>
      {brandState.loading ? (
        <CircularProgress />
      ) : (
        <BrandForm brand={slug && brandState.brand} />
      )}
    </DmBox>
  );
};
