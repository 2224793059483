import { api } from "../config";

export const RewardValidationLogService = {
  getRewardValidationLogs: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/reward-validation-logs/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteRewardValidationLog: async (payload) => {
    try {
      const { data } = await api.post(
        "api/admin/reward-validation-logs",
        payload
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
