import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  ListItem,
  ListItemText,
  List,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../const";
import { UrlUtil } from "../../utils";

export const ViewNotificationPage = () => {
  const { id } = useParams();
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const notification = notifications.find(
    (notification) => notification.id === id
  );
  const navigate = useNavigate();
  return (
    <DmBox>
      <Box mb="1rem">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate("/notifications")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </Box>
      <Typography sx={{ fontWeight: 600, pl: "1.3rem", mb: "1rem" }}>
        Details
      </Typography>
      <Divider />
      <Box pl="1.3rem">
        <Box
          component="img"
          src={
            notification.details?.image?.path
              ? UrlUtil.getFullImagePath(notification.details?.image.path)
              : "/images/default-thumbnail-image.gif"
          }
          sx={{
            width: "250px",
            height: "250px",
            objectFit: "contain",
            border: "1px solid",
            borderColor: "divider",
            marginTop: "1.3rem",
            padding: ".5rem",
          }}
        />
      </Box>
      <List sx={{ pt: "0px" }}>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Id
            </Typography>
            <Box>
              <Typography variant="value">{notification.id}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              User Name
            </Typography>
            <Box>
              <Typography variant="value">
                {notification.details.name}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Title
            </Typography>
            <Box>
              <Typography variant="value">
                {notification.details.title}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Message
            </Typography>
            <Box>
              <Typography variant="value">
                {notification.details.message}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Deal Slug
            </Typography>
            <Box>
              <Typography variant="value">
                {notification.details?.dealSlug}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Category Slug
            </Typography>
            <Box>
              <Typography variant="value">
                {notification.details?.categorySlug}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Action
            </Typography>
            <Box>
              <Typography variant="value">{notification.action}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Is Seen
            </Typography>
            <Box>
              <Typography variant="value">
                {notification.readAt
                  ? moment(notification.readAt).format(DATE_TIME_FORMAT)
                  : "Not yet"}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Type
            </Typography>
            <Box>
              <Typography variant="value">
                {notification.notificationType}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Model Type
            </Typography>
            <Box>
              <Typography variant="value">{notification.modelType}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Created
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(notification.createdAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Updated
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(notification.updatedAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </DmBox>
  );
};
