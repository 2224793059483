import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import { RewardService } from "../../services";
import { toast } from "react-toastify";

import {
  createRewardFailure,
  createRewardSuccess,
  deleteRewardFailure,
  deleteRewardSuccess,
  fetchRewardFailure,
  fetchRewardSuccess,
  fetchRewardsFailure,
  fetchRewardsSuccess,
  updateRewardFailure,
  updateRewardSuccess,
} from "./rewardAction";
import {
  CREATE_REWARD_REQUEST,
  DELETE_REWARD_REQUEST,
  FETCH_REWARDS_REQUEST,
  FETCH_REWARD_REQUEST,
  UPDATE_REWARD_REQUEST,
} from "./rewardActionType";

function* getRewardsSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      RewardService.getRewards,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchRewardsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchRewardsFailure(error));
  }
}

function* getRewardSaga(action) {
  try {
    const { data } = yield call(RewardService.getReward, action.slug);
    yield put(fetchRewardSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchRewardFailure(error));
  }
}

function* createRewardSaga(action) {
  try {
    const {
      meta: { onSuccess },
    } = action;
    const { data } = yield call(RewardService.createReward, action.payload);
    yield put(createRewardSuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createRewardFailure(error));
  }
}
function* updateRewardSaga(action) {
  try {
    const {
      slug,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(RewardService.updateReward, slug, payload);
    yield put(updateRewardSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateRewardFailure(error));
  }
}

function* deleteRewardSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(RewardService.deleteReward, payload);
    yield put(deleteRewardSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteRewardFailure(error));
  }
}

export function* rewardWatcherSaga() {
  yield takeEvery(FETCH_REWARDS_REQUEST, getRewardsSaga);
  yield takeEvery(FETCH_REWARD_REQUEST, getRewardSaga);
  yield takeEvery(CREATE_REWARD_REQUEST, createRewardSaga);
  yield takeEvery(UPDATE_REWARD_REQUEST, updateRewardSaga);
  yield takeEvery(DELETE_REWARD_REQUEST, deleteRewardSaga);
}
