import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { loggedEmployeeSelector } from "../store/selector";
import { StringUtil } from "../utils";

export const Authenticate = ({ children, model, action }) => {
  const { roles } = useSelector(loggedEmployeeSelector);
  const location = useLocation();
  const paths = location.pathname.split("/");
  const { email } = useParams();
  const { employees } = useSelector((state) => state.employee);

  const isAuthenticated = () => {
    for (let eachRole of roles) {
      if (eachRole.isSuperAdmin) return true;
      for (let eachResource of eachRole.resources) {
        if (
          eachResource.canPerformAction === action &&
          eachResource.name.toLowerCase() === model
        )
          return true;
      }
    }
    return false;
  };

  const isLoggedEmployeeTryingToSeeAdminDetails = () => {
    if (
      paths.includes("employees") &&
      (paths.includes("edit") || paths.includes("details"))
    ) {
      if (StringUtil.isEmployeeSuperAdmin(roles)) return false; // logged user is super admin
      const givenEmployee = _.find(
        employees,
        (employee) => employee.email === email
      );
      if (StringUtil.isEmployeeSuperAdmin(givenEmployee?.roles)) return true;
    }
    return false;
  };

  return isAuthenticated() && !isLoggedEmployeeTryingToSeeAdminDetails() ? (
    children
  ) : (
    <></>
  );
};
