import {
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
  EMPLOYEE_LOGIN_FAILURE,
  EMPLOYEE_LOGIN_REQUEST,
  EMPLOYEE_LOGIN_SUCCESS,
  EMPLOYEE_LOGOUT_REQUEST,
  FETCH_EMPLOYEES_FAILURE,
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEE_OPTIONS_FAILURE,
  FETCH_EMPLOYEE_OPTIONS_REQUEST,
  FETCH_EMPLOYEE_OPTIONS_SUCCESS,
  FETCH_EMPLOYEE_REQUEST,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_UPDATED_EMPLOYEE_FAILURE,
  FETCH_UPDATED_EMPLOYEE_REQUEST,
  FETCH_UPDATED_EMPLOYEE_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  UPDATE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_FAILURE,
  UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_REQUEST,
  UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_SUCCESS,
  UPDATE_EMPLOYEE_PROFILE_FAILURE,
  UPDATE_EMPLOYEE_PROFILE_REQUEST,
  UPDATE_EMPLOYEE_PROFILE_SUCCESS,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_FAIlURE,
  UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_REQUEST,
  UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_SUCCESS,
  VERIFY_EMPLOYEE_TOKEN_REQUEST,
} from "./employeeActionType";

export const employeeLoginRequest = (payload, onSuccessLoginHandler) => {
  return {
    type: EMPLOYEE_LOGIN_REQUEST,
    payload,
    meta: {
      onSuccessHandler: onSuccessLoginHandler,
    },
  };
};
export const employeeLoginSuccess = (payload) => {
  return {
    type: EMPLOYEE_LOGIN_SUCCESS,
    payload,
  };
};
export const employeeLoginFailure = (error) => {
  return {
    type: EMPLOYEE_LOGIN_FAILURE,
    error,
  };
};

export const employeeLogOutRequest = () => {
  return {
    type: EMPLOYEE_LOGOUT_REQUEST,
  };
};

export const verifyEmployeeTokenRequest = (token) => {
  return {
    type: VERIFY_EMPLOYEE_TOKEN_REQUEST,
    payload: token,
  };
};

export const fetchEmployeesRequest = (offset, searchQuery) => {
  return {
    type: FETCH_EMPLOYEES_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchEmployeesSuccess = (data) => {
  return {
    type: FETCH_EMPLOYEES_SUCCESS,
    payload: data,
  };
};

export const fetchEmployeesFailure = (error) => {
  return {
    type: FETCH_EMPLOYEES_FAILURE,
    error,
  };
};

export const fetchEmployeeRequest = (email) => {
  return {
    type: FETCH_EMPLOYEE_REQUEST,
    email,
  };
};
export const fetchEmployeeSuccess = (data) => {
  return {
    type: FETCH_EMPLOYEE_SUCCESS,
    payload: data,
  };
};
export const fetchEmployeeFailure = (error) => {
  return {
    type: FETCH_EMPLOYEE_FAILURE,
    error,
  };
};

export const fetchEmployeeOptionsRequest = () => {
  return {
    type: FETCH_EMPLOYEE_OPTIONS_REQUEST,
  };
};

export const fetchEmployeeOptionsSuccess = (data) => {
  return {
    type: FETCH_EMPLOYEE_OPTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchEmployeeOptionsFailure = (error) => {
  return {
    type: FETCH_EMPLOYEE_OPTIONS_FAILURE,
    error,
  };
};

export const createEmployeeRequest = (payload, onSuccess) => {
  return {
    type: CREATE_EMPLOYEE_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};
export const createEmployeeSuccess = (data) => {
  return {
    type: CREATE_EMPLOYEE_SUCCESS,
    payload: data,
  };
};
export const createEmployeeFailure = (error) => {
  return {
    type: CREATE_EMPLOYEE_FAILURE,
    error,
  };
};

export const updateEmployeeRequest = (email, payload, onSuccess) => {
  return {
    type: UPDATE_EMPLOYEE_REQUEST,
    email,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateEmployeeSuccess = () => {
  return {
    type: UPDATE_EMPLOYEE_SUCCESS,
  };
};

export const updateEmployeeFailure = (error) => {
  return {
    type: UPDATE_EMPLOYEE_FAILURE,
    error,
  };
};
export const updateEmployeeProfileRequest = (email, payload, onSuccess) => {
  return {
    type: UPDATE_EMPLOYEE_PROFILE_REQUEST,
    email,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateEmployeeProfileSuccess = (data) => {
  return {
    type: UPDATE_EMPLOYEE_PROFILE_SUCCESS,
    payload: data,
  };
};

export const updateEmployeeProfileFailure = (error) => {
  return {
    type: UPDATE_EMPLOYEE_PROFILE_FAILURE,
    error,
  };
};

export const deleteEmployeeRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_EMPLOYEE_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteEmployeeSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_SUCCESS,
  };
};

export const deleteEmployeeFailure = (error) => {
  return {
    type: DELETE_EMPLOYEE_FAILURE,
    error,
  };
};

export const resetPasswordRequest = (email, payload) => {
  return {
    type: RESET_PASSWORD_REQUEST,
    email,
    payload,
  };
};

export const resetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
};

export const resetPasswordFailure = () => {
  return {
    type: RESET_PASSWORD_FAILURE,
  };
};

export const updateEmployeeNotificationSummarySeenRequest = (
  payload,
  onSuccess
) => {
  return {
    type: UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};
export const updateEmployeeNotificationSummarySeenSuccess = (data) => {
  return {
    type: UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_SUCCESS,
    payload: data,
  };
};
export const updateEmployeeNotificationSummarySeenFailure = (error) => {
  return {
    type: UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_FAILURE,
    error,
  };
};

export const fetchUpdatedEmployeeRequest = (email) => {
  return {
    type: FETCH_UPDATED_EMPLOYEE_REQUEST,
    email,
  };
};

export const fetchUpdatedEmployeeSuccess = (data) => {
  return {
    type: FETCH_UPDATED_EMPLOYEE_SUCCESS,
    payload: data,
  };
};

export const fetchUpdatedEmployeeFailure = (error) => {
  return {
    type: FETCH_UPDATED_EMPLOYEE_FAILURE,
    error,
  };
};

export const updateMarkEmployeeNotificationReadRequest = (payload) => {
  return {
    type: UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_REQUEST,
    payload,
  };
};

export const updateMarkEmployeeNotificationReadSuccess = (data) => {
  return {
    type: UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_SUCCESS,
    payload: data,
  };
};

export const updateMarkEmployeeNotificationReadFailure = (error) => {
  return {
    type: UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_FAIlURE,
    error,
  };
};
