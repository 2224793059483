import { Box, Button, Tab, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  CountryView,
  RewardRuleView,
  RewardView,
} from "../../components/views";
import { Action, Models } from "../../const";
import { Authenticate } from "../../hoc";

export const ViewRewardPage = () => {
  const { slug } = useParams();
  const rewards = useSelector((state) => state.reward.rewards);
  const reward = rewards.find((eachDeal) => eachDeal.slug === slug);
  const { rewardRules, country } = reward;
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const TabValue = {
    DETAILS: "1",
    REWARD_RULE: "2",
    COUNTRY: "3",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DmBox>
      <Stack direction="row" spacing={5} mb="1rem">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate("/rewards")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Authenticate model={Models.REWARD} action={Action.EDIT}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(`/rewards/edit/${reward.slug}`)}
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Authenticate>
      </Stack>
      <TabContext value={value} width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Details" value={TabValue.DETAILS} />
            <Tab label="Reward Rule" value={TabValue.REWARD_RULE} />
            <Tab label="Country" value={TabValue.COUNTRY} />
          </TabList>
        </Box>
        <TabPanel value={TabValue.DETAILS} sx={{ p: "1rem 0px" }}>
          <RewardView reward={reward} />
        </TabPanel>
        <TabPanel value={TabValue.REWARD_RULE} sx={{ p: "1rem 0px" }}>
          {rewardRules.map((rewardRule, index) => (
            <RewardRuleView
              key={rewardRule.id}
              rewardRule={rewardRule}
              title={`#${index + 1} ${rewardRule.title} Details`}
            />
          ))}
        </TabPanel>
        <TabPanel value={TabValue.COUNTRY} sx={{ p: "1rem 0px" }}>
          <CountryView country={country} title="Country Details" />
        </TabPanel>
      </TabContext>
    </DmBox>
  );
};
