import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Select,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import {
  ColumnOption,
  ColumnTitle,
  DmBox,
  DmTable,
  StatusChip,
} from "../../components";
import { searchQuerySelector } from "../../store/selector";
import { useNavigate } from "react-router-dom";
import {
  Action,
  CanPerformAction,
  DATE_TIME_FORMAT,
  Models,
} from "../../const";
import {
  ConfirmModal,
  DealFeaturedSettingModal,
  DmModal,
} from "../../components/Modal";
import { useToggle } from "../../hooks";
import {
  deleteDealRequest,
  fetchDealsRequest,
  toggleDealsFeaturedStatusRequest,
  toggleDealsStatusRequest,
} from "../../store";
import moment from "moment";
import _ from "lodash";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { Authenticate } from "../../hoc/Authenticate";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

export const DealListPage = () => {
  const dealState = useSelector((state) => state.deal);
  const searchQuery = useSelector(searchQuerySelector);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRowId, setActiveRowId] = useState(); // this is used only for hide and show ... options
  const [offset, setOffset] = useState({ limit: 10, skip: 0 });
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [openModal, setOpenModal] = useToggle();
  const [openFeaturedSettingModal, setFeaturedSettingModal] = useToggle();
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [featuredFilter, setFeaturedFilter] = React.useState();
  const [statusFilter, setStatusFilter] = React.useState();
  const [isOptionClick, setIsOptionClick] = useState({
    // to check which option was clicked
    enable: false,
    disable: false,
    featured: false,
    unFeatured: false,
    featuredSetting: false,
    delete: false,
  });

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onChangeStatusHandler = (status) => {
    setStatusFilter(status);
  };

  const onChangeFeaturedHandler = (isFeatured) => {
    setFeaturedFilter(isFeatured);
  };

  const onClickMenuItem = (action, row) => {
    switch (action) {
      case Action.TOGGLE:
        const disable = row.isActive && true; // meaning user want to disable
        const enable = !disable;
        setIsOptionClick(() => ({
          featured: false,
          unFeatured: false,
          featuredSetting: false,
          delete: false,
          enable,
          disable,
        }));
        setSelectedRows([row]);
        setOpenModal();
        break;
      case Action.DELETE:
        setIsOptionClick(() => ({
          enable: false,
          disable: false,
          featured: false,
          unFeatured: false,
          featuredSetting: false,
          delete: true,
        }));
        setSelectedRows([row]);
        setOpenModal();
        break;
      case Action.FEATURED_SETTING_ACTION:
        setIsOptionClick((prv) => ({
          enable: false,
          disable: false,
          featured: false,
          unFeatured: false,
          featuredSetting: true,
          delete: false,
        }));
        setSelectedRows([row]);
        setFeaturedSettingModal();
        break;
      case Action.VIEW:
        navigate(`/deals/details/${row?.slug}`);
        break;
      default: // meaning user want to unFeatured
        const unFeatured = row.isFeatured && true;
        const featured = !unFeatured;
        setIsOptionClick((prev) => ({
          enable: false,
          disable: false,
          featured,
          unFeatured,
          featuredSetting: false,
          delete: false,
        }));
        setSelectedRows([row]);
        setOpenModal();
        break;
    }
  };

  const onMouseEnterRowHandler = (row) => {
    setActiveRowId(row.id);
  };

  const onMouseLeaveRowHandler = () => {
    setAnchorEl(null);
    setActiveRowId(null);
  };

  useEffect(() => {
    dispatch(
      fetchDealsRequest(
        offset,
        searchQuery,
        statusFilter ?? "",
        featuredFilter ?? ""
      )
    );
  }, [dispatch, offset, searchQuery, statusFilter, featuredFilter]);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    const skip = getSkip(currentPage);
    setOffset({ limit: currentRowsPerPage, skip: skip });
  };

  const onChangePage = (page) => {
    const skip = getSkip(page);
    setOffset({ ...offset, skip });
  };

  const onDelete = () => {
    setIsOptionClick((prev) => ({
      ...prev,
      delete: true,
    }));
    setOpenModal();
  };

  const reInitializeOptionClick = () => {
    setIsOptionClick({
      disable: false,
      enable: false,
      delete: false,
      featured: false,
      unFeatured: false,
      featuredSetting: false,
    }); // re-initialize
  };

  const onDeleteConfirm = () => {
    const dealIds = _.map(selectedRows, (row) => {
      return {
        id: row.id,
        user_id: row.user.id,
      };
    });
    dispatch(deleteDealRequest({ dealIds }, onSuccess));
    reInitializeOptionClick();
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const onSuccess = () => {
    setOffset({ limit: offset.limit, skip: offset.skip });
  };

  const onToggleDealStatus = (reviewMessage, sendOnlyNotification) => {
    dispatch(
      toggleDealsStatusRequest(
        {
          dealIds: _.map(selectedRows, "id"),
          isActive: isOptionClick.enable ?? isOptionClick.disable,
          reviewMessage,
          sendOnlyNotification,
        },
        onSuccess
      )
    );
    reInitializeOptionClick();
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const onToggleFeaturedDealStatus = () => {
    dispatch(
      toggleDealsFeaturedStatusRequest(
        {
          dealIds: _.map(selectedRows, "id"),
          isFeatured: isOptionClick.featured ?? isOptionClick.unFeatured,
        },
        onSuccess
      )
    );
    reInitializeOptionClick();
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const getConfirmModalTitle = () => {
    if (isOptionClick.delete) {
      return selectedRows.length > 1
        ? `Are you sure want to delete ${selectedRows.length} deals ?`
        : "Are you sure want to delete this deal ?";
    }
    if (!isOptionClick.enable && !isOptionClick.disable) {
      return selectedRows.length > 1
        ? `Are you sure want to ${
            isOptionClick.unFeatured ? "un-featured" : "featured"
          } ${selectedRows.length} deals ?`
        : `Are you sure want to ${
            isOptionClick.unFeatured ? "un-featured" : "featured"
          } this deal ?`;
    }
    return selectedRows.length > 1
      ? `Are you sure want to ${isOptionClick.disable ? "disable" : "enable"} ${
          selectedRows.length
        } deals ?`
      : `Are you sure want to  ${
          isOptionClick.disable ? "disable" : "enable"
        } this deal ?`;
  };

  const getConfirmButtonText = () => {
    if (isOptionClick.delete) return "Delete";
    if (!isOptionClick.enable && !isOptionClick.disable)
      return isOptionClick.unFeatured ? "Un-featured" : "featured";

    return isOptionClick.disable ? "Disable" : "Enable";
  };

  const onConfirm = (reviewMessage, sendOnlyNotification) => {
    if (isOptionClick.delete) return onDeleteConfirm();
    if (!isOptionClick.enable && !isOptionClick.disable)
      return onToggleFeaturedDealStatus();
    return onToggleDealStatus(reviewMessage, sendOnlyNotification);
  };

  const getSkip = (page) => {
    const skip = page - 1 > 0 ? page - 1 : 0;
    const calculateSkip = offset.limit * skip;
    return calculateSkip;
  };
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const columns = [
    {
      name: <ColumnTitle title={"Id"} />,
      selector: (row) => row.id,
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Title"} />,
      selector: (row) => (
        <Button
          variant="link"
          onClick={() => navigate(`/deals/details/${row?.slug}`)}
        >
          {row.title}
        </Button>
      ),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Price"} />,
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Regular Price"} />,
      selector: (row) => row.regularPrice,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Type"} />,
      selector: (row) => row.dealType,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Category"} />,
      selector: (row) => row?.category?.displayName,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Country"} />,
      selector: (row) => row?.country?.name,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Brand"} />,
      selector: (row) => row?.brand?.displayName,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Store"} />,
      selector: (row) => row?.dealStore?.displayName,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"User"} />,
      selector: (row) => row?.user?.name,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Views"} />,
      selector: (row) => row.views,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Likes"} />,
      selector: (row) => row.totalLikes,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Dislikes"} />,
      selector: (row) => row.totalDislikes,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Enabled"} />,
      selector: (row) => <StatusChip status={row.isActive} />,
    },
    {
      name: <ColumnTitle title={"Featured"} />,
      selector: (row) => <StatusChip status={row.isFeatured} />,
    },
    {
      name: <ColumnTitle title={"Expired"} />,
      selector: (row) => moment(row.expiredDate).format(DATE_TIME_FORMAT),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Duration In Days"} />,
      selector: (row) =>
        row.featuredStartDate &&
        moment(row.featuredEndDate).diff(moment(row.featuredStartDate), "days"),
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Featured Start Date"} />,
      selector: (row) =>
        row.featuredStartDate &&
        moment(row.featuredStartDate).format(DATE_TIME_FORMAT),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Featured End Date"} />,
      selector: (row) =>
        row.featuredStartDate &&
        moment(row.featuredEndDate).format(DATE_TIME_FORMAT),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Created"} />,
      selector: (row) => moment(row.createdAt).format(DATE_TIME_FORMAT),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Updated"} />,
      selector: (row) => moment(row.updatedAt).format(DATE_TIME_FORMAT),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={""} />,
      cell: (row) => (
        <>
          {activeRowId === row.id && (
            <ColumnOption
              row={row}
              open={open}
              anchorEl={anchorEl}
              handleClick={handleOptionClick}
              handleClose={handleOptionClose}
            >
              <Authenticate
                model={Models.DEAL}
                action={CanPerformAction.CAN_EDIT}
              >
                <MenuItem onClick={() => onClickMenuItem(Action.TOGGLE, row)}>
                  <ListItemIcon>
                    {row.isActive ? <ToggleOffIcon /> : <ToggleOnIcon />}
                  </ListItemIcon>
                  <ListItemText>
                    {row.isActive ? "Disable" : "Enable"}
                  </ListItemText>
                </MenuItem>
              </Authenticate>
              <Authenticate
                model={Models.DEAL}
                action={CanPerformAction.CAN_EDIT}
              >
                <MenuItem onClick={() => onClickMenuItem(null, row)}>
                  <ListItemIcon>
                    {row.isFeatured ? <ToggleOffIcon /> : <ToggleOnIcon />}
                  </ListItemIcon>
                  <ListItemText>
                    {row.isFeatured ? "UnFeatured" : "Featured"}
                  </ListItemText>
                </MenuItem>
              </Authenticate>
              <Authenticate
                model={Models.DEAL}
                action={CanPerformAction.CAN_EDIT}
              >
                <MenuItem
                  onClick={() =>
                    onClickMenuItem(Action.FEATURED_SETTING_ACTION, row)
                  }
                >
                  <ListItemIcon>
                    <SettingsSuggestIcon />
                  </ListItemIcon>
                  <ListItemText>Featured Setting</ListItemText>
                </MenuItem>
              </Authenticate>
              <Authenticate
                model={Models.DEAL}
                action={CanPerformAction.CAN_DELETE}
              >
                <MenuItem onClick={() => onClickMenuItem(Action.DELETE, row)}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </Authenticate>
              <MenuItem onClick={() => onClickMenuItem(Action.VIEW, row)}>
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText>View</ListItemText>
              </MenuItem>
            </ColumnOption>
          )}
        </>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => moment(row?.expiredDate).isBefore(new Date(), "day"),
      style: (row) => ({
        backgroundColor: "#E05851",
        color: "#F9F9F9",
      }),
    },
  ];

  return (
    <DmBox>
      <DmTable
        columns={columns}
        data={dealState.deals}
        title={"Deal List"}
        pending={dealState.loading}
        totalRow={dealState.totalDeals}
        onRowMouseEnter={onMouseEnterRowHandler}
        onRowMouseLeave={onMouseLeaveRowHandler}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        conditionalRowStyles={conditionalRowStyles}
        actions={
          <Stack direction="row" spacing={2} sx={{ width: "300px" }}>
            <FormControl fullWidth size="small">
              <InputLabel>Status</InputLabel>
              <Select
                value={featuredFilter}
                label="Status"
                onChange={(e) => onChangeStatusHandler(e.target.value)}
                sx={{ fontSize: ".9rem" }}
              >
                <MenuItem value={""} sx={{ fontSize: ".9rem" }}>
                  Select
                </MenuItem>
                <MenuItem value={true} sx={{ fontSize: ".9rem" }}>
                  Active
                </MenuItem>
                <MenuItem value={false} sx={{ fontSize: ".9rem" }}>
                  Inactive
                </MenuItem>
                <MenuItem value={"expired"} sx={{ fontSize: ".9rem" }}>
                  Expired
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel>Featured</InputLabel>
              <Select
                value={featuredFilter}
                label="Featured"
                onChange={(e) => onChangeFeaturedHandler(e.target.value)}
                sx={{ fontSize: ".9rem" }}
              >
                <MenuItem value={""} sx={{ fontSize: ".9rem" }}>
                  Select
                </MenuItem>
                <MenuItem value={true} sx={{ fontSize: ".9rem" }}>
                  Featured
                </MenuItem>
                <MenuItem value={false} sx={{ fontSize: ".9rem" }}>
                  Not Featured
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        }
        contextActions={
          <Stack direction="row" spacing={2}>
            <Authenticate
              model={Models.DEAL}
              action={CanPerformAction.CAN_DELETE}
            >
              <Button
                onClick={onDelete}
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "red",
                  },
                }}
              >
                Delete
              </Button>
            </Authenticate>
            <Authenticate
              model={Models.DEAL}
              action={CanPerformAction.CAN_EDIT}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setIsOptionClick({
                    enable: true,
                    disable: false,
                    delete: false,
                    featured: false,
                    unFeatured: false,
                    featuredSetting: false,
                  });
                  setOpenModal();
                }}
              >
                Enable
              </Button>
            </Authenticate>
            <Authenticate
              model={Models.DEAL}
              action={CanPerformAction.CAN_EDIT}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setIsOptionClick({
                    disable: true,
                    enable: false,
                    delete: false,
                    featured: false,
                    unFeatured: false,
                    featuredSetting: false,
                  });
                  setOpenModal();
                }}
              >
                Disable
              </Button>
            </Authenticate>
            <Authenticate
              model={Models.DEAL}
              action={CanPerformAction.CAN_EDIT}
            >
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  setIsOptionClick({
                    disable: false,
                    enable: false,
                    delete: false,
                    featured: true,
                    unFeatured: false,
                    featuredSetting: false,
                  });
                  setOpenModal();
                }}
              >
                Featured
              </Button>
            </Authenticate>
            <Authenticate
              model={Models.DEAL}
              action={CanPerformAction.CAN_EDIT}
            >
              <Button
                variant="outlined"
                color="warning"
                onClick={() => {
                  setIsOptionClick({
                    disable: false,
                    enable: false,
                    delete: false,
                    featured: false,
                    unFeatured: true,
                    featuredSetting: false,
                  });
                  setOpenModal();
                }}
              >
                Un-featured
              </Button>
            </Authenticate>
          </Stack>
        }
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
      <DmModal open={openModal} closeModal={setOpenModal}>
        <ConfirmModal
          title={getConfirmModalTitle()}
          subTitle={isOptionClick.delete && "This action cannot be undone"}
          btnConfirmText={getConfirmButtonText()}
          btnConfirmStyles={
            isOptionClick.enable || isOptionClick.featured
              ? {
                  bgcolor: (theme) => theme.palette.status.activeBg,
                  color: (theme) => theme.palette.status.activeColor,
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.status.activeBg,
                  },
                }
              : undefined
          }
          btnCancelText="Cancel"
          onCancel={setOpenModal}
          onConfirm={onConfirm}
          isEnableOrDisableOption={
            isOptionClick.enable || isOptionClick.disable
          }
        />
      </DmModal>
      <DmModal
        open={openFeaturedSettingModal}
        closeModal={setFeaturedSettingModal}
      >
        <DealFeaturedSettingModal
          deal={selectedRows[0]}
          onCancel={setFeaturedSettingModal}
          onSuccess={onSuccess}
        />
      </DmModal>
    </DmBox>
  );
};
