import React, { useState } from "react";
import { Stack, Button, Typography } from "@mui/material";
import moment from "moment/moment";
import { Form, Field } from "react-final-form";
import { InputDate } from "../Input";
import { ValidationUtil } from "../../utils";
import { updateDealFeaturedDateRequest } from "../../store";
import { useDispatch } from "react-redux";

export const FeaturedSettingForm = ({
  deal,
  onCancel,
  onSuccess: onSuccessCallBack,
}) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    deal?.featuredStartDate ? moment(deal.featuredStartDate) : null
  );
  const [endDate, setEndDate] = useState(
    deal?.featuredEndDate ? moment(deal.featuredEndDate) : null
  );

  const onSubmit = async (values) => {
    const { featured_start_date, featured_end_date } = values;
    const payload = {
      featured_start_date,
      featured_end_date,
    };
    dispatch(updateDealFeaturedDateRequest(deal.id, payload, onSuccess));
  };

  const onSuccess = () => {
    onCancel();
    onSuccessCallBack();
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, errors }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            if (Object.keys(errors).length === 0) form.reset();
          }}
          noValidate
          autoComplete="off"
        >
          <Stack alignItems="center">
            <Typography variant="h5">Featured Setting</Typography>
            <Typography sx={{ my: "1rem" }}>{deal.title}</Typography>
            <Stack spacing={3} maxWidth="750px" minWidth="500px" width="60%">
              <Field
                name="featured_start_date"
                value={startDate}
                inputFormat="dd-MM-yyyy"
                defaultValue={startDate}
                disablePast
                component={InputDate}
                onChange={(newValue) => setStartDate(newValue)}
                label="Start Date"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="featured_end_date"
                value={endDate}
                defaultValue={endDate}
                disablePast
                inputFormat="dd-MM-yyyy"
                component={InputDate}
                onChange={(newValue) => setEndDate(newValue)}
                label="End Date"
                validate={ValidationUtil.requiredValidator}
              />
              <Stack direction="row" spacing={2} mt={"30px"}>
                <Button
                  sx={{
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                  size="large"
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "#03a9f4",
                    "&:hover": {
                      backgroundColor: "#0288d1",
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  onClick={onCancel}
                  size="large"
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
