import {
  Stack,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, InputSwitch } from "../";
import {
  createRoleRequest,
  fetchRoleOptionsRequest,
  updateRoleRequest,
} from "../../store";
import { ArrayUtil, ValidationUtil } from "../../utils";
import { AutoCompleteMultiSelectCheckBox } from "../Input";
export const RoleForm = ({ role }) => {
  const [isActive, setIsActive] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(
    role?.id && role.isSuperAdmin ? true : false
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);
  const roleOptions = roleState.roleOptions;
  const [resourceOptions, setResourceOptions] = useState([]);

  // form state
  const [selectedOptions, setSelectedOptions] = useState(
    role?.resources ? ArrayUtil.getOptions(role.resources) : []
  );

  const onSubmit = async (values) => {
    const {
      name,
      displayName,
      resources,
      description,
      isSuperAdmin,
      isActive,
    } = values;
    let selectedResources = [];
    for (let resource of resources) {
      selectedResources.push(resource.value);
    }
    const payload = {
      name,
      display_name: displayName,
      resources: selectedResources,
      description,
      is_super_admin: isSuperAdmin ?? false,
      is_active: isActive ?? false,
    };
    role?.id
      ? dispatch(updateRoleRequest(role.slug, payload, onSuccess))
      : dispatch(createRoleRequest(payload, onSuccess));
  };

  const onSuccess = () => {
    navigate("/roles");
  };

  const initialValues = role
    ? {
        name: role?.name,
        displayName: role?.displayName,
        description: role?.description,
        isSuperAdmin: role?.isSuperAdmin,
        isActive: role?.isActive,
      }
    : {};

  useEffect(() => {
    dispatch(fetchRoleOptionsRequest());
  }, [dispatch]);

  useEffect(() => {
    const options = ArrayUtil.getOptions(roleOptions);
    setResourceOptions(options);
  }, [roleOptions]);

  return (
    <>
      {roleState.roleOptionsLoading ? (
        <CircularProgress />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={(event) => {
                handleSubmit(event);
              }}
              noValidate
              autoComplete="off"
            >
              <Stack alignItems="center">
                <Typography variant="h4">
                  {role ? `Edit ${role.name}` : "Add new role"}
                </Typography>
                <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
                  <Field
                    name="name"
                    component={Input}
                    label="Name"
                    validate={ValidationUtil.requiredValidator}
                  />
                  <Field
                    name="displayName"
                    component={Input}
                    label="Display Name"
                    validate={ValidationUtil.requiredValidator}
                  />
                  {!isSuperAdmin && (
                    <Field
                      name="resources"
                      component={AutoCompleteMultiSelectCheckBox}
                      label="Resources"
                      options={resourceOptions}
                      handleOnChange={(value) => setSelectedOptions(value)}
                      value={selectedOptions}
                      defaultValue={selectedOptions}
                      validate={ValidationUtil.requiredValidator}
                    />
                  )}
                  <Field
                    name="description"
                    component={Input}
                    label="Description"
                    type="textarea"
                    multiline
                    inputProps={{
                      sx: {
                        minHeight: "150px",
                      },
                    }}
                  />
                  <Box>
                    <Field
                      name="isSuperAdmin"
                      checked={isSuperAdmin}
                      component={InputSwitch}
                      handleOnChange={(value) => setIsSuperAdmin(value)}
                      type="checkbox"
                      label="Is Super Admin"
                      labelPlacement="start"
                      sx={{ ml: "0px" }}
                    />
                  </Box>
                  <Box>
                    <Field
                      name="isActive"
                      checked={isActive}
                      component={InputSwitch}
                      handleOnChange={(value) => setIsActive(value)}
                      type="checkbox"
                      label="Is Active"
                      labelPlacement="start"
                      sx={{ ml: "0px" }}
                    />
                  </Box>
                  <Stack direction="row" spacing={2} mt={"30px"}>
                    <Button
                      sx={{
                        width: "20%",
                        boxShadow: "none",
                        "&:hover": {
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                    <Button
                      sx={{
                        width: "20%",
                        boxShadow: "none",
                        backgroundColor: "#03a9f4",
                        "&:hover": {
                          backgroundColor: "#0288d1",
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      onClick={() => navigate("/roles")}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          )}
        />
      )}
    </>
  );
};
