import { call, put, takeEvery } from "redux-saga/effects";
import { NotificationService } from "../../services";
import { toast } from "react-toastify";
import { NotifyUtil } from "../../utils";
import {
  deleteEmployeeAllNotificationFailure,
  deleteEmployeeAllNotificationSuccess,
  deleteNotificationFailure,
  deleteNotificationSuccess,
  fetchEmployeeNotificationsFailure,
  fetchEmployeeNotificationsSuccess,
  fetchNotificationsFailure,
  fetchNotificationsSuccess,
} from "./notificationAction";
import {
  DELETE_EMPLOYEE_ALL_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_REQUEST,
  FETCH_EMPLOYEE_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_REQUEST,
} from "./notificationActionType";

function* fetchNotificationsSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      NotificationService.getNotifications,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchNotificationsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchNotificationsFailure(error));
  }
}

function* fetchEmployeeNotificationsSaga(action) {
  try {
    const { data } = yield call(
      NotificationService.getEmployeeNotifications,
      action.page
    );
    yield put(fetchEmployeeNotificationsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchEmployeeNotificationsFailure(error));
  }
}

function* deleteNotificationSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(NotificationService.deleteNotification, payload);
    yield put(deleteNotificationSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteNotificationFailure(error));
  }
}

function* deleteEmployeeAllNotificationsSaga(action) {
  try {
    yield call(NotificationService.deleteEmployeeAllNotifications);
    yield put(deleteEmployeeAllNotificationSuccess());
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteEmployeeAllNotificationFailure(error));
  }
}

export function* notificationWatcherSaga() {
  yield takeEvery(FETCH_NOTIFICATIONS_REQUEST, fetchNotificationsSaga);
  yield takeEvery(
    FETCH_EMPLOYEE_NOTIFICATIONS_REQUEST,
    fetchEmployeeNotificationsSaga
  );
  yield takeEvery(DELETE_NOTIFICATION_REQUEST, deleteNotificationSaga);
  yield takeEvery(
    DELETE_EMPLOYEE_ALL_NOTIFICATION_REQUEST,
    deleteEmployeeAllNotificationsSaga
  );
}
