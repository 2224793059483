import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import {
  createBannerFailure,
  createBannerSuccess,
  deleteBannerFailure,
  deleteBannerSuccess,
  fetchBannerFailure,
  fetchBannersFailure,
  fetchBannersSuccess,
  fetchBannerSuccess,
  updateBannerFailure,
  updateBannerSuccess,
} from "./bannerAction";
import {
  CREATE_BANNER_REQUEST,
  DELETE_BANNER_REQUEST,
  FETCH_BANNERS_REQUEST,
  FETCH_BANNER_REQUEST,
  UPDATE_BANNER_REQUEST,
} from "./bannerActionType";
import { BannerService } from "../../services";
import { toast } from "react-toastify";

function* getBannersSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      BannerService.getBanners,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchBannersSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchBannersFailure(error));
  }
}
function* getBannerSaga(action) {
  try {
    const { data } = yield call(BannerService.getBanner, action.slug);
    yield put(fetchBannerSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchBannerFailure(error));
  }
}

function* createBannerSaga(action) {
  try {
    const {
      meta: { onSuccess },
    } = action;
    const { data } = yield call(BannerService.createBanner, action.payload);
    yield put(createBannerSuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createBannerFailure(error));
  }
}
function* updateBannerSaga(action) {
  try {
    const {
      slug,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(BannerService.updateBanner, slug, payload);
    yield put(updateBannerSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateBannerFailure(error));
  }
}

function* deleteBannerSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(BannerService.deleteBanner, payload);
    yield put(deleteBannerSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteBannerFailure(error));
  }
}

export function* bannerWatcherSaga() {
  yield takeEvery(FETCH_BANNERS_REQUEST, getBannersSaga);
  yield takeEvery(FETCH_BANNER_REQUEST, getBannerSaga);
  yield takeEvery(CREATE_BANNER_REQUEST, createBannerSaga);
  yield takeEvery(UPDATE_BANNER_REQUEST, updateBannerSaga);
  yield takeEvery(DELETE_BANNER_REQUEST, deleteBannerSaga);
}
