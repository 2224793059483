import {
  Stack,
  Button,
  InputAdornment,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, InputFile } from "../";
import { updateEmployeeProfileRequest } from "../../store";
import { ValidationUtil } from "../../utils";
import { useSelector } from "react-redux";

export const ProfileForm = ({ employee }) => {
  const employeeState = useSelector((state) => state.employee);
  const [profileImages, setProfileImages] = useState(
    employee?.image?.path ? [employee.image] : []
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const { firstName, lastName, email, mobileNumber, address } = values;

    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("mobile_number", mobileNumber);
    formData.append("address", address);
    formData.append(
      "image",
      profileImages[0]?.size
        ? profileImages[0]
        : JSON.stringify(profileImages[0])
    );
    dispatch(updateEmployeeProfileRequest(employee.email, formData, onSuccess));
  };

  const onSuccess = () => {
    navigate("/profile");
  };

  const initialValues = employee
    ? {
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        mobileNumber: employee.mobileNumber,
        address: employee.address,
        nationalId: employee.nationalId,
        isActive: employee.isActive,
      }
    : {};

  return (
    <>
      {employeeState.loading ? (
        <CircularProgress />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, form, submitting, errors }) => (
            <form
              onSubmit={(event) => {
                handleSubmit(event);
                // if (Object.keys(errors).length === 0) form.reset();
              }}
              noValidate
              autoComplete="off"
            >
              <Stack alignItems="center">
                <Typography variant="h4">Profile</Typography>
                <Stack spacing={2} maxWidth="650px" width="70%" mt={"1rem"}>
                  <Field
                    name="firstName"
                    component={Input}
                    label="First Name"
                    validate={ValidationUtil.requiredValidator}
                  />
                  <Field
                    name="lastName"
                    component={Input}
                    label="Last Name"
                    validate={ValidationUtil.requiredValidator}
                  />

                  <Field
                    name="email"
                    component={Input}
                    label="Email"
                    type="email"
                    validate={ValidationUtil.emailValidator}
                  />

                  <Field
                    name="mobileNumber"
                    component={Input}
                    label="Mobile Number"
                    type="number"
                    validate={ValidationUtil.mobileNumberValidator}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+88</InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    name="address"
                    component={Input}
                    label="Address"
                    type="textarea"
                    multiline
                    inputProps={{
                      sx: {
                        minHeight: "150px",
                      },
                    }}
                    validate={ValidationUtil.requiredValidator}
                  />
                  <Field
                    name="image"
                    key={
                      profileImages.length > 0
                        ? "profile image"
                        : "profile-image"
                    } // changed key to re-render validate function
                    component={InputFile}
                    files={profileImages}
                    label="Profile image"
                    onChangeHandler={(value) => {
                      setProfileImages(value);
                    }}
                    validate={
                      employee?.image
                        ? ValidationUtil.composeValidators(
                            () =>
                              ValidationUtil.requiredValidator(profileImages),
                            () =>
                              ValidationUtil.numberOfFilesValidator(
                                profileImages ?? [],
                                1
                              ),
                            () =>
                              ValidationUtil.fileSizeValidator(profileImages)
                          )
                        : ValidationUtil.composeValidators(
                            ValidationUtil.requiredValidator,
                            ValidationUtil.fileSizeValidator,
                            ValidationUtil.fileExtensionValidator,
                            (files) =>
                              ValidationUtil.numberOfFilesValidator(
                                files ?? [],
                                1
                              )
                          )
                    }
                    sx={{
                      border: "2px dashed #5E54FF",
                      p: ".8rem",
                      alignItems: "center",
                    }}
                  />
                  <Stack direction="row" spacing={2} mt={"30px"}>
                    <Button
                      sx={{
                        width: "20%",
                        boxShadow: "none",
                        "&:hover": {
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          )}
        />
      )}
    </>
  );
};
