import {
  CREATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  FETCH_CATEGORY_OPTIONS_FAILURE,
  FETCH_CATEGORY_OPTIONS_REQUEST,
  FETCH_CATEGORY_OPTIONS_SUCCESS,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
} from "./categoryActionType";

export const fetchCategoriesRequest = (offset, searchQuery, featuredFilter) => {
  return {
    type: FETCH_CATEGORIES_REQUEST,
    offset,
    searchQuery,
    featuredFilter,
  };
};

export const fetchCategoriesSuccess = (data) => {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const fetchCategoriesFailure = (error) => {
  return {
    type: FETCH_CATEGORIES_FAILURE,
    error,
  };
};

export const fetchCategoryRequest = (slug) => {
  return {
    type: FETCH_CATEGORY_REQUEST,
    slug,
  };
};
export const fetchCategorySuccess = (data) => {
  return {
    type: FETCH_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const fetchCategoryFailure = (error) => {
  return {
    type: FETCH_CATEGORY_FAILURE,
    error,
  };
};

export const fetchCategoryOptionsRequest = () => {
  return {
    type: FETCH_CATEGORY_OPTIONS_REQUEST,
  };
};

export const fetchCategoryOptionsSuccess = (data) => {
  return {
    type: FETCH_CATEGORY_OPTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchCategoryOptionsFailure = (error) => {
  return {
    type: FETCH_CATEGORY_OPTIONS_FAILURE,
    error,
  };
};

export const createCategoryRequest = (payload, onSuccess) => {
  return {
    type: CREATE_CATEGORY_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const createCategorySuccess = (data) => {
  return {
    type: CREATE_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const createCategoryFailure = (error) => {
  return {
    type: CREATE_CATEGORY_FAILURE,
    error,
  };
};

export const updateCategoryRequest = (slug, payload, onSuccess) => {
  return {
    type: UPDATE_CATEGORY_REQUEST,
    slug,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateCategorySuccess = () => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
  };
};

export const updateCategoryFailure = (error) => {
  return {
    type: UPDATE_CATEGORY_FAILURE,
    error,
  };
};

export const deleteCategoryRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_CATEGORY_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteCategorySuccess = () => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
  };
};

export const deleteCategoryFailure = (error) => {
  return {
    type: DELETE_CATEGORY_FAILURE,
    error,
  };
};
