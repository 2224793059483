import { createTheme } from "@mui/material/styles";

export const defaultTheme = createTheme({
  typography: {
    fontFamily: "Inter, Helvetica, Arial, sans-serif",
  },
  palette: {
    icon: {
      default: "rgb(238, 238, 238)",
    },
    drawerActiveMenu: "rgb(30, 41, 58)",
    boxBgColor: "rgb(247, 249, 252)",
    status: {
      activeBg: "#12B76A",
      activeColor: "#D1FADF",
      inActiveBg: "#FEE4E2",
      inactiveColor: "#F04438",
    },
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
  },
  borderColor: "rgba(0, 0, 0, 0.12)",
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "initial",
        },
      },
      // defaultProps: {
      //   disableRipple: true,
      // },
      variants: [
        {
          props: { variant: "capsule" },
          style: {
            height: "32px",
            padding: "8px 16px",
            borderRadius: "16px",
            fontSize: "16px",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "link" },
          style: {
            fontSize: "13px",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "status" },
          style: {
            padding: "4px 12px",
            width: "90px",
            borderRadius: "4px",
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow:
            "rgb(50 50 93 / 2%) 0px 2px 5px -1px, rgb(0 0 0 / 5%) 0px 1px 3px -1px",
          borderRadius: "8px",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "value" },
          style: {
            color: "rgb(101, 116, 139)",
          },
        },
      ],
    },
    MuiListItem: {
      variants: [
        {
          props: { variant: "view" },
          style: {
            paddingLeft: "1.4rem",
            borderBottom: "1px solid rgb(230, 232, 240)",
          },
        },
      ],
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: "14px",
        },
      },
      variants: [
        {
          props: { variant: "view" },
          style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          },
        },
      ],
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "initial",
        },
      },
    },
  },
});
