import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DmBox } from "../../components";
import { StoreForm } from "../../components/forms";
import { fetchStoreRequest } from "../../store";

export const AddStorePage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const storeState = useSelector((state) => state.store);

  useEffect(() => {
    if (slug) dispatch(fetchStoreRequest(slug));
  }, [dispatch, slug]);

  return (
    <DmBox>
      {storeState.loading ? (
        <CircularProgress />
      ) : (
        <StoreForm store={slug && storeState.store} />
      )}
    </DmBox>
  );
};
