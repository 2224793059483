import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { StringUtil, UrlUtil } from "../../utils";
import { DATE_TIME_FORMAT } from "../../const";
import moment from "moment";
import { DealView, UserView } from "../../components/views";

export const ViewCommentPage = () => {
  const { id } = useParams();
  const comments = useSelector((state) => state.comment.comments);
  const foundComment = comments.find((eachComment) => eachComment.id === id);
  const { user, deal, ...comment } = foundComment;
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const TabValue = {
    DETAILS: "1",
    USER: "2",
    DEAL: "3",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DmBox>
      <Button
        variant="text"
        color="secondary"
        onClick={(e) => navigate("/comments")}
        startIcon={<ArrowBackIcon />}
      >
        Back
      </Button>
      <TabContext value={value} width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Details" value={TabValue.DETAILS} />
            <Tab label="User" value={TabValue.USER} />
            <Tab label="Deal" value={TabValue.DEAL} />
          </TabList>
        </Box>
        <TabPanel value={TabValue.DETAILS} sx={{ p: "1rem 0px" }}>
          <Typography sx={{ fontWeight: 600, pl: "1.3rem", mb: "1rem" }}>
            Details
          </Typography>
          <Divider />
          <List sx={{ pt: "0px" }}>
            <Box sx={{ pl: "1.3rem", display: "flex", flexWrap: "wrap" }}>
              {comment.images.map((eachImage) => (
                <Box
                  component="img"
                  key={eachImage.name}
                  src={
                    eachImage?.path
                      ? UrlUtil.getFullImagePath(eachImage.path)
                      : "/images/default-thumbnail-image.gif"
                  }
                  sx={{
                    width: "250px",
                    height: "250px",
                    objectFit: "contain",
                    border: "1px solid",
                    borderColor: "divider",
                    marginTop: "1.3rem",
                    marginBottom: ".5rem",
                    marginRight: ".8rem",
                    padding: ".5rem",
                  }}
                />
              ))}
            </Box>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Id
                </Typography>
                <Box>
                  <Typography variant="value">{comment.id}</Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Content
                </Typography>
                <Box>
                  <Typography variant="value">{comment?.content}</Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Total Likes
                </Typography>
                <Box>
                  <Typography variant="value">{comment.totalLikes}</Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Total Dislikes
                </Typography>
                <Box>
                  <Typography variant="value">
                    {comment.totalDislikes}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Enabled
                </Typography>
                <Box>
                  <Typography variant="value">
                    {StringUtil.getYesOrNo(comment.isActive)}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Created
                </Typography>
                <Box>
                  <Typography variant="value">
                    {moment(comment.createdAt).format(DATE_TIME_FORMAT)}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Updated
                </Typography>
                <Box>
                  <Typography variant="value">
                    {moment(comment.updatedAt).format(DATE_TIME_FORMAT)}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
          </List>
        </TabPanel>
        <TabPanel value={TabValue.USER} sx={{ p: "1rem 0px" }}>
          <UserView user={user} title="User Details" />
        </TabPanel>
        <TabPanel value={TabValue.DEAL} sx={{ p: "1rem 0px" }}>
          <DealView deal={deal} title="Deal Details" />
        </TabPanel>
      </TabContext>
    </DmBox>
  );
};
