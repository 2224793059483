import {
  FETCH_CATEGORY_BRAND_STORE_SUMMARY_FAILURE,
  FETCH_CATEGORY_BRAND_STORE_SUMMARY_REQUEST,
  FETCH_CATEGORY_BRAND_STORE_SUMMARY_SUCCESS,
  FETCH_SUMMARY_FAILURE,
  FETCH_SUMMARY_REQUEST,
  FETCH_SUMMARY_SUCCESS,
  FETCH_TOP_CONTRIBUTORS_DASHBOARD_FAILURE,
  FETCH_TOP_CONTRIBUTORS_DASHBOARD_REQUEST,
  FETCH_TOP_CONTRIBUTORS_DASHBOARD_SUCCESS,
  FETCH_TOP_MOST_REPORTED_DEALS_FAILURE,
  FETCH_TOP_MOST_REPORTED_DEALS_REQUEST,
  FETCH_TOP_MOST_REPORTED_DEALS_SUCCESS,
  FETCH_TOP_MOST_SEARCHED_KEYWORDS_FAILURE,
  FETCH_TOP_MOST_SEARCHED_KEYWORDS_REQUEST,
  FETCH_TOP_MOST_SEARCHED_KEYWORDS_SUCCESS,
  FETCH_TOP_MOST_VIEWED_DEALS_FAILURE,
  FETCH_TOP_MOST_VIEWED_DEALS_REQUEST,
  FETCH_TOP_MOST_VIEWED_DEALS_SUCCESS,
  FETCH_MONTHLY_DEALS_SUMMARY_FAILURE,
  FETCH_MONTHLY_DEALS_SUMMARY_REQUEST,
  FETCH_MONTHLY_DEALS_SUMMARY_SUCCESS,
  FETCH_MONTHLY_REPORTS_SUMMARY_FAILURE,
  FETCH_MONTHLY_REPORTS_SUMMARY_REQUEST,
  FETCH_MONTHLY_REPORTS_SUMMARY_SUCCESS,
  FETCH_MONTHLY_USERS_SUMMARY_REQUEST,
  FETCH_MONTHLY_USERS_SUMMARY_SUCCESS,
  FETCH_MONTHLY_USERS_SUMMARY_FAILURE,
  FETCH_ACTIVE_USER_SUMMARY_REQUEST,
  FETCH_ACTIVE_USER_SUMMARY_SUCCESS,
  FETCH_ACTIVE_USER_SUMMARY_FAILURE,
} from "./dashboardActionType";

export const fetchTopContributorsDashboardRequest = (limit) => {
  return {
    type: FETCH_TOP_CONTRIBUTORS_DASHBOARD_REQUEST,
    limit,
  };
};

export const fetchTopContributorsDashboardSuccess = (data) => {
  return {
    type: FETCH_TOP_CONTRIBUTORS_DASHBOARD_SUCCESS,
    payload: data,
  };
};
export const fetchTopContributorsDashboardFailure = (error) => {
  return {
    type: FETCH_TOP_CONTRIBUTORS_DASHBOARD_FAILURE,
    error,
  };
};

export const fetchMonthlyDealsSummaryRequest = () => {
  return {
    type: FETCH_MONTHLY_DEALS_SUMMARY_REQUEST,
  };
};

export const fetchMonthlyDealsSummarySuccess = (data) => {
  return {
    type: FETCH_MONTHLY_DEALS_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const fetchMonthlyDealsSummaryFailure = (error) => {
  return {
    type: FETCH_MONTHLY_DEALS_SUMMARY_FAILURE,
    error,
  };
};

export const fetchMonthlyUsersSummaryRequest = () => {
  return {
    type: FETCH_MONTHLY_USERS_SUMMARY_REQUEST,
  };
};

export const fetchMonthlyUsersSummarySuccess = (data) => {
  return {
    type: FETCH_MONTHLY_USERS_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const fetchMonthlyUsersSummaryFailure = (error) => {
  return {
    type: FETCH_MONTHLY_USERS_SUMMARY_FAILURE,
    error,
  };
};

export const fetchMonthlyReportsSummaryRequest = () => {
  return {
    type: FETCH_MONTHLY_REPORTS_SUMMARY_REQUEST,
  };
};

export const fetchMonthlyReportsSummarySuccess = (data) => {
  return {
    type: FETCH_MONTHLY_REPORTS_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const fetchMonthlyReportsSummaryFailure = (error) => {
  return {
    type: FETCH_MONTHLY_REPORTS_SUMMARY_FAILURE,
    error,
  };
};

export const fetchSummaryRequest = () => {
  return {
    type: FETCH_SUMMARY_REQUEST,
  };
};

export const fetchSummarySuccess = (data) => {
  return {
    type: FETCH_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const fetchSummaryFailure = (error) => {
  return {
    type: FETCH_SUMMARY_FAILURE,
    error,
  };
};

export const fetchTopMostViewedDealsRequest = (limit) => {
  return {
    type: FETCH_TOP_MOST_VIEWED_DEALS_REQUEST,
    limit,
  };
};

export const fetchTopMostViewedDealsSuccess = (data) => {
  return {
    type: FETCH_TOP_MOST_VIEWED_DEALS_SUCCESS,
    payload: data,
  };
};
export const fetchTopMostViewedDealsFailure = (error) => {
  return {
    type: FETCH_TOP_MOST_VIEWED_DEALS_FAILURE,
    error,
  };
};

export const fetchTopMostReportedDealsRequest = (limit) => {
  return {
    type: FETCH_TOP_MOST_REPORTED_DEALS_REQUEST,
    limit,
  };
};

export const fetchTopMostReportedDealsSuccess = (data) => {
  return {
    type: FETCH_TOP_MOST_REPORTED_DEALS_SUCCESS,
    payload: data,
  };
};

export const fetchTopMostReportedDealsFailure = (error) => {
  return {
    type: FETCH_TOP_MOST_REPORTED_DEALS_FAILURE,
    error,
  };
};

export const fetchTopMostSearchedKeywordsRequest = (limit) => {
  return {
    type: FETCH_TOP_MOST_SEARCHED_KEYWORDS_REQUEST,
    limit,
  };
};

export const fetchTopMostSearchedKeywordsSuccess = (data) => {
  return {
    type: FETCH_TOP_MOST_SEARCHED_KEYWORDS_SUCCESS,
    payload: data,
  };
};

export const fetchTopMostSearchedKeywordsFailure = (error) => {
  return {
    type: FETCH_TOP_MOST_SEARCHED_KEYWORDS_FAILURE,
    error,
  };
};

export const fetchCategoryBrandStoreSummaryRequest = () => {
  return {
    type: FETCH_CATEGORY_BRAND_STORE_SUMMARY_REQUEST,
  };
};

export const fetchCategoryBrandStoreSummarySuccess = (data) => {
  return {
    type: FETCH_CATEGORY_BRAND_STORE_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const fetchCategoryBrandStoreSummaryFailure = (error) => {
  return {
    type: FETCH_CATEGORY_BRAND_STORE_SUMMARY_FAILURE,
    error,
  };
};

export const fetchActiveUserSummaryRequest = () => {
  return {
    type: FETCH_ACTIVE_USER_SUMMARY_REQUEST,
  };
};

export const fetchActiveUserSummarySuccess = (data) => {
  return {
    type: FETCH_ACTIVE_USER_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const fetchActiveUserSummaryFailure = (error) => {
  return {
    type: FETCH_ACTIVE_USER_SUMMARY_FAILURE,
    error,
  };
};
