import {
  DELETE_DEAL_FAILURE,
  DELETE_DEAL_REQUEST,
  FETCH_DEALS_FAILURE,
  FETCH_DEALS_REQUEST,
  FETCH_DEALS_SUCCESS,
  FETCH_DEAL_BY_SLUG_FAILURE,
  FETCH_DEAL_BY_SLUG_REQUEST,
  FETCH_DEAL_BY_SLUG_SUCCESS,
  TOGGLE_DEALS_FEATURED_STATUS_FAILURE,
  TOGGLE_DEALS_FEATURED_STATUS_REQUEST,
  TOGGLE_DEALS_STATUS_FAILURE,
  TOGGLE_DEALS_STATUS_REQUEST,
  UPDATE_DEAL_FEATURED_DATE_FAILURE,
  UPDATE_DEAL_FEATURED_DATE_REQUEST,
} from "./dealActionType";

const initialState = {
  loading: false,
  deals: [],
  deal: {},
  error: "",
  totalDeals: 0,
};

export const DealReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEALS_REQUEST:
    case FETCH_DEAL_BY_SLUG_REQUEST:
    case DELETE_DEAL_REQUEST:
    case TOGGLE_DEALS_STATUS_REQUEST:
    case TOGGLE_DEALS_FEATURED_STATUS_REQUEST:
    case UPDATE_DEAL_FEATURED_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_DEALS_SUCCESS:
      const { deals, totalDeals } = action.payload;
      return {
        ...state,
        loading: false,
        deals: deals,
        totalDeals: totalDeals,
      };

    case FETCH_DEAL_BY_SLUG_SUCCESS:
      return {
        ...state,
        loading: false,
        deal: action.payload,
      };

    case FETCH_DEALS_FAILURE:
    case FETCH_DEAL_BY_SLUG_FAILURE:
    case DELETE_DEAL_FAILURE:
    case TOGGLE_DEALS_STATUS_FAILURE:
    case TOGGLE_DEALS_FEATURED_STATUS_FAILURE:
    case UPDATE_DEAL_FEATURED_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
