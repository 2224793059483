import { Stack, Button, Box, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Input, InputSwitch } from "../";
import { updateRedeemRewardRequest } from "../../store";
import { ArrayUtil, ValidationUtil } from "../../utils";
import { AutoCompleteSelect, InputFile } from "../Input";

export const RedeemRewardStatusForm = ({
  redeemReward,
  onCancel,
  onSuccess: onSuccessCallBack,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  const redeemRewardStatus = useSelector(
    (state) => state.redeemReward.redeemRewardStatus
  );
  const dispatch = useDispatch();
  const redeemRewardStatusOptions =
    ArrayUtil.getReportStatusOption(redeemRewardStatus);
  // form state
  const [selectedOption, setSelectedOption] = useState({
    label: _.capitalize(redeemReward.status),
    value: redeemReward.status,
  });
  const [files, setFiles] = useState(
    redeemReward?.image?.path ? [redeemReward.image] : []
  );

  const onSubmit = async (values) => {
    const { message, redeemRewardStatus, isActive } = values;
    let formData = new FormData();
    formData.append("message", message);
    formData.append("status", redeemRewardStatus.value);
    formData.append("sendNotification", sendNotification);
    formData.append("is_active", isActive);
    formData.append(
      "image",
      files[0]?.size ? files[0] : JSON.stringify(files[0])
    );
    dispatch(updateRedeemRewardRequest(redeemReward.id, formData, onSuccess));
  };

  const onSuccess = () => {
    onCancel();
    onSuccessCallBack();
  };

  const initialValues = {
    message: redeemReward.message,
    isActive: redeemReward.isActive,
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
          }}
          noValidate
          autoComplete="off"
        >
          <Stack alignItems="center">
            <Typography variant="h5">Update Redeem Status</Typography>
            <Stack spacing={2} width="100%" mt="1.5rem">
              <Field
                name="redeemRewardStatus"
                component={AutoCompleteSelect}
                label="Status"
                options={redeemRewardStatusOptions}
                handleOnChange={(value) => setSelectedOption(value)}
                value={selectedOption}
                defaultValue={selectedOption}
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="message"
                component={Input}
                label="Review Message"
                type="textarea"
                multiline
                inputProps={{
                  sx: {
                    minHeight: "150px",
                  },
                }}
                validate={ValidationUtil.requiredValidator}
              />
              <Box>
                <Field
                  name="sendOnlyNotification"
                  checked={sendNotification}
                  component={InputSwitch}
                  handleOnChange={(value) => setSendNotification(value)}
                  type="checkbox"
                  label="Send Notification"
                  labelPlacement="start"
                  sx={{ ml: "0px" }}
                />
              </Box>
              <Box>
                <Field
                  name="isActive"
                  checked={isActive}
                  component={InputSwitch}
                  handleOnChange={(value) => setIsActive(value)}
                  type="checkbox"
                  label="Is Active"
                  labelPlacement="start"
                  sx={{ ml: "0px" }}
                />
              </Box>
              <Field
                name="image"
                key={files.length > 0 ? 1 : 0} // changed key to re-render validate function
                component={InputFile}
                files={files}
                onChangeHandler={(value) => {
                  setFiles(value);
                }}
                validate={
                  files.length &&
                  ValidationUtil.composeValidators(
                    () => {
                      return ValidationUtil.requiredValidator(files);
                    },
                    () => ValidationUtil.numberOfFilesValidator(files ?? [], 1),
                    () => ValidationUtil.fileSizeValidator(files),
                    () => ValidationUtil.fileExtensionValidator(files)
                  )
                }
                sx={{
                  border: "2px dashed #5E54FF",
                  p: ".8rem",
                  alignItems: "center",
                }}
              />
              <Stack direction="row" spacing={2} mt={"30px"}>
                <Button
                  sx={{
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "#03a9f4",
                    "&:hover": {
                      backgroundColor: "#0288d1",
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
