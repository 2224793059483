import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import { toast } from "react-toastify";
import {
  CREATE_ROLE_REQUEST,
  DELETE_ROLE_REQUEST,
  FETCH_ROLES_REQUEST,
  FETCH_ROLE_OPTIONS_REQUEST,
  FETCH_ROLE_REQUEST,
  UPDATE_ROLE_REQUEST,
} from "./roleActionType";
import {
  createRoleFailure,
  createRoleSuccess,
  deleteRoleFailure,
  deleteRoleSuccess,
  fetchRoleFailure,
  fetchRoleOptionsFailure,
  fetchRoleOptionsSuccess,
  fetchRolesFailure,
  fetchRolesSuccess,
  fetchRoleSuccess,
  updateRoleFailure,
  updateRoleSuccess,
} from "./roleAction";
import { RoleService } from "../../services";

function* getRolesSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      RoleService.getRoles,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchRolesSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchRolesFailure(error));
  }
}

function* fetchRoleSaga(action) {
  try {
    const { data } = yield call(RoleService.getRole, action.slug);
    yield put(fetchRoleSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchRoleFailure(error));
  }
}

function* fetchRoleOptionsSaga() {
  try {
    const { data } = yield call(RoleService.getRoleOptions);
    yield put(fetchRoleOptionsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchRoleOptionsFailure(error));
  }
}

function* createRoleSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    const { data } = yield call(RoleService.createRole, payload);
    yield put(createRoleSuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createRoleFailure(error));
  }
}

function* updateRoleSaga(action) {
  try {
    const {
      slug,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(RoleService.updateRole, slug, payload);
    yield put(updateRoleSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateRoleFailure(error));
  }
}

function* deleteRoleSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(RoleService.deleteRole, payload);
    yield put(deleteRoleSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteRoleFailure(error));
  }
}

export function* roleWatcherSaga() {
  yield takeEvery(FETCH_ROLES_REQUEST, getRolesSaga);
  yield takeEvery(FETCH_ROLE_REQUEST, fetchRoleSaga);
  yield takeEvery(FETCH_ROLE_OPTIONS_REQUEST, fetchRoleOptionsSaga);
  yield takeEvery(CREATE_ROLE_REQUEST, createRoleSaga);
  yield takeEvery(UPDATE_ROLE_REQUEST, updateRoleSaga);
  yield takeEvery(DELETE_ROLE_REQUEST, deleteRoleSaga);
}
