import {
  DELETE_REWARD_VALIDATION_LOG_FAILURE,
  DELETE_REWARD_VALIDATION_LOG_REQUEST,
  DELETE_REWARD_VALIDATION_LOG_SUCCESS,
  FETCH_REWARD_VALIDATION_LOGS_FAILURE,
  FETCH_REWARD_VALIDATION_LOGS_REQUEST,
  FETCH_REWARD_VALIDATION_LOGS_SUCCESS,
} from "./rewardValidationLogActionType";

export const fetchRewardValidationLogsRequest = (offset, searchQuery) => {
  return {
    type: FETCH_REWARD_VALIDATION_LOGS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchRewardValidationLogsSuccess = (data) => {
  return {
    type: FETCH_REWARD_VALIDATION_LOGS_SUCCESS,
    payload: data,
  };
};

export const fetchRewardValidationLogsFailure = (error) => {
  return {
    type: FETCH_REWARD_VALIDATION_LOGS_FAILURE,
    error,
  };
};

export const deleteRewardValidationLogRequest = (
  payload,
  onSuccessfullyDelete
) => {
  return {
    type: DELETE_REWARD_VALIDATION_LOG_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteRewardValidationLogSuccess = () => {
  return {
    type: DELETE_REWARD_VALIDATION_LOG_SUCCESS,
  };
};

export const deleteRewardValidationLogFailure = (error) => {
  return {
    type: DELETE_REWARD_VALIDATION_LOG_FAILURE,
    error,
  };
};
