import { Stack, Button, InputAdornment, Box, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, InputDate, InputSwitch, InputFile } from "../";
import { createBannerRequest, updateBannerRequest } from "../../store";
import { ValidationUtil } from "../../utils";

export const BannerForm = ({ banner }) => {
  const [startDate, setStartDate] = useState(
    banner?.startDate ? moment(banner.startDate) : null
  );
  const [endDate, setEndDate] = useState(
    banner?.endDate ? moment(banner.endDate) : null
  );
  const [isActive, setIsActive] = useState(false);
  const [files, setFiles] = useState(banner?.image?.path ? [banner.image] : []);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const { name, link, startDate, endDate, isActive } = values;
    let formData = new FormData();

    formData.append("name", name);
    formData.append("link", link);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("is_active", isActive ?? false);
    formData.append(
      "image",
      files[0]?.size ? files[0] : JSON.stringify(files[0])
    );
    banner?.id
      ? dispatch(updateBannerRequest(banner.slug, formData, onSuccess))
      : dispatch(createBannerRequest(formData, onSuccess));
  };

  const onSuccess = () => {
    navigate("/banners");
  };

  const initialValues = banner
    ? {
        name: banner.name,
        link: banner.link,
        isActive: banner.isActive,
      }
    : {};

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, errors }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            if (Object.keys(errors).length === 0) form.reset();
          }}
          noValidate
          autoComplete="off"
        >
          <Stack alignItems="center">
            <Typography variant="h4">
              {banner ? `Edit ${banner.name}` : "Add new banner"}
            </Typography>
            <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
              <Field
                name="name"
                component={Input}
                label="Name"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="link"
                component={Input}
                label="Link"
                validate={ValidationUtil.composeValidators(
                  ValidationUtil.requiredValidator,
                  ValidationUtil.isValidHttpUrl
                )}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">http</InputAdornment>
                  ),
                }}
              />
              <Field
                name="startDate"
                value={startDate}
                inputFormat="dd-MM-yyyy"
                defaultValue={startDate}
                disablePast
                component={InputDate}
                onChange={(newValue) => setStartDate(newValue)}
                label="Start Date"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="endDate"
                value={endDate}
                defaultValue={endDate}
                disablePast
                inputFormat="dd-MM-yyyy"
                component={InputDate}
                onChange={(newValue) => setEndDate(newValue)}
                label="End Date"
                validate={ValidationUtil.requiredValidator}
              />
              <Box>
                <Field
                  name="isActive"
                  checked={isActive}
                  component={InputSwitch}
                  handleOnChange={(value) => setIsActive(value)}
                  type="checkbox"
                  label="Is Active"
                  labelPlacement="start"
                  sx={{ ml: "0px" }}
                />
              </Box>
              <Field
                name="image"
                component={InputFile}
                files={files}
                key={files.length > 0 ? 1 : 0} // changed key to re-render validate function
                onChangeHandler={(value) => {
                  setFiles(value);
                }}
                validate={
                  banner?.image
                    ? ValidationUtil.composeValidators(
                        () => ValidationUtil.requiredValidator(files),
                        () =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1),
                        () => ValidationUtil.fileSizeValidator(files)
                      )
                    : ValidationUtil.composeValidators(
                        ValidationUtil.requiredValidator,
                        ValidationUtil.fileSizeValidator,
                        ValidationUtil.fileExtensionValidator,
                        (files) =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1)
                      )
                }
                sx={{
                  border: "2px dashed #5E54FF",
                  p: ".8rem",
                  alignItems: "center",
                }}
              />
              <Stack direction="row" spacing={2} mt={"30px"}>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    backgroundColor: "#03a9f4",
                    "&:hover": {
                      backgroundColor: "#0288d1",
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  onClick={() => navigate("/banners")}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
