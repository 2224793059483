import React, { useEffect } from "react";
import { FacebookPageForm } from "../../components/forms";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchFacebookPageRequest } from "../../store";
import { DmBox } from "../../components";

export const AddFacebookPage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const facebookPageState = useSelector((state) => state.facebookPage);
  useEffect(() => {
    if (slug) dispatch(fetchFacebookPageRequest(slug));
  }, [dispatch, slug]);

  return (
    <DmBox>
      {facebookPageState.loading ? (
        <CircularProgress />
      ) : (
        <FacebookPageForm
          facebookPage={slug && facebookPageState.facebookPage}
        />
      )}
    </DmBox>
  );
};
