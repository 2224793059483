export const FETCH_REWARD_RULES_REQUEST = "FETCH_REWARD_RULES_REQUEST";
export const FETCH_REWARD_RULES_SUCCESS = "FETCH_REWARD_RULES_SUCCESS";
export const FETCH_REWARD_RULES_FAILURE = "FETCH_REWARD_RULES_FAILURE";

export const FETCH_REWARD_RULE_REQUEST = "FETCH_REWARD_RULE_REQUEST";
export const FETCH_REWARD_RULE_SUCCESS = "FETCH_REWARD_RULE_SUCCESS";
export const FETCH_REWARD_RULE_FAILURE = "FETCH_REWARD_RULE_FAILURE";

export const CREATE_REWARD_RULE_REQUEST = "CREATE_REWARD_RULE_REQUEST";
export const CREATE_REWARD_RULE_SUCCESS = "CREATE_REWARD_RULE_SUCCESS";
export const CREATE_REWARD_RULE_FAILURE = "CREATE_REWARD_RULE_FAILURE";

export const UPDATE_REWARD_RULE_REQUEST = "UPDATE_REWARD_RULE_REQUEST";
export const UPDATE_REWARD_RULE_SUCCESS = "UPDATE_REWARD_RULE_SUCCESS";
export const UPDATE_REWARD_RULE_FAILURE = "UPDATE_REWARD_RULE_FAILURE";

export const DELETE_REWARD_RULE_REQUEST = "DELETE_REWARD_RULE_REQUEST";
export const DELETE_REWARD_RULE_SUCCESS = "DELETE_REWARD_RULE_SUCCESS";
export const DELETE_REWARD_RULE_FAILURE = "DELETE_REWARD_RULE_FAILURE";

export const FETCH_ALL_REWARD_RULES_REQUEST = "FETCH_ALL_REWARD_RULES_REQUEST";
export const FETCH_ALL_REWARD_RULES_SUCCESS = "FETCH_ALL_REWARD_RULES_SUCCESS";
export const FETCH_ALL_REWARD_RULES_FAILURE = "FETCH_ALL_REWARD_RULES_FAILURE";
