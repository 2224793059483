import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { FacebookPageService } from "../../services/facebookPageService";
import { NotifyUtil } from "../../utils";
import {
  createFacebookPageFailure,
  createFacebookPageSuccess,
  deleteFacebookPageFailure,
  deleteFacebookPageSuccess,
  exportFacebookPageFailure,
  exportFacebookPageSuccess,
  fetchFacebookPageFailure,
  fetchFacebookPagesFailure,
  fetchFacebookPagesSuccess,
  fetchFacebookPageSuccess,
  updateFacebookPageFailure,
  updateFacebookPageSuccess,
} from "./facebookPageAction";
import {
  CREATE_FACEBOOK_PAGE_REQUEST,
  DELETE_FACEBOOK_PAGE_REQUEST,
  EXPORT_FACEBOOK_PAGES_REQUEST,
  FETCH_FACEBOOK_PAGES_REQUEST,
  FETCH_FACEBOOK_PAGE_REQUEST,
  UPDATE_FACEBOOK_PAGE_REQUEST,
} from "./facebookPageActionType";

function* getFacebookPagesSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      FacebookPageService.getFacebookPages,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchFacebookPagesSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchFacebookPagesFailure(error));
  }
}
function* getFacebookPageSaga(action) {
  try {
    const { data } = yield call(
      FacebookPageService.getFacebookPage,
      action.slug
    );
    yield put(fetchFacebookPageSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchFacebookPageFailure(error));
  }
}

function* createFacebookPageSaga(action) {
  try {
    const {
      meta: { onSuccess },
    } = action;
    const { data } = yield call(
      FacebookPageService.createFacebookPage,
      action.payload
    );
    yield put(createFacebookPageSuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createFacebookPageFailure(error));
  }
}
function* updateFacebookPageSaga(action) {
  try {
    const {
      slug,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(FacebookPageService.updateFacebookPage, slug, payload);
    yield put(updateFacebookPageSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateFacebookPageFailure(error));
  }
}

function* deleteFacebookPageSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(FacebookPageService.deleteFacebookPage, payload);
    yield put(deleteFacebookPageSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteFacebookPageFailure(error));
  }
}
function* exportFacebookPagesSaga(action) {
  try {
    const {
      meta: { onSuccess },
    } = action;
    const data = yield call(FacebookPageService.exportFacebookPages);
    yield put(exportFacebookPageSuccess());
    onSuccess(data);
    toast.success("Exported successfully");
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(exportFacebookPageFailure(error));
  }
}

export function* facebookPageWatcherSaga() {
  yield takeEvery(FETCH_FACEBOOK_PAGES_REQUEST, getFacebookPagesSaga);
  yield takeEvery(FETCH_FACEBOOK_PAGE_REQUEST, getFacebookPageSaga);
  yield takeEvery(CREATE_FACEBOOK_PAGE_REQUEST, createFacebookPageSaga);
  yield takeEvery(UPDATE_FACEBOOK_PAGE_REQUEST, updateFacebookPageSaga);
  yield takeEvery(DELETE_FACEBOOK_PAGE_REQUEST, deleteFacebookPageSaga);
  yield takeEvery(EXPORT_FACEBOOK_PAGES_REQUEST, exportFacebookPagesSaga);
}
