import { Box, Button, CircularProgress, Stack, Tab } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  BrandView,
  CountryView,
  DealStoreView,
  DealView,
  UserView,
} from "../../components/views";
import { CategoryView } from "../../components/views";
import {
  deleteDealRequest,
  fetchDealBySlugRequest,
  toggleDealsFeaturedStatusRequest,
  toggleDealsStatusRequest,
} from "../../store";
import {
  ConfirmModal,
  DealFeaturedSettingModal,
  DmModal,
} from "../../components/Modal";
import { useToggle } from "../../hooks";
import { CanPerformAction, Models } from "../../const";
import { Authenticate } from "../../hoc/Authenticate";

export const ViewDealPage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const dealState = useSelector((state) => state.deal);
  const deal = dealState.deal;
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const [openModal, setOpenModal] = useToggle();
  const [openFeaturedSettingModal, setFeaturedSettingModal] = useToggle();
  const [isOptionClick, setIsOptionClick] = useState({
    // to check which option was clicked
    enable: false,
    disable: false,
    featured: false,
    unFeatured: false,
    featuredSetting: false,
    delete: false,
  });

  const TabValue = {
    DETAILS: "1",
    USER: "2",
    CATEGORY: "3",
    BRAND: "4",
    DEAL_STORE: "5",
    COUNTRY: "6",
  };

  const onError = useCallback(() => {
    navigate("/deals");
  }, [navigate]);

  useEffect(() => {
    dispatch(fetchDealBySlugRequest(slug, onError));
  }, [dispatch, slug, onError]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSuccess = () => {
    dispatch(fetchDealBySlugRequest(slug, onError));
  };

  const onToggleDealStatus = (reviewMessage, sendOnlyNotification) => {
    dispatch(
      toggleDealsStatusRequest(
        {
          dealIds: [deal.id],
          isActive: isOptionClick.enable ?? isOptionClick.disable,
          reviewMessage,
          sendOnlyNotification,
        },
        onSuccess
      )
    );
    setOpenModal(); // close modal
  };

  const onToggleFeaturedDealStatus = () => {
    dispatch(
      toggleDealsFeaturedStatusRequest(
        {
          dealIds: [deal.id],
          isFeatured: isOptionClick.featured ?? isOptionClick.unFeatured,
        },
        onSuccess
      )
    );
    setOpenModal(); // close modal
  };

  const onDeleteConfirm = () => {
    const dealIds = [
      {
        id: deal.id,
        user_id: deal.user.id,
      },
    ];
    dispatch(deleteDealRequest({ dealIds }, () => navigate("/deals")));
  };

  const onConfirm = (reviewMessage, sendOnlyNotification) => {
    if (isOptionClick.delete) return onDeleteConfirm();
    if (!isOptionClick.enable && !isOptionClick.disable)
      return onToggleFeaturedDealStatus();
    return onToggleDealStatus(reviewMessage, sendOnlyNotification);
  };

  const getConfirmModalTitle = () => {
    if (isOptionClick.delete) return "Are you sure want to delete this deal ?";
    if (!isOptionClick.enable && !isOptionClick.disable) {
      return `Are you sure want to ${
        isOptionClick.unFeatured ? "un-featured" : "featured"
      } this deal ?`;
    }
    return `Are you sure want to  ${
      isOptionClick.disable ? "disable" : "enable"
    } this deal ?`;
  };

  const getConfirmButtonText = () => {
    if (isOptionClick.delete) return "Delete";
    if (!isOptionClick.enable && !isOptionClick.disable)
      return isOptionClick.unFeatured ? "Un-featured" : "featured";

    return isOptionClick.disable ? "Disable" : "Enable";
  };

  return (
    <DmBox>
      <Stack direction="row" justifyContent="space-between">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate("/deals")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Stack direction="row" spacing={2}>
          <Authenticate
            model={Models.DEAL}
            action={CanPerformAction.CAN_DELETE}
          >
            <Button
              onClick={() => {
                setIsOptionClick({
                  enable: false,
                  disable: false,
                  delete: true,
                  featured: false,
                  unFeatured: false,
                  featuredSetting: false,
                });
                setOpenModal();
              }}
              sx={{
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
            >
              Delete
            </Button>
          </Authenticate>
          <Authenticate model={Models.DEAL} action={CanPerformAction.CAN_EDIT}>
            <Button
              variant="contained"
              onClick={() => {
                setIsOptionClick({
                  enable: true,
                  disable: false,
                  delete: false,
                  featured: false,
                  unFeatured: false,
                  featuredSetting: false,
                });
                setOpenModal();
              }}
            >
              Enable
            </Button>
          </Authenticate>
          <Authenticate model={Models.DEAL} action={CanPerformAction.CAN_EDIT}>
            <Button
              variant="outlined"
              onClick={() => {
                setIsOptionClick({
                  disable: true,
                  enable: false,
                  delete: false,
                  featured: false,
                  unFeatured: false,
                  featuredSetting: false,
                });
                setOpenModal();
              }}
            >
              Disable
            </Button>
          </Authenticate>
          <Authenticate model={Models.DEAL} action={CanPerformAction.CAN_EDIT}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setIsOptionClick({
                  disable: false,
                  enable: false,
                  delete: false,
                  featured: true,
                  unFeatured: false,
                  featuredSetting: false,
                });
                setOpenModal();
              }}
            >
              Featured
            </Button>
          </Authenticate>
          <Authenticate model={Models.DEAL} action={CanPerformAction.CAN_EDIT}>
            <Button
              variant="outlined"
              color="warning"
              onClick={() => {
                setIsOptionClick({
                  disable: false,
                  enable: false,
                  delete: false,
                  featured: false,
                  unFeatured: true,
                  featuredSetting: false,
                });
                setOpenModal();
              }}
            >
              Un-featured
            </Button>
          </Authenticate>
          <Authenticate model={Models.DEAL} action={CanPerformAction.CAN_EDIT}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setIsOptionClick({
                  disable: false,
                  enable: false,
                  delete: false,
                  featured: false,
                  unFeatured: false,
                  featuredSetting: true,
                });
                setFeaturedSettingModal();
              }}
            >
              Featured Setting
            </Button>
          </Authenticate>
        </Stack>
      </Stack>
      {!deal?.id || dealState.loading ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <TabContext value={value} width="100%">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Details" value={TabValue.DETAILS} />
                <Tab label="User" value={TabValue.USER} />
                <Tab label="Category" value={TabValue.CATEGORY} />
                <Tab label="Brand" value={TabValue.BRAND} />
                <Tab label="Deal Store" value={TabValue.DEAL_STORE} />
                <Tab label="Country" value={TabValue.COUNTRY} />
              </TabList>
            </Box>
            <TabPanel value={TabValue.DETAILS} sx={{ p: "1rem 0px" }}>
              <DealView deal={deal} />
            </TabPanel>
            <TabPanel value={TabValue.USER} sx={{ p: "1rem 0px" }}>
              <UserView user={deal.user} title="User Details" />
            </TabPanel>
            <TabPanel value={TabValue.CATEGORY} sx={{ p: "1rem 0px" }}>
              <CategoryView category={deal.category} title="Category Details" />
            </TabPanel>
            <TabPanel value={TabValue.BRAND} sx={{ p: "1rem 0px" }}>
              <BrandView brand={deal.brand} title="Brand Details" />
            </TabPanel>
            <TabPanel value={TabValue.DEAL_STORE} sx={{ p: "1rem 0px" }}>
              <DealStoreView
                dealStore={deal.dealStore}
                title="Deal Store Details"
              />
            </TabPanel>
            <TabPanel value={TabValue.COUNTRY} sx={{ p: "1rem 0px" }}>
              <CountryView country={deal.country} title="Country Details" />
            </TabPanel>
          </TabContext>
          <DmModal open={openModal} closeModal={setOpenModal}>
            <ConfirmModal
              title={getConfirmModalTitle()}
              subTitle={isOptionClick.delete && "This action cannot be undone"}
              btnConfirmText={getConfirmButtonText()}
              btnConfirmStyles={
                isOptionClick.enable || isOptionClick.featured
                  ? {
                      bgcolor: (theme) => theme.palette.status.activeBg,
                      color: (theme) => theme.palette.status.activeColor,
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.status.activeBg,
                      },
                    }
                  : undefined
              }
              btnCancelText="Cancel"
              onCancel={setOpenModal}
              onConfirm={onConfirm}
              isEnableOrDisableOption={
                isOptionClick.enable || isOptionClick.disable
              }
            />
          </DmModal>
          <DmModal
            open={openFeaturedSettingModal}
            closeModal={setFeaturedSettingModal}
          >
            <DealFeaturedSettingModal
              deal={deal}
              onCancel={setFeaturedSettingModal}
              onSuccess={onSuccess}
            />
          </DmModal>
        </>
      )}
    </DmBox>
  );
};
