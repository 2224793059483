import { Stack, InputAdornment, IconButton, Button } from "@mui/material";
import { Form, Field } from "react-final-form";
import React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Input } from "../";
import { ValidationUtil } from "../../utils";
import { useDispatch } from "react-redux";
import { resetPasswordRequest } from "../../store";
import { useState } from "react";
import { useRef } from "react";

export const ResetPasswordForm = ({
  employee,
  onSuccess,
  isResetPasswordFromProfile = true,
}) => {
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const { currentPassword, newPassword, confirmPassword } = values;
    dispatch(
      resetPasswordRequest(employee.email, {
        currentPassword,
        newPassword,
        confirmPassword,
      })
    );
    onSuccess && onSuccess();
  };

  const confirmPasswordRef = useRef();
  const newPasswordRef = useRef();
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, errors }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            if (Object.keys(errors).length === 0) form.reset();
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={2}>
            {isResetPasswordFromProfile && (
              <Field
                name="currentPassword"
                component={Input}
                label="Current Password"
                validate={ValidationUtil.requiredValidator}
                sx={{ width: "100%", maxWidth: "500px" }}
                InputProps={{
                  type: showPassword.currentPassword ? "text" : "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword((prev) => ({
                            ...prev,
                            currentPassword: !prev.currentPassword,
                          }))
                        }
                      >
                        {showPassword.currentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Field
              name="newPassword"
              component={Input}
              inputRef={newPasswordRef}
              label="New Password"
              sx={{ width: "100%", maxWidth: "500px" }}
              validate={ValidationUtil.requiredValidator}
              InputProps={{
                type: showPassword.newPassword ? "text" : "password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPassword((prev) => ({
                          ...prev,
                          newPassword: !prev.newPassword,
                        }))
                      }
                    >
                      {showPassword.newPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              name="confirmPassword"
              component={Input}
              inputRef={confirmPasswordRef}
              label="Confirm Password"
              sx={{ width: "100%", maxWidth: "500px" }}
              validate={ValidationUtil.composeValidators(
                ValidationUtil.requiredValidator,
                () =>
                  ValidationUtil.confirmPasswordValidator(
                    confirmPasswordRef.current.value,
                    newPasswordRef.current.value
                  )
              )}
              InputProps={{
                type: showPassword.confirmPassword ? "text" : "password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPassword((prev) => ({
                          ...prev,
                          confirmPassword: !prev.confirmPassword,
                        }))
                      }
                    >
                      {showPassword.confirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Button
            disabled={submitting}
            sx={{ marginTop: "30px" }}
            variant="contained"
            type="submit"
          >
            Submit
          </Button>
        </form>
      )}
    />
  );
};
