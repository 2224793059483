import {
  DELETE_EMAIL_HISTORY_FAILURE,
  DELETE_EMAIL_HISTORY_REQUEST,
  FETCH_EMAIL_HISTORIES_FAILURE,
  FETCH_EMAIL_HISTORIES_REQUEST,
  FETCH_EMAIL_HISTORIES_SUCCESS,
} from "./emailHistoryActionType";

const initialState = {
  loading: false,
  emailHistories: [],
  totalEmailHistories: 0,
  error: "",
};

export const EmailHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_HISTORIES_REQUEST:
    case DELETE_EMAIL_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EMAIL_HISTORIES_SUCCESS:
      const { emailHistories, totalEmailHistories } = action.payload;
      return {
        ...state,
        loading: false,
        emailHistories,
        totalEmailHistories,
      };
    case FETCH_EMAIL_HISTORIES_FAILURE:
    case DELETE_EMAIL_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
