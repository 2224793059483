import {
  DELETE_SEARCH_LOG_FAILURE,
  DELETE_SEARCH_LOG_REQUEST,
  DELETE_SEARCH_LOG_SUCCESS,
  FETCH_SEARCH_LOGS_FAILURE,
  FETCH_SEARCH_LOGS_REQUEST,
  FETCH_SEARCH_LOGS_SUCCESS,
} from "./searchLogActionType";

export const fetchSearchLogsRequest = (offset, searchQuery) => {
  return {
    type: FETCH_SEARCH_LOGS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchSearchLogsSuccess = (data) => {
  return {
    type: FETCH_SEARCH_LOGS_SUCCESS,
    payload: data,
  };
};

export const fetchSearchLogsFailure = (error) => {
  return {
    type: FETCH_SEARCH_LOGS_FAILURE,
    error,
  };
};

export const deleteSearchLogRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_SEARCH_LOG_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteSearchLogSuccess = () => {
  return {
    type: DELETE_SEARCH_LOG_SUCCESS,
  };
};

export const deleteSearchLogFailure = (error) => {
  return {
    type: DELETE_SEARCH_LOG_FAILURE,
    error,
  };
};
