import {
  DELETE_STORE_REVIEW_FAILURE,
  DELETE_STORE_REVIEW_REQUEST,
  FETCH_STORE_REVIEWS_FAILURE,
  FETCH_STORE_REVIEWS_REQUEST,
  FETCH_STORE_REVIEWS_SUCCESS,
  TOGGLE_STORE_REVIEWS_STATUS_FAILURE,
  TOGGLE_STORE_REVIEWS_STATUS_REQUEST,
} from "./storeReviewActionType";

const initialState = {
  loading: false,
  storeReviews: [],
  error: "",
  totalStoreReviews: 0,
};

export const StoreReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORE_REVIEWS_REQUEST:
    case TOGGLE_STORE_REVIEWS_STATUS_REQUEST:
    case DELETE_STORE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_STORE_REVIEWS_SUCCESS:
      const { storeReviews, totalStoreReviews } = action.payload;
      return {
        ...state,
        loading: false,
        storeReviews: storeReviews,
        totalStoreReviews: totalStoreReviews,
      };

    case FETCH_STORE_REVIEWS_FAILURE:
    case TOGGLE_STORE_REVIEWS_STATUS_FAILURE:
    case DELETE_STORE_REVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
