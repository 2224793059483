import { Box, Stack } from "@mui/material";
import React from "react";
import { Navbar } from "../components";
import { Outlet } from "react-router-dom";

export const LayoutPage = () => {
  return (
    <Stack>
      <Navbar />
      <Box ml="14rem">
        <Outlet />
      </Box>
    </Stack>
  );
};
