import {
  Box,
  Button,
  Tab,
  Stack,
  ListItem,
  ListItemText,
  List,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { RewardRuleView, RewardView, UserView } from "../../components/views";
import { DATE_TIME_FORMAT } from "../../const";
import moment from "moment";
import { StringUtil, UrlUtil } from "../../utils";
import {
  fetchRedeemRewardRequest,
  fetchRedeemRewardStatusRequest,
} from "../../store";
import { useToggle } from "../../hooks";
import { DmModal, RedeemRewardStatusModal } from "../../components/Modal";
import EditIcon from "@mui/icons-material/Edit";

export const ViewRedeemRewardPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, redeemReward } = useSelector((state) => state.redeemReward);
  const [openStatusModal, setOpenStatusModal] = useToggle();
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const TabValue = {
    DETAILS: "1",
    REWARD: "2",
    REWARD_RULE: "3",
    USER: "4",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchRedeemRewardRequest(id));
  }, [dispatch, id]);

  const onSuccess = () => {
    dispatch(fetchRedeemRewardRequest(id));
  };

  useEffect(() => {
    dispatch(fetchRedeemRewardStatusRequest());
  }, [dispatch]);

  return (
    <DmBox>
      <Stack direction="row" justifyContent="space-between">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate("/redeem-rewards")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Button
          variant="text"
          color="success"
          onClick={setOpenStatusModal}
          startIcon={<EditIcon />}
        >
          Update Status
        </Button>
      </Stack>
      {!redeemReward?.id || loading ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <TabContext value={value} width="100%">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Details" value={TabValue.DETAILS} />
                <Tab label="Reward" value={TabValue.REWARD} />
                <Tab label="Reward Rule" value={TabValue.REWARD_RULE} />
                <Tab label="User" value={TabValue.USER} />
              </TabList>
            </Box>
            <TabPanel value={TabValue.DETAILS} sx={{ p: "1rem 0px" }}>
              {redeemReward.image?.path && (
                <Box pl="1.3rem">
                  <Box
                    component="img"
                    src={UrlUtil.getFullImagePath(redeemReward.image.path)}
                    sx={{
                      width: "250px",
                      height: "250px",
                      objectFit: "contain",
                      border: "1px solid",
                      borderColor: "divider",
                      marginTop: "1.3rem",
                      padding: ".5rem",
                    }}
                  />
                </Box>
              )}
              <List sx={{ pt: "0px" }}>
                <ListItem variant="view">
                  <ListItemText disableTypography variant="view">
                    <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                      Id
                    </Typography>
                    <Box>
                      <Typography variant="value">{redeemReward.id}</Typography>
                    </Box>
                  </ListItemText>
                </ListItem>
                <ListItem variant="view">
                  <ListItemText disableTypography variant="view">
                    <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                      Total Availed
                    </Typography>
                    <Box>
                      <Typography variant="value">
                        {redeemReward.totalAvailed}
                      </Typography>
                    </Box>
                  </ListItemText>
                </ListItem>
                <ListItem variant="view">
                  <ListItemText disableTypography variant="view">
                    <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                      Review Message
                    </Typography>
                    <Box>
                      <Typography variant="value">
                        {redeemReward?.message}
                      </Typography>
                    </Box>
                  </ListItemText>
                </ListItem>
                <ListItem variant="view">
                  <ListItemText disableTypography variant="view">
                    <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                      Status
                    </Typography>
                    <Box>
                      <Typography variant="value">
                        {redeemReward.status}
                      </Typography>
                    </Box>
                  </ListItemText>
                </ListItem>
                <ListItem variant="view">
                  <ListItemText disableTypography variant="view">
                    <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                      Enable
                    </Typography>
                    <Box>
                      <Typography variant="value">
                        {StringUtil.getYesOrNo(redeemReward.isActive)}
                      </Typography>
                    </Box>
                  </ListItemText>
                </ListItem>
                <ListItem variant="view">
                  <ListItemText disableTypography variant="view">
                    <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                      Created
                    </Typography>
                    <Box>
                      <Typography variant="value">
                        {moment(redeemReward.createdAt).format(
                          DATE_TIME_FORMAT
                        )}
                      </Typography>
                    </Box>
                  </ListItemText>
                </ListItem>
                <ListItem variant="view">
                  <ListItemText disableTypography variant="view">
                    <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                      Updated
                    </Typography>
                    <Box>
                      <Typography variant="value">
                        {moment(redeemReward.updatedAt).format(
                          DATE_TIME_FORMAT
                        )}
                      </Typography>
                    </Box>
                  </ListItemText>
                </ListItem>
              </List>
            </TabPanel>
            <TabPanel value={TabValue.REWARD} sx={{ p: "1rem 0px" }}>
              <RewardView reward={redeemReward.reward} />
            </TabPanel>
            <TabPanel value={TabValue.REWARD_RULE} sx={{ p: "1rem 0px" }}>
              {redeemReward.reward.rewardRules.map((rewardRule, index) => (
                <RewardRuleView
                  key={rewardRule.id}
                  rewardRule={rewardRule}
                  title={`#${index + 1} ${rewardRule.title} Details`}
                />
              ))}
            </TabPanel>
            <TabPanel value={TabValue.USER} sx={{ p: "1rem 0px" }}>
              <UserView user={redeemReward.user} title="Details" />
            </TabPanel>
          </TabContext>
          <DmModal open={openStatusModal} closeModal={setOpenStatusModal}>
            <RedeemRewardStatusModal
              redeemReward={redeemReward}
              onCancel={setOpenStatusModal}
              onSuccess={onSuccess}
            />
          </DmModal>
        </>
      )}
    </DmBox>
  );
};
