import {
  DELETE_EMPLOYEE_ALL_NOTIFICATION_FAILURE,
  DELETE_EMPLOYEE_ALL_NOTIFICATION_REQUEST,
  DELETE_EMPLOYEE_ALL_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  FETCH_EMPLOYEE_NOTIFICATIONS_FAILURE,
  FETCH_EMPLOYEE_NOTIFICATIONS_REQUEST,
  FETCH_EMPLOYEE_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
} from "./notificationActionType";

export const fetchNotificationsRequest = (offset, searchQuery) => {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchNotificationsSuccess = (data) => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const fetchNotificationsFailure = (error) => {
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    error,
  };
};

export const fetchEmployeeNotificationsRequest = (page) => {
  return {
    type: FETCH_EMPLOYEE_NOTIFICATIONS_REQUEST,
    page,
  };
};

export const fetchEmployeeNotificationsSuccess = (data) => {
  return {
    type: FETCH_EMPLOYEE_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const fetchEmployeeNotificationsFailure = (error) => {
  return {
    type: FETCH_EMPLOYEE_NOTIFICATIONS_FAILURE,
    error,
  };
};

export const deleteNotificationRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_NOTIFICATION_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteNotificationSuccess = () => {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
  };
};

export const deleteNotificationFailure = (error) => {
  return {
    type: DELETE_NOTIFICATION_FAILURE,
    error,
  };
};

export const deleteEmployeeAllNotificationRequest = () => {
  return {
    type: DELETE_EMPLOYEE_ALL_NOTIFICATION_REQUEST,
  };
};

export const deleteEmployeeAllNotificationSuccess = () => {
  return {
    type: DELETE_EMPLOYEE_ALL_NOTIFICATION_SUCCESS,
  };
};

export const deleteEmployeeAllNotificationFailure = (error) => {
  return {
    type: DELETE_EMPLOYEE_ALL_NOTIFICATION_FAILURE,
    error,
  };
};
