import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { NotifyUtil } from "../../utils";
import {
  DELETE_USER_REQUEST,
  FETCH_USERS_REQUEST,
  TOGGLE_USERS_STATUS_REQUEST,
} from "./userActionType";
import {
  deleteUserFailure,
  deleteUserSuccess,
  fetchUsersFailure,
  fetchUsersSuccess,
  toggleUsersStatusFailure,
  toggleUsersStatusSuccess,
} from "./userAction";
import { UserService } from "../../services";

function* fetchUsersSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      UserService.getUsers,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchUsersSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchUsersFailure(error));
  }
}

function* toggleUsersStatusSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(UserService.toggleUsersStatus, payload);
    yield put(toggleUsersStatusSuccess());
    toast.success("Successfully status updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(toggleUsersStatusFailure(error));
  }
}

function* deleteUserSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(UserService.deleteUser, payload);
    yield put(deleteUserSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteUserFailure(error));
  }
}

export function* userWatcherSaga() {
  yield takeEvery(FETCH_USERS_REQUEST, fetchUsersSaga);
  yield takeEvery(TOGGLE_USERS_STATUS_REQUEST, toggleUsersStatusSaga);
  yield takeEvery(DELETE_USER_REQUEST, deleteUserSaga);
}
