import { api } from "../config";

export const BrandService = {
  getBrands: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/brands/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getBrand: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/brands/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  createBrand: async (payload) => {
    try {
      const { data } = await api.post(
        "api/admin/brands/",
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateBrand: async (slug, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/brands/update/${slug}`,
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteBrand: async (payload) => {
    try {
      const { data } = await api.post("api/admin/brands/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
