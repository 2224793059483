import socketIo from "socket.io-client";

export const clientSocket = socketIo.connect(process.env.REACT_APP_API_DOMAIN);

export const SocketEvents = {
  DEAL_CREATED: "DEAL_CREATED",
  JOIN_ROOM: "JOIN_ROOM",
  USER_CREATED: "USER_CREATED",
  REPORT_CREATED: "REPORT_CREATED",
  REDEEM_REWARD_CREATED: "REDEEM_REWARD_CREATED",
};

export const SocketRooms = {
  ADMIN: "ADMIN",
};
