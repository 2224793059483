export const FETCH_ROLES_REQUEST = "FETCH_ROLES_REQUEST";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAILURE = "FETCH_ROLES_FAILURE";

export const FETCH_ROLE_REQUEST = "FETCH_ROLE_REQUEST";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
export const FETCH_ROLE_FAILURE = "FETCH_ROLE_FAILURE";

export const FETCH_ROLE_OPTIONS_REQUEST = "FETCH_ROLE_OPTIONS_REQUEST";
export const FETCH_ROLE_OPTIONS_SUCCESS = "FETCH_ROLE_OPTIONS_SUCCESS";
export const FETCH_ROLE_OPTIONS_FAILURE = "FETCH_ROLE_OPTIONS_FAILURE";

export const CREATE_ROLE_REQUEST = "CREATE_ROLE_REQUEST";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";
