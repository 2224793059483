import {
  Box,
  Button,
  Tab,
  ListItem,
  ListItemText,
  List,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { RewardRuleView, RewardView, UserView } from "../../components/views";
import { StringUtil } from "../../utils";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../const";

export const ViewRewardValidationLogPage = () => {
  const { id } = useParams();
  const rewardValidationLogs = useSelector(
    (state) => state.rewardValidationLog.rewardValidationLogs
  );
  const rewardValidationLog = rewardValidationLogs.find(
    (eachLog) => eachLog.id === id
  );
  const { user, reward } = rewardValidationLog;
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const TabValue = {
    DETAILS: "1",
    REWARD: "2",
    REWARD_RULE: "3",
    USER: "4",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DmBox>
      <Button
        variant="text"
        color="secondary"
        onClick={(e) => navigate("/reward-validation-logs")}
        startIcon={<ArrowBackIcon />}
        sx={{ mb: "1rem" }}
      >
        Back
      </Button>
      <TabContext value={value} width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Details" value={TabValue.DETAILS} />
            <Tab label="Reward" value={TabValue.REWARD} />
            <Tab label="Reward Rule" value={TabValue.REWARD_RULE} />
            <Tab label="User" value={TabValue.USER} />
          </TabList>
        </Box>
        <TabPanel value={TabValue.DETAILS} sx={{ p: "1rem 0px" }}>
          <List sx={{ pt: "0px" }}>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Id
                </Typography>
                <Box>
                  <Typography variant="value">
                    {rewardValidationLog.id}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Model
                </Typography>
                <Box>
                  <Typography variant="value">
                    {rewardValidationLog.model}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Model Reference
                </Typography>
                <Box>
                  <Typography variant="value">
                    {rewardValidationLog?.modelReference}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Property
                </Typography>
                <Box>
                  <Typography variant="value">
                    {rewardValidationLog.property}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Operator
                </Typography>
                <Box>
                  <Typography variant="value">
                    {rewardValidationLog.operator}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Comparison Value
                </Typography>
                <Box>
                  <Typography variant="value">
                    {rewardValidationLog.comparisonValue}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Message
                </Typography>
                <Box>
                  <Typography variant="value">
                    {rewardValidationLog.message}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Is Success
                </Typography>
                <Box>
                  <Typography variant="value">
                    {StringUtil.getYesOrNo(rewardValidationLog.isSuccess)}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>

            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Created
                </Typography>
                <Box>
                  <Typography variant="value">
                    {moment(rewardValidationLog.createdAt).format(
                      DATE_TIME_FORMAT
                    )}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Updated
                </Typography>
                <Box>
                  <Typography variant="value">
                    {moment(rewardValidationLog.updatedAt).format(
                      DATE_TIME_FORMAT
                    )}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
          </List>
        </TabPanel>
        <TabPanel value={TabValue.REWARD} sx={{ p: "1rem 0px" }}>
          <RewardView reward={reward} />
        </TabPanel>
        <TabPanel value={TabValue.REWARD_RULE} sx={{ p: "1rem 0px" }}>
          {reward.rewardRules.map((rewardRule, index) => (
            <RewardRuleView
              key={rewardRule.id}
              rewardRule={rewardRule}
              title={`#${index + 1} ${rewardRule.title} Details`}
            />
          ))}
        </TabPanel>
        <TabPanel value={TabValue.USER} sx={{ p: "1rem 0px" }}>
          <UserView user={user} title="User Details" />
        </TabPanel>
      </TabContext>
    </DmBox>
  );
};
