import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import {
  ColumnOption,
  DmTable,
  ColumnTitle,
  DmBox,
  StatusChip,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRedeemRewardStatusRequest,
  fetchRedeemRewardsRequest,
} from "../../store";
import moment from "moment";
import {
  Action,
  CanPerformAction,
  DATE_FORMAT,
  Models,
  RedeemRewardStatuses,
} from "../../const";
import { useToggle } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { searchQuerySelector } from "../../store/selector";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { Authenticate } from "../../hoc/Authenticate";
import { DmModal, RedeemRewardStatusModal } from "../../components/Modal";

export const RedeemRewardListPage = () => {
  const dispatch = useDispatch();
  const redeemRewardState = useSelector((state) => state.redeemReward);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [activeRow, setActiveRow] = useState({});
  const [activeRowId, setActiveRowId] = useState(); // this is used only for hide and show ... options
  const [offset, setOffset] = useState({ limit: 10, skip: 0 });
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openStatusModal, setOpenStatusModal] = useToggle();
  const searchQuery = useSelector(searchQuerySelector);
  const [redeemRewardStatus, setRedeemRewardStatus] = useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  // const [toggleCleared, setToggleCleared] = React.useState(false);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
    // if (state.selectedRows.length === 1) setActiveRow(state.selectedRows[0]);
  }, []);

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseEnterRowHandler = (row) => {
    // if (selectedRows.length === 0) setActiveRow(row);
    setActiveRowId(row.id);
  };
  const onMouseLeaveRowHandler = () => {
    setAnchorEl(null);
    setActiveRowId(null);
  };

  // const onSuccessfullyDelete = () => {
  //   setOffset({ limit: 10, skip: 0 });
  // };

  //   const onDelete = () => {
  //     dispatch(
  //       deleteRewardRequest(
  //         { rewardIds: _.map(selectedRows, "id") },
  //         onSuccessfullyDelete
  //       )
  //     );
  //     setOpenModal(); // close modal
  //     setToggleCleared(!toggleCleared); // clear all selection
  //   };

  const onClickMenuItem = (action, row) => {
    switch (action) {
      //   case Action.DELETE:
      //     setSelectedRows([row]);
      //     if (selectedRows.length === 1) setActiveRow(row);
      //     setOpenModal();
      //     break;
      case Action.EDIT:
        setSelectedRows([row]);
        setOpenStatusModal();
        break;
      case Action.VIEW:
        navigate(`/redeem-rewards/details/${row.id}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(
      fetchRedeemRewardsRequest(offset, searchQuery, redeemRewardStatus)
    );
  }, [dispatch, offset, searchQuery, redeemRewardStatus]);

  useEffect(() => {
    dispatch(fetchRedeemRewardStatusRequest());
  }, [dispatch]);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    const skip = getSkip(currentPage);
    setOffset({ limit: currentRowsPerPage, skip: skip });
  };

  const onChangePage = (page) => {
    const skip = getSkip(page);
    setOffset({ ...offset, skip });
  };

  const getSkip = (page) => {
    const skip = page - 1 > 0 ? page - 1 : 0;
    const calculateSkip = offset.limit * skip;
    return calculateSkip;
  };

  const onSuccess = () => {
    setOffset({ limit: offset.limit, skip: offset.skip });
  };

  const onChangeStatusHandler = (value) => {
    setRedeemRewardStatus(value);
  };

  const columns = [
    {
      name: <ColumnTitle title={"Title"} />,
      selector: (row) => (
        <Button
          variant="link"
          onClick={() => navigate(`/redeem-rewards/details/${row.id}`)}
        >
          {row.id}
        </Button>
      ),
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Reward"} />,
      selector: (row) => row.reward.title,
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"User"} />,
      selector: (row) => row.user.name,
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Email"} />,
      selector: (row) => row.user.email,
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Status"} />,
      selector: (row) => row.status,
    },
    {
      name: <ColumnTitle title={"Enabled"} />,
      selector: (row) => <StatusChip status={row.isActive} />,
    },
    {
      name: <ColumnTitle title={"Created"} />,
      selector: (row) => moment(row.createdAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Updated"} />,
      selector: (row) => moment(row.updatedAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={""} />,
      cell: (row) => (
        <>
          {activeRowId === row.id && (
            <ColumnOption
              row={row}
              open={open}
              anchorEl={anchorEl}
              handleClick={handleOptionClick}
              handleClose={handleOptionClose}
              onClickMenuItem={onClickMenuItem}
            >
              <Authenticate
                model={Models.REDEEM_REWARD}
                action={CanPerformAction.CAN_EDIT}
              >
                <MenuItem onClick={() => onClickMenuItem(Action.EDIT, row)}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText>Update Status</ListItemText>
                </MenuItem>
              </Authenticate>
              <MenuItem onClick={() => onClickMenuItem(Action.VIEW, row)}>
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText>View</ListItemText>
              </MenuItem>
            </ColumnOption>
          )}
        </>
      ),
    },
  ];

  return (
    <DmBox>
      <DmTable
        columns={columns}
        data={redeemRewardState.redeemRewards}
        title={"Redeem Reward List"}
        pending={redeemRewardState.loading}
        totalRow={redeemRewardState.totalRedeemRewards}
        onRowMouseEnter={onMouseEnterRowHandler}
        onRowMouseLeave={onMouseLeaveRowHandler}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        actions={
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
              <InputLabel>Status</InputLabel>
              <Select
                value={redeemRewardStatus}
                label="Status"
                onChange={(e) => onChangeStatusHandler(e.target.value)}
                sx={{ fontSize: ".9rem" }}
              >
                <MenuItem value={""} sx={{ fontSize: ".9rem" }}>
                  Select
                </MenuItem>
                <MenuItem
                  value={RedeemRewardStatuses.PENDING}
                  sx={{ fontSize: ".9rem" }}
                >
                  Pending
                </MenuItem>
                <MenuItem
                  value={RedeemRewardStatuses.ACCEPTED}
                  sx={{ fontSize: ".9rem" }}
                >
                  Accepted
                </MenuItem>
                <MenuItem
                  value={RedeemRewardStatuses.DECLINED}
                  sx={{ fontSize: ".9rem" }}
                >
                  Declined
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        }
        onSelectedRowsChange={handleRowSelected}
        //clearSelectedRows={toggleCleared}
      />
      {/* <DmModal open={openModal} closeModal={setOpenModal}>
        <ConfirmModal
          title={
            selectedRows.length > 1
              ? `Are you sure want to delete ${selectedRows.length} rewards ?`
              : `Are you sure you want to delete ${activeRow.title} ?`
          }
          subTitle="This action cannot be undone"
          btnConfirmText={"Delete"}
          btnCancelText="Cancel"
          onCancel={setOpenModal}
          onConfirm={onDelete}
        />
      </DmModal> */}
      <DmModal open={openStatusModal} closeModal={setOpenStatusModal}>
        <RedeemRewardStatusModal
          redeemReward={selectedRows[0]}
          onCancel={setOpenStatusModal}
          onSuccess={onSuccess}
        />
      </DmModal>
    </DmBox>
  );
};
