import {
  Stack,
  Typography,
  Divider,
  Paper,
  TableRow,
  TableCell,
  CircularProgress,
  Box,
  Link,
} from "@mui/material";
import React, { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DmBox, StatusChip, SummaryItem, SummaryTable } from "../components";
import { AreaChart, BarChart, PieChart } from "../components/charts";
import { DATE_TIME_FORMAT, MONTHS } from "../const";
import {
  fetchCategoryBrandStoreSummaryRequest,
  fetchSummaryRequest,
  fetchTopContributorsDashboardRequest,
  fetchTopMostReportedDealsRequest,
  fetchTopMostSearchedKeywordsRequest,
  fetchTopMostViewedDealsRequest,
  fetchMonthlyDealsSummaryRequest,
  fetchMonthlyReportsSummaryRequest,
  fetchMonthlyUsersSummaryRequest,
  fetchActiveUserSummaryRequest,
} from "../store";
import { loggedEmployeeSelector } from "../store/selector";
import { ArrayUtil, StringUtil, UrlUtil } from "../utils";
import PersonIcon from "@mui/icons-material/Person";
import DiscountIcon from "@mui/icons-material/Discount";
import ReportIcon from "@mui/icons-material/Report";
import _ from "lodash";
import moment from "moment";
import { clientSocket, SocketEvents, SocketRooms } from "../const/socket";

export const DashboardPage = () => {
  const loggedEmployee = useSelector(loggedEmployeeSelector);
  const dashboardState = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const monthlyDealsSummary = ArrayUtil.getMonthlySummaryFormattedData(
    dashboardState.monthlyDealsSummary
  );
  const monthlyUsersSummary = ArrayUtil.getMonthlySummaryFormattedData(
    dashboardState.monthlyUsersSummary
  );
  const monthlyReportsSummary = ArrayUtil.getMonthlySummaryFormattedData(
    dashboardState.monthlyReportsSummary
  );
  const monthlyActiveUsersSummary = ArrayUtil.getMonthlySummaryFormattedData(
    dashboardState.activeUserSummary.monthly
  );

  const dailyActiveUserSummary = _.map(
    dashboardState.activeUserSummary.daily,
    "value"
  );
  const dailyActiveUserCategories = _.map(
    dashboardState.activeUserSummary.daily,
    "day"
  );

  const {
    totalTodayDeals,
    totalDeals,
    totalExpiredDeals,
    totalActiveDeals,
    totalUsers,
    //totalEmployees,
    totalLikes,
    totalComments,
    totalReports,
    totalDiscountType,
    totalCouponType,
    totalBuyXGetXType,
    totalOfferType,
    averageMonthlyActiveUsers,
    averageDailyActiveUsers,
  } = dashboardState.summary;

  const {
    categoryDeals: categorySummary,
    brandDeals: brandSummary,
    storeDeals: storeSummary,
  } = dashboardState.categoryBrandStoreSummary;

  // state
  const [contributorLimit, setContributorLimit] = useState(10);
  const [topMostViewedDealsLimit, setTopMostViewedDealsLimit] = useState(10);
  const [topMostReportedDealsLimit, setTopMostReportedDealsLimit] =
    useState(10);
  const [topMostSearchedKeywordsLimit, setTopMostSearchedKeywordsLimit] =
    useState(10);

  //const [totalActiveVisitors, setTotalActiveVisitors] = useState(0);

  //use effect

  useEffect(() => {
    dispatch(fetchTopContributorsDashboardRequest(contributorLimit));
  }, [dispatch, contributorLimit]);

  useEffect(() => {
    dispatch(fetchTopMostViewedDealsRequest(topMostViewedDealsLimit));
  }, [dispatch, topMostViewedDealsLimit]);

  useEffect(() => {
    dispatch(fetchTopMostReportedDealsRequest(topMostReportedDealsLimit));
  }, [dispatch, topMostReportedDealsLimit]);

  useEffect(() => {
    dispatch(fetchTopMostSearchedKeywordsRequest(topMostSearchedKeywordsLimit));
  }, [dispatch, topMostSearchedKeywordsLimit]);

  useEffect(() => {
    Promise.all([
      dispatch(fetchMonthlyDealsSummaryRequest()),
      dispatch(fetchMonthlyUsersSummaryRequest()),
      dispatch(fetchMonthlyReportsSummaryRequest()),
      dispatch(fetchSummaryRequest()),
      dispatch(fetchCategoryBrandStoreSummaryRequest()),
      dispatch(fetchActiveUserSummaryRequest()),
    ]);
  }, [dispatch]);

  // socket
  // const totalActiveVisitorsSocketEventHandler = useCallback(
  //   (currentActiveVisitors) => {
  //     console.log(currentActiveVisitors);
  //     setTotalActiveVisitors(currentActiveVisitors);
  //   },
  //   []
  // );

  const summarySocketEventHandler = useCallback(() => {
    dispatch(fetchSummaryRequest());
  }, [dispatch]);

  useEffect(() => {
    // emit join room
    console.log(clientSocket);
    clientSocket.emit(SocketEvents.JOIN_ROOM, SocketRooms.ADMIN); // join room
  }, []);

  useEffect(() => {
    // subscribe to user created events
    clientSocket.on(SocketEvents.USER_CREATED, summarySocketEventHandler);
    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      clientSocket.off(SocketEvents.USER_CREATED, summarySocketEventHandler);
    };
  }, [summarySocketEventHandler]);

  useEffect(() => {
    // subscribe to report created events
    clientSocket.on(SocketEvents.REPORT_CREATED, summarySocketEventHandler);
    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      clientSocket.off(SocketEvents.REPORT_CREATED, summarySocketEventHandler);
    };
  }, [summarySocketEventHandler]);

  useEffect(() => {
    // subscribe to deal created events
    clientSocket.on(SocketEvents.DEAL_CREATED, summarySocketEventHandler);
    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      clientSocket.off(SocketEvents.DEAL_CREATED, summarySocketEventHandler);
    };
  }, [summarySocketEventHandler]);

  // useEffect(() => {
  //   // subscribe to socket events
  //   clientSocket.on(
  //     SocketEvents.TOTAL_ACTIVE_VISITORS,
  //     totalActiveVisitorsSocketEventHandler
  //   );
  //   return () => {
  //     // before the component is destroyed
  //     // unbind all event handlers used in this component
  //     clientSocket.off(
  //       SocketEvents.TOTAL_ACTIVE_VISITORS,
  //       totalActiveVisitorsSocketEventHandler
  //     );
  //   };
  // }, [totalActiveVisitorsSocketEventHandler]);

  return (
    <DmBox>
      <Stack spacing={1}>
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="1.8rem"
          lineHeight={1.25}
        >
          Dashboard
        </Typography>
        <Typography>Welcome back, {loggedEmployee.firstName}!</Typography>
      </Stack>
      <Divider sx={{ m: "1rem 0" }} />
      {/* dashboard summary */}
      <Stack direction="row" spacing={2}>
        {/* <SummaryItem
          key={"Live Visitors"}
          title={"Live Visitors"}
          fontColor="rgb(122, 79, 1)"
          bgcolor="rgb(255, 247, 205)"
          linearGradient="linear-gradient(135deg, rgba(183, 129, 3, 0) 0%, rgba(183, 129, 3, 0.24) 100%)"
          icon={<BadgeIcon style={{ color: "rgb(183, 129, 3)" }} />}
          value={totalActiveVisitors}
        /> */}
        <SummaryItem
          title={"Total Users"}
          value={StringUtil.abbrNum(totalUsers)}
          linearGradient="linear-gradient(135deg, rgba(0, 123, 85, 0) 0%, rgba(0, 123, 85, 0.24) 100%)"
          bgcolor="rgb(200, 250, 205)"
          fontColor="rgb(0, 82, 73)"
          icon={<PersonIcon style={{ color: "rgb(0, 123, 85)" }} />}
        />
        <SummaryItem
          title={"Monthly Active Users"}
          value={StringUtil.abbrNum(averageMonthlyActiveUsers)}
          linearGradient="linear-gradient(to right top, #38e163, #3ce56b, #40e974, #45ec7c, #49f084)"
          bgcolor="rgb(197, 230, 134)"
          fontColor="rgb(10, 10, 10)"
          icon={<PersonIcon style={{ color: "rgb(0, 123, 85)" }} />}
        />
        <SummaryItem
          title={"Daily Active Users"}
          value={StringUtil.abbrNum(averageDailyActiveUsers)}
          linearGradient="linear-gradient(to right top, #38e163, #3ce56b, #40e974, #45ec7c, #49f084)"
          bgcolor="rgb(197, 230, 134)"
          fontColor="rgb(10, 10, 10)"
          icon={<PersonIcon style={{ color: "rgb(0, 123, 85)" }} />}
        />
        <SummaryItem
          title={"Today Deals"}
          value={StringUtil.abbrNum(totalTodayDeals)}
          linearGradient="linear-gradient(135deg, rgba(12, 83, 183, 0) 0%, rgba(12, 83, 183, 0.24) 100%)"
          bgcolor="#1FD1F9"
          fontColor="#6617CB"
          icon={<DiscountIcon style={{ color: "rgb(12, 83, 183)" }} />}
        />
        <SummaryItem
          key={"Deals"}
          title={"Total Deals"}
          value={StringUtil.abbrNum(totalDeals)}
          fontColor="rgb(4, 41, 122)"
          bgcolor="rgb(208, 242, 255)"
          linearGradient="linear-gradient(135deg, rgba(12, 83, 183, 0) 0%, rgba(12, 83, 183, 0.24) 100%)"
          icon={<DiscountIcon style={{ color: "rgb(12, 83, 183)" }} />}
        />
        <SummaryItem
          key={"Reports"}
          title={"Total Reports"}
          fontColor="rgb(122, 12, 46)"
          bgcolor="rgb(255, 231, 217)"
          linearGradient="linear-gradient(135deg, rgba(183, 33, 54, 0) 0%, rgba(183, 33, 54, 0.24) 100%)"
          icon={<ReportIcon style={{ color: "rgb(183, 33, 54)" }} />}
          value={StringUtil.abbrNum(totalReports)}
        />
      </Stack>
      {/* monthly deals and reports */}
      <Stack mt="1rem" direction="row" justifyContent="center" spacing={2}>
        <Paper
          sx={{
            p: "1rem",
            boxShadow: (theme) => theme.palette.boxShadow,
            width: "50%",
          }}
        >
          <AreaChart
            title="Monthly Deals"
            seriesName="Deals"
            data={monthlyDealsSummary}
            categories={MONTHS}
            height={450}
          />
        </Paper>
        <Paper
          sx={{
            p: "1rem",
            boxShadow: (theme) => theme.palette.boxShadow,
            width: "50%",
          }}
        >
          <BarChart
            title="Monthly Reports"
            seriesName="Reports"
            data={monthlyReportsSummary}
            categories={MONTHS}
            height={450}
          />
        </Paper>
      </Stack>
      {/* monthly and daily active user */}
      <Stack mt="1rem" direction="row" justifyContent="center" spacing={2}>
        <Paper
          sx={{
            p: "1rem",
            boxShadow: (theme) => theme.palette.boxShadow,
            width: "50%",
          }}
        >
          <BarChart
            title="Monthly Active Users"
            seriesName="Users"
            data={monthlyActiveUsersSummary}
            categories={MONTHS}
            height={450}
          />
        </Paper>
        <Paper
          sx={{
            p: "1rem",
            boxShadow: (theme) => theme.palette.boxShadow,
            width: "50%",
          }}
        >
          <AreaChart
            title={`${moment(moment().month() + 1, "M").format(
              "MMMM"
            )} - Daily Active Users`} // month start from 0
            seriesName="Users"
            data={dailyActiveUserSummary}
            categories={dailyActiveUserCategories}
            height={450}
          />
        </Paper>
      </Stack>
      {/* new user summary */}
      <Stack mt="1rem" direction="row" justifyContent="center" spacing={2}>
        <Paper
          sx={{
            p: "1rem",
            boxShadow: (theme) => theme.palette.boxShadow,
            width: "100%",
          }}
        >
          <BarChart
            title="Monthly New Users"
            seriesName="Users"
            data={monthlyUsersSummary}
            categories={MONTHS}
            height={450}
          />
        </Paper>
      </Stack>
      {/* store and brand summary */}
      <Stack mt="1rem" direction="row" justifyContent="center" spacing={2}>
        <Paper
          sx={{
            p: "1rem",
            boxShadow: (theme) => theme.palette.boxShadow,
            width: "50%",
          }}
        >
          <BarChart
            title="Top 10 Store Summary"
            seriesName="Deals"
            data={_.map(storeSummary, "value")}
            categories={_.map(storeSummary, "name")}
            height={450}
          />
        </Paper>
        <Paper
          sx={{
            p: "1rem",
            boxShadow: (theme) => theme.palette.boxShadow,
            width: "50%",
          }}
        >
          <AreaChart
            title="Top 10 Brand Summary"
            seriesName="Deals"
            data={_.map(brandSummary, "value")}
            categories={_.map(brandSummary, "name")}
            height={450}
          />
        </Paper>
      </Stack>
      {/* category summary */}
      <Stack mt="1rem" direction="row" justifyContent="center" spacing={2}>
        <Paper
          sx={{
            p: "1rem",
            boxShadow: (theme) => theme.palette.boxShadow,
            width: "100%",
          }}
        >
          <BarChart
            title="Top 20 Category Summary"
            seriesName="Deals"
            data={_.map(categorySummary, "value")}
            categories={_.map(categorySummary, "name")}
            height={450}
          />
        </Paper>
      </Stack>
      {/* most searched keywords */}
      <Stack mt="1rem" direction="row" justifyContent="center" spacing={2}>
        <SummaryTable
          key="topMostSearchedKeywordsTable"
          loading={dashboardState.topMostReportedDealsLoading}
          columns={["Sl.", "Keyword", "Searched", "Created", "Updated"]}
          title="Top Most Searched Keywords"
          onChangeLimitHandler={setTopMostSearchedKeywordsLimit}
          limit={topMostSearchedKeywordsLimit}
        >
          {dashboardState.topMostSearchedKeywords.map(
            (searchKeyword, index) => (
              <TableRow
                key={searchKeyword.keyword}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell align="left">{searchKeyword.keyword}</TableCell>
                <TableCell align="left">{searchKeyword.counter}</TableCell>
                <TableCell align="left">
                  {moment(searchKeyword.createdAt).format(DATE_TIME_FORMAT)}
                </TableCell>
                <TableCell align="left">
                  {moment(searchKeyword.updatedAt).format(DATE_TIME_FORMAT)}
                </TableCell>
              </TableRow>
            )
          )}
        </SummaryTable>
        {dashboardState.loading ? (
          <CircularProgress />
        ) : (
          <Paper
            sx={{
              p: "1rem",
              boxShadow: (theme) => theme.palette.boxShadow,
              width: "50%",
              minWidth: "450px",
            }}
          >
            <PieChart
              title="Deal Types Summary"
              series={[
                totalDiscountType,
                totalCouponType,
                totalBuyXGetXType,
                totalOfferType,
              ]}
              labels={[
                "Total Discounts",
                "Total Coupons",
                "Total BuyXGetX",
                "Total Offers",
              ]}
              height={450}
              type="donut"
            />
          </Paper>
        )}
      </Stack>
      {/* summary and contributions */}
      <Stack mt="1rem" direction="row" justifyContent="center" spacing={2}>
        {dashboardState.loading ? (
          <CircularProgress />
        ) : (
          <Paper
            sx={{
              p: "1rem",
              boxShadow: (theme) => theme.palette.boxShadow,
              width: "50%",
              minWidth: "450px",
            }}
          >
            <PieChart
              series={[
                totalDeals,
                totalActiveDeals,
                totalLikes,
                totalComments,
                totalExpiredDeals,
                totalReports,
              ]}
              labels={[
                "Total Deals",
                "Total Active Deals",
                "Total Likes",
                "Total Comments",
                "Total Expired Deals",
                "Total Reports",
              ]}
              height={450}
            />
          </Paper>
        )}
        <SummaryTable
          key="contributorsTable"
          loading={dashboardState.contributorsLoading}
          columns={[
            "Sl.",
            "Name",
            "Email",
            "Image",
            "Deals",
            "Reputation",
            "Enabled",
          ]}
          title="Top Contributors"
          onChangeLimitHandler={setContributorLimit}
          limit={contributorLimit}
        >
          {dashboardState.contributors.map((contributor, index) => (
            <TableRow
              key={contributor.email}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell align="left">{contributor.name}</TableCell>
              <TableCell align="left">{contributor.email}</TableCell>
              <TableCell align="left">
                <Box
                  component="img"
                  sx={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                  src={
                    contributor.image?.path
                      ? UrlUtil.getFullImagePath(contributor.image.path)
                      : "/images/default-thumbnail-image.gif"
                  }
                />
              </TableCell>
              <TableCell align="left">{contributor.totalDeals}</TableCell>
              <TableCell align="left">{contributor.reputation}</TableCell>
              <TableCell align="left">
                <StatusChip status={contributor.isActive} />
              </TableCell>
            </TableRow>
          ))}
        </SummaryTable>
      </Stack>
      <Stack mt="1rem">
        <SummaryTable
          key="topMostViewedDealsTable"
          loading={dashboardState.topMostViewedDealsLoading}
          columns={[
            "Sl.",
            "Id",
            "Title",
            "Price",
            "Regular Price",
            "Type",
            "Views",
            "Likes",
            "Dislikes",
            "Comments",
            "Enabled",
          ]}
          title="Top Most Viewed Deals"
          onChangeLimitHandler={setTopMostViewedDealsLimit}
          limit={topMostViewedDealsLimit}
        >
          {dashboardState.topMostViewedDeals.map((deal, index) => (
            <TableRow
              key={deal.slug}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell align="left">{deal.id}</TableCell>
              <TableCell align="left">
                <Link
                  href={UrlUtil.getDomainDealViewUrl(
                    deal.category.slug,
                    deal.slug,
                    deal.country.slug
                  )}
                  target="_blank"
                  rel="noopener"
                  sx={{ textDecoration: "none" }}
                >
                  {deal.title}
                </Link>
              </TableCell>
              <TableCell align="left">{deal.price}</TableCell>
              <TableCell align="left">{deal.regularPrice}</TableCell>
              <TableCell align="left">{_.capitalize(deal.dealType)}</TableCell>
              <TableCell align="left">{deal.views}</TableCell>
              <TableCell align="left">{deal.totalLikes}</TableCell>
              <TableCell align="left">{deal.totalDislikes}</TableCell>
              <TableCell align="left">{deal.totalComments}</TableCell>
              <TableCell align="left">
                <StatusChip status={deal.isActive} />
              </TableCell>
            </TableRow>
          ))}
        </SummaryTable>
      </Stack>
      <Stack mt="1rem">
        <SummaryTable
          key="topMostReportedDealsTable"
          loading={dashboardState.topMostReportedDealsLoading}
          columns={[
            "Sl.",
            "Id",
            "Title",
            "Price",
            "Regular Price",
            "Type",
            "Reports",
            "Views",
            "Enabled",
          ]}
          title="Top Most Reported Deals"
          onChangeLimitHandler={setTopMostReportedDealsLimit}
          limit={topMostReportedDealsLimit}
        >
          {dashboardState.topMostReportedDeals.map((deal, index) => (
            <TableRow
              key={deal.slug}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell align="left">{deal.id}</TableCell>
              <TableCell align="left">
                <Link
                  href={UrlUtil.getDomainDealViewUrl(
                    deal.category.slug,
                    deal.slug,
                    deal.country.id
                  )}
                  target="_blank"
                  rel="noopener"
                  sx={{ textDecoration: "none" }}
                >
                  {deal.title}
                </Link>
              </TableCell>
              <TableCell align="left">{deal.price}</TableCell>
              <TableCell align="left">{deal.regularPrice}</TableCell>
              <TableCell align="left">{_.capitalize(deal.dealType)}</TableCell>
              <TableCell align="left">{deal.reports}</TableCell>
              <TableCell align="left">{deal.views}</TableCell>
              <TableCell align="left">
                <StatusChip status={deal.isActive} />
              </TableCell>
            </TableRow>
          ))}
        </SummaryTable>
      </Stack>
    </DmBox>
  );
};
