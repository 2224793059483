import { api } from "../config";

export const DashboardService = {
  getTopContributors: async (limit) => {
    try {
      const { data } = await api.get(
        `api/admin/dashboard/get-top-contributors/?limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getMonthlyDealsSummary: async () => {
    try {
      const { data } = await api.get(
        "api/admin/dashboard/get-monthly-deals-summary"
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getMonthlyUsersSummary: async () => {
    try {
      const { data } = await api.get(
        "api/admin/dashboard/get-monthly-users-summary"
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getMonthlyReportsSummary: async () => {
    try {
      const { data } = await api.get(
        "api/admin/dashboard/get-monthly-reports-summary"
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getSummary: async () => {
    try {
      const { data } = await api.get("api/admin/dashboard/get-summary");
      return data;
    } catch (error) {
      throw error;
    }
  },
  getTopMostViewedDeals: async (limit) => {
    try {
      const { data } = await api.get(
        `api/admin/dashboard/get-top-most-viewed-deals/?limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getTopMostReportedDeals: async (limit) => {
    try {
      const { data } = await api.get(
        `api/admin/dashboard/get-top-most-reported-deals/?limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getTopMostSearchedKeywords: async (limit) => {
    try {
      const { data } = await api.get(
        `api/admin/dashboard/get-top-most-searched-keywords/?limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getCategoryBrandStoreSummary: async () => {
    try {
      const { data } = await api.get(
        "api/admin/dashboard/get-category-brand-store-summary"
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getActiveUserSummary: async () => {
    try {
      const { data } = await api.get(
        "api/admin/dashboard/get-active-user-summary"
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
