import { Stack, Button, Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, InputSwitch, InputFile } from "../";
import {
  createRewardRequest,
  fetchAllCountriesRequest,
  fetchAllRewardRulesRequest,
  updateRewardRequest,
} from "../../store";
import { ArrayUtil, ValidationUtil } from "../../utils";
import { AutoCompleteMultiSelectCheckBox, AutoCompleteSelect } from "../Input";
import ReactQuill from "react-quill";
import { QuilConfig } from "../../config/quillConfig";
import "react-quill/dist/quill.snow.css";

export const RewardForm = ({ reward }) => {
  const [isActive, setIsActive] = useState(false);
  const [files, setFiles] = useState(reward?.image?.path ? [reward.image] : []);
  const countryState = useSelector((state) => state.country);
  const rewardRuleState = useSelector((state) => state.rewardRule);
  const allRewardRules = rewardRuleState?.rewardRules;
  const countryOptions = ArrayUtil.getOptions(countryState?.countries);
  const [rewardRuleOptions, setRewardRuleOptions] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(
    reward?.country?.id && {
      value: reward?.country?.id,
      label: reward?.country?.displayName ?? reward?.country?.name,
    }
  );

  const [selectedRewardRules, setSelectedRewardRules] = useState(
    reward?.rewardRules ? ArrayUtil.getOptions(reward.rewardRules) : []
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = reward
    ? {
        title: reward.title,
        displayTitle: reward.displayTitle,
        availLimit: reward.availLimit,
        description: reward.description,
        deductibleReputation: reward.deductibleReputation,
        isActive: reward.isActive,
      }
    : {};

  useEffect(() => {
    dispatch(fetchAllCountriesRequest());
    dispatch(fetchAllRewardRulesRequest());
  }, [dispatch]);

  useEffect(() => {
    const options = ArrayUtil.getOptions(allRewardRules);
    setRewardRuleOptions(options);
  }, [allRewardRules]);

  const onSubmit = async (values) => {
    const {
      title,
      displayTitle,
      country,
      rewardRules,
      availLimit,
      description,
      deductibleReputation,
      isActive,
    } = values;
    let formData = new FormData();
    let rewardRuleIds = [];
    for (let rewardRule of rewardRules) {
      rewardRuleIds.push(rewardRule.value);
    }
    formData.append("title", title);
    formData.append("display_title", displayTitle);
    formData.append("country", country.value);
    formData.append("deductible_reputation", deductibleReputation ?? 0); // is optional
    formData.append("avail_limit", availLimit);
    formData.append("description", description);
    formData.append("reward_rules", rewardRuleIds.join(","));
    formData.append("is_active", isActive ?? false);
    formData.append(
      "image",
      files[0]?.size ? files[0] : JSON.stringify(files[0])
    );
    reward?.id
      ? dispatch(updateRewardRequest(reward.slug, formData, onSuccess))
      : dispatch(createRewardRequest(formData, onSuccess));
  };

  const onSuccess = () => {
    navigate("/rewards");
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, errors }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            if (Object.keys(errors).length === 0) form.reset();
          }}
          noValidate
          autoComplete="off"
        >
          <Stack alignItems="center">
            <Typography variant="h4">
              {reward ? `Edit ${reward.title}` : "Add new reward"}
            </Typography>
            <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
              <Field
                name="title"
                component={Input}
                label="Title"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="displayTitle"
                component={Input}
                label="Display Title"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="country"
                component={AutoCompleteSelect}
                label="Country"
                options={countryOptions}
                handleOnChange={(value) => setSelectedCountry(value)}
                value={selectedCountry}
                defaultValue={selectedCountry}
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="availLimit"
                component={Input}
                label="Avail Limit"
                type="number"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="deductibleReputation"
                component={Input}
                label="Deductible Reputation"
                type="number"
              />
              <Field
                name="rewardRules"
                component={AutoCompleteMultiSelectCheckBox}
                label="Reward Rules"
                options={rewardRuleOptions}
                handleOnChange={(value) => setSelectedRewardRules(value)}
                value={selectedRewardRules}
                defaultValue={selectedRewardRules}
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="description"
                validate={ValidationUtil.requiredValidator}
              >
                {({ input: { value, ...input }, meta }) => (
                  <>
                    <ReactQuill
                      modules={QuilConfig.modules}
                      formats={QuilConfig.formats}
                      theme="snow"
                      value={value}
                      {...input}
                      placeholder="Descriptions"
                      className="deal-form-editor"
                      onChange={(newValue, delta, source) => {
                        if (source === "user") {
                          input.onChange(newValue);
                        }
                      }}
                    />
                    <Typography
                      color="error"
                      sx={{
                        fontSize: "0.75rem",
                        pl: "8px",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      {meta.error && meta.touched && meta.error}
                    </Typography>
                  </>
                )}
              </Field>
              <Box>
                <Field
                  name="isActive"
                  checked={isActive}
                  component={InputSwitch}
                  handleOnChange={(value) => setIsActive(value)}
                  type="checkbox"
                  label="Is Active"
                  labelPlacement="start"
                  sx={{ ml: "0px" }}
                />
              </Box>
              <Field
                name="image"
                component={InputFile}
                files={files}
                key={files.length > 0 ? 1 : 0} // changed key to re-render validate function
                onChangeHandler={(value) => {
                  setFiles(value);
                }}
                validate={
                  reward?.image
                    ? ValidationUtil.composeValidators(
                        () => ValidationUtil.requiredValidator(files),
                        () =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1),
                        () => ValidationUtil.fileSizeValidator(files)
                      )
                    : ValidationUtil.composeValidators(
                        ValidationUtil.requiredValidator,
                        ValidationUtil.fileSizeValidator,
                        ValidationUtil.fileExtensionValidator,
                        (files) =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1)
                      )
                }
                sx={{
                  border: "2px dashed #5E54FF",
                  p: ".8rem",
                  alignItems: "center",
                }}
              />
              <Stack direction="row" spacing={2} mt={"30px"}>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    backgroundColor: "#03a9f4",
                    "&:hover": {
                      backgroundColor: "#0288d1",
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  onClick={() => navigate("/rewards")}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
