import { api } from "../config";

export const RewardRuleService = {
  getRewardRules: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/reward-rules/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getAllRewardRules: async () => {
    try {
      const { data } = await api.get("api/admin/reward-rules/all");
      return data;
    } catch (error) {
      throw error;
    }
  },
  getRewardRule: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/reward-rules/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  createRewardRule: async (payload) => {
    try {
      const { data } = await api.post(
        "api/admin/reward-rules/",
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateRewardRule: async (slug, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/reward-rules/update/${slug}`,
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteRewardRule: async (payload) => {
    try {
      const { data } = await api.post("api/admin/reward-rules/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
