import _ from "lodash";
import {
  DELETE_EMPLOYEE_ALL_NOTIFICATION_FAILURE,
  DELETE_EMPLOYEE_ALL_NOTIFICATION_REQUEST,
  DELETE_EMPLOYEE_ALL_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION_REQUEST,
  FETCH_EMPLOYEE_NOTIFICATIONS_FAILURE,
  FETCH_EMPLOYEE_NOTIFICATIONS_REQUEST,
  FETCH_EMPLOYEE_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
} from "./notificationActionType";

const initialState = {
  loading: false,
  notifications: [],
  employeeNotifications: [],
  totalNotifications: 0,
  hasMoreEmployeeNotification: true,
  error: "",
};

export const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
    case FETCH_EMPLOYEE_NOTIFICATIONS_REQUEST:
    case DELETE_EMPLOYEE_ALL_NOTIFICATION_REQUEST:
    case DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      const { notifications, totalNotifications } = action.payload;
      return {
        ...state,
        loading: false,
        notifications: notifications,
        totalNotifications: totalNotifications,
      };
    case FETCH_EMPLOYEE_NOTIFICATIONS_SUCCESS:
      for (const newNotification of action.payload) {
        const index = _.findIndex(state.employeeNotifications, {
          id: newNotification.id,
        });
        if (index !== -1)
          state.employeeNotifications.splice(index, 1, newNotification);
        else state.employeeNotifications.push(newNotification);
      }

      const notificationsOrderBy = _.orderBy(
        state.employeeNotifications,
        ["createdAt"],
        ["desc"]
      );

      return {
        ...state,
        loading: false,
        hasMoreEmployeeNotification: action.payload.length !== 0,
        employeeNotifications: notificationsOrderBy,
      };

    case DELETE_EMPLOYEE_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        employeeNotifications: [],
      };

    case FETCH_NOTIFICATIONS_FAILURE:
    case FETCH_EMPLOYEE_NOTIFICATIONS_FAILURE:
    case DELETE_NOTIFICATION_FAILURE:
    case DELETE_EMPLOYEE_ALL_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
