import { api } from "../config";

export const CountryService = {
  getAllCountries: async () => {
    try {
      const { data } = await api.get("api/admin/countries/all");
      return data;
    } catch (error) {
      throw error;
    }
  },
  getCountries: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/countries/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getCountry: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/countries/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  createCountry: async (payload) => {
    try {
      const { data } = await api.post("api/admin/countries/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateCountry: async (slug, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/countries/update/${slug}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteCountry: async (payload) => {
    try {
      const { data } = await api.post("api/admin/countries/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
