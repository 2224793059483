import {
  CREATE_FACEBOOK_PAGE_FAILURE,
  CREATE_FACEBOOK_PAGE_REQUEST,
  CREATE_FACEBOOK_PAGE_SUCCESS,
  DELETE_FACEBOOK_PAGE_FAILURE,
  DELETE_FACEBOOK_PAGE_REQUEST,
  DELETE_FACEBOOK_PAGE_SUCCESS,
  EXPORT_FACEBOOK_PAGES_FAILURE,
  EXPORT_FACEBOOK_PAGES_REQUEST,
  EXPORT_FACEBOOK_PAGES_SUCCESS,
  FETCH_FACEBOOK_PAGES_FAILURE,
  FETCH_FACEBOOK_PAGES_REQUEST,
  FETCH_FACEBOOK_PAGES_SUCCESS,
  FETCH_FACEBOOK_PAGE_FAILURE,
  FETCH_FACEBOOK_PAGE_REQUEST,
  FETCH_FACEBOOK_PAGE_SUCCESS,
  UPDATE_FACEBOOK_PAGE_FAILURE,
  UPDATE_FACEBOOK_PAGE_REQUEST,
  UPDATE_FACEBOOK_PAGE_SUCCESS,
} from "./facebookPageActionType";

export const fetchFacebookPagesRequest = (offset, searchQuery) => {
  return {
    type: FETCH_FACEBOOK_PAGES_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchFacebookPagesSuccess = (data) => {
  return {
    type: FETCH_FACEBOOK_PAGES_SUCCESS,
    payload: data,
  };
};

export const fetchFacebookPagesFailure = (error) => {
  return {
    type: FETCH_FACEBOOK_PAGES_FAILURE,
    error,
  };
};

export const fetchFacebookPageRequest = (slug) => {
  return {
    type: FETCH_FACEBOOK_PAGE_REQUEST,
    slug,
  };
};

export const fetchFacebookPageSuccess = (data) => {
  return {
    type: FETCH_FACEBOOK_PAGE_SUCCESS,
    payload: data,
  };
};

export const fetchFacebookPageFailure = (error) => {
  return {
    type: FETCH_FACEBOOK_PAGE_FAILURE,
    error,
  };
};

export const createFacebookPageRequest = (payload, onSuccess) => {
  return {
    type: CREATE_FACEBOOK_PAGE_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};
export const createFacebookPageSuccess = (data) => {
  return {
    type: CREATE_FACEBOOK_PAGE_SUCCESS,
    payload: data,
  };
};
export const createFacebookPageFailure = (error) => {
  return {
    type: CREATE_FACEBOOK_PAGE_FAILURE,
    error,
  };
};

export const updateFacebookPageRequest = (slug, payload, onSuccess) => {
  return {
    type: UPDATE_FACEBOOK_PAGE_REQUEST,
    slug,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateFacebookPageSuccess = () => {
  return {
    type: UPDATE_FACEBOOK_PAGE_SUCCESS,
  };
};

export const updateFacebookPageFailure = (error) => {
  return {
    type: UPDATE_FACEBOOK_PAGE_FAILURE,
    error,
  };
};

export const deleteFacebookPageRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_FACEBOOK_PAGE_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteFacebookPageSuccess = () => {
  return {
    type: DELETE_FACEBOOK_PAGE_SUCCESS,
  };
};

export const deleteFacebookPageFailure = (error) => {
  return {
    type: DELETE_FACEBOOK_PAGE_FAILURE,
    error,
  };
};

export const exportFacebookPageRequest = (onSuccessfullyExported) => {
  return {
    type: EXPORT_FACEBOOK_PAGES_REQUEST,
    meta: {
      onSuccess: onSuccessfullyExported,
    },
  };
};
export const exportFacebookPageSuccess = () => {
  return {
    type: EXPORT_FACEBOOK_PAGES_SUCCESS,
  };
};
export const exportFacebookPageFailure = (error) => {
  return {
    type: EXPORT_FACEBOOK_PAGES_FAILURE,
    error,
  };
};
