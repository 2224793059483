import { Stack, Button, InputAdornment, Box, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, InputSwitch } from "../";
import { createBrandRequest, updateBrandRequest } from "../../store";
import { ValidationUtil } from "../../utils";

export const BrandForm = ({ brand }) => {
  const [isActive, setIsActive] = useState(true);
  //const [files, setFiles] = useState(brand?.image?.path ? [brand.image] : []);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const { name, displayName, websiteLink, isActive } = values;
    //let formData = new FormData();

    // formData.append("name", name);
    // formData.append("display_name", displayName);
    // formData.append("website_link", websiteLink);
    // formData.append("is_active", isActive ?? false);
    // formData.append(
    //   "image",
    //   files[0]?.size ? files[0] : JSON.stringify(files[0])
    // );
    const payload = {
      name,
      display_name: displayName,
      website_link: websiteLink,
      is_active: isActive,
    };
    brand?.id
      ? dispatch(updateBrandRequest(brand.slug, payload, onSuccess))
      : dispatch(createBrandRequest(payload, onSuccess));
  };

  const onSuccess = () => {
    navigate("/brands");
  };

  const initialValues = brand
    ? {
        name: brand.name,
        displayName: brand.displayName,
        websiteLink: brand.websiteLink,
        isActive: brand.isActive,
      }
    : {};

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, errors }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            if (Object.keys(errors).length === 0) form.reset();
          }}
          noValidate
          autoComplete="off"
        >
          <Stack alignItems="center">
            <Typography variant="h4">
              {brand ? `Edit ${brand.name}` : "Add new brand"}
            </Typography>
            <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
              <Field
                name="name"
                component={Input}
                label="Name"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="displayName"
                component={Input}
                label="Display Name"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="websiteLink"
                component={Input}
                label="Website Link"
                validate={ValidationUtil.composeValidators(
                  ValidationUtil.requiredValidator,
                  ValidationUtil.isValidHttpUrl
                )}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">http</InputAdornment>
                  ),
                }}
              />
              <Box>
                <Field
                  name="isActive"
                  checked={isActive}
                  defaultValue={isActive}
                  component={InputSwitch}
                  handleOnChange={(value) => setIsActive(value)}
                  type="checkbox"
                  label="Is Active"
                  labelPlacement="start"
                  sx={{ ml: "0px" }}
                />
              </Box>
              {/* <Field
                name="image"
                component={InputFile}
                files={files}
                key={files.length > 0 ? 1 : 0} // changed key to re-render validate function
                onChangeHandler={(value) => {
                  setFiles(value);
                }}
                validate={
                  brand?.image
                    ? ValidationUtil.composeValidators(
                        () => ValidationUtil.requiredValidator(files),
                        () =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1),
                        () => ValidationUtil.fileSizeValidator(files)
                      )
                    : ValidationUtil.composeValidators(
                        ValidationUtil.requiredValidator,
                        ValidationUtil.fileSizeValidator,
                        ValidationUtil.fileExtensionValidator,
                        (files) =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1)
                      )
                }
                sx={{
                  border: "2px dashed #5E54FF",
                  p: ".8rem",
                  alignItems: "center",
                }}
              /> */}
              <Stack direction="row" spacing={2} mt={"30px"}>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    backgroundColor: "#03a9f4",
                    "&:hover": {
                      backgroundColor: "#0288d1",
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  onClick={() => navigate("/brands")}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
