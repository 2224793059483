import {
  CREATE_FACEBOOK_PAGE_FAILURE,
  CREATE_FACEBOOK_PAGE_REQUEST,
  DELETE_FACEBOOK_PAGE_FAILURE,
  DELETE_FACEBOOK_PAGE_REQUEST,
  FETCH_FACEBOOK_PAGES_FAILURE,
  FETCH_FACEBOOK_PAGES_REQUEST,
  FETCH_FACEBOOK_PAGES_SUCCESS,
  FETCH_FACEBOOK_PAGE_FAILURE,
  FETCH_FACEBOOK_PAGE_REQUEST,
  FETCH_FACEBOOK_PAGE_SUCCESS,
  UPDATE_FACEBOOK_PAGE_FAILURE,
  UPDATE_FACEBOOK_PAGE_REQUEST,
  EXPORT_FACEBOOK_PAGES_FAILURE,
} from "./facebookPageActionType";

const initialState = {
  loading: false,
  facebookPages: [],
  facebookPage: {},
  error: "",
  totalFacebookPages: 0,
};

export const FacebookPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FACEBOOK_PAGES_REQUEST:
    case FETCH_FACEBOOK_PAGE_REQUEST:
    case CREATE_FACEBOOK_PAGE_REQUEST:
    case UPDATE_FACEBOOK_PAGE_REQUEST:
    case DELETE_FACEBOOK_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FACEBOOK_PAGES_SUCCESS:
      const { facebookPages, totalFacebookPages } = action.payload;
      return {
        ...state,
        loading: false,
        facebookPages: facebookPages,
        totalFacebookPages: totalFacebookPages,
      };
    case FETCH_FACEBOOK_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        facebookPage: action.payload,
      };

    case FETCH_FACEBOOK_PAGES_FAILURE:
    case FETCH_FACEBOOK_PAGE_FAILURE:
    case CREATE_FACEBOOK_PAGE_FAILURE:
    case UPDATE_FACEBOOK_PAGE_FAILURE:
    case DELETE_FACEBOOK_PAGE_FAILURE:
    case EXPORT_FACEBOOK_PAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
