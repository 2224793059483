import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import {
  createCountryFailure,
  createCountrySuccess,
  deleteCountryFailure,
  deleteCountrySuccess,
  fetchCountriesFailure,
  updateCountryFailure,
  updateCountrySuccess,
  fetchCountriesSuccess,
  fetchCountrySuccess,
  fetchCountryFailure,
  fetchAllCountriesSuccess,
  fetchAllCountriesFailure,
} from "./countryAction";
import {
  CREATE_COUNTRY_REQUEST,
  DELETE_COUNTRY_REQUEST,
  FETCH_ALL_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRY_REQUEST,
  UPDATE_COUNTRY_REQUEST,
} from "./countryActionType";
import { CountryService } from "../../services";
import { toast } from "react-toastify";

function* getCountriesSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      CountryService.getCountries,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchCountriesSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchCountriesFailure(error));
  }
}

function* getAllCountriesSaga() {
  try {
    const { data } = yield call(CountryService.getAllCountries);
    yield put(fetchAllCountriesSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchAllCountriesFailure(error));
  }
}

function* getCountrySaga(action) {
  try {
    const { data } = yield call(CountryService.getCountry, action.slug);
    yield put(fetchCountrySuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchCountryFailure(error));
  }
}

function* createCountrySaga(action) {
  try {
    const {
      meta: { onSuccess },
    } = action;
    const { data } = yield call(CountryService.createCountry, action.payload);
    yield put(createCountrySuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createCountryFailure(error));
  }
}
function* updateCountrySaga(action) {
  try {
    const {
      slug,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(CountryService.updateCountry, slug, payload);
    yield put(updateCountrySuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateCountryFailure(error));
  }
}

function* deleteCountrySaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(CountryService.deleteCountry, payload);
    yield put(deleteCountrySuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteCountryFailure(error));
  }
}

export function* countryWatcherSaga() {
  yield takeEvery(FETCH_COUNTRIES_REQUEST, getCountriesSaga);
  yield takeEvery(FETCH_ALL_COUNTRIES_REQUEST, getAllCountriesSaga);
  yield takeEvery(FETCH_COUNTRY_REQUEST, getCountrySaga);
  yield takeEvery(CREATE_COUNTRY_REQUEST, createCountrySaga);
  yield takeEvery(UPDATE_COUNTRY_REQUEST, updateCountrySaga);
  yield takeEvery(DELETE_COUNTRY_REQUEST, deleteCountrySaga);
}
