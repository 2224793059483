import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { NotifyUtil } from "../../utils";
import {
  createResourceFailure,
  createResourceSuccess,
  deleteResourceFailure,
  deleteResourceSuccess,
  fetchResourceFailure,
  fetchResourceOptionsFailure,
  fetchResourceOptionsSuccess,
  fetchResourcesFailure,
  fetchResourcesSuccess,
  fetchResourceSuccess,
  updateResourceFailure,
  updateResourceSuccess,
} from "./resourceAction";
import { ResourceService } from "../../services";
import {
  CREATE_RESOURCE_REQUEST,
  DELETE_RESOURCE_REQUEST,
  FETCH_RESOURCES_REQUEST,
  FETCH_RESOURCE_OPTIONS_REQUEST,
  FETCH_RESOURCE_REQUEST,
  UPDATE_RESOURCE_REQUEST,
} from "./resourceActionType";

function* fetchResourcesSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      ResourceService.getResources,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchResourcesSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchResourcesFailure(error));
  }
}

function* fetchResourceSaga(action) {
  try {
    const { data } = yield call(ResourceService.getResource, action.slug);
    yield put(fetchResourceSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchResourceFailure(error));
  }
}

function* fetchResourceOptionsSaga() {
  try {
    const { data } = yield call(ResourceService.getResourceOptions);
    yield put(fetchResourceOptionsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchResourceOptionsFailure(error));
  }
}

function* createResourceSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    const { data } = yield call(ResourceService.createResource, payload);
    yield put(createResourceSuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createResourceFailure(error));
  }
}

function* updateResourceSaga(action) {
  try {
    const {
      slug,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(ResourceService.updateResource, slug, payload);
    yield put(updateResourceSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateResourceFailure(error));
  }
}

function* deleteResourceSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(ResourceService.deleteResource, payload);
    yield put(deleteResourceSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteResourceFailure(error));
  }
}

export function* resourceWatcherSaga() {
  yield takeEvery(FETCH_RESOURCES_REQUEST, fetchResourcesSaga);
  yield takeEvery(FETCH_RESOURCE_REQUEST, fetchResourceSaga);
  yield takeEvery(FETCH_RESOURCE_OPTIONS_REQUEST, fetchResourceOptionsSaga);
  yield takeEvery(CREATE_RESOURCE_REQUEST, createResourceSaga);
  yield takeEvery(UPDATE_RESOURCE_REQUEST, updateResourceSaga);
  yield takeEvery(DELETE_RESOURCE_REQUEST, deleteResourceSaga);
}
