import { all, fork } from "redux-saga/effects";
import { employeeWatcherSaga } from "./employee/employeeSaga";
import { bannerWatcherSaga } from "./banner/bannerSaga";
import { badgeWatcherSaga } from "./badge/badgeSaga";
import { brandWatcherSaga } from "./brand/brandSaga";
import { categoryWatcherSaga } from "./category/categorySaga";
import { countryWatcherSaga } from "./country/countrySaga";
import { clientWatcherSaga } from "./client/clientSaga";
import { commentWatcherSaga } from "./comment/commentSaga";
import { dealWatcherSaga } from "./deal/dealSaga";
import { reportWatcherSaga } from "./report/reportSaga";
import { roleWatcherSaga } from "./role/roleSaga";
import { resourceWatcherSaga } from "./resource/resourceSaga";
import { storeWatcherSaga } from "./store/storeSaga";
import { userWatcherSaga } from "./user/userSaga";
import { dashboardWatcherSaga } from "./dashboard/dashboardSaga";
import { searchLogWatcherSaga } from "./searchLog/searchLogSaga";
import { facebookPageWatcherSaga } from "./facebookPage/facebookPageSaga";
import { emailHistoryWatcherSaga } from "./emailHistory/emailHistorySaga";
import { notificationWatcherSaga } from "./notification/notificationSaga";
import { rewardRuleWatcherSaga } from "./rewardRule/rewardRuleSaga";
import { rewardWatcherSaga } from "./reward/rewardSaga";
import { rewardValidationLogWatcherSaga } from "./rewardValidationLog/rewardValidationLogSaga";
import { redeemRewardWatcherSaga } from "./redeemReward/redeemRewardSaga";
import { dealAlertWatcherSaga } from "./dealAlert/dealAlertSaga";
import { storeReviewWatcherSaga } from "./storeReview/storeReviewSaga";

export default function* rootSaga() {
  yield all([
    fork(employeeWatcherSaga),
    fork(dashboardWatcherSaga),
    fork(bannerWatcherSaga),
    fork(badgeWatcherSaga),
    fork(brandWatcherSaga),
    fork(categoryWatcherSaga),
    fork(countryWatcherSaga),
    fork(clientWatcherSaga),
    fork(commentWatcherSaga),
    fork(dealWatcherSaga),
    fork(reportWatcherSaga),
    fork(roleWatcherSaga),
    fork(resourceWatcherSaga),
    fork(storeWatcherSaga),
    fork(userWatcherSaga),
    fork(searchLogWatcherSaga),
    fork(facebookPageWatcherSaga),
    fork(emailHistoryWatcherSaga),
    fork(notificationWatcherSaga),
    fork(rewardRuleWatcherSaga),
    fork(rewardWatcherSaga),
    fork(rewardValidationLogWatcherSaga),
    fork(redeemRewardWatcherSaga),
    fork(dealAlertWatcherSaga),
    fork(storeReviewWatcherSaga),
  ]);
}
