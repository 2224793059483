import {
  Box,
  ListItem,
  ListItemText,
  List,
  Typography,
  Divider,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { DATE_TIME_FORMAT } from "../../const";
import { StringUtil, UrlUtil } from "../../utils";

export const EmployeeView = ({ employee, title = "Details" }) => {
  return (
    <>
      <Typography sx={{ fontWeight: 600, pl: "1.3rem", mb: "1rem" }}>
        {title}
      </Typography>
      <Divider />
      <Box pl="1.3rem">
        <Box
          component="img"
          src={
            employee?.image?.path
              ? UrlUtil.getFullImagePath(employee.image.path)
              : "/images/default-thumbnail-image.gif"
          }
          sx={{
            width: "250px",
            height: "250px",
            objectFit: "contain",
            border: "1px solid",
            borderColor: "divider",
            marginTop: "1.3rem",
            padding: ".5rem",
          }}
        />
      </Box>
      <List sx={{ pt: "0px" }}>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Id
            </Typography>
            <Box>
              <Typography variant="value">{employee.id}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              First Name
            </Typography>
            <Box>
              <Typography variant="value">{employee.firstName}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Last Name
            </Typography>
            <Box>
              <Typography variant="value">{employee.lastName}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Email
            </Typography>
            <Box>
              <Typography variant="value">{employee.email}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Mobile Number
            </Typography>
            <Box>
              <Typography variant="value">{employee.mobileNumber}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Address
            </Typography>
            <Box>
              <Typography variant="value">{employee.address}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              National Id
            </Typography>
            <Box>
              <Typography variant="value">{employee.nationalId}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              National Id Copy
            </Typography>
            <Box>
              <Box
                component="img"
                src={
                  employee?.nidImage?.path
                    ? UrlUtil.getFullImagePath(employee.nidImage.path)
                    : "/images/default-thumbnail-image.gif"
                }
                sx={{
                  width: "250px",
                  height: "250px",
                  objectFit: "contain",
                  border: "1px solid",
                  borderColor: "divider",
                  marginTop: "1.3rem",
                  padding: ".5rem",
                }}
              />
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Enabled
            </Typography>
            <Box>
              <Typography variant="value">
                {StringUtil.getYesOrNo(employee.isActive)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>

        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Created
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(employee.createdAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Updated
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(employee.updatedAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};
