import {
  Box,
  Button,
  ListItem,
  ListItemText,
  List,
  Typography,
  Divider,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DATE_TIME_FORMAT } from "../../const";
import moment from "moment";

export const ViewEmailHistoryPage = () => {
  const { id } = useParams();
  const emailHistories = useSelector(
    (state) => state.emailHistory.emailHistories
  );
  const foundSearchLog = emailHistories.find((eachRole) => eachRole.id === id);
  const navigate = useNavigate();
  return (
    <DmBox>
      <Button
        variant="text"
        color="secondary"
        onClick={(e) => navigate("/email-histories")}
        startIcon={<ArrowBackIcon />}
      >
        Back
      </Button>
      <Typography sx={{ fontWeight: 600, pl: "1.3rem", my: "1rem" }}>
        Details
      </Typography>
      <Divider />
      <List sx={{ pt: "0px" }}>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Id
            </Typography>
            <Box>
              <Typography variant="value">{foundSearchLog.id}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              From
            </Typography>
            <Box>
              <Typography variant="value">{foundSearchLog.from}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              To
            </Typography>
            <Box>
              <Typography variant="value">{foundSearchLog.to}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Subject
            </Typography>
            <Box>
              <Typography variant="value">{foundSearchLog.subject}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Verification Code
            </Typography>
            <Box>
              <Typography variant="value">
                {foundSearchLog.verificationCode}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Body
            </Typography>
            <Box sx={{ overflowWrap: "anywhere" }}>
              <Typography variant="value">{foundSearchLog.body}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Status
            </Typography>
            <Box>
              <Typography variant="value">
                {foundSearchLog.status ? "Sent" : "Fail"}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>

        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Error Log
            </Typography>
            <Box>
              <Typography variant="value">{foundSearchLog.errorLog}</Typography>
            </Box>
          </ListItemText>
        </ListItem>

        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Created
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(foundSearchLog.createdAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Updated
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(foundSearchLog.updatedAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </DmBox>
  );
};
