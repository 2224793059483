import { api } from "../config";

export const ReportService = {
  getReports: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/reports/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getReport: async (reportId) => {
    try {
      const { data } = await api.get(`api/admin/reports/${reportId}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getReportStatus: async () => {
    try {
      const { data } = await api.get("api/admin/reports/status");
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteReport: async (payload) => {
    try {
      const { data } = await api.post("api/admin/reports/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateReport: async (id, payload) => {
    try {
      const { data } = await api.put(`api/admin/reports/update/${id}`, payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
