import {
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  TOGGLE_USERS_STATUS_FAILURE,
  TOGGLE_USERS_STATUS_REQUEST,
} from "./userActionType";

const initialState = {
  loading: false,
  users: [],
  totalUsers: 0,
  error: "",
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
    case TOGGLE_USERS_STATUS_REQUEST:
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_USERS_SUCCESS:
      const { users, totalUsers } = action.payload;
      return {
        ...state,
        loading: false,
        users: users,
        totalUsers: totalUsers,
      };
    case FETCH_USERS_FAILURE:
    case TOGGLE_USERS_STATUS_FAILURE:
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
