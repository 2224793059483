import React, { useEffect } from "react";
import { RoleForm } from "../../components/forms";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DmBox } from "../../components";
import { fetchRoleRequest } from "../../store";

export const AddRolePage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);

  useEffect(() => {
    if (slug) dispatch(fetchRoleRequest(slug));
  }, [dispatch, slug]);

  return (
    <DmBox>
      {roleState.loading ? (
        <CircularProgress />
      ) : (
        <RoleForm role={slug && roleState.role} />
      )}
    </DmBox>
  );
};
