import {
  CREATE_REWARD_FAILURE,
  CREATE_REWARD_REQUEST,
  DELETE_REWARD_FAILURE,
  DELETE_REWARD_REQUEST,
  FETCH_REWARDS_FAILURE,
  FETCH_REWARDS_REQUEST,
  FETCH_REWARDS_SUCCESS,
  FETCH_REWARD_FAILURE,
  FETCH_REWARD_REQUEST,
  FETCH_REWARD_SUCCESS,
  UPDATE_REWARD_FAILURE,
  UPDATE_REWARD_REQUEST,
} from "./rewardActionType";

const initialState = {
  loading: false,
  rewards: [],
  reward: {},
  error: "",
  totalRewards: 0,
};

export const RewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REWARDS_REQUEST:
    case FETCH_REWARD_REQUEST:
    case CREATE_REWARD_REQUEST:
    case UPDATE_REWARD_REQUEST:
    case DELETE_REWARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REWARDS_SUCCESS:
      const { rewards, totalRewards } = action.payload;
      return {
        ...state,
        loading: false,
        rewards,
        totalRewards,
      };

    case FETCH_REWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        reward: action.payload,
      };

    case FETCH_REWARDS_FAILURE:
    case FETCH_REWARD_FAILURE:
    case CREATE_REWARD_FAILURE:
    case UPDATE_REWARD_FAILURE:
    case DELETE_REWARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
