import { api } from "../config";

export const RewardService = {
  getRewards: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/rewards/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getReward: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/rewards/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  createReward: async (payload) => {
    try {
      const { data } = await api.post("api/admin/rewards/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateReward: async (slug, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/rewards/update/${slug}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteReward: async (payload) => {
    try {
      const { data } = await api.post("api/admin/rewards/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
