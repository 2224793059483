import { Box, Button, Tab, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ResourceView, RoleView } from "../../components/views";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import { Authenticate } from "../../hoc/Authenticate";
import { Action, Models } from "../../const";

export const ViewRolePage = () => {
  const { slug } = useParams();
  const roles = useSelector((state) => state.role.roles);
  const foundRole = roles.find((eachRole) => eachRole.slug === slug);
  const { resources, ...role } = foundRole;
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const TabValue = {
    DETAILS: "1",
    RESOURCE: "2",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <DmBox>
      <Stack direction="row" spacing={5} mb="1rem">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate("/roles")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Authenticate model={Models.ROLE} action={Action.EDIT}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(`/roles/edit/${role.slug}`)}
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Authenticate>
      </Stack>
      <TabContext value={value} width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Details" value={TabValue.DETAILS} />
            <Tab label="Resource" value={TabValue.RESOURCE} />
          </TabList>
        </Box>
        <TabPanel value={TabValue.DETAILS} sx={{ p: "1rem 0px" }}>
          <RoleView role={role} />
        </TabPanel>
        <TabPanel value={TabValue.RESOURCE} sx={{ p: "1rem 0px" }}>
          {resources.map((resource) => (
            <ResourceView key={resource.id} resource={resource} />
          ))}
        </TabPanel>
      </TabContext>
    </DmBox>
  );
};
