import { call, put, takeLatest } from "redux-saga/effects";
import { EmployeeService } from "../../services";
import {
  createEmployeeFailure,
  createEmployeeSuccess,
  deleteEmployeeFailure,
  deleteEmployeeSuccess,
  employeeLoginFailure,
  employeeLoginSuccess,
  employeeLogOutRequest,
  fetchEmployeeFailure,
  fetchEmployeeOptionsFailure,
  fetchEmployeeOptionsSuccess,
  fetchEmployeesFailure,
  fetchEmployeesSuccess,
  fetchEmployeeSuccess,
  fetchUpdatedEmployeeFailure,
  fetchUpdatedEmployeeSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  updateEmployeeFailure,
  updateEmployeeNotificationSummarySeenFailure,
  updateEmployeeNotificationSummarySeenSuccess,
  updateEmployeeProfileFailure,
  updateEmployeeProfileSuccess,
  updateEmployeeSuccess,
  updateMarkEmployeeNotificationReadFailure,
  updateMarkEmployeeNotificationReadSuccess,
} from "./employeeAction";
import {
  CREATE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_REQUEST,
  EMPLOYEE_LOGIN_REQUEST,
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEE_OPTIONS_REQUEST,
  FETCH_EMPLOYEE_REQUEST,
  FETCH_UPDATED_EMPLOYEE_REQUEST,
  RESET_PASSWORD_REQUEST,
  UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_REQUEST,
  UPDATE_EMPLOYEE_PROFILE_REQUEST,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_REQUEST,
  VERIFY_EMPLOYEE_TOKEN_REQUEST,
} from "./employeeActionType";
import { NotifyUtil } from "../../utils";
import { toast } from "react-toastify";

function* loginSaga(action) {
  const { onSuccessHandler } = action.meta;
  try {
    const { email, password } = action.payload;
    const { data } = yield call(EmployeeService.login, email, password);
    toast.success("Login successfully");
    yield put(employeeLoginSuccess(data));
    onSuccessHandler();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(employeeLoginFailure(error));
  }
}

function* verifyTokenSaga(action) {
  try {
    yield call(EmployeeService.verifyToken, action.payload);
  } catch (error) {
    yield put(employeeLogOutRequest()); // destroy user object
    toast.error("Please login for security purpose"); // show error notification when redirection required
  }
}

function* fetchEmployeesSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      EmployeeService.getEmployees,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchEmployeesSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchEmployeesFailure(error));
  }
}

function* fetchEmployeeSaga(action) {
  try {
    const { data } = yield call(EmployeeService.getEmployee, action.email);
    yield put(fetchEmployeeSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchEmployeeFailure(error));
  }
}

function* fetchEmployeeOptionsSaga() {
  try {
    const { data } = yield call(EmployeeService.getEmployeeOptions);
    yield put(fetchEmployeeOptionsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchEmployeeOptionsFailure(error));
  }
}

function* createEmployeeSaga(action) {
  try {
    const {
      meta: { onSuccess },
    } = action;
    const { data } = yield call(EmployeeService.createEmployee, action.payload);
    yield put(createEmployeeSuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createEmployeeFailure(error));
  }
}

function* updateEmployeeSaga(action) {
  try {
    const {
      email,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(EmployeeService.updateEmployee, email, payload);
    yield put(updateEmployeeSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateEmployeeFailure(error));
  }
}

function* resetPasswordSaga(action) {
  try {
    const { email, payload } = action;
    yield call(EmployeeService.resetPassword, email, payload);
    yield put(resetPasswordSuccess());
    toast.success("Successfully password rested");
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(resetPasswordFailure(error));
  }
}
function* updateEmployeeProfileSaga(action) {
  try {
    const {
      email,
      payload,
      meta: { onSuccess },
    } = action;
    const { data } = yield call(
      EmployeeService.updateEmployeeProfile,
      email,
      payload
    );
    yield put(updateEmployeeProfileSuccess(data));
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateEmployeeProfileFailure(error));
  }
}

function* deleteEmployeeSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(EmployeeService.deleteEmployee, payload);
    yield put(deleteEmployeeSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteEmployeeFailure(error));
  }
}

function* updateEmployeeNotificationSummarySeenSaga(action) {
  try {
    const {
      meta: { onSuccess },
    } = action;
    const { data } = yield call(
      EmployeeService.updateEmployeeNotificationSummarySeen,
      action.payload
    );
    yield put(updateEmployeeNotificationSummarySeenSuccess(data));
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateEmployeeNotificationSummarySeenFailure(error));
  }
}

function* fetchUpdatedEmployeeSaga(action) {
  try {
    const { data } = yield call(
      EmployeeService.getUpdatedEmployee,
      action.email
    );
    yield put(fetchUpdatedEmployeeSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchUpdatedEmployeeFailure(error));
  }
}

function* updateMarkEmployeeNotificationReadSaga(action) {
  try {
    yield call(
      EmployeeService.updateMarkEmployeeNotificationRead,
      action.payload
    );
    yield put(updateMarkEmployeeNotificationReadSuccess());
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateMarkEmployeeNotificationReadFailure(error));
  }
}

export function* employeeWatcherSaga() {
  yield takeLatest(EMPLOYEE_LOGIN_REQUEST, loginSaga);
  yield takeLatest(VERIFY_EMPLOYEE_TOKEN_REQUEST, verifyTokenSaga);
  yield takeLatest(FETCH_EMPLOYEES_REQUEST, fetchEmployeesSaga);
  yield takeLatest(FETCH_EMPLOYEE_REQUEST, fetchEmployeeSaga);
  yield takeLatest(FETCH_EMPLOYEE_OPTIONS_REQUEST, fetchEmployeeOptionsSaga);
  yield takeLatest(CREATE_EMPLOYEE_REQUEST, createEmployeeSaga);
  yield takeLatest(UPDATE_EMPLOYEE_REQUEST, updateEmployeeSaga);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga);
  yield takeLatest(UPDATE_EMPLOYEE_PROFILE_REQUEST, updateEmployeeProfileSaga);
  yield takeLatest(DELETE_EMPLOYEE_REQUEST, deleteEmployeeSaga);
  yield takeLatest(
    UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_REQUEST,
    updateEmployeeNotificationSummarySeenSaga
  );
  yield takeLatest(FETCH_UPDATED_EMPLOYEE_REQUEST, fetchUpdatedEmployeeSaga);
  yield takeLatest(
    UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_REQUEST,
    updateMarkEmployeeNotificationReadSaga
  );
}
