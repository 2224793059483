import {
  CREATE_COUNTRY_FAILURE,
  CREATE_COUNTRY_REQUEST,
  CREATE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAILURE,
  DELETE_COUNTRY_REQUEST,
  DELETE_COUNTRY_SUCCESS,
  FETCH_ALL_COUNTRIES_FAILURE,
  FETCH_ALL_COUNTRIES_REQUEST,
  FETCH_ALL_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRY_FAILURE,
  FETCH_COUNTRY_REQUEST,
  FETCH_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAILURE,
  UPDATE_COUNTRY_REQUEST,
  UPDATE_COUNTRY_SUCCESS,
} from "./countryActionType";

export const fetchCountriesRequest = (offset, searchQuery) => {
  return {
    type: FETCH_COUNTRIES_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchCountriesSuccess = (data) => {
  return {
    type: FETCH_COUNTRIES_SUCCESS,
    payload: data,
  };
};

export const fetchCountriesFailure = (error) => {
  return {
    type: FETCH_COUNTRIES_FAILURE,
    error,
  };
};

export const fetchAllCountriesRequest = () => {
  return {
    type: FETCH_ALL_COUNTRIES_REQUEST,
  };
};

export const fetchAllCountriesSuccess = (data) => {
  return {
    type: FETCH_ALL_COUNTRIES_SUCCESS,
    payload: data,
  };
};

export const fetchAllCountriesFailure = (error) => {
  return {
    type: FETCH_ALL_COUNTRIES_FAILURE,
    error,
  };
};

export const fetchCountryRequest = (slug) => {
  return {
    type: FETCH_COUNTRY_REQUEST,
    slug,
  };
};

export const fetchCountrySuccess = (data) => {
  return {
    type: FETCH_COUNTRY_SUCCESS,
    payload: data,
  };
};

export const fetchCountryFailure = (error) => {
  return {
    type: FETCH_COUNTRY_FAILURE,
    error,
  };
};

export const createCountryRequest = (payload, onSuccess) => {
  return {
    type: CREATE_COUNTRY_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};
export const createCountrySuccess = (data) => {
  return {
    type: CREATE_COUNTRY_SUCCESS,
    payload: data,
  };
};
export const createCountryFailure = (error) => {
  return {
    type: CREATE_COUNTRY_FAILURE,
    error,
  };
};

export const updateCountryRequest = (slug, payload, onSuccess) => {
  return {
    type: UPDATE_COUNTRY_REQUEST,
    slug,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateCountrySuccess = () => {
  return {
    type: UPDATE_COUNTRY_SUCCESS,
  };
};

export const updateCountryFailure = (error) => {
  return {
    type: UPDATE_COUNTRY_FAILURE,
    error,
  };
};

export const deleteCountryRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_COUNTRY_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteCountrySuccess = () => {
  return {
    type: DELETE_COUNTRY_SUCCESS,
  };
};

export const deleteCountryFailure = (error) => {
  return {
    type: DELETE_COUNTRY_FAILURE,
    error,
  };
};
