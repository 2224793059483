import React, { useEffect } from "react";
import { BadgeForm } from "../../components/forms";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchBadgeRequest } from "../../store";
import { DmBox } from "../../components";

export const AddBadgePage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const badgeState = useSelector((state) => state.badge);
  useEffect(() => {
    if (slug) dispatch(fetchBadgeRequest(slug));
  }, [dispatch, slug]);

  return (
    <DmBox>
      {badgeState.loading ? (
        <CircularProgress />
      ) : (
        <BadgeForm badge={slug && badgeState.badge} />
      )}
    </DmBox>
  );
};
