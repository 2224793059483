import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import { toast } from "react-toastify";
import {
  DELETE_REPORT_REQUEST,
  FETCH_REPORTS_REQUEST,
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_STATUS_REQUEST,
  UPDATE_REPORT_REQUEST,
} from "./reportActionType";
import {
  deleteReportFailure,
  deleteReportSuccess,
  fetchReportFailure,
  fetchReportsFailure,
  fetchReportsSuccess,
  fetchReportStatusFailure,
  fetchReportStatusSuccess,
  fetchReportSuccess,
  updateReportFailure,
  updateReportSuccess,
} from "./reportAction";
import { ReportService } from "../../services";

function* getReportsSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      ReportService.getReports,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchReportsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchReportsFailure(error));
  }
}

function* getReportSaga(action) {
  try {
    const { data } = yield call(ReportService.getReport, action.reportId);
    yield put(fetchReportSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchReportFailure(error));
  }
}

function* getReportStatusSaga() {
  try {
    const { data } = yield call(ReportService.getReportStatus);
    yield put(fetchReportStatusSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchReportStatusFailure(error));
  }
}

function* deleteReportSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(ReportService.deleteReport, payload);
    yield put(deleteReportSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteReportFailure(error));
  }
}

function* updateReportSaga(action) {
  try {
    const {
      id,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(ReportService.updateReport, id, payload);
    yield put(updateReportSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateReportFailure(error));
  }
}

export function* reportWatcherSaga() {
  yield takeEvery(FETCH_REPORTS_REQUEST, getReportsSaga);
  yield takeEvery(FETCH_REPORT_REQUEST, getReportSaga);
  yield takeEvery(FETCH_REPORT_STATUS_REQUEST, getReportStatusSaga);
  yield takeEvery(UPDATE_REPORT_REQUEST, updateReportSaga);
  yield takeEvery(DELETE_REPORT_REQUEST, deleteReportSaga);
}
