import { InputBase, InputAdornment } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { useRef } from "react";
import { setSearchQuery } from "../store";

export const Search = ({ ...props }) => {
  const dispatch = useDispatch();
  const inputSearchRef = useRef();

  const onKeyDown = (event, query) => {
    if (event.key === "Enter") {
      dispatch(setSearchQuery(query.trim()));
    }
  };

  useEffect(() => {
    dispatch(setSearchQuery(""));
  }, [dispatch]);

  const onChangeHandler = (query) => {
    if (query === "") dispatch(setSearchQuery(query.trim()));
  };

  return (
    <InputBase
      sx={{
        padding: ".2rem",
        "&:hover": { backgroundColor: "rgb(242, 242, 242)" },
        width: "100%",
      }}
      {...props}
      inputProps={{ ref: inputSearchRef }}
      onKeyDown={(e) => onKeyDown(e, inputSearchRef.current.value)}
      onChange={() => onChangeHandler(inputSearchRef.current.value)}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon sx={{ color: "rgb(158, 158, 158)" }} />
        </InputAdornment>
      }
    />
  );
};
