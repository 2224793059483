import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createCategoryRequest,
  fetchCategoryOptionsRequest,
  updateCategoryRequest,
} from "../../store";
import { InputFile, InputSwitch, Input, AutoCompleteSelect } from "../Input";
import { ArrayUtil, ValidationUtil } from "../../utils";

export const CategoryForm = ({ category }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const limit = searchParams.get("limit");
  const page = searchParams.get("page");
  const dispatch = useDispatch();
  const [files, setFiles] = useState(
    category?.image?.path ? [category.image] : []
  );
  const categoryState = useSelector((state) => state.category);
  const categories = categoryState.categories;
  const [categoryOptions, setCategoryOptions] = useState([]);

  // form state
  const [selectedOption, setSelectedOption] = useState(
    category?.parent?.id && {
      value: category.parent?.id,
      label: category.parent?.displayName ?? category.parent?.name,
    }
  );

  const [status, setStatus] = useState({
    isRoot: category?.isRoot ?? false,
    isFeatured: category?.isFeatured,
    isActive: category?.isActive,
  });

  const initialValues = category
    ? {
        name: category.name,
        displayName: category.displayName,
        isFeatured: category.isFeatured,
        isRoot: category.isRoot,
        isActive: category.isActive,
      }
    : {};

  useEffect(() => {
    dispatch(fetchCategoryOptionsRequest());
  }, [dispatch]);

  useEffect(() => {
    const options = ArrayUtil.getCategoryOptions(categories);
    setCategoryOptions(options);
  }, [categories]);

  const onSubmit = (values) => {
    const { name, displayName, parent, isRoot, isFeatured, isActive } = values;
    let formData = new FormData();

    formData.append("name", name);
    formData.append("display_name", displayName);
    if (!isRoot) formData.append("parent_id", parent.value);
    formData.append("is_root", isRoot ?? false);
    formData.append("is_featured", isFeatured ?? false);
    formData.append("is_active", isActive ?? false);
    formData.append(
      "image",
      files[0]?.size ? files[0] : JSON.stringify(files[0])
    );
    category?.id
      ? dispatch(updateCategoryRequest(category.slug, formData, onSuccess))
      : dispatch(createCategoryRequest(formData, onSuccess));
  };

  const onSuccess = () => {
    const queryParams = new URLSearchParams();
    queryParams.append("limit", limit);
    queryParams.append("page", page);
    navigate(`/categories?${queryParams.toString()}`);
  };

  return (
    <>
      {categoryState.categoryOptionLoading ? (
        <CircularProgress />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={(event) => {
                handleSubmit(event);
              }}
              noValidate
              autoComplete="off"
            >
              <Stack alignItems="center">
                <Typography variant="h4">
                  {category ? `Edit ${category.slug}` : "Add new category"}
                </Typography>
                <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
                  <Field
                    name="name"
                    component={Input}
                    label="Name"
                    validate={ValidationUtil.requiredValidator}
                  />
                  <Field
                    name="displayName"
                    component={Input}
                    label="Display Name"
                    validate={ValidationUtil.requiredValidator}
                  />
                  {!status.isRoot && (
                    <Field
                      name="parent"
                      component={AutoCompleteSelect}
                      label="Parent"
                      options={categoryOptions}
                      handleOnChange={(value) => setSelectedOption(value)}
                      value={selectedOption}
                      defaultValue={selectedOption}
                      validate={ValidationUtil.requiredValidator}
                    />
                  )}
                  <Box>
                    <Field
                      name="isRoot"
                      checked={status.isRoot}
                      component={InputSwitch}
                      handleOnChange={(value) =>
                        setStatus((v) => ({ ...v, isRoot: value }))
                      }
                      type="checkbox"
                      label="Is Root"
                      labelPlacement="start"
                      sx={{ ml: "0px" }}
                    />
                  </Box>
                  {!status.isRoot && (
                    <Box>
                      <Field
                        name="isFeatured"
                        checked={status.isFeatured}
                        component={InputSwitch}
                        handleOnChange={(value) =>
                          setStatus((v) => ({ ...v, isFeatured: value }))
                        }
                        type="checkbox"
                        label="Is Featured"
                        labelPlacement="start"
                        sx={{ ml: "0px" }}
                      />
                    </Box>
                  )}
                  <Box>
                    <Field
                      name="isActive"
                      checked={status.isActive}
                      defaultValue={status.isActive}
                      component={InputSwitch}
                      handleOnChange={(value) =>
                        setStatus((v) => ({ ...v, isActive: value }))
                      }
                      type="checkbox"
                      label="Is Active"
                      labelPlacement="start"
                      sx={{ ml: "0px" }}
                    />
                  </Box>
                  {!status.isRoot && (
                    <Field
                      name="image"
                      key={files.length > 0 ? 1 : 0} // changed key to re-render validate function
                      component={InputFile}
                      files={files}
                      onChangeHandler={(value) => {
                        setFiles(value);
                      }}
                      validate={
                        category?.image
                          ? ValidationUtil.composeValidators(
                              () => {
                                return ValidationUtil.requiredValidator(files);
                              },
                              () =>
                                ValidationUtil.numberOfFilesValidator(
                                  files ?? [],
                                  1
                                ),
                              () => ValidationUtil.fileSizeValidator(files),
                              () => ValidationUtil.fileExtensionValidator(files)
                            )
                          : ValidationUtil.composeValidators(
                              ValidationUtil.requiredValidator,
                              ValidationUtil.fileSizeValidator,
                              ValidationUtil.fileExtensionValidator,
                              (files) =>
                                ValidationUtil.numberOfFilesValidator(
                                  files ?? [],
                                  1
                                )
                            )
                      }
                      sx={{
                        border: "2px dashed #5E54FF",
                        p: ".8rem",
                        alignItems: "center",
                      }}
                    />
                  )}
                  <Stack direction="row" spacing={2} mt={"30px"}>
                    <Button
                      sx={{
                        width: "20%",
                        boxShadow: "none",
                        "&:hover": {
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                    <Button
                      sx={{
                        width: "20%",
                        boxShadow: "none",
                        backgroundColor: "#03a9f4",
                        "&:hover": {
                          backgroundColor: "#0288d1",
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      onClick={onSuccess}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          )}
        />
      )}
    </>
  );
};
