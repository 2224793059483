import {
  Box,
  ListItem,
  ListItemText,
  List,
  Typography,
  Divider,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { DATE_TIME_FORMAT } from "../../const";
import { StringUtil, UrlUtil } from "../../utils";

export const CategoryView = ({ category, title = "Details" }) => {
  return (
    <>
      <Typography sx={{ fontWeight: 600, pl: "1.3rem", mb: "1rem" }}>
        {title}
      </Typography>
      <Divider />
      <Box pl="1.3rem">
        <Box
          component="img"
          src={
            category?.image?.path
              ? UrlUtil.getFullImagePath(category.image.path)
              : "/images/default-thumbnail-image.gif"
          }
          sx={{
            width: "250px",
            height: "250px",
            objectFit: "contain",
            border: "1px solid",
            borderColor: "divider",
            marginTop: "1.3rem",
            padding: ".5rem",
          }}
        />
      </Box>
      <List sx={{ pt: "0px" }}>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Id
            </Typography>
            <Box>
              <Typography variant="value">{category.id}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Name
            </Typography>
            <Box>
              <Typography variant="value">{category.name}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Display Name
            </Typography>
            <Box>
              <Typography variant="value">{category.displayName}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Slug
            </Typography>
            <Box>
              <Typography variant="value">{category.slug}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Is Root
            </Typography>
            <Box>
              <Typography variant="value">
                {StringUtil.getYesOrNo(category.isRoot)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        {category.parent?.displayName && (
          <ListItem variant="view">
            <ListItemText disableTypography variant="view">
              <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                Parent
              </Typography>
              <Box>
                <Typography variant="value">
                  {category.parent?.displayName}
                </Typography>
              </Box>
            </ListItemText>
          </ListItem>
        )}
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Is Featured
            </Typography>
            <Box>
              <Typography variant="value">
                {StringUtil.getYesOrNo(category.isFeatured)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Enabled
            </Typography>
            <Box>
              <Typography variant="value">
                {StringUtil.getYesOrNo(category.isActive)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>

        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Created
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(category.createdAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Updated
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(category.updatedAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};
