import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import { DealAlertService } from "../../services";
import { toast } from "react-toastify";
import {
  DELETE_DEAL_ALERT_REQUEST,
  FETCH_DEAL_ALERTS_REQUEST,
} from "./dealAlertActionType";
import {
  deleteDealAlertFailure,
  deleteDealAlertSuccess,
  fetchDealAlertsFailure,
  fetchDealAlertsSuccess,
} from "./dealAlertAction";

function* getDealAlertsSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      DealAlertService.getDealAlerts,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchDealAlertsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchDealAlertsFailure(error));
  }
}

function* deleteDealAlertSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(DealAlertService.deleteDealAlert, payload);
    yield put(deleteDealAlertSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteDealAlertFailure(error));
  }
}

export function* dealAlertWatcherSaga() {
  yield takeEvery(FETCH_DEAL_ALERTS_REQUEST, getDealAlertsSaga);
  yield takeEvery(DELETE_DEAL_ALERT_REQUEST, deleteDealAlertSaga);
}
