import {
  CREATE_COUNTRY_FAILURE,
  CREATE_COUNTRY_REQUEST,
  DELETE_COUNTRY_FAILURE,
  DELETE_COUNTRY_REQUEST,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILURE,
  UPDATE_COUNTRY_FAILURE,
  UPDATE_COUNTRY_REQUEST,
  FETCH_COUNTRY_REQUEST,
  FETCH_COUNTRY_SUCCESS,
  FETCH_COUNTRY_FAILURE,
  FETCH_ALL_COUNTRIES_REQUEST,
  FETCH_ALL_COUNTRIES_SUCCESS,
  FETCH_ALL_COUNTRIES_FAILURE,
} from "./countryActionType";

const initialState = {
  loading: false,
  countries: [],
  country: {},
  error: "",
  totalCountries: 0,
};

export const CountryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_REQUEST:
    case FETCH_ALL_COUNTRIES_REQUEST:
    case FETCH_COUNTRY_REQUEST:
    case CREATE_COUNTRY_REQUEST:
    case UPDATE_COUNTRY_REQUEST:
    case DELETE_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COUNTRIES_SUCCESS:
      const { countries, totalCountries } = action.payload;
      return {
        ...state,
        loading: false,
        countries: countries,
        totalCountries: totalCountries,
      };

    case FETCH_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        country: action.payload,
      };

    case FETCH_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };

    case FETCH_COUNTRIES_FAILURE:
    case FETCH_ALL_COUNTRIES_FAILURE:
    case FETCH_COUNTRY_FAILURE:
    case CREATE_COUNTRY_FAILURE:
    case UPDATE_COUNTRY_FAILURE:
    case DELETE_COUNTRY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
