import { Stack, Button, Box, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, InputSwitch, InputFile } from "../";
import { createBadgeRequest, updateBadgeRequest } from "../../store";
import { ValidationUtil } from "../../utils";

export const BadgeForm = ({ badge }) => {
  const [isActive, setIsActive] = useState(false);
  const [files, setFiles] = useState(badge?.image?.path ? [badge.image] : []);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const { name, display_name, limit, label, is_active } = values;
    let formData = new FormData();

    formData.append("name", name);
    formData.append("display_name", display_name);
    formData.append("limit", limit);
    formData.append("label", label);
    formData.append("is_active", is_active ?? false);
    formData.append(
      "image",
      files[0]?.size ? files[0] : JSON.stringify(files[0])
    );
    badge?.id
      ? dispatch(updateBadgeRequest(badge.slug, formData, onSuccess))
      : dispatch(createBadgeRequest(formData, onSuccess));
  };

  const onSuccess = () => {
    navigate("/badges");
  };

  const initialValues = badge
    ? {
        name: badge.name,
        display_name: badge.displayName,
        limit: badge.limit,
        label: badge.label,
        is_active: badge.isActive,
      }
    : {};

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, errors }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            if (Object.keys(errors).length === 0) form.reset();
          }}
          noValidate
          autoComplete="off"
        >
          <Stack alignItems="center">
            <Typography variant="h4">
              {badge ? `Edit ${badge.name}` : "Add new badge"}
            </Typography>
            <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
              <Field
                name="name"
                component={Input}
                label="Name"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="display_name"
                component={Input}
                label="Display Name"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="limit"
                component={Input}
                label="Limit"
                validate={ValidationUtil.requiredValidator}
                type="number"
              />
              <Field
                name="label"
                component={Input}
                label="Label"
                validate={ValidationUtil.requiredValidator}
                type="number"
              />
              <Box>
                <Field
                  name="is_active"
                  checked={isActive}
                  component={InputSwitch}
                  handleOnChange={(value) => setIsActive(value)}
                  type="checkbox"
                  label="Is Active"
                  labelPlacement="start"
                  sx={{ ml: "0px" }}
                />
              </Box>
              <Field
                name="image"
                component={InputFile}
                files={files}
                key={files.length > 0 ? 1 : 0} // changed key to re-render validate function
                onChangeHandler={(value) => {
                  setFiles(value);
                }}
                validate={
                  badge?.image
                    ? ValidationUtil.composeValidators(
                        () => ValidationUtil.requiredValidator(files),
                        () =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1),
                        () => ValidationUtil.fileSizeValidator(files)
                      )
                    : ValidationUtil.composeValidators(
                        ValidationUtil.requiredValidator,
                        ValidationUtil.fileSizeValidator,
                        ValidationUtil.fileExtensionValidator,
                        (files) =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1)
                      )
                }
                sx={{
                  border: "2px dashed #5E54FF",
                  p: ".8rem",
                  alignItems: "center",
                }}
              />
              <Stack direction="row" spacing={2} mt={"30px"}>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    backgroundColor: "#03a9f4",
                    "&:hover": {
                      backgroundColor: "#0288d1",
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  onClick={() => navigate("/badges")}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
