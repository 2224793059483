import {
  CREATE_REWARD_RULE_FAILURE,
  CREATE_REWARD_RULE_REQUEST,
  CREATE_REWARD_RULE_SUCCESS,
  DELETE_REWARD_RULE_FAILURE,
  DELETE_REWARD_RULE_REQUEST,
  DELETE_REWARD_RULE_SUCCESS,
  FETCH_ALL_REWARD_RULES_FAILURE,
  FETCH_ALL_REWARD_RULES_REQUEST,
  FETCH_ALL_REWARD_RULES_SUCCESS,
  FETCH_REWARD_RULES_FAILURE,
  FETCH_REWARD_RULES_REQUEST,
  FETCH_REWARD_RULES_SUCCESS,
  FETCH_REWARD_RULE_FAILURE,
  FETCH_REWARD_RULE_REQUEST,
  FETCH_REWARD_RULE_SUCCESS,
  UPDATE_REWARD_RULE_FAILURE,
  UPDATE_REWARD_RULE_REQUEST,
  UPDATE_REWARD_RULE_SUCCESS,
} from "./rewardRuleActionType";

export const fetchRewardRulesRequest = (offset, searchQuery) => {
  return {
    type: FETCH_REWARD_RULES_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchRewardRulesSuccess = (data) => {
  return {
    type: FETCH_REWARD_RULES_SUCCESS,
    payload: data,
  };
};

export const fetchRewardRulesFailure = (error) => {
  return {
    type: FETCH_REWARD_RULES_FAILURE,
    error,
  };
};

export const fetchRewardRuleRequest = (slug) => {
  return {
    type: FETCH_REWARD_RULE_REQUEST,
    slug,
  };
};

export const fetchRewardRuleSuccess = (data) => {
  return {
    type: FETCH_REWARD_RULE_SUCCESS,
    payload: data,
  };
};

export const fetchRewardRuleFailure = (error) => {
  return {
    type: FETCH_REWARD_RULE_FAILURE,
    error,
  };
};

export const createRewardRuleRequest = (payload, onSuccess) => {
  return {
    type: CREATE_REWARD_RULE_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};
export const createRewardRuleSuccess = (data) => {
  return {
    type: CREATE_REWARD_RULE_SUCCESS,
    payload: data,
  };
};
export const createRewardRuleFailure = (error) => {
  return {
    type: CREATE_REWARD_RULE_FAILURE,
    error,
  };
};

export const updateRewardRuleRequest = (slug, payload, onSuccess) => {
  return {
    type: UPDATE_REWARD_RULE_REQUEST,
    slug,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateRewardRuleSuccess = () => {
  return {
    type: UPDATE_REWARD_RULE_SUCCESS,
  };
};

export const updateRewardRuleFailure = (error) => {
  return {
    type: UPDATE_REWARD_RULE_FAILURE,
    error,
  };
};

export const deleteRewardRuleRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_REWARD_RULE_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteRewardRuleSuccess = () => {
  return {
    type: DELETE_REWARD_RULE_SUCCESS,
  };
};

export const deleteRewardRuleFailure = (error) => {
  return {
    type: DELETE_REWARD_RULE_FAILURE,
    error,
  };
};

export const fetchAllRewardRulesRequest = () => {
  return {
    type: FETCH_ALL_REWARD_RULES_REQUEST,
  };
};

export const fetchAllRewardRulesSuccess = (data) => {
  return {
    type: FETCH_ALL_REWARD_RULES_SUCCESS,
    payload: data,
  };
};

export const fetchAllRewardRulesFailure = (error) => {
  return {
    type: FETCH_ALL_REWARD_RULES_FAILURE,
    error,
  };
};
