import {
  Stack,
  Button,
  InputAdornment,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, InputSwitch, InputFile } from "../";
import { useToggle } from "../../hooks";
import {
  createEmployeeRequest,
  fetchEmployeeOptionsRequest,
  updateEmployeeRequest,
} from "../../store";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ArrayUtil, ValidationUtil } from "../../utils";
import { AutoCompleteMultiSelectCheckBox } from "../Input";

export const EmployeeForm = ({ employee }) => {
  const [isActive, setIsActive] = useState(false);
  const [showPassword, toggleHandler] = useToggle();
  const [nidImages, setNidImages] = useState(
    employee?.nidImage?.path ? [employee.nidImage] : []
  );
  const [profileImages, setProfileImages] = useState(
    employee?.image?.path ? [employee.image] : []
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employeeState = useSelector((state) => state.employee);
  const employeeOptions = employeeState.employeeOptions;
  const [roleOptions, setRoleOptions] = useState([]);

  // form state
  const [selectedOptions, setSelectedOptions] = useState(
    employee?.roles ? ArrayUtil.getOptions(employee.roles) : []
  );

  useEffect(() => {
    dispatch(fetchEmployeeOptionsRequest());
  }, [dispatch]);

  useEffect(() => {
    const options = ArrayUtil.getOptions(employeeOptions);
    setRoleOptions(options);
  }, [employeeOptions]);

  const onSubmit = async (values) => {
    const {
      firstName,
      lastName,
      password,
      email,
      roles,
      nationalId,
      mobileNumber,
      address,
      isActive,
    } = values;

    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    if (!employee?.id) formData.append("password", password); // only when create employee add password to form data
    formData.append("email", email);
    for (let role of roles) {
      formData.append("roles", role.value);
    }
    formData.append("national_id", nationalId);
    formData.append("mobile_number", mobileNumber);
    formData.append("address", address);
    formData.append("is_active", isActive ?? false);
    formData.append(
      "nid_image",
      nidImages[0]?.size ? nidImages[0] : JSON.stringify(nidImages[0])
    );
    formData.append(
      "image",
      profileImages[0]?.size
        ? profileImages[0]
        : JSON.stringify(profileImages[0])
    );
    employee?.id
      ? dispatch(updateEmployeeRequest(employee.email, formData, onSuccess))
      : dispatch(createEmployeeRequest(formData, onSuccess));
  };

  const onSuccess = () => {
    navigate("/employees");
  };

  const initialValues = employee
    ? {
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        mobileNumber: employee.mobileNumber,
        address: employee.address,
        nationalId: employee.nationalId,
        isActive: employee.isActive,
      }
    : {};

  return (
    <>
      {employeeState.employeeOptionsLoading ? (
        <CircularProgress />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, form, submitting, errors }) => (
            <form
              onSubmit={(event) => {
                handleSubmit(event);
                // if (Object.keys(errors).length === 0) form.reset();
              }}
              noValidate
              autoComplete="off"
            >
              <Stack alignItems="center">
                <Typography variant="h4">
                  {employee
                    ? `Edit ${employee.firstName} ${employee.lastName}`
                    : "Add new employee"}
                </Typography>
                <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
                  <Field
                    name="firstName"
                    component={Input}
                    label="First Name"
                    validate={ValidationUtil.requiredValidator}
                  />
                  <Field
                    name="lastName"
                    component={Input}
                    label="Last Name"
                    validate={ValidationUtil.requiredValidator}
                  />
                  {!employee?.id && (
                    <Field
                      name="password"
                      component={Input}
                      label="Password"
                      validate={ValidationUtil.requiredValidator}
                      InputProps={{
                        type: showPassword ? "text" : "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={toggleHandler}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  <Field
                    name="email"
                    component={Input}
                    label="Email"
                    type="email"
                    validate={ValidationUtil.emailValidator}
                  />
                  <Field
                    name="roles"
                    component={AutoCompleteMultiSelectCheckBox}
                    label="Roles"
                    options={roleOptions}
                    handleOnChange={(value) => setSelectedOptions(value)}
                    value={selectedOptions}
                    defaultValue={selectedOptions}
                    validate={ValidationUtil.requiredValidator}
                  />
                  <Field
                    name="nationalId"
                    component={Input}
                    label="National Id"
                    type="number"
                    validate={ValidationUtil.requiredValidator}
                  />
                  <Field
                    name="nidImage"
                    key={nidImages.length > 0 ? "nid image" : "nid-image"} // changed key to re-render validate function
                    component={InputFile}
                    files={nidImages}
                    label="National id copy"
                    onChangeHandler={(value) => {
                      setNidImages(value);
                    }}
                    validate={
                      employee?.nidImage
                        ? ValidationUtil.composeValidators(
                            () => ValidationUtil.requiredValidator(nidImages),
                            () =>
                              ValidationUtil.numberOfFilesValidator(
                                nidImages ?? [],
                                1
                              ),
                            () => ValidationUtil.fileSizeValidator(nidImages)
                          )
                        : ValidationUtil.composeValidators(
                            ValidationUtil.requiredValidator,
                            ValidationUtil.fileSizeValidator,
                            ValidationUtil.fileExtensionValidator,
                            (files) =>
                              ValidationUtil.numberOfFilesValidator(
                                files ?? [],
                                1
                              )
                          )
                    }
                    sx={{
                      border: "2px dashed #5E54FF",
                      p: ".8rem",
                      alignItems: "center",
                    }}
                  />
                  <Field
                    name="mobileNumber"
                    component={Input}
                    label="Mobile Number"
                    type="number"
                    validate={ValidationUtil.mobileNumberValidator}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+88</InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    name="address"
                    component={Input}
                    label="Address"
                    type="textarea"
                    multiline
                    inputProps={{
                      sx: {
                        minHeight: "150px",
                      },
                    }}
                    validate={ValidationUtil.requiredValidator}
                  />
                  <Box>
                    <Field
                      name="isActive"
                      checked={isActive}
                      component={InputSwitch}
                      handleOnChange={(value) => setIsActive(value)}
                      type="checkbox"
                      label="Is Active"
                      labelPlacement="start"
                      sx={{ ml: "0px" }}
                    />
                  </Box>
                  <Field
                    name="image"
                    key={
                      profileImages.length > 0
                        ? "profile image"
                        : "profile-image"
                    } // changed key to re-render validate function
                    component={InputFile}
                    files={profileImages}
                    label="Profile image"
                    onChangeHandler={(value) => {
                      setProfileImages(value);
                    }}
                    validate={
                      employee?.image
                        ? ValidationUtil.composeValidators(
                            () =>
                              ValidationUtil.requiredValidator(profileImages),
                            () =>
                              ValidationUtil.numberOfFilesValidator(
                                profileImages ?? [],
                                1
                              ),
                            () =>
                              ValidationUtil.fileSizeValidator(profileImages)
                          )
                        : ValidationUtil.composeValidators(
                            ValidationUtil.requiredValidator,
                            ValidationUtil.fileSizeValidator,
                            ValidationUtil.fileExtensionValidator,
                            (files) =>
                              ValidationUtil.numberOfFilesValidator(
                                files ?? [],
                                1
                              )
                          )
                    }
                    sx={{
                      border: "2px dashed #5E54FF",
                      p: ".8rem",
                      alignItems: "center",
                    }}
                  />
                  <Stack direction="row" spacing={2} mt={"30px"}>
                    <Button
                      sx={{
                        width: "20%",
                        boxShadow: "none",
                        "&:hover": {
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                    <Button
                      sx={{
                        width: "20%",
                        boxShadow: "none",
                        backgroundColor: "#03a9f4",
                        "&:hover": {
                          backgroundColor: "#0288d1",
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      onClick={() => navigate("/employees")}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          )}
        />
      )}
    </>
  );
};
