import {
  CREATE_BRAND_FAILURE,
  CREATE_BRAND_REQUEST,
  CREATE_BRAND_SUCCESS,
  DELETE_BRAND_FAILURE,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_SUCCESS,
  FETCH_BRANDS_FAILURE,
  FETCH_BRANDS_REQUEST,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRAND_FAILURE,
  FETCH_BRAND_REQUEST,
  FETCH_BRAND_SUCCESS,
  UPDATE_BRAND_FAILURE,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
} from "./brandActionType";

export const fetchBrandsRequest = (offset, searchQuery) => {
  return {
    type: FETCH_BRANDS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchBrandsSuccess = (data) => {
  return {
    type: FETCH_BRANDS_SUCCESS,
    payload: data,
  };
};

export const fetchBrandsFailure = (error) => {
  return {
    type: FETCH_BRANDS_FAILURE,
    error,
  };
};

export const fetchBrandRequest = (slug) => {
  return {
    type: FETCH_BRAND_REQUEST,
    slug,
  };
};
export const fetchBrandSuccess = (data) => {
  return {
    type: FETCH_BRAND_SUCCESS,
    payload: data,
  };
};
export const fetchBrandFailure = (error) => {
  return {
    type: FETCH_BRAND_FAILURE,
    error,
  };
};

export const createBrandRequest = (payload, onSuccess) => {
  return {
    type: CREATE_BRAND_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};
export const createBrandSuccess = (data) => {
  return {
    type: CREATE_BRAND_SUCCESS,
    payload: data,
  };
};
export const createBrandFailure = (error) => {
  return {
    type: CREATE_BRAND_FAILURE,
    error,
  };
};

export const updateBrandRequest = (slug, payload, onSuccess) => {
  return {
    type: UPDATE_BRAND_REQUEST,
    slug,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateBrandSuccess = () => {
  return {
    type: UPDATE_BRAND_SUCCESS,
  };
};

export const updateBrandFailure = (error) => {
  return {
    type: UPDATE_BRAND_FAILURE,
    error,
  };
};

export const deleteBrandRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_BRAND_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteBrandSuccess = () => {
  return {
    type: DELETE_BRAND_SUCCESS,
  };
};

export const deleteBrandFailure = (error) => {
  return {
    type: DELETE_BRAND_FAILURE,
    error,
  };
};
