import { Box, Button, Stack, Tab } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { EmployeeView, RoleView } from "../../components/views";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Authenticate } from "../../hoc/Authenticate";
import { Action, Models } from "../../const";

export const ViewEmployeePage = () => {
  const { email } = useParams();
  const employees = useSelector((state) => state.employee.employees);
  const foundEmployee = employees.find(
    (eachEmployee) => eachEmployee.email === email
  );
  const { roles, ...employee } = foundEmployee;
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const TabValue = {
    DETAILS: "1",
    ROLE: "2",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DmBox>
      <Stack direction="row" spacing={5} mb="1rem">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate("/employees")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Authenticate model={Models.EMPLOYEE} action={Action.EDIT}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(`/employees/edit/${employee.email}`)}
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Authenticate>
      </Stack>
      <TabContext value={value} width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Details" value={TabValue.DETAILS} />
            <Tab label="Role" value={TabValue.ROLE} />
          </TabList>
        </Box>
        <TabPanel value={TabValue.DETAILS} sx={{ p: "1rem 0px" }}>
          <EmployeeView employee={employee} />
        </TabPanel>
        <TabPanel value={TabValue.ROLE} sx={{ p: "1rem 0px" }}>
          {roles.map((role) => (
            <RoleView key={role.id} role={role} />
          ))}
        </TabPanel>
      </TabContext>
    </DmBox>
  );
};
