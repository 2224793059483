import {
  FETCH_REDEEM_REWARDS_FAILURE,
  FETCH_REDEEM_REWARDS_REQUEST,
  FETCH_REDEEM_REWARDS_SUCCESS,
  FETCH_REDEEM_REWARD_FAILURE,
  FETCH_REDEEM_REWARD_REQUEST,
  FETCH_REDEEM_REWARD_STATUS_FAILURE,
  FETCH_REDEEM_REWARD_STATUS_REQUEST,
  FETCH_REDEEM_REWARD_STATUS_SUCCESS,
  FETCH_REDEEM_REWARD_SUCCESS,
  UPDATE_REDEEM_REWARD_FAILURE,
  UPDATE_REDEEM_REWARD_REQUEST,
  UPDATE_REDEEM_REWARD_SUCCESS,
} from "./redeemRewardActionType";

export const fetchRedeemRewardsRequest = (offset, searchQuery, status) => {
  return {
    type: FETCH_REDEEM_REWARDS_REQUEST,
    offset,
    searchQuery,
    status,
  };
};

export const fetchRedeemRewardsSuccess = (data) => {
  return {
    type: FETCH_REDEEM_REWARDS_SUCCESS,
    payload: data,
  };
};

export const fetchRedeemRewardsFailure = (error) => {
  return {
    type: FETCH_REDEEM_REWARDS_FAILURE,
    error,
  };
};

export const fetchRedeemRewardRequest = (redeemRewardId) => {
  return {
    type: FETCH_REDEEM_REWARD_REQUEST,
    redeemRewardId,
  };
};

export const fetchRedeemRewardSuccess = (data) => {
  return {
    type: FETCH_REDEEM_REWARD_SUCCESS,
    payload: data,
  };
};

export const fetchRedeemRewardFailure = (error) => {
  return {
    type: FETCH_REDEEM_REWARD_FAILURE,
    error,
  };
};

export const fetchRedeemRewardStatusRequest = () => {
  return {
    type: FETCH_REDEEM_REWARD_STATUS_REQUEST,
  };
};

export const fetchRedeemRewardStatusSuccess = (data) => {
  return {
    type: FETCH_REDEEM_REWARD_STATUS_SUCCESS,
    payload: data,
  };
};

export const fetchRedeemRewardStatusFailure = (error) => {
  return {
    type: FETCH_REDEEM_REWARD_STATUS_FAILURE,
    error,
  };
};

export const updateRedeemRewardRequest = (id, payload, onSuccess) => {
  return {
    type: UPDATE_REDEEM_REWARD_REQUEST,
    id,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateRedeemRewardSuccess = () => {
  return {
    type: UPDATE_REDEEM_REWARD_SUCCESS,
  };
};

export const updateRedeemRewardFailure = (error) => {
  return {
    type: UPDATE_REDEEM_REWARD_FAILURE,
    error,
  };
};
