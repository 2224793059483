import { Box, Modal } from "@mui/material";
import React from "react";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  p: 6,
};

export const DmModal = ({ children, open, closeModal }) => {
  return (
    <Modal
      open={open}
      onClose={closeModal}
      BackdropProps={{ sx: { bgcolor: "rgba(243, 244, 255, 0.6)" } }}
    >
      <Box sx={boxStyle}>
        <Box sx={{ position: "relative" }}>
          <IconButton
            component="span"
            onClick={closeModal}
            sx={{
              position: "absolute",
              top: "-55px",
              right: "-40px",
              color: "#5E54FF",
              "&:hover": {
                bgcolor: "transparent",
              },
            }}
          >
            <CancelIcon />
          </IconButton>
          {React.cloneElement(children)}
        </Box>
      </Box>
    </Modal>
  );
};
