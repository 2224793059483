import {
  CREATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  FETCH_CATEGORY_OPTIONS_FAILURE,
  FETCH_CATEGORY_OPTIONS_REQUEST,
  FETCH_CATEGORY_OPTIONS_SUCCESS,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
} from "./categoryActionType";

const initialState = {
  loading: false,
  categoryOptionLoading: false,
  categories: [],
  category: {},
  totalCategories: 0,
  error: "",
};

export const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
    case FETCH_CATEGORY_REQUEST:
    case CREATE_CATEGORY_REQUEST:
    case UPDATE_CATEGORY_REQUEST:
    case DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CATEGORY_OPTIONS_REQUEST:
      return {
        ...state,
        categoryOptionLoading: true,
      };
    case FETCH_CATEGORIES_SUCCESS:
      const { categories, totalCategories } = action.payload;
      return {
        ...state,
        loading: false,
        categories: categories,
        totalCategories: totalCategories,
      };
    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        category: action.payload,
      };
    case FETCH_CATEGORY_OPTIONS_SUCCESS:
      return {
        ...state,
        categoryOptionLoading: false,
        categories: action.payload,
      };

    case FETCH_CATEGORY_OPTIONS_FAILURE:
      return {
        ...state,
        categoryOptionLoading: false,
        error: action.error,
      };
    case FETCH_CATEGORIES_FAILURE:
    case FETCH_CATEGORY_FAILURE:
    case CREATE_CATEGORY_FAILURE:
    case UPDATE_CATEGORY_FAILURE:
    case DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
