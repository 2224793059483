import { Stack, Button, Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, InputSwitch, InputFile } from "../";
import { createCountryRequest, updateCountryRequest } from "../../store";
import { ValidationUtil } from "../../utils";

export const CountryForm = ({ country }) => {
  const [isActive, setIsActive] = useState(country?.isActive ?? true);
  const twoLetterNameCodeRef = useRef();
  const [files, setFiles] = useState(
    country?.image?.path ? [country.image] : []
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const { name, twoLetterNameCode, currency, currencyCode, symbol } = values;
    let formData = new FormData();

    formData.append("name", name);
    formData.append("two_letter_name_code", twoLetterNameCode);
    formData.append("currency", currency);
    formData.append("currency_code", currencyCode);
    formData.append("symbol", symbol);
    formData.append("is_active", isActive ?? false);
    formData.append(
      "image",
      files[0]?.size ? files[0] : JSON.stringify(files[0])
    );
    country?.id
      ? dispatch(updateCountryRequest(country.slug, formData, onSuccess))
      : dispatch(createCountryRequest(formData, onSuccess));
  };

  const onSuccess = () => {
    navigate("/countries");
  };

  const initialValues = country
    ? {
        name: country.name,
        twoLetterNameCode: country.twoLetterNameCode,
        currency: country.currency,
        currencyCode: country.currencyCode,
        symbol: country.symbol,
        isActive: country.isActive,
      }
    : {};

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, errors }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            if (Object.keys(errors).length === 0) form.reset();
          }}
          noValidate
          autoComplete="off"
        >
          <Stack alignItems="center">
            <Typography variant="h4">
              {country ? `Edit ${country.name}` : "Add new country"}
            </Typography>
            <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
              <Field
                name="name"
                component={Input}
                label="Name"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="twoLetterNameCode"
                component={Input}
                inputRef={twoLetterNameCodeRef}
                label="Two Letter Name Code"
                validate={ValidationUtil.composeValidators(
                  ValidationUtil.requiredValidator,
                  () =>
                    ValidationUtil.lengthValidator(
                      twoLetterNameCodeRef.current.value,
                      2
                    )
                )}
              />
              <Field
                name="currency"
                component={Input}
                label="Currency"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="currencyCode"
                component={Input}
                label="Currency Code"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="symbol"
                component={Input}
                label="Symbol"
                validate={ValidationUtil.requiredValidator}
              />
              <Box>
                <Field
                  name="isActive"
                  checked={isActive}
                  defaultValue={isActive}
                  component={InputSwitch}
                  handleOnChange={(value) => setIsActive(value)}
                  type="checkbox"
                  label="Is Active"
                  labelPlacement="start"
                  sx={{ ml: "0px" }}
                />
              </Box>
              <Field
                name="image"
                component={InputFile}
                files={files}
                key={files.length > 0 ? 1 : 0} // changed key to re-render validate function
                onChangeHandler={(value) => {
                  setFiles(value);
                }}
                validate={
                  country?.image
                    ? ValidationUtil.composeValidators(
                        () => ValidationUtil.requiredValidator(files),
                        () =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1),
                        () => ValidationUtil.fileSizeValidator(files)
                      )
                    : ValidationUtil.composeValidators(
                        ValidationUtil.requiredValidator,
                        ValidationUtil.fileSizeValidator,
                        ValidationUtil.fileExtensionValidator,
                        (files) =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1)
                      )
                }
                sx={{
                  border: "2px dashed #5E54FF",
                  p: ".8rem",
                  alignItems: "center",
                }}
              />
              <Stack direction="row" spacing={2} mt={"30px"}>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    backgroundColor: "#03a9f4",
                    "&:hover": {
                      backgroundColor: "#0288d1",
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  onClick={() => navigate("/countries")}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
