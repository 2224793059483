import { api } from "../config";

export const NotificationService = {
  getNotifications: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/notifications/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getEmployeeNotifications: async (page) => {
    try {
      const { data } = await api.get(
        `api/admin/notifications/employee-notifications?page=${page}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteNotification: async (payload) => {
    try {
      const { data } = await api.post(
        "api/admin/notifications/delete",
        payload
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteEmployeeAllNotifications: async () => {
    try {
      const { data } = await api.post(
        "api/admin/notifications/employee-notifications/delete"
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
};
