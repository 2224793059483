import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { NotifyUtil } from "../../utils";
import {
  CREATE_CLIENT_REQUEST,
  DELETE_CLIENT_REQUEST,
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENT_REQUEST,
  UPDATE_CLIENT_REQUEST,
} from "./clientActionType";
import {
  createClientFailure,
  createClientSuccess,
  deleteClientFailure,
  deleteClientSuccess,
  fetchClientFailure,
  fetchClientsFailure,
  fetchClientsSuccess,
  fetchClientSuccess,
  updateClientFailure,
  updateClientSuccess,
} from "./clientAction";
import { ClientService } from "../../services";

function* fetchClientsSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      ClientService.getClients,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchClientsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchClientsFailure(error));
  }
}

function* fetchClientSaga(action) {
  try {
    const { data } = yield call(ClientService.getClient, action.email);
    yield put(fetchClientSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchClientFailure(error));
  }
}

function* createClientSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    const { data } = yield call(ClientService.createClient, payload);
    yield put(createClientSuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createClientFailure(error));
  }
}

function* updateClientSaga(action) {
  try {
    const {
      email,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(ClientService.updateClient, email, payload);
    yield put(updateClientSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateClientFailure(error));
  }
}

function* deleteClientSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(ClientService.deleteClient, payload);
    yield put(deleteClientSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteClientFailure(error));
  }
}

export function* clientWatcherSaga() {
  yield takeEvery(FETCH_CLIENTS_REQUEST, fetchClientsSaga);
  yield takeEvery(FETCH_CLIENT_REQUEST, fetchClientSaga);
  yield takeEvery(CREATE_CLIENT_REQUEST, createClientSaga);
  yield takeEvery(UPDATE_CLIENT_REQUEST, updateClientSaga);
  yield takeEvery(DELETE_CLIENT_REQUEST, deleteClientSaga);
}
