import React, { useEffect } from "react";
import { RewardForm } from "../../components/forms";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchRewardRequest } from "../../store";
import { DmBox } from "../../components";

export const AddRewardPage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const rewardState = useSelector((state) => state.reward);
  useEffect(() => {
    if (slug) dispatch(fetchRewardRequest(slug));
  }, [dispatch, slug]);

  return (
    <DmBox>
      {rewardState.loading ? (
        <CircularProgress />
      ) : (
        <RewardForm reward={slug && rewardState.reward} />
      )}
    </DmBox>
  );
};
