import {
  Box,
  ListItem,
  ListItemText,
  List,
  Typography,
  Divider,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { DATE_TIME_FORMAT } from "../../const";
import { StringUtil } from "../../utils";

export const RoleView = ({ role, title = "Details" }) => {
  return (
    <>
      <Typography sx={{ fontWeight: 600, pl: "1.3rem", mb: "1rem" }}>
        {title}
      </Typography>
      <Divider />
      <List sx={{ pt: "0px" }}>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Id
            </Typography>
            <Box>
              <Typography variant="value">{role.id}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Name
            </Typography>
            <Box>
              <Typography variant="value">{role.name}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Display Name
            </Typography>
            <Box>
              <Typography variant="value">{role.displayName}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Slug
            </Typography>
            <Box>
              <Typography variant="value">{role.slug}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Description
            </Typography>
            <Box>
              <Typography variant="value">{role.description}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Is super admin
            </Typography>
            <Box>
              <Typography variant="value">
                {StringUtil.getYesOrNo(role.isSuperAdmin)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Enabled
            </Typography>
            <Box>
              <Typography variant="value">
                {StringUtil.getYesOrNo(role.isActive)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>

        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Created
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(role.createdAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Updated
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(role.updatedAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};
