import {
  Box,
  Button,
  Tab,
  Stack,
  ListItem,
  ListItemText,
  List,
  Typography,
  Chip,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  BrandView,
  CategoryView,
  DealStoreView,
  UserView,
} from "../../components/views";
import { DATE_TIME_FORMAT } from "../../const";
import moment from "moment";
import { StringUtil } from "../../utils";

export const ViewDealAlertPage = () => {
  const { id } = useParams();
  const dealAlerts = useSelector((state) => state.dealAlert.dealAlerts);
  const dealAlert = dealAlerts.find((dealAlert) => dealAlert.id === id);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState("1");
  const TabValue = {
    DETAILS: "1",
    CATEGORIES: "2",
    STORES: "3",
    BRANDS: "4",
    USER: "5",
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <DmBox>
      <Stack direction="row" justifyContent="space-between">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate("/deal-alerts")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </Stack>
      <TabContext value={activeTab} width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab label="Details" value={TabValue.DETAILS} />
            <Tab label="Categories" value={TabValue.CATEGORIES} />
            <Tab label="Stores" value={TabValue.STORES} />
            <Tab label="Brands" value={TabValue.BRANDS} />
            <Tab label="User" value={TabValue.USER} />
          </TabList>
        </Box>
        <TabPanel value={TabValue.DETAILS} sx={{ p: "1rem 0px" }}>
          <List sx={{ pt: "0px" }}>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Id
                </Typography>
                <Box>
                  <Typography variant="value">{dealAlert.id}</Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Title
                </Typography>
                <Box>
                  <Typography variant="value">{dealAlert.title}</Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Deal Alert Type
                </Typography>
                <Box>
                  <Typography variant="value">
                    {dealAlert?.dealAlertType}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Keywords
                </Typography>
                <Box>
                  <Stack direction="row" flexWrap="wrap" spacing={2}>
                    {dealAlert?.keywords?.map((keyword) => (
                      <Chip key={keyword} label={keyword} variant="outlined" />
                    ))}
                  </Stack>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Is Enabled
                </Typography>
                <Box>
                  <Typography variant="value">
                    {StringUtil.getYesOrNo(dealAlert.isEnabled)}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Enable
                </Typography>
                <Box>
                  <Typography variant="value">
                    {StringUtil.getYesOrNo(dealAlert.isActive)}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Created
                </Typography>
                <Box>
                  <Typography variant="value">
                    {moment(dealAlert.createdAt).format(DATE_TIME_FORMAT)}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem variant="view">
              <ListItemText disableTypography variant="view">
                <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                  Updated
                </Typography>
                <Box>
                  <Typography variant="value">
                    {moment(dealAlert.updatedAt).format(DATE_TIME_FORMAT)}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
          </List>
        </TabPanel>
        <TabPanel value={TabValue.CATEGORIES} sx={{ p: "1rem 0px" }}>
          {dealAlert.categories.map((category, index) => (
            <CategoryView
              key={category.id}
              category={category}
              title={`#${index + 1} ${category.name} Details`}
            />
          ))}
        </TabPanel>
        <TabPanel value={TabValue.STORES} sx={{ p: "1rem 0px" }}>
          {dealAlert.stores.map((store, index) => (
            <DealStoreView
              key={store.id}
              dealStore={store}
              title={`#${index + 1} ${store.name} Details`}
            />
          ))}
        </TabPanel>
        <TabPanel value={TabValue.BRANDS} sx={{ p: "1rem 0px" }}>
          {dealAlert.brands.map((brand, index) => (
            <BrandView
              key={brand.id}
              brand={brand}
              title={`#${index + 1} ${brand.name} Details`}
            />
          ))}
        </TabPanel>
        <TabPanel value={TabValue.USER} sx={{ p: "1rem 0px" }}>
          <UserView user={dealAlert.user} title="Details" />
        </TabPanel>
      </TabContext>
    </DmBox>
  );
};
