import { api } from "../config";

export const BadgeService = {
  getBadges: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/badges/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getBadge: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/badges/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  createBadge: async (payload) => {
    try {
      const { data } = await api.post("api/admin/badges/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateBadge: async (slug, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/badges/update/${slug}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteBadge: async (payload) => {
    try {
      const { data } = await api.post("api/admin/badges/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
