import { Button, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DmBox } from "../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { CategoryView } from "../../components/views";
import { Authenticate } from "../../hoc/Authenticate";
import { Action, Models } from "../../const";

export const ViewCategoryPage = () => {
  const { slug } = useParams();
  const categories = useSelector((state) => state.category.categories);
  const category = categories.find(
    (eachCategory) => eachCategory.slug === slug
  );
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const limit = searchParams.get("limit");
  const page = searchParams.get("page");
  const queryParams = new URLSearchParams();
  queryParams.append("page", page);
  queryParams.append("limit", limit);

  return (
    <DmBox>
      <Stack direction="row" spacing={5} mb="1rem">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate(`/categories?${queryParams.toString()}`)}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Authenticate model={Models.CATEGORY} action={Action.EDIT}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              navigate(
                `/categories/edit/${category.slug}?${queryParams.toString()}`
              )
            }
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Authenticate>
      </Stack>
      <CategoryView category={category} />
    </DmBox>
  );
};
