import {
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  FETCH_CLIENTS_FAILURE,
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
} from "./clientActionType";

const initialState = {
  loading: false,
  clients: [],
  client: {},
  error: "",
  totalClients: 0,
};

export const ClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENTS_REQUEST:
    case FETCH_CLIENT_REQUEST:
    case CREATE_CLIENT_REQUEST:
    case UPDATE_CLIENT_REQUEST:
    case DELETE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_CLIENTS_SUCCESS:
      const { clients, totalClients } = action.payload;
      return {
        ...state,
        loading: false,
        clients: clients,
        totalClients: totalClients,
      };

    case FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        client: action.payload,
      };
    case FETCH_CLIENTS_FAILURE:
    case FETCH_CLIENT_FAILURE:
    case CREATE_CLIENT_FAILURE:
    case UPDATE_CLIENT_FAILURE:
    case DELETE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
