import { Box, Button, CircularProgress, Stack, Tab } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DealView, ReportView, UserView } from "../../components/views";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { fetchReportRequest, fetchReportStatusRequest } from "../../store";
import EditIcon from "@mui/icons-material/Edit";
import { useToggle } from "../../hooks";
import { DmModal, ReportModal } from "../../components/Modal";

export const ViewReportPage = () => {
  const { id } = useParams();
  const [reportStatusModal, setReportStatusModal] = useToggle();
  const dispatch = useDispatch();
  const { loading, report } = useSelector((state) => state.report);
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const TabValue = {
    DETAILS: "1",
    USER: "2",
    DEAL: "3",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchReportRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchReportStatusRequest());
  }, [dispatch]);

  const onSuccess = () => {
    dispatch(fetchReportRequest(id)); // fetch updated report
  };

  return (
    <DmBox>
      <Stack direction="row" justifyContent="space-between">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate("/reports")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Button
          variant="text"
          color="success"
          onClick={setReportStatusModal}
          startIcon={<EditIcon />}
        >
          Update Status
        </Button>
      </Stack>
      {!report?.id || loading ? (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <TabContext value={value} width="100%">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Details" value={TabValue.DETAILS} />
                <Tab label="User" value={TabValue.USER} />
                <Tab label="Deal" value={TabValue.DEAL} />
              </TabList>
            </Box>
            <TabPanel value={TabValue.DETAILS} sx={{ p: "1rem 0px" }}>
              <ReportView report={report} />
            </TabPanel>
            <TabPanel value={TabValue.USER} sx={{ p: "1rem 0px" }}>
              <UserView user={report.user} title="User Details" />
            </TabPanel>
            <TabPanel value={TabValue.DEAL} sx={{ p: "1rem 0px" }}>
              <DealView deal={report.deal} title="Deal Details" />
            </TabPanel>
          </TabContext>
          <DmModal open={reportStatusModal} closeModal={setReportStatusModal}>
            <ReportModal
              report={report}
              onCancel={setReportStatusModal}
              onSuccess={onSuccess}
            />
          </DmModal>
        </>
      )}
    </DmBox>
  );
};
