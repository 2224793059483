import { api } from "../config";

export const UserService = {
  getUsers: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/users/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  toggleUsersStatus: async (payload) => {
    try {
      const { data } = await api.post("api/admin/users/toggle-status", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteUser: async (payload) => {
    try {
      const { data } = await api.post("api/admin/users/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
