export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const FETCH_EMPLOYEE_NOTIFICATIONS_REQUEST =
  "FETCH_EMPLOYEE_NOTIFICATIONS_REQUEST";
export const FETCH_EMPLOYEE_NOTIFICATIONS_SUCCESS =
  "FETCH_EMPLOYEE_NOTIFICATIONS_SUCCESS";
export const FETCH_EMPLOYEE_NOTIFICATIONS_FAILURE =
  "FETCH_EMPLOYEE_NOTIFICATIONS_FAILURE";

export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILURE = "DELETE_NOTIFICATION_FAILURE";

export const DELETE_EMPLOYEE_ALL_NOTIFICATION_REQUEST =
  "DELETE_EMPLOYEE_ALL_NOTIFICATION_REQUEST";
export const DELETE_EMPLOYEE_ALL_NOTIFICATION_SUCCESS =
  "DELETE_EMPLOYEE_ALL_NOTIFICATION_SUCCESS";
export const DELETE_EMPLOYEE_ALL_NOTIFICATION_FAILURE =
  "DELETE_EMPLOYEE_ALL_NOTIFICATION_FAILURE";
