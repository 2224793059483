import { api } from "../config";

export const ResourceService = {
  getResources: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/resources/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getResource: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/resources/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getResourceOptions: async () => {
    try {
      const { data } = await api.get("api/admin/roles/role-options");
      return data;
    } catch (error) {
      throw error;
    }
  },
  createResource: async (payload) => {
    try {
      const { data } = await api.post(
        "api/admin/resources/",
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateResource: async (slug, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/resources/update/${slug}`,
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteResource: async (payload) => {
    try {
      const { data } = await api.post("api/admin/resources/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
