import {
  DELETE_DEAL_ALERT_FAILURE,
  DELETE_DEAL_ALERT_REQUEST,
  FETCH_DEAL_ALERTS_FAILURE,
  FETCH_DEAL_ALERTS_REQUEST,
  FETCH_DEAL_ALERTS_SUCCESS,
} from "./dealAlertActionType";

const initialState = {
  loading: false,
  dealAlerts: [],
  error: "",
  totalDealAlerts: 0,
};

export const DealAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEAL_ALERTS_REQUEST:
    case DELETE_DEAL_ALERT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DEAL_ALERTS_SUCCESS:
      const { dealAlerts, totalDealAlerts } = action.payload;
      return {
        ...state,
        loading: false,
        dealAlerts: dealAlerts,
        totalDealAlerts: totalDealAlerts,
      };
    case FETCH_DEAL_ALERTS_FAILURE:
    case DELETE_DEAL_ALERT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
