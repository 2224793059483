import {
  DELETE_EMAIL_HISTORY_FAILURE,
  DELETE_EMAIL_HISTORY_REQUEST,
  DELETE_EMAIL_HISTORY_SUCCESS,
  FETCH_EMAIL_HISTORIES_FAILURE,
  FETCH_EMAIL_HISTORIES_REQUEST,
  FETCH_EMAIL_HISTORIES_SUCCESS,
} from "./emailHistoryActionType";

export const fetchEmailHistoriesRequest = (offset, searchQuery) => {
  return {
    type: FETCH_EMAIL_HISTORIES_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchEmailHistoriesSuccess = (data) => {
  return {
    type: FETCH_EMAIL_HISTORIES_SUCCESS,
    payload: data,
  };
};

export const fetchEmailHistoriesFailure = (error) => {
  return {
    type: FETCH_EMAIL_HISTORIES_FAILURE,
    error,
  };
};

export const deleteEmailHistoryRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_EMAIL_HISTORY_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteEmailHistorySuccess = () => {
  return {
    type: DELETE_EMAIL_HISTORY_SUCCESS,
  };
};

export const deleteEmailHistoryFailure = (error) => {
  return {
    type: DELETE_EMAIL_HISTORY_FAILURE,
    error,
  };
};
