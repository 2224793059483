import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import {
  createBadgeFailure,
  createBadgeSuccess,
  deleteBadgeFailure,
  deleteBadgeSuccess,
  fetchBadgeFailure,
  fetchBadgesFailure,
  fetchBadgesSuccess,
  fetchBadgeSuccess,
  updateBadgeFailure,
  updateBadgeSuccess,
} from "./badgeAction";
import {
  CREATE_BADGE_REQUEST,
  DELETE_BADGE_REQUEST,
  FETCH_BADGES_REQUEST,
  FETCH_BADGE_REQUEST,
  UPDATE_BADGE_REQUEST,
} from "./badgeActionType";
import { BadgeService } from "../../services";
import { toast } from "react-toastify";

function* getBadgesSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      BadgeService.getBadges,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchBadgesSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchBadgesFailure(error));
  }
}
function* getBadgeSaga(action) {
  try {
    const { data } = yield call(BadgeService.getBadge, action.slug);
    yield put(fetchBadgeSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchBadgeFailure(error));
  }
}

function* createBadgeSaga(action) {
  try {
    const {
      meta: { onSuccess },
    } = action;
    const { data } = yield call(BadgeService.createBadge, action.payload);
    yield put(createBadgeSuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createBadgeFailure(error));
  }
}
function* updateBadgeSaga(action) {
  try {
    const {
      slug,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(BadgeService.updateBadge, slug, payload);
    yield put(updateBadgeSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateBadgeFailure(error));
  }
}

function* deleteBadgeSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(BadgeService.deleteBadge, payload);
    yield put(deleteBadgeSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteBadgeFailure(error));
  }
}

export function* badgeWatcherSaga() {
  yield takeEvery(FETCH_BADGES_REQUEST, getBadgesSaga);
  yield takeEvery(FETCH_BADGE_REQUEST, getBadgeSaga);
  yield takeEvery(CREATE_BADGE_REQUEST, createBadgeSaga);
  yield takeEvery(UPDATE_BADGE_REQUEST, updateBadgeSaga);
  yield takeEvery(DELETE_BADGE_REQUEST, deleteBadgeSaga);
}
