import { Stack, Button, Box, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Input, InputSwitch } from "../";
import { updateReportRequest } from "../../store";
import { ArrayUtil, ValidationUtil } from "../../utils";
import { AutoCompleteSelect } from "../Input";

export const ReportForm = ({
  report,
  onCancel,
  onSuccess: onSuccessCallBack,
}) => {
  const [isActive, setIsActive] = useState(false);
  const reportStatus = useSelector((state) => state.report.reportStatus);
  const dispatch = useDispatch();
  const reportStatusOptions = ArrayUtil.getReportStatusOption(reportStatus);
  // form state
  const [selectedOption, setSelectedOption] = useState({
    label: _.capitalize(report.status),
    value: report.status,
  });

  const onSubmit = async (values) => {
    const { reviewMessage, reportStatus, isActive } = values;
    const payload = {
      review_message: reviewMessage,
      status: reportStatus.value,
      is_active: isActive,
    };
    dispatch(updateReportRequest(report.id, payload, onSuccess));
  };

  const onSuccess = () => {
    onCancel();
    onSuccessCallBack();
  };

  const initialValues = {
    description: report.description,
    isActive: report.isActive,
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
          }}
          noValidate
          autoComplete="off"
        >
          <Stack alignItems="center">
            <Typography variant="h5">Review Report</Typography>
            <Stack spacing={2} width="100%" mt="1.5rem">
              <Field
                name="description"
                component={Input}
                label="Description"
                type="textarea"
                multiline
                inputProps={{
                  sx: {
                    minHeight: "150px",
                  },
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
              <Field
                name="reportStatus"
                component={AutoCompleteSelect}
                label="report"
                options={reportStatusOptions}
                handleOnChange={(value) => setSelectedOption(value)}
                value={selectedOption}
                defaultValue={selectedOption}
              />
              <Field
                name="reviewMessage"
                component={Input}
                label="Review Message"
                type="textarea"
                multiline
                inputProps={{
                  sx: {
                    minHeight: "150px",
                  },
                }}
                validate={ValidationUtil.requiredValidator}
              />
              <Box>
                <Field
                  name="isActive"
                  checked={isActive}
                  component={InputSwitch}
                  handleOnChange={(value) => setIsActive(value)}
                  type="checkbox"
                  label="Is Active"
                  labelPlacement="start"
                  sx={{ ml: "0px" }}
                />
              </Box>
              <Stack direction="row" spacing={2} mt={"30px"}>
                <Button
                  sx={{
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "#03a9f4",
                    "&:hover": {
                      backgroundColor: "#0288d1",
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
