"./bannerActionType";

import {
  CREATE_ROLE_FAILURE,
  CREATE_ROLE_REQUEST,
  DELETE_ROLE_FAILURE,
  DELETE_ROLE_REQUEST,
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLE_FAILURE,
  FETCH_ROLE_OPTIONS_FAILURE,
  FETCH_ROLE_OPTIONS_REQUEST,
  FETCH_ROLE_OPTIONS_SUCCESS,
  FETCH_ROLE_REQUEST,
  FETCH_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  UPDATE_ROLE_REQUEST,
} from "./roleActionType";

const initialState = {
  loading: false,
  roleOptionsLoading: false,
  roles: [],
  role: {},
  roleOptions: [],
  error: "",
  totalRoles: 0,
};

export const RoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLES_REQUEST:
    case FETCH_ROLE_REQUEST:
    case CREATE_ROLE_REQUEST:
    case UPDATE_ROLE_REQUEST:
    case DELETE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ROLE_OPTIONS_REQUEST:
      return {
        ...state,
        roleOptionsLoading: true,
      };
    case FETCH_ROLES_SUCCESS:
      const { roles, totalRoles } = action.payload;
      return {
        ...state,
        loading: false,
        roles: roles,
        totalRoles: totalRoles,
      };
    case FETCH_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        role: action.payload,
      };
    case FETCH_ROLE_OPTIONS_SUCCESS:
      return {
        ...state,
        roleOptionsLoading: false,
        roleOptions: action.payload,
      };
    case FETCH_ROLE_OPTIONS_FAILURE:
      return {
        ...state,
        roleOptionsLoading: false,
        error: action.error,
      };
    case FETCH_ROLES_FAILURE:
    case FETCH_ROLE_FAILURE:
    case CREATE_ROLE_FAILURE:
    case UPDATE_ROLE_FAILURE:
    case DELETE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
