import React, { useEffect } from "react";
import { CountryForm } from "../../components/forms";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchCountryRequest } from "../../store";
import { DmBox } from "../../components";

export const AddCountryPage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const countryState = useSelector((state) => state.country);
  useEffect(() => {
    if (slug) dispatch(fetchCountryRequest(slug));
  }, [dispatch, slug]);

  return (
    <DmBox>
      {countryState.loading ? (
        <CircularProgress />
      ) : (
        <CountryForm country={slug && countryState.country} />
      )}
    </DmBox>
  );
};
