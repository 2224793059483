export const FETCH_BADGES_REQUEST = "FETCH_BADGES_REQUEST";
export const FETCH_BADGES_SUCCESS = "FETCH_BADGES_SUCCESS";
export const FETCH_BADGES_FAILURE = "FETCH_BADGES_FAILURE";

export const FETCH_BADGE_REQUEST = "FETCH_BADGE_REQUEST";
export const FETCH_BADGE_SUCCESS = "FETCH_BADGE_SUCCESS";
export const FETCH_BADGE_FAILURE = "FETCH_BADGE_FAILURE";

export const CREATE_BADGE_REQUEST = "CREATE_BADGE_REQUEST";
export const CREATE_BADGE_SUCCESS = "CREATE_BADGE_SUCCESS";
export const CREATE_BADGE_FAILURE = "CREATE_BADGE_FAILURE";

export const UPDATE_BADGE_REQUEST = "UPDATE_BADGE_REQUEST";
export const UPDATE_BADGE_SUCCESS = "UPDATE_BADGE_SUCCESS";
export const UPDATE_BADGE_FAILURE = "UPDATE_BADGE_FAILURE";

export const DELETE_BADGE_REQUEST = "DELETE_BADGE_REQUEST";
export const DELETE_BADGE_SUCCESS = "DELETE_BADGE_SUCCESS";
export const DELETE_BADGE_FAILURE = "DELETE_BADGE_FAILURE";
