import { api } from "../config";

export const RoleService = {
  getRoles: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/roles/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getRole: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/roles/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getRoleOptions: async () => {
    try {
      const { data } = await api.get("api/admin/resources/resource-options");
      return data;
    } catch (error) {
      throw error;
    }
  },
  createRole: async (payload) => {
    try {
      const { data } = await api.post(
        "api/admin/roles/",
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateRole: async (slug, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/roles/update/${slug}`,
        JSON.stringify(payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteRole: async (payload) => {
    try {
      const { data } = await api.post("api/admin/roles/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
