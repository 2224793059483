import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Menu,
  Stack,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
  deleteEmployeeAllNotificationRequest,
  fetchEmployeeNotificationsRequest,
} from "../store/notification/notificationAction";
import { NotificationItem } from "./notificationItem";
import { loggedEmployeeSelector } from "../store/selector";
import {
  fetchUpdatedEmployeeRequest,
  updateEmployeeNotificationSummarySeenRequest,
  updateMarkEmployeeNotificationReadRequest,
} from "../store/employee/employeeAction";
import { StringUtil } from "../utils";
import { useNavigate } from "react-router-dom";
import { Models } from "../const";
import { clientSocket, SocketEvents } from "../const/socket";
import InfiniteScroll from "react-infinite-scroll-component";

export const NotificationBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { employeeNotifications, hasMoreEmployeeNotification } = useSelector(
    (store) => store.notification
  );
  const [hasMore, setHasMore] = useState(true);
  const [anchorElNotificationNav, setAnchorElNotificationNav] = useState(false);
  const loggedEmployee = useSelector(loggedEmployeeSelector);
  const loggedEmployeeEmail = loggedEmployee.email;
  const [hideNotificationBadge, setHideNotificationBadge] = useState(true);
  const [currentNumberOfNotifications, setCurrentNumberOfNotifications] =
    useState(0);

  const onSuccessNotification = () => {
    dispatch(fetchUpdatedEmployeeRequest(loggedEmployee.email));
  };

  const socketEventHandler = useCallback(() => {
    dispatch(fetchEmployeeNotificationsRequest(0));
    dispatch(fetchUpdatedEmployeeRequest(loggedEmployee.email));
  }, [dispatch, loggedEmployee.email]);

  useEffect(() => {
    dispatch(fetchEmployeeNotificationsRequest(page));
    dispatch(fetchUpdatedEmployeeRequest(loggedEmployeeEmail));
  }, [dispatch, page, loggedEmployeeEmail]);

  useEffect(() => {
    if (loggedEmployee.currentNumberOfNotifications !== 0)
      setHideNotificationBadge(loggedEmployee.isNotificationsSummarySeen);
    setCurrentNumberOfNotifications(
      loggedEmployee.currentNumberOfNotifications
    );
  }, [loggedEmployee]);

  useEffect(() => {
    // subscribe to deal created events
    clientSocket.on(SocketEvents.DEAL_CREATED, socketEventHandler);
    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      clientSocket.off(SocketEvents.DEAL_CREATED, socketEventHandler);
    };
  }, [socketEventHandler]);

  useEffect(() => {
    // subscribe to report created events
    clientSocket.on(SocketEvents.REPORT_CREATED, socketEventHandler);
    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      clientSocket.off(SocketEvents.REPORT_CREATED, socketEventHandler);
    };
  }, [socketEventHandler]);

  useEffect(() => {
    // subscribe to redeem reward created events
    clientSocket.on(SocketEvents.REDEEM_REWARD_CREATED, socketEventHandler);
    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      clientSocket.off(SocketEvents.REDEEM_REWARD_CREATED, socketEventHandler);
    };
  }, [socketEventHandler]);

  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotificationNav(event.currentTarget);
    dispatch(
      updateEmployeeNotificationSummarySeenRequest(
        {
          token: loggedEmployee.token,
          is_notifications_summary_seen: true,
        },
        onSuccessNotification
      )
    );
    setHideNotificationBadge(true);
  };

  const handleCloseNotificationMenu = () => {
    setAnchorElNotificationNav(null);
  };

  const handleNotification = (notification) => {
    dispatch(
      updateMarkEmployeeNotificationReadRequest({
        notificationId: notification.id,
      })
    );
    dispatch(fetchEmployeeNotificationsRequest(0)); // need to refetch the notifications due to read
    switch (notification.modelType) {
      case Models.DEAL:
        navigate(`deals/details/${notification.details.dealSlug}`);
        break;
      case Models.REPORT:
        navigate(`reports/details/${notification.model}`);
        break;
      default:
        navigate(`redeem-rewards/details/${notification.model}`);
        break;
    }
    setAnchorElNotificationNav(null);
  };

  const fetchMoreNotifications = () => {
    const nextPage = Math.ceil(employeeNotifications.length / 10);
    setPage(nextPage);
  };

  useEffect(() => {
    if (
      employeeNotifications.length >= loggedEmployee.notifications.length ||
      !hasMoreEmployeeNotification
    )
      setHasMore(false);
  }, [
    hasMoreEmployeeNotification,
    employeeNotifications.length,
    loggedEmployee.notifications.length,
  ]);

  const handleDeleteAllNotifications = () => {
    dispatch(deleteEmployeeAllNotificationRequest());
    dispatch(fetchEmployeeNotificationsRequest(0));
    dispatch(fetchUpdatedEmployeeRequest(loggedEmployeeEmail));
  };

  return (
    <>
      <IconButton onClick={handleOpenNotificationMenu}>
        <Badge
          badgeContent={currentNumberOfNotifications}
          color="error"
          invisible={hideNotificationBadge}
        >
          {hideNotificationBadge ? (
            <NotificationsIcon />
          ) : (
            <NotificationsActiveIcon color="primary" />
          )}
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorElNotificationNav}
        open={Boolean(anchorElNotificationNav)}
        onClose={handleCloseNotificationMenu}
      >
        <List
          disablePadding
          id="scrollNotificationList"
          sx={{
            minWidth: "450px",
            maxWidth: "450px",
            maxHeight: "450px",
            overflowY: "auto",
            boxShadow:
              "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;",
          }}
        >
          <Stack direction="row">
            <ListItem sx={{ fontSize: "1rem", fontWeight: 500 }}>
              Notifications (
              {StringUtil.abbrNum(loggedEmployee?.notifications?.length) ?? ""})
            </ListItem>
            {loggedEmployee?.notifications?.length !== 0 && (
              <ListItem
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{
                    fontSize: ".9rem",
                    fontWeight: 500,
                  }}
                  color="error"
                  endIcon={<DeleteIcon />}
                  onClick={handleDeleteAllNotifications}
                >
                  Clear All
                </Button>
              </ListItem>
            )}
          </Stack>
          <Divider />
          <InfiniteScroll
            dataLength={employeeNotifications?.length} //This is important field to render the next data
            next={fetchMoreNotifications}
            hasMore={hasMore}
            loader={
              <Box sx={{ p: "8px 16px", textAlign: "center" }}>
                <CircularProgress />
              </Box>
            }
            scrollableTarget="scrollNotificationList"
          >
            {employeeNotifications?.map((eachNotification, index) => (
              <Box key={eachNotification.id}>
                <ListItemButton
                  onClick={() => handleNotification(eachNotification)}
                >
                  <NotificationItem notification={eachNotification} />
                </ListItemButton>
                {index !== employeeNotifications?.length - 1 && (
                  <Divider variant="inset" component="li" />
                )}
              </Box>
            ))}
          </InfiniteScroll>
        </List>
      </Menu>
    </>
  );
};
