export const FETCH_COMMENTS_REQUEST = "FETCH_COMMENTS_REQUEST";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

export const TOGGLE_COMMENTS_STATUS_REQUEST = "TOGGLE_COMMENTS_STATUS_REQUEST";
export const TOGGLE_COMMENTS_STATUS_SUCCESS = "TOGGLE_COMMENTS_STATUS_SUCCESS";
export const TOGGLE_COMMENTS_STATUS_FAILURE = "TOGGLE_COMMENTS_STATUS_FAILURE";
