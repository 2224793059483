import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createResourceRequest,
  fetchResourceOptionsRequest,
  updateResourceRequest,
} from "../../store";
import { InputSwitch, Input, AutoCompleteSelect } from "../Input";
import { ArrayUtil, ValidationUtil, StringUtil } from "../../utils";
import { CanPerformAction } from "../../const";

export const ResourceForm = ({ resource }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resourceState = useSelector((state) => state.resource);
  const canPerformActionOptions = ArrayUtil.getCanPerformActionOptions([
    CanPerformAction.CAN_ADD,
    CanPerformAction.CAN_EDIT,
    CanPerformAction.CAN_VIEW,
    CanPerformAction.CAN_DELETE,
    CanPerformAction.CAN_EXPORT,
  ]);

  // form state
  const [isActive, setIsActive] = useState(false);
  const [selectedCanPerformActionOption, setSelectedCanPerformActionOption] =
    useState(
      resource?.id && {
        value: resource.canPerformAction,
        label: StringUtil.convertCapUnderscore(resource.canPerformAction),
      }
    );

  const initialValues = resource
    ? {
        name: resource.name,
        displayName: resource.displayName,
        isActive: resource.isActive,
      }
    : {};

  useEffect(() => {
    dispatch(fetchResourceOptionsRequest());
  }, [dispatch]);

  const onSubmit = (values) => {
    const { name, displayName, role, canPerformAction, isActive } = values;
    const payload = {
      name,
      display_name: displayName,
      role: role?.value,
      can_perform_action: canPerformAction.value,
      is_active: isActive ?? false,
    };
    resource?.id
      ? dispatch(updateResourceRequest(resource.slug, payload, onSuccess))
      : dispatch(createResourceRequest(payload, onSuccess));
  };

  const onSuccess = () => {
    navigate("/resources");
  };

  return (
    <>
      {resourceState.resourceOptionLoading ? (
        <CircularProgress />
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={(event) => {
                handleSubmit(event);
              }}
              noValidate
              autoComplete="off"
            >
              <Stack alignItems="center">
                <Typography variant="h4">
                  {resource
                    ? `${resource.displayName} Permission`
                    : "Add new permission"}
                </Typography>
                <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
                  <Field
                    name="name"
                    component={Input}
                    label="Name"
                    placeholder="Model Name"
                    validate={ValidationUtil.requiredValidator}
                  />
                  <Field
                    name="displayName"
                    component={Input}
                    label="Display Name"
                    validate={ValidationUtil.requiredValidator}
                  />
                  <Field
                    name="canPerformAction"
                    component={AutoCompleteSelect}
                    label="Can Perform Action"
                    options={canPerformActionOptions}
                    handleOnChange={(value) =>
                      setSelectedCanPerformActionOption(value)
                    }
                    value={selectedCanPerformActionOption}
                    defaultValue={selectedCanPerformActionOption}
                    validate={ValidationUtil.requiredValidator}
                  />
                  <Box>
                    <Field
                      name="isActive"
                      checked={isActive}
                      component={InputSwitch}
                      handleOnChange={(value) => setIsActive(value)}
                      type="checkbox"
                      label="Is Active"
                      labelPlacement="start"
                      sx={{ ml: "0px" }}
                    />
                  </Box>

                  <Stack direction="row" spacing={2} mt={"30px"}>
                    <Button
                      sx={{
                        width: "20%",
                        boxShadow: "none",
                        "&:hover": {
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                    <Button
                      sx={{
                        width: "20%",
                        boxShadow: "none",
                        backgroundColor: "#03a9f4",
                        "&:hover": {
                          backgroundColor: "#0288d1",
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      onClick={() => navigate("/resources")}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          )}
        />
      )}
    </>
  );
};
