export const NotifyUtil = {
  errorMessage: (error) => {
    if (process.env.REACT_APP_ENV === "dev") console.log(error.stack);

    if (!error.response)
      return "It seems we can't connect to the server right now!";
    if (error.response?.data) return error.response.data.message;

    return "Something went wrong!!! We are sorry for this experience. You can email us at support@mamadiscount.com";
  },
};
