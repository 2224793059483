import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import { toast } from "react-toastify";
import { EmailHistoryService } from "../../services";
import {
  DELETE_EMAIL_HISTORY_REQUEST,
  FETCH_EMAIL_HISTORIES_REQUEST,
} from "./emailHistoryActionType";
import {
  deleteEmailHistoryFailure,
  deleteEmailHistorySuccess,
  fetchEmailHistoriesFailure,
  fetchEmailHistoriesSuccess,
} from "./emailHistoryAction";

function* getEmailHistoriesSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      EmailHistoryService.getEmailHistories,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchEmailHistoriesSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchEmailHistoriesFailure(error));
  }
}

function* deleteEmailHistorySaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(EmailHistoryService.deleteEmailHistory, payload);
    yield put(deleteEmailHistorySuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteEmailHistoryFailure(error));
  }
}

export function* emailHistoryWatcherSaga() {
  yield takeEvery(FETCH_EMAIL_HISTORIES_REQUEST, getEmailHistoriesSaga);
  yield takeEvery(DELETE_EMAIL_HISTORY_REQUEST, deleteEmailHistorySaga);
}
