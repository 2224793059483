import { Paper, Checkbox, CircularProgress } from "@mui/material";
import React from "react";
import SortIcon from "@mui/icons-material/ArrowDownward";
import DataTable from "react-data-table-component";

export const DmTable = ({
  title,
  data,
  columns,
  pending,
  onRowMouseEnter = undefined,
  onRowMouseLeave = undefined,
  onChangeRowsPerPage = undefined,
  onChangePage = undefined,
  totalRow,
  paginationDefaultPage = 1,
  paginationPerPage = 10,
  actions = undefined,
  contextActions = undefined,
  onSelectedRowsChange = undefined,
  clearSelectedRows = undefined,
  selectableRowDisabled = undefined,
  sx = {},
  conditionalRowStyles = [],
}) => {
  return (
    <Paper sx={sx}>
      <DataTable
        title={title}
        columns={columns}
        data={data}
        sortIcon={<SortIcon />}
        pagination
        paginationDefaultPage={paginationDefaultPage}
        paginationPerPage={paginationPerPage}
        paginationServer
        paginationTotalRows={totalRow}
        selectableRows
        selectableRowsComponent={Checkbox}
        progressPending={pending}
        progressComponent={<CircularProgress />}
        striped
        responsive
        fixedHeader
        onRowMouseEnter={(row) => onRowMouseEnter && onRowMouseEnter(row)}
        onRowMouseLeave={() => onRowMouseLeave && onRowMouseLeave()}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        actions={actions}
        contextActions={contextActions}
        onSelectedRowsChange={onSelectedRowsChange}
        clearSelectedRows={clearSelectedRows}
        selectableRowDisabled={selectableRowDisabled}
        conditionalRowStyles={conditionalRowStyles}
      />
    </Paper>
  );
};
