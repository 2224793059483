import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { NotifyUtil } from "../../utils";
import {
  CREATE_BRAND_REQUEST,
  DELETE_BRAND_REQUEST,
  FETCH_BRANDS_REQUEST,
  FETCH_BRAND_REQUEST,
  UPDATE_BRAND_REQUEST,
} from "./brandActionType";
import {
  createBrandFailure,
  createBrandSuccess,
  deleteBrandFailure,
  deleteBrandSuccess,
  fetchBrandFailure,
  fetchBrandsFailure,
  fetchBrandsSuccess,
  fetchBrandSuccess,
  updateBrandFailure,
  updateBrandSuccess,
} from "./brandAction";
import { BrandService } from "../../services";

function* fetchBrandsSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      BrandService.getBrands,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchBrandsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchBrandsFailure(error));
  }
}

function* fetchBrandSaga(action) {
  try {
    const { data } = yield call(BrandService.getBrand, action.slug);
    yield put(fetchBrandSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchBrandFailure(error));
  }
}

function* createBrandSaga(action) {
  try {
    const {
      meta: { onSuccess },
    } = action;
    const { data } = yield call(BrandService.createBrand, action.payload);
    yield put(createBrandSuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createBrandFailure(error));
  }
}

function* updateBrandSaga(action) {
  try {
    const {
      slug,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(BrandService.updateBrand, slug, payload);
    yield put(updateBrandSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateBrandFailure(error));
  }
}

function* deleteBrandSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(BrandService.deleteBrand, payload);
    yield put(deleteBrandSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteBrandFailure(error));
  }
}

export function* brandWatcherSaga() {
  yield takeEvery(FETCH_BRANDS_REQUEST, fetchBrandsSaga);
  yield takeEvery(FETCH_BRAND_REQUEST, fetchBrandSaga);
  yield takeEvery(CREATE_BRAND_REQUEST, createBrandSaga);
  yield takeEvery(UPDATE_BRAND_REQUEST, updateBrandSaga);
  yield takeEvery(DELETE_BRAND_REQUEST, deleteBrandSaga);
}
