import { Stack, Button, InputAdornment, Box, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, InputSwitch } from "../";
import { createClientRequest, updateClientRequest } from "../../store";
import { ValidationUtil } from "../../utils";

export const ClientForm = ({ client }) => {
  const [isActive, setIsActive] = useState(false);
  const [updateToken, setUpdateToken] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const { email, mobileNumber, websiteLink, address, updateToken, isActive } =
      values;
    const payload = {
      email,
      mobile_number: mobileNumber,
      website_link: websiteLink,
      address: address.trim(),
      is_active: isActive ?? false,
      update_token: updateToken ?? false,
    };
    client?.id
      ? dispatch(updateClientRequest(client.email, payload, onSuccess))
      : dispatch(createClientRequest(payload, onSuccess));
  };

  const onSuccess = () => {
    navigate("/clients");
  };

  const initialValues = client
    ? {
        email: client.email,
        mobileNumber: client.mobileNumber,
        websiteLink: client.websiteLink,
        address: client.address,
        isActive: client.isActive,
      }
    : {};

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, errors }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            // if (Object.keys(errors).length === 0) form.reset();
          }}
          noValidate
          autoComplete="off"
        >
          <Stack alignItems="center">
            <Typography variant="h4">
              {client ? `Edit ${client.email}` : "Add new client"}
            </Typography>
            <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
              <Field
                name="email"
                component={Input}
                label="Email"
                validate={ValidationUtil.emailValidator}
              />
              <Field
                name="mobileNumber"
                component={Input}
                label="Mobile Number"
                type="number"
                validate={ValidationUtil.mobileNumberValidator}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+88</InputAdornment>
                  ),
                }}
              />
              <Field
                name="websiteLink"
                component={Input}
                label="Website Link"
                validate={ValidationUtil.composeValidators(
                  ValidationUtil.requiredValidator,
                  ValidationUtil.isValidHttpUrl
                )}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">http</InputAdornment>
                  ),
                }}
              />
              <Field
                name="address"
                component={Input}
                label="Address"
                type="textarea"
                multiline
                inputProps={{
                  sx: {
                    minHeight: "150px",
                  },
                }}
                validate={ValidationUtil.requiredValidator}
              />
              <Box>
                <Field
                  name="isActive"
                  checked={isActive}
                  component={InputSwitch}
                  handleOnChange={(value) => setIsActive(value)}
                  type="checkbox"
                  label="Is Active"
                  labelPlacement="start"
                  sx={{ ml: "0px" }}
                />
              </Box>
              {client?.id && (
                <Box>
                  <Field
                    name="updateToken"
                    checked={updateToken}
                    component={InputSwitch}
                    onChange={(value) => setUpdateToken(value)}
                    type="checkbox"
                    label="Update Token"
                    labelPlacement="start"
                    sx={{ ml: "0px" }}
                  />
                </Box>
              )}

              <Stack direction="row" spacing={2} mt={"30px"}>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    backgroundColor: "#03a9f4",
                    "&:hover": {
                      backgroundColor: "#0288d1",
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  onClick={() => navigate("/clients")}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
