import { createStore, applyMiddleware } from "redux";
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootWatcherSaga";
import { persistStore, persistReducer } from "redux-persist";
//import storage from "redux-persist/lib/storage";
import storageIndexDB from "redux-persist-indexeddb-storage";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage: storageIndexDB("discountMamaAdmin"),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store, null);

export default store;
