import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import {
  ColumnOption,
  ColumnTitle,
  DmBox,
  DmTable,
  StatusChip,
} from "../../components";
import { searchQuerySelector } from "../../store/selector";
import { UrlUtil } from "../../utils";
import { useNavigate } from "react-router-dom";
import {
  Action,
  CanPerformAction,
  DATE_TIME_FORMAT,
  Models,
} from "../../const";
import { ConfirmModal, DmModal } from "../../components/Modal";
import { useToggle } from "../../hooks";
import _ from "lodash";
import {
  deleteUserRequest,
  fetchUsersRequest,
  toggleUsersStatusRequest,
} from "../../store";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { Authenticate } from "../../hoc/Authenticate";
import DeleteIcon from "@mui/icons-material/Delete";

export const UserListPage = () => {
  const userState = useSelector((state) => state.user);
  const searchQuery = useSelector(searchQuerySelector);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRow, setActiveRow] = useState({});
  const [activeRowId, setActiveRowId] = useState(); // this is used only for hide and show ... options
  const [offset, setOffset] = useState({ limit: 10, skip: 0 });
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [openModal, setOpenModal] = useToggle();
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [isOptionClick, setIsOptionClick] = useState({
    // to check which option was clicked
    disable: false,
    enable: false,
    delete: false,
  });

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickMenuItem = (action, row) => {
    switch (action) {
      case Action.TOGGLE:
        const disable = row.isActive && true; // meaning user want to disable
        const enable = !disable;
        setIsOptionClick({ disable, enable, delete: false });
        setSelectedRows([row]);
        if (selectedRows.length === 1) setActiveRow(row);
        setOpenModal();
        break;
      case Action.DELETE:
        setIsOptionClick({ disable: false, enable: false, delete: true });
        setSelectedRows([row]);
        if (selectedRows.length === 1) setActiveRow(row);
        setOpenModal();
        break;
      case Action.EDIT:
        navigate(`/users/edit/${row?.email}`);
        break;
      case Action.VIEW:
        navigate(`/users/details/${row?.email}`);
        break;
      default:
        break;
    }
  };

  const onMouseEnterRowHandler = (row) => {
    if (selectedRows.length === 0) setActiveRow(row);
    setActiveRowId(row.id);
  };

  const onMouseLeaveRowHandler = () => {
    setAnchorEl(null);
    setActiveRowId(null);
  };

  useEffect(() => {
    dispatch(fetchUsersRequest(offset, searchQuery));
  }, [dispatch, offset, searchQuery]);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    const skip = getSkip(currentPage);
    setOffset({ limit: currentRowsPerPage, skip: skip });
  };

  const onChangePage = (page) => {
    const skip = getSkip(page);
    setOffset({ ...offset, skip });
  };

  const getConfirmModalTitle = () => {
    if (isOptionClick.delete) {
      return selectedRows.length > 1
        ? `Are you sure want to delete ${selectedRows.length} users ?`
        : `Are you want to delete ${activeRow.name} ?`;
    }
    return selectedRows.length > 1
      ? `Are you sure want to ${isOptionClick.disable ? "disable" : "enable"} ${
          selectedRows.length
        } users ?`
      : `Are you want to  ${isOptionClick.disable ? "disable" : "enable"} ${
          activeRow.name
        } ?`;
  };

  const onSuccess = () => {
    setOffset({ limit: offset.limit, skip: offset.skip });
  };

  const onDelete = () => {
    dispatch(
      deleteUserRequest({ userIds: _.map(selectedRows, "id") }, onSuccess)
    );
    reInitializeOptionClick(); // re-initialize
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const reInitializeOptionClick = () => {
    setIsOptionClick({ disable: false, enable: false, delete: false }); // re-initialize
  };

  const onToggleUsersStatus = () => {
    dispatch(
      toggleUsersStatusRequest(
        {
          userIds: _.map(selectedRows, "id"),
          isActive: isOptionClick.enable ?? isOptionClick.disable,
        },
        onSuccess
      )
    );
    reInitializeOptionClick(); // re-initialize
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const getSkip = (page) => {
    const skip = page - 1 > 0 ? page - 1 : 0;
    const calculateSkip = offset.limit * skip;
    return calculateSkip;
  };
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
    if (state.selectedRows.length === 1) setActiveRow(state.selectedRows[0]);
  }, []);

  const columns = [
    {
      name: <ColumnTitle title={"Id"} />,
      selector: (row) => row.id,
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Name"} />,
      selector: (row) => (
        <Button
          variant="link"
          onClick={() => navigate(`/users/details/${row.email}`)}
        >
          {row.name}
        </Button>
      ),
      minWidth: "150px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Slug"} />,
      selector: (row) => row?.slug,
    },
    {
      name: <ColumnTitle title={"Country"} />,
      selector: (row) => row.country.name,
      minWidth: "150px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Country Image"} />,
      maxWidth: "150px",
      selector: (row) => (
        <Box
          component="img"
          sx={{ width: "50px", height: "50px", objectFit: "contain" }}
          src={UrlUtil.getFullImagePath(row.country.image.path)}
        />
      ),
    },
    {
      name: <ColumnTitle title={"Email"} />,
      selector: (row) => row.email,
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Phone"} />,
      selector: (row) => row.mobileNumber,
      minWidth: "150px",
    },
    {
      name: <ColumnTitle title={"Deals"} />,
      selector: (row) => row.totalDeals,
    },
    {
      name: <ColumnTitle title={"Reputation"} />,
      selector: (row) => row.reputation,
    },
    {
      name: <ColumnTitle title={"Enabled"} />,
      selector: (row) => <StatusChip status={row.isActive} />,
    },
    {
      name: <ColumnTitle title={"Image"} />,
      maxWidth: "150px",
      selector: (row) => (
        <Box
          component="img"
          sx={{ width: "50px", height: "50px", objectFit: "contain" }}
          src={
            row.image?.path
              ? UrlUtil.getFullImagePath(row.image.path)
              : "/images/default-thumbnail-image.gif"
          }
        />
      ),
    },
    {
      name: <ColumnTitle title={"Last Logged In"} />,
      selector: (row) =>
        row?.lastLoggedIn && moment(row?.lastLoggedIn).format(DATE_TIME_FORMAT),
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Created"} />,
      selector: (row) => moment(row.createdAt).format(DATE_TIME_FORMAT),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Updated"} />,
      selector: (row) => moment(row.updatedAt).format(DATE_TIME_FORMAT),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={""} />,
      cell: (row) => (
        <>
          {activeRowId === row.id && (
            <ColumnOption
              row={row}
              open={open}
              anchorEl={anchorEl}
              handleClick={handleOptionClick}
              handleClose={handleOptionClose}
            >
              <Authenticate
                model={Models.USER}
                action={CanPerformAction.CAN_EDIT}
              >
                <MenuItem onClick={() => onClickMenuItem(Action.TOGGLE, row)}>
                  <ListItemIcon>
                    {row.isActive ? <ToggleOffIcon /> : <ToggleOnIcon />}
                  </ListItemIcon>
                  <ListItemText>
                    {row.isActive ? "Disable" : "Enable"}
                  </ListItemText>
                </MenuItem>
              </Authenticate>
              <Authenticate
                model={Models.USER}
                action={CanPerformAction.CAN_DELETE}
              >
                <MenuItem onClick={() => onClickMenuItem(Action.DELETE, row)}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </Authenticate>
              <MenuItem onClick={() => onClickMenuItem(Action.VIEW, row)}>
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText>View</ListItemText>
              </MenuItem>
            </ColumnOption>
          )}
        </>
      ),
    },
  ];

  return (
    <DmBox>
      <DmTable
        columns={columns}
        data={userState.users}
        title={"User List"}
        pending={userState.loading}
        totalRow={userState.totalUsers}
        onRowMouseEnter={onMouseEnterRowHandler}
        onRowMouseLeave={onMouseLeaveRowHandler}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        contextActions={
          <Stack direction="row" spacing={2}>
            <Authenticate model={Models.USER} action={CanPerformAction.EDIT}>
              <Button
                variant="contained"
                onClick={() => {
                  setIsOptionClick({
                    enable: true,
                    disable: false,
                    delete: false,
                  });
                  setOpenModal();
                }}
              >
                Enable
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsOptionClick({
                    disable: true,
                    enable: false,
                    delete: false,
                  });
                  setOpenModal();
                }}
              >
                Disable
              </Button>
            </Authenticate>
            <Authenticate
              model={Models.USER}
              action={CanPerformAction.CAN_DELETE}
            >
              <Button
                onClick={() => {
                  setIsOptionClick({
                    disable: false,
                    enable: false,
                    delete: true,
                  });
                  setOpenModal();
                }}
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "red",
                  },
                }}
              >
                Delete
              </Button>
            </Authenticate>
          </Stack>
        }
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
      <DmModal open={openModal} closeModal={setOpenModal}>
        <ConfirmModal
          title={getConfirmModalTitle()}
          subTitle="This action cannot be undone"
          btnConfirmText={
            isOptionClick.delete
              ? "Delete"
              : isOptionClick.disable
              ? "Disable"
              : "Enable"
          }
          btnConfirmStyles={
            isOptionClick.enable
              ? {
                  bgcolor: (theme) => theme.palette.status.activeBg,
                  color: (theme) => theme.palette.status.activeColor,
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.status.activeBg,
                  },
                }
              : undefined
          }
          btnCancelText="Cancel"
          onCancel={setOpenModal}
          onConfirm={() => {
            isOptionClick.delete ? onDelete() : onToggleUsersStatus();
          }}
        />
      </DmModal>
    </DmBox>
  );
};
