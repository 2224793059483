import {
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  TOGGLE_USERS_STATUS_FAILURE,
  TOGGLE_USERS_STATUS_REQUEST,
  TOGGLE_USERS_STATUS_SUCCESS,
} from "./userActionType";

export const fetchUsersRequest = (offset, searchQuery) => {
  return {
    type: FETCH_USERS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchUsersSuccess = (data) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: data,
  };
};

export const fetchUsersFailure = (error) => {
  return {
    type: FETCH_USERS_FAILURE,
    error,
  };
};

export const toggleUsersStatusRequest = (payload, onSuccess) => {
  return {
    type: TOGGLE_USERS_STATUS_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const toggleUsersStatusSuccess = () => {
  return {
    type: TOGGLE_USERS_STATUS_SUCCESS,
  };
};

export const toggleUsersStatusFailure = (error) => {
  return {
    type: TOGGLE_USERS_STATUS_FAILURE,
    error,
  };
};

export const deleteUserRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_USER_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteUserSuccess = () => {
  return {
    type: DELETE_USER_SUCCESS,
  };
};

export const deleteUserFailure = (error) => {
  return {
    type: DELETE_USER_FAILURE,
    error,
  };
};
