import {
  Stack,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, InputSwitch } from "../";
import { createRewardRuleRequest, updateRewardRuleRequest } from "../../store";
import { ValidationUtil } from "../../utils";

export const RewardRuleForm = ({ rewardRule }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState(
    rewardRule?.operator
  );
  const [selectedModel, setSelectedModel] = useState(rewardRule?.model);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const { title, model, operator, property, comparisonValue, isActive } =
      values;
    const payload = {
      title,
      model,
      operator,
      property,
      comparison_value: comparisonValue,
      is_active: isActive ?? false,
    };
    rewardRule?.id
      ? dispatch(updateRewardRuleRequest(rewardRule.slug, payload, onSuccess))
      : dispatch(createRewardRuleRequest(payload, onSuccess));
  };

  const onSuccess = () => {
    navigate("/reward-rules");
  };

  const initialValues = rewardRule
    ? {
        title: rewardRule.title,
        model: rewardRule.model,
        operator: rewardRule.operator,
        property: rewardRule.property,
        comparisonValue: rewardRule.comparisonValue,
        isActive: rewardRule.isActive,
      }
    : {};

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, errors }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            // if (Object.keys(errors).length === 0) form.reset();
          }}
          noValidate
          autoComplete="off"
        >
          <Stack alignItems="center">
            <Typography variant="h4">
              {rewardRule ? `Edit ${rewardRule.slug}` : "Add new reward rule"}
            </Typography>
            <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
              <Field
                name="title"
                component={Input}
                label="Title"
                validate={ValidationUtil.requiredValidator}
              />
              <Field name="model" validate={ValidationUtil.requiredValidator}>
                {({ input: { value, ...input }, meta }) => (
                  <>
                    <FormControl fullWidth>
                      <InputLabel>Model</InputLabel>
                      <Select
                        value={selectedModel}
                        label="Model"
                        {...input}
                        onChange={(e) => {
                          setSelectedModel(e.target.value);
                          input.onChange(e.target.value);
                        }}
                      >
                        <MenuItem value={"badge"}>Badge</MenuItem>
                        <MenuItem value={"deal"}>Deal</MenuItem>
                        <MenuItem value={"user"}>User</MenuItem>
                      </Select>
                    </FormControl>
                    {meta.error && meta.touched && (
                      <Typography
                        color="error"
                        sx={{
                          fontSize: "0.75rem",
                          pl: "8px",
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        {meta.error}
                      </Typography>
                    )}
                  </>
                )}
              </Field>
              <Field
                name="property"
                component={Input}
                label="Property"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="operator"
                validate={ValidationUtil.requiredValidator}
              >
                {({ input: { value, ...input }, meta }) => (
                  <>
                    <FormControl fullWidth>
                      <InputLabel>Operator</InputLabel>
                      <Select
                        value={selectedOperator}
                        label="Operator"
                        {...input}
                        onChange={(e) => {
                          setSelectedOperator(e.target.value);
                          input.onChange(e.target.value);
                        }}
                      >
                        <MenuItem value={"equal"}>Equal</MenuItem>
                        <MenuItem value={"less_than"}>Less Than</MenuItem>
                        <MenuItem value={"greater_than"}>Greater Than</MenuItem>
                        <MenuItem value={"less_than_equal"}>
                          Less Than Equal
                        </MenuItem>
                        <MenuItem value={"greater_than_equal"}>
                          Greater Than Equal
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {meta.error && meta.touched && (
                      <Typography
                        color="error"
                        sx={{
                          fontSize: "0.75rem",
                          pl: "8px",
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        {meta.error}
                      </Typography>
                    )}
                  </>
                )}
              </Field>
              <Field
                name="comparisonValue"
                component={Input}
                label="Comparison Value"
                validate={ValidationUtil.requiredValidator}
              />
              <Box>
                <Field
                  name="isActive"
                  checked={isActive}
                  component={InputSwitch}
                  handleOnChange={(value) => setIsActive(value)}
                  type="checkbox"
                  label="Is Active"
                  labelPlacement="start"
                  sx={{ ml: "0px" }}
                />
              </Box>
              <Stack direction="row" spacing={2} mt={"30px"}>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    backgroundColor: "#03a9f4",
                    "&:hover": {
                      backgroundColor: "#0288d1",
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  onClick={() => navigate("/reward-rules")}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
