export const EMPLOYEE_LOGIN_REQUEST = "EMPLOYEE_LOGIN_REQUEST";
export const EMPLOYEE_LOGIN_SUCCESS = "EMPLOYEE_LOGIN_SUCCESS";
export const EMPLOYEE_LOGIN_FAILURE = "EMPLOYEE_LOGIN_FAILURE";

export const FETCH_EMPLOYEES_REQUEST = "FETCH_EMPLOYEES_REQUEST";
export const FETCH_EMPLOYEES_SUCCESS = "FETCH_EMPLOYEES_SUCCESS";
export const FETCH_EMPLOYEES_FAILURE = "FETCH_EMPLOYEES_FAILURE";

export const FETCH_EMPLOYEE_REQUEST = "FETCH_EMPLOYEE_REQUEST";
export const FETCH_EMPLOYEE_SUCCESS = "FETCH_EMPLOYEE_SUCCESS";
export const FETCH_EMPLOYEE_FAILURE = "FETCH_EMPLOYEE_FAILURE";

export const FETCH_EMPLOYEE_OPTIONS_REQUEST = "FETCH_EMPLOYEE_OPTIONS_REQUEST";
export const FETCH_EMPLOYEE_OPTIONS_SUCCESS = "FETCH_EMPLOYEE_OPTIONS_SUCCESS";
export const FETCH_EMPLOYEE_OPTIONS_FAILURE = "FETCH_EMPLOYEE_OPTIONS_FAILURE";

export const CREATE_EMPLOYEE_REQUEST = "CREATE_EMPLOYEE_REQUEST";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_FAILURE = "CREATE_EMPLOYEE_FAILURE";

export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAILURE = "UPDATE_EMPLOYEE_FAILURE";

export const UPDATE_EMPLOYEE_PROFILE_REQUEST =
  "UPDATE_EMPLOYEE_PROFILE_REQUEST";
export const UPDATE_EMPLOYEE_PROFILE_SUCCESS =
  "UPDATE_EMPLOYEE_PROFILE_SUCCESS";
export const UPDATE_EMPLOYEE_PROFILE_FAILURE =
  "UPDATE_EMPLOYEE_PROFILE_FAILURE";

export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_FAILURE = "DELETE_EMPLOYEE_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const VERIFY_EMPLOYEE_TOKEN_REQUEST = "VERIFY_EMPLOYEE_TOKEN_REQUEST";
export const EMPLOYEE_LOGOUT_REQUEST = "EMPLOYEE_LOGOUT_REQUEST"; // none async request

export const UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_REQUEST =
  "UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_REQUEST";
export const UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_SUCCESS =
  "UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_SUCCESS";
export const UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_FAILURE =
  "UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_FAILURE";

export const FETCH_UPDATED_EMPLOYEE_REQUEST = "FETCH_UPDATED_EMPLOYEE_REQUEST";
export const FETCH_UPDATED_EMPLOYEE_SUCCESS = "FETCH_UPDATED_EMPLOYEE_SUCCESS";
export const FETCH_UPDATED_EMPLOYEE_FAILURE = "FETCH_UPDATED_EMPLOYEE_FAILURE";

export const UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_REQUEST =
  "UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_REQUEST";
export const UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_SUCCESS =
  "UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_SUCCESS";
export const UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_FAIlURE =
  "UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_FAIlURE";
