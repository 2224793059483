import {
  CREATE_ROLE_FAILURE,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  FETCH_ROLES_FAILURE,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLE_FAILURE,
  FETCH_ROLE_OPTIONS_FAILURE,
  FETCH_ROLE_OPTIONS_REQUEST,
  FETCH_ROLE_OPTIONS_SUCCESS,
  FETCH_ROLE_REQUEST,
  FETCH_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
} from "./roleActionType";

export const fetchRolesRequest = (offset, searchQuery) => {
  return {
    type: FETCH_ROLES_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchRolesSuccess = (data) => {
  return {
    type: FETCH_ROLES_SUCCESS,
    payload: data,
  };
};

export const fetchRolesFailure = (error) => {
  return {
    type: FETCH_ROLES_FAILURE,
    error,
  };
};

export const fetchRoleRequest = (slug) => {
  return {
    type: FETCH_ROLE_REQUEST,
    slug,
  };
};
export const fetchRoleSuccess = (data) => {
  return {
    type: FETCH_ROLE_SUCCESS,
    payload: data,
  };
};
export const fetchRoleFailure = (error) => {
  return {
    type: FETCH_ROLE_FAILURE,
    error,
  };
};

export const fetchRoleOptionsRequest = () => {
  return {
    type: FETCH_ROLE_OPTIONS_REQUEST,
  };
};

export const fetchRoleOptionsSuccess = (data) => {
  return {
    type: FETCH_ROLE_OPTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchRoleOptionsFailure = (error) => {
  return {
    type: FETCH_ROLE_OPTIONS_FAILURE,
    error,
  };
};

export const createRoleRequest = (payload, onSuccess) => {
  return {
    type: CREATE_ROLE_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const createRoleSuccess = (data) => {
  return {
    type: CREATE_ROLE_SUCCESS,
    payload: data,
  };
};

export const createRoleFailure = (error) => {
  return {
    type: CREATE_ROLE_FAILURE,
    error,
  };
};

export const updateRoleRequest = (slug, payload, onSuccess) => {
  return {
    type: UPDATE_ROLE_REQUEST,
    slug,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateRoleSuccess = () => {
  return {
    type: UPDATE_ROLE_SUCCESS,
  };
};

export const updateRoleFailure = (error) => {
  return {
    type: UPDATE_ROLE_FAILURE,
    error,
  };
};

export const deleteRoleRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_ROLE_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteRoleSuccess = () => {
  return {
    type: DELETE_ROLE_SUCCESS,
  };
};

export const deleteRoleFailure = (error) => {
  return {
    type: DELETE_ROLE_FAILURE,
    error,
  };
};
