import {
  CREATE_BADGE_FAILURE,
  CREATE_BADGE_REQUEST,
  DELETE_BADGE_FAILURE,
  DELETE_BADGE_REQUEST,
  FETCH_BADGES_FAILURE,
  FETCH_BADGES_REQUEST,
  FETCH_BADGES_SUCCESS,
  FETCH_BADGE_FAILURE,
  FETCH_BADGE_REQUEST,
  FETCH_BADGE_SUCCESS,
  UPDATE_BADGE_FAILURE,
  UPDATE_BADGE_REQUEST,
} from "./badgeActionType";

const initialState = {
  loading: false,
  badges: [],
  badge: {},
  error: "",
  totalBadges: 0,
};

export const BadgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BADGES_REQUEST:
    case FETCH_BADGE_REQUEST:
    case CREATE_BADGE_REQUEST:
    case UPDATE_BADGE_REQUEST:
    case DELETE_BADGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BADGES_SUCCESS:
      const { badges, totalBadges } = action.payload;
      return {
        ...state,
        loading: false,
        badges: badges,
        totalBadges: totalBadges,
      };
    case FETCH_BADGE_SUCCESS:
      return {
        ...state,
        loading: false,
        badge: action.payload,
      };

    case FETCH_BADGES_FAILURE:
    case FETCH_BADGE_FAILURE:
    case CREATE_BADGE_FAILURE:
    case UPDATE_BADGE_FAILURE:
    case DELETE_BADGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
