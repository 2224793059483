export const FETCH_REDEEM_REWARDS_REQUEST = "FETCH_REDEEM_REWARDS_REQUEST";
export const FETCH_REDEEM_REWARDS_SUCCESS = "FETCH_REDEEM_REWARDS_SUCCESS";
export const FETCH_REDEEM_REWARDS_FAILURE = "FETCH_REDEEM_REWARDS_FAILURE";

export const FETCH_REDEEM_REWARD_REQUEST = "FETCH_REDEEM_REWARD_REQUEST";
export const FETCH_REDEEM_REWARD_SUCCESS = "FETCH_REDEEM_REWARD_SUCCESS";
export const FETCH_REDEEM_REWARD_FAILURE = "FETCH_REDEEM_REWARD_FAILURE";

export const UPDATE_REDEEM_REWARD_REQUEST = "UPDATE_REDEEM_REWARD_REQUEST";
export const UPDATE_REDEEM_REWARD_SUCCESS = "UPDATE_REDEEM_REWARD_SUCCESS";
export const UPDATE_REDEEM_REWARD_FAILURE = "UPDATE_REDEEM_REWARD_FAILURE";

export const FETCH_REDEEM_REWARD_STATUS_REQUEST =
  "FETCH_REDEEM_REWARD_STATUS_REQUEST";
export const FETCH_REDEEM_REWARD_STATUS_SUCCESS =
  "FETCH_REDEEM_REWARD_STATUS_SUCCESS";
export const FETCH_REDEEM_REWARD_STATUS_FAILURE =
  "FETCH_REDEEM_REWARD_STATUS_FAILURE";
