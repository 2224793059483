import React, { useEffect, useState, useCallback } from "react";
import { Button, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ColumnOption, DmTable, ColumnTitle, DmBox } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRewardValidationLogRequest,
  fetchRewardValidationLogsRequest,
} from "../../store";
import moment from "moment";
import {
  Action,
  CanPerformAction,
  DATE_TIME_FORMAT,
  Models,
} from "../../const";
import { useNavigate } from "react-router-dom";
import { searchQuerySelector } from "../../store/selector";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { StringUtil } from "../../utils";
import { useToggle } from "../../hooks";
import _ from "lodash";
import { Authenticate } from "../../hoc/Authenticate";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmModal, DmModal } from "../../components/Modal";

export const RewardValidationLogListPage = () => {
  const dispatch = useDispatch();
  const rewardValidationLogState = useSelector(
    (state) => state.rewardValidationLog
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRowId, setActiveRowId] = useState(); // this is used only for hide and show ... options
  const [offset, setOffset] = useState({ limit: 10, skip: 0 });
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useToggle();
  const searchQuery = useSelector(searchQuerySelector);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseEnterRowHandler = (row) => {
    setActiveRowId(row.id);
  };
  const onMouseLeaveRowHandler = () => {
    setAnchorEl(null);
    setActiveRowId(null);
  };

  const onSuccessfullyDelete = () => {
    setOffset({ limit: offset.limit, skip: offset.skip });
  };

  const onDelete = () => {
    dispatch(
      deleteRewardValidationLogRequest(
        { rewardValidationLogIds: _.map(selectedRows, "id") },
        onSuccessfullyDelete
      )
    );
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const onClickMenuItem = (action, row) => {
    switch (action) {
      case Action.DELETE:
        setSelectedRows([row]);
        setOpenModal();
        break;
      case Action.VIEW:
        navigate(`/reward-validation-logs/details/${row.id}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchRewardValidationLogsRequest(offset, searchQuery));
  }, [dispatch, offset, searchQuery]);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    const skip = getSkip(currentPage);
    setOffset({ limit: currentRowsPerPage, skip: skip });
  };

  const onChangePage = (page) => {
    const skip = getSkip(page);
    setOffset({ ...offset, skip });
  };

  const getSkip = (page) => {
    const skip = page - 1 > 0 ? page - 1 : 0;
    const calculateSkip = offset.limit * skip;
    return calculateSkip;
  };

  const columns = [
    {
      name: <ColumnTitle title={"Id"} />,
      selector: (row) => (
        <Button
          variant="link"
          onClick={() => navigate(`/reward-validation-logs/details/${row.id}`)}
        >
          {row.id}
        </Button>
      ),
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Model"} />,
      selector: (row) => row.model,
    },
    {
      name: <ColumnTitle title={"Property"} />,
      selector: (row) => row.property,
    },
    {
      name: <ColumnTitle title={"User"} />,
      selector: (row) => row.user.name,
    },
    {
      name: <ColumnTitle title={"User Email"} />,
      selector: (row) => row.user.email,
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Reward Title"} />,
      selector: (row) => row.reward.title,
      minWidth: "310px",
    },
    {
      name: <ColumnTitle title={"Avail Limit"} />,
      selector: (row) => row.reward.availLimit,
    },
    {
      name: <ColumnTitle title={"Is Success"} />,
      selector: (row) => StringUtil.getYesOrNo(row.isSuccess),
    },
    {
      name: <ColumnTitle title={"Created"} />,
      selector: (row) => moment(row.createdAt).format(DATE_TIME_FORMAT),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Updated"} />,
      selector: (row) => moment(row.updatedAt).format(DATE_TIME_FORMAT),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={""} />,
      cell: (row) => (
        <>
          {activeRowId === row.id && (
            <ColumnOption
              row={row}
              open={open}
              anchorEl={anchorEl}
              handleClick={handleOptionClick}
              handleClose={handleOptionClose}
              onClickMenuItem={onClickMenuItem}
            >
              <Authenticate
                model={Models.REWARD_VALIDATION_LOG}
                action={CanPerformAction.CAN_DELETE}
              >
                <MenuItem onClick={() => onClickMenuItem(Action.DELETE, row)}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </Authenticate>
              <MenuItem onClick={() => onClickMenuItem(Action.VIEW, row)}>
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText>View</ListItemText>
              </MenuItem>
            </ColumnOption>
          )}
        </>
      ),
    },
  ];

  return (
    <DmBox>
      <DmTable
        columns={columns}
        data={rewardValidationLogState.rewardValidationLogs}
        title={"Reward Validation Log List"}
        pending={rewardValidationLogState.loading}
        totalRow={rewardValidationLogState.totalRewardValidationLogs}
        onRowMouseEnter={onMouseEnterRowHandler}
        onRowMouseLeave={onMouseLeaveRowHandler}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        contextActions={
          <Authenticate
            model={Models.REWARD_VALIDATION_LOG}
            action={CanPerformAction.CAN_DELETE}
          >
            <Button
              onClick={setOpenModal}
              sx={{
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
            >
              Delete
            </Button>
          </Authenticate>
        }
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
      <DmModal open={openModal} closeModal={setOpenModal}>
        <ConfirmModal
          title={
            selectedRows.length > 1
              ? `Are you sure want to delete ${selectedRows.length} reward validation logs ?`
              : `Are you sure you want to delete this reward validation log ?`
          }
          subTitle="This action cannot be undone"
          btnConfirmText={"Delete"}
          btnCancelText="Cancel"
          onCancel={setOpenModal}
          onConfirm={onDelete}
        />
      </DmModal>
    </DmBox>
  );
};
