export const FETCH_STORE_REVIEWS_REQUEST = "FETCH_STORE_REVIEWS_REQUEST";
export const FETCH_STORE_REVIEWS_SUCCESS = "FETCH_STORE_REVIEWS_SUCCESS";
export const FETCH_STORE_REVIEWS_FAILURE = "FETCH_STORE_REVIEWS_FAILURE";

export const DELETE_STORE_REVIEW_REQUEST = "DELETE_STORE_REVIEW_REQUEST";
export const DELETE_STORE_REVIEW_SUCCESS = "DELETE_STORE_REVIEW_SUCCESS";
export const DELETE_STORE_REVIEW_FAILURE = "DELETE_STORE_REVIEW_FAILURE";

export const TOGGLE_STORE_REVIEWS_STATUS_REQUEST =
  "TOGGLE_STORE_REVIEWS_STATUS_REQUEST";
export const TOGGLE_STORE_REVIEWS_STATUS_SUCCESS =
  "TOGGLE_STORE_REVIEWS_STATUS_SUCCESS";
export const TOGGLE_STORE_REVIEWS_STATUS_FAILURE =
  "TOGGLE_STORE_REVIEWS_STATUS_FAILURE";
