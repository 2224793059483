import { call, put, takeEvery } from "redux-saga/effects";
import { CategoryService } from "../../services";
import { toast } from "react-toastify";
import { NotifyUtil } from "../../utils";
import {
  createCategoryFailure,
  createCategorySuccess,
  deleteCategoryFailure,
  deleteCategorySuccess,
  fetchCategoriesFailure,
  fetchCategoriesSuccess,
  fetchCategoryFailure,
  fetchCategoryOptionsFailure,
  fetchCategoryOptionsSuccess,
  fetchCategorySuccess,
  updateCategoryFailure,
  updateCategorySuccess,
} from "./categoryAction";
import {
  CREATE_CATEGORY_REQUEST,
  DELETE_CATEGORY_REQUEST,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORY_OPTIONS_REQUEST,
  FETCH_CATEGORY_REQUEST,
  UPDATE_CATEGORY_REQUEST,
} from "./categoryActionType";

function* fetchCategoriesSaga(action) {
  try {
    const { limit, page } = action.offset;
    const { data } = yield call(
      CategoryService.getCategories,
      page,
      limit,
      action.searchQuery,
      action.featuredFilter
    );
    yield put(fetchCategoriesSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchCategoriesFailure(error));
  }
}

function* fetchCategorySaga(action) {
  try {
    const { data } = yield call(CategoryService.getCategory, action.slug);
    yield put(fetchCategorySuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchCategoryFailure(error));
  }
}

function* fetchCategoryOptionsSaga() {
  try {
    const { data } = yield call(CategoryService.getCategoryOptions);
    yield put(fetchCategoryOptionsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchCategoryOptionsFailure(error));
  }
}

function* createCategorySaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    const { data } = yield call(CategoryService.createCategory, payload);
    yield put(createCategorySuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createCategoryFailure(error));
  }
}
function* updateCategorySaga(action) {
  try {
    const {
      slug,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(CategoryService.updateCategory, slug, payload);
    yield put(updateCategorySuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateCategoryFailure(error));
  }
}

function* deleteCategorySaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(CategoryService.deleteCategory, payload);
    yield put(deleteCategorySuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteCategoryFailure(error));
  }
}

export function* categoryWatcherSaga() {
  yield takeEvery(FETCH_CATEGORIES_REQUEST, fetchCategoriesSaga);
  yield takeEvery(FETCH_CATEGORY_REQUEST, fetchCategorySaga);
  yield takeEvery(FETCH_CATEGORY_OPTIONS_REQUEST, fetchCategoryOptionsSaga);
  yield takeEvery(CREATE_CATEGORY_REQUEST, createCategorySaga);
  yield takeEvery(UPDATE_CATEGORY_REQUEST, updateCategorySaga);
  yield takeEvery(DELETE_CATEGORY_REQUEST, deleteCategorySaga);
}
