import {
  CREATE_BANNER_FAILURE,
  CREATE_BANNER_REQUEST,
  CREATE_BANNER_SUCCESS,
  DELETE_BANNER_FAILURE,
  DELETE_BANNER_REQUEST,
  DELETE_BANNER_SUCCESS,
  FETCH_BANNERS_FAILURE,
  FETCH_BANNERS_REQUEST,
  FETCH_BANNERS_SUCCESS,
  FETCH_BANNER_FAILURE,
  FETCH_BANNER_REQUEST,
  FETCH_BANNER_SUCCESS,
  UPDATE_BANNER_FAILURE,
  UPDATE_BANNER_REQUEST,
  UPDATE_BANNER_SUCCESS,
} from "./bannerActionType";

export const fetchBannersRequest = (offset, searchQuery) => {
  return {
    type: FETCH_BANNERS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchBannersSuccess = (data) => {
  return {
    type: FETCH_BANNERS_SUCCESS,
    payload: data,
  };
};

export const fetchBannersFailure = (error) => {
  return {
    type: FETCH_BANNERS_FAILURE,
    error,
  };
};

export const fetchBannerRequest = (slug) => {
  return {
    type: FETCH_BANNER_REQUEST,
    slug,
  };
};

export const fetchBannerSuccess = (data) => {
  return {
    type: FETCH_BANNER_SUCCESS,
    payload: data,
  };
};

export const fetchBannerFailure = (error) => {
  return {
    type: FETCH_BANNER_FAILURE,
    error,
  };
};

export const createBannerRequest = (payload, onSuccess) => {
  return {
    type: CREATE_BANNER_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};
export const createBannerSuccess = (data) => {
  return {
    type: CREATE_BANNER_SUCCESS,
    payload: data,
  };
};
export const createBannerFailure = (error) => {
  return {
    type: CREATE_BANNER_FAILURE,
    error,
  };
};

export const updateBannerRequest = (slug, payload, onSuccess) => {
  return {
    type: UPDATE_BANNER_REQUEST,
    slug,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateBannerSuccess = () => {
  return {
    type: UPDATE_BANNER_SUCCESS,
  };
};

export const updateBannerFailure = (error) => {
  return {
    type: UPDATE_BANNER_FAILURE,
    error,
  };
};

export const deleteBannerRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_BANNER_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteBannerSuccess = () => {
  return {
    type: DELETE_BANNER_SUCCESS,
  };
};

export const deleteBannerFailure = (error) => {
  return {
    type: DELETE_BANNER_FAILURE,
    error,
  };
};
