export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const TOGGLE_USERS_STATUS_REQUEST = "TOGGLE_USERS_STATUS_REQUEST";
export const TOGGLE_USERS_STATUS_SUCCESS = "TOGGLE_USERS_STATUS_SUCCESS";
export const TOGGLE_USERS_STATUS_FAILURE = "TOGGLE_USERS_STATUS_FAILURE";
