import {
  Box,
  ListItem,
  ListItemText,
  List,
  Typography,
  Divider,
  Link,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { DATE_TIME_FORMAT } from "../../const";
import { StringUtil, UrlUtil } from "../../utils";

export const DealStoreView = ({ dealStore, title = "Details" }) => {
  return (
    <>
      <Typography sx={{ fontWeight: 600, pl: "1.3rem", mb: "1rem" }}>
        {title}
      </Typography>
      <Divider />
      <Box pl="1.3rem">
        <Box
          component="img"
          src={
            dealStore?.image?.path
              ? UrlUtil.getFullImagePath(dealStore.image.path)
              : "/images/default-thumbnail-image.gif"
          }
          sx={{
            width: "250px",
            height: "250px",
            objectFit: "contain",
            border: "1px solid",
            borderColor: "divider",
            marginTop: "1.3rem",
            padding: ".5rem",
          }}
        />
      </Box>
      <List sx={{ pt: "0px" }}>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Id
            </Typography>
            <Box>
              <Typography variant="value">{dealStore.id}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Name
            </Typography>
            <Box>
              <Typography variant="value">{dealStore.name}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Display Name
            </Typography>
            <Box>
              <Typography variant="value">{dealStore.displayName}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Slug
            </Typography>
            <Box>
              <Typography variant="value">{dealStore.slug}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Website Link
            </Typography>
            <Box>
              <Link href={dealStore.websiteLink} target="_blank" rel="noopener">
                {dealStore.websiteLink}
              </Link>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Address
            </Typography>
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>SL</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Longitude</TableCell>
                      <TableCell>Latitude</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dealStore.addresses.map((address, index) => (
                      <>
                        {address && (
                          <TableRow
                            key={address}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{address}</TableCell>
                            <TableCell>
                              {dealStore.location.coordinates[index * 2]}
                            </TableCell>
                            <TableCell>
                              {dealStore.location.coordinates[index * 2 + 1]}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Description
            </Typography>
            <Box>
              <Typography variant="value">{dealStore.description}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Enabled
            </Typography>
            <Box>
              <Typography variant="value">
                {StringUtil.getYesOrNo(dealStore.isActive)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>

        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Created
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(dealStore.createdAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Updated
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(dealStore.updatedAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};
