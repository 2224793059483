import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import {
  ColumnOption,
  ColumnTitle,
  DmBox,
  DmTable,
  StatusChip,
} from "../../components";
import {
  loggedEmployeeSelector,
  searchQuerySelector,
} from "../../store/selector";
import { StringUtil, UrlUtil } from "../../utils";
import { useNavigate } from "react-router-dom";
import { Action, CanPerformAction, DATE_FORMAT, Models } from "../../const";
import {
  ConfirmModal,
  DmModal,
  ResetPasswordModal,
} from "../../components/Modal";
import { useToggle } from "../../hooks";
import _ from "lodash";
import { deleteEmployeeRequest, fetchEmployeesRequest } from "../../store";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { Authenticate } from "../../hoc/Authenticate";
import KeyIcon from "@mui/icons-material/Key";

export const EmployeeListPage = () => {
  const employeeState = useSelector((state) => state.employee);
  const loggedEmployee = useSelector(loggedEmployeeSelector);
  const searchQuery = useSelector(searchQuerySelector);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRow, setActiveRow] = useState({});
  const [activeRowId, setActiveRowId] = useState(); // this is used only for hide and show ... options
  const [offset, setOffset] = useState({ limit: 10, skip: 0 });
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModal, setOpenModal] = useToggle();
  const [resetPasswordModal, setResetPasswordModal] = useToggle();
  const [toggleCleared, setToggleCleared] = useState(false);

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickMenuItem = (action, row) => {
    switch (action) {
      case Action.DELETE:
        setSelectedRows([row]);
        if (selectedRows.length === 1) setActiveRow(row);
        setOpenModal();
        break;
      case Action.RESET_PASSWORD:
        setSelectedRows([row]);
        setResetPasswordModal();
        break;
      case Action.EDIT:
        navigate(`/employees/edit/${row?.email}`);
        break;
      case Action.VIEW:
        navigate(`/employees/details/${row?.email}`);
        break;
      default:
        break;
    }
  };

  const onMouseEnterRowHandler = (row) => {
    if (selectedRows.length === 0) setActiveRow(row);
    setActiveRowId(row.id);
  };

  const onMouseLeaveRowHandler = () => {
    setAnchorEl(null);
    setActiveRowId(null);
  };

  useEffect(() => {
    dispatch(fetchEmployeesRequest(offset, searchQuery));
  }, [dispatch, offset, searchQuery]);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    const skip = getSkip(currentPage);
    setOffset({ limit: currentRowsPerPage, skip: skip });
  };

  const onChangePage = (page) => {
    const skip = getSkip(page);
    setOffset({ ...offset, skip });
  };

  const onDelete = () => {
    dispatch(
      deleteEmployeeRequest(
        { employeeIds: _.map(selectedRows, "id") },
        onSuccessfullyDelete
      )
    );
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const onSuccessfullyResetPassword = () => {
    setResetPasswordModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const onSuccessfullyDelete = () => {
    setOffset({ limit: offset.limit, skip: offset.skip });
  };

  const getSkip = (page) => {
    const skip = page - 1 > 0 ? page - 1 : 0;
    const calculateSkip = offset.limit * skip;
    return calculateSkip;
  };
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
    if (state.selectedRows.length === 1) setActiveRow(state.selectedRows[0]);
  }, []);

  const columns = [
    {
      name: <ColumnTitle title={"Id"} />,
      selector: (row) => row.id,
      minWidth: "210px",
    },

    {
      name: <ColumnTitle title={"First Name"} />,
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Last Name"} />,
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Email"} />,
      selector: (row) => row.email,
    },
    {
      name: <ColumnTitle title={"Role"} />,
      selector: (row) => (
        <Stack direction="row" spacing={2}>
          {row.roles.map((eachRole) => (
            <Chip
              key={StringUtil.getUniqueId()}
              variant="outlined"
              label={eachRole.name}
            />
          ))}
        </Stack>
      ),
    },
    {
      name: <ColumnTitle title={"Enabled"} />,
      selector: (row) => <StatusChip status={row.isActive} />,
    },
    {
      name: <ColumnTitle title={"Image"} />,
      maxWidth: "150px",
      selector: (row) => (
        <Box
          component="img"
          sx={{ width: "50px", height: "50px", objectFit: "contain" }}
          src={
            row.image?.path
              ? UrlUtil.getFullImagePath(row.image.path)
              : "/images/default-thumbnail-image.gif"
          }
        />
      ),
    },
    {
      name: <ColumnTitle title={"Created"} />,
      selector: (row) => moment(row.createdAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Updated"} />,
      selector: (row) => moment(row.updatedAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={""} />,
      cell: (row) => (
        <>
          {activeRowId === row.id &&
            (!StringUtil.isEmployeeSuperAdmin(row.roles) ||
              StringUtil.isEmployeeSuperAdmin(loggedEmployee.roles)) && (
              <ColumnOption
                row={row}
                open={open}
                anchorEl={anchorEl}
                handleClick={handleOptionClick}
                handleClose={handleOptionClose}
                onClickMenuItem={onClickMenuItem}
              >
                <Authenticate
                  model={Models.EMPLOYEE}
                  action={CanPerformAction.CAN_EDIT}
                >
                  <MenuItem onClick={() => onClickMenuItem(Action.EDIT, row)}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>
                </Authenticate>
                {row.id !== loggedEmployee.id && (
                  // if it's the logged user then he can't delete his own account
                  <Authenticate
                    key={Action.DELETE}
                    model={Models.EMPLOYEE}
                    action={CanPerformAction.CAN_DELETE}
                  >
                    <MenuItem
                      onClick={() => onClickMenuItem(Action.DELETE, row)}
                    >
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  </Authenticate>
                )}
                {row.id !== loggedEmployee.id && ( // logged employee can't reset password from here
                  <Authenticate
                    key={Action.RESET_PASSWORD}
                    model={Models.EMPLOYEE}
                    action={CanPerformAction.CAN_EDIT}
                  >
                    <MenuItem
                      onClick={() =>
                        onClickMenuItem(Action.RESET_PASSWORD, row)
                      }
                    >
                      <ListItemIcon>
                        <KeyIcon />
                      </ListItemIcon>
                      <ListItemText>Reset Password</ListItemText>
                    </MenuItem>
                  </Authenticate>
                )}
                <MenuItem onClick={() => onClickMenuItem(Action.VIEW, row)}>
                  <ListItemIcon>
                    <VisibilityIcon />
                  </ListItemIcon>
                  <ListItemText>View</ListItemText>
                </MenuItem>
              </ColumnOption>
            )}
        </>
      ),
    },
  ];

  return (
    <DmBox>
      <DmTable
        columns={columns}
        data={employeeState.employees}
        title={"Employee List"}
        actions={
          <Authenticate
            model={Models.EMPLOYEE}
            action={CanPerformAction.CAN_ADD}
          >
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/employees/add")}
            >
              Add
            </Button>
          </Authenticate>
        }
        pending={employeeState.loading}
        totalRow={employeeState.totalEmployees}
        onRowMouseEnter={onMouseEnterRowHandler}
        onRowMouseLeave={onMouseLeaveRowHandler}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        selectableRowDisabled={(row) =>
          row.id === loggedEmployee.id ||
          StringUtil.isEmployeeSuperAdmin(row.roles)
        }
        contextActions={
          <Authenticate
            model={Models.EMPLOYEE}
            action={CanPerformAction.CAN_DELETE}
          >
            <Button
              onClick={setOpenModal}
              sx={{
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
            >
              Delete
            </Button>
          </Authenticate>
        }
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
      <DmModal open={openModal} closeModal={setOpenModal}>
        <ConfirmModal
          title={
            selectedRows.length > 1
              ? `Are you sure want to delete ${selectedRows.length} employees ?`
              : `Are you sure you want to delete ${activeRow.firstName} ?`
          }
          subTitle="This action cannot be undone"
          btnConfirmText={"Delete"}
          btnCancelText="Cancel"
          onCancel={setOpenModal}
          onConfirm={onDelete}
        />
      </DmModal>
      <DmModal open={resetPasswordModal} closeModal={setResetPasswordModal}>
        <ResetPasswordModal
          employee={selectedRows[0]}
          onSuccess={onSuccessfullyResetPassword}
        />
      </DmModal>
    </DmBox>
  );
};
