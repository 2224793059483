import {
  FETCH_REDEEM_REWARDS_FAILURE,
  FETCH_REDEEM_REWARDS_REQUEST,
  FETCH_REDEEM_REWARDS_SUCCESS,
  FETCH_REDEEM_REWARD_FAILURE,
  FETCH_REDEEM_REWARD_REQUEST,
  FETCH_REDEEM_REWARD_STATUS_FAILURE,
  FETCH_REDEEM_REWARD_STATUS_REQUEST,
  FETCH_REDEEM_REWARD_STATUS_SUCCESS,
  FETCH_REDEEM_REWARD_SUCCESS,
  UPDATE_REDEEM_REWARD_FAILURE,
  UPDATE_REDEEM_REWARD_REQUEST,
} from "./redeemRewardActionType";

const initialState = {
  loading: false,
  redeemRewards: [],
  redeemReward: {},
  redeemRewardStatus: [],
  error: "",
  totalRedeemRewards: 0,
};

export const RedeemRewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REDEEM_REWARDS_REQUEST:
    case FETCH_REDEEM_REWARD_REQUEST:
    case FETCH_REDEEM_REWARD_STATUS_REQUEST:
    case UPDATE_REDEEM_REWARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REDEEM_REWARDS_SUCCESS:
      const { redeemRewards, totalRedeemRewards } = action.payload;
      return {
        ...state,
        loading: false,
        redeemRewards,
        totalRedeemRewards,
      };

    case FETCH_REDEEM_REWARD_SUCCESS:
      return {
        ...state,
        loading: false,
        redeemReward: action.payload,
      };

    case FETCH_REDEEM_REWARD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        redeemRewardStatus: action.payload,
      };

    case FETCH_REDEEM_REWARDS_FAILURE:
    case FETCH_REDEEM_REWARD_FAILURE:
    case UPDATE_REDEEM_REWARD_FAILURE:
    case FETCH_REDEEM_REWARD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
