import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DmBox } from "../../components";
import { CategoryForm } from "../../components/forms";
import { fetchCategoryRequest } from "../../store";

export const AddCategoryPage = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.category);

  useEffect(() => {
    if (slug) dispatch(fetchCategoryRequest(slug));
  }, [dispatch, slug]);

  return (
    <DmBox>
      {categoryState.loading ? (
        <CircularProgress />
      ) : (
        <CategoryForm category={slug && categoryState.category} />
      )}
    </DmBox>
  );
};
