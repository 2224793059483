import {
  CREATE_REWARD_RULE_FAILURE,
  CREATE_REWARD_RULE_REQUEST,
  DELETE_REWARD_RULE_FAILURE,
  DELETE_REWARD_RULE_REQUEST,
  FETCH_ALL_REWARD_RULES_FAILURE,
  FETCH_ALL_REWARD_RULES_REQUEST,
  FETCH_ALL_REWARD_RULES_SUCCESS,
  FETCH_REWARD_RULES_FAILURE,
  FETCH_REWARD_RULES_REQUEST,
  FETCH_REWARD_RULES_SUCCESS,
  FETCH_REWARD_RULE_FAILURE,
  FETCH_REWARD_RULE_REQUEST,
  FETCH_REWARD_RULE_SUCCESS,
  UPDATE_REWARD_RULE_FAILURE,
  UPDATE_REWARD_RULE_REQUEST,
} from "./rewardRuleActionType";

const initialState = {
  loading: false,
  rewardRules: [],
  rewardRule: {},
  error: "",
  totalRewardRules: 0,
};

export const RewardRuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REWARD_RULES_REQUEST:
    case FETCH_REWARD_RULE_REQUEST:
    case FETCH_ALL_REWARD_RULES_REQUEST:
    case CREATE_REWARD_RULE_REQUEST:
    case UPDATE_REWARD_RULE_REQUEST:
    case DELETE_REWARD_RULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REWARD_RULES_SUCCESS:
      const { rewardRules, totalRewardRules } = action.payload;
      return {
        ...state,
        loading: false,
        rewardRules,
        totalRewardRules,
      };

    case FETCH_ALL_REWARD_RULES_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardRules: action.payload.rewardRules,
      };

    case FETCH_REWARD_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardRule: action.payload,
      };

    case FETCH_REWARD_RULES_FAILURE:
    case FETCH_REWARD_RULE_FAILURE:
    case FETCH_ALL_REWARD_RULES_FAILURE:
    case CREATE_REWARD_RULE_FAILURE:
    case UPDATE_REWARD_RULE_FAILURE:
    case DELETE_REWARD_RULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
