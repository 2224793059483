import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { NotifyUtil } from "../../utils";
import {
  deleteCommentFailure,
  deleteCommentSuccess,
  fetchCommentsFailure,
  fetchCommentsSuccess,
  toggleCommentsStatusFailure,
  toggleCommentsStatusSuccess,
} from "./commentAction";
import {
  DELETE_COMMENT_REQUEST,
  FETCH_COMMENTS_REQUEST,
  TOGGLE_COMMENTS_STATUS_REQUEST,
} from "./commentActionType";
import { CommentService } from "../../services";

function* fetchCommentsSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      CommentService.getComments,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchCommentsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchCommentsFailure(error));
  }
}

function* toggleCommentsStatusSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(CommentService.toggleCommentsStatus, payload);
    yield put(toggleCommentsStatusSuccess());
    toast.success("Successfully status updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(toggleCommentsStatusFailure(error));
  }
}

function* deleteCommentSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(CommentService.deleteComment, payload);
    yield put(deleteCommentSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteCommentFailure(error));
  }
}

export function* commentWatcherSaga() {
  yield takeEvery(FETCH_COMMENTS_REQUEST, fetchCommentsSaga);
  yield takeEvery(TOGGLE_COMMENTS_STATUS_REQUEST, toggleCommentsStatusSaga);
  yield takeEvery(DELETE_COMMENT_REQUEST, deleteCommentSaga);
}
