import {
  Box,
  Button,
  ListItem,
  ListItemText,
  List,
  Typography,
  Divider,
  Stack,
  Link,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import { Action, DATE_TIME_FORMAT, Models } from "../../const";
import { StringUtil, UrlUtil } from "../../utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { Authenticate } from "../../hoc/Authenticate";

export const ViewFacebookPage = () => {
  const { slug } = useParams();
  const facebookPages = useSelector(
    (state) => state.facebookPage.facebookPages
  );
  const facebookPage = facebookPages.find(
    (eachFacebookPage) => eachFacebookPage.slug === slug
  );
  const navigate = useNavigate();

  return (
    <DmBox>
      <Stack direction="row" spacing={5} mb="1rem">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate("/facebook-pages")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Authenticate model={Models.FACEBOOK_PAGE} action={Action.EDIT}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              navigate(`/facebook-pages/edit/${facebookPage.slug}`)
            }
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Authenticate>
      </Stack>
      <Typography sx={{ fontWeight: 600, pl: "1.3rem", mb: "1rem" }}>
        Details
      </Typography>
      <Divider />
      <Box pl="1.3rem">
        <Box
          component="img"
          src={
            facebookPage?.image?.path
              ? UrlUtil.getFullImagePath(facebookPage.image.path)
              : "/images/default-thumbnail-image.gif"
          }
          sx={{
            width: "250px",
            height: "250px",
            objectFit: "contain",
            border: "1px solid",
            borderColor: "divider",
            marginTop: "1.3rem",
            padding: ".5rem",
          }}
        />
      </Box>
      <List sx={{ pt: "0px" }}>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Id
            </Typography>
            <Box>
              <Typography variant="value">{facebookPage.id}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Name
            </Typography>
            <Box>
              <Typography variant="value">{facebookPage.name}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Slug
            </Typography>
            <Box>
              <Typography variant="value">{facebookPage.slug}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Url
            </Typography>
            <Box>
              <Typography variant="value">
                <Link href={facebookPage.url} target="_blank" rel="noopener">
                  {facebookPage.url}
                </Link>
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Description
            </Typography>
            <Box>
              <Typography variant="value">
                {facebookPage.description}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Enabled
            </Typography>
            <Box>
              <Typography variant="value">
                {StringUtil.getYesOrNo(facebookPage.isActive)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Created
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(facebookPage.createdAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Updated
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(facebookPage.updatedAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </DmBox>
  );
};
