import React, { useEffect, useState, useCallback } from "react";
import { Box, Button, Stack } from "@mui/material";
import {
  ColumnOption,
  DmTable,
  ColumnTitle,
  DmBox,
  StatusChip,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFacebookPageRequest,
  exportFacebookPageRequest,
  fetchFacebookPagesRequest,
} from "../../store";
import moment from "moment";
import { Action, CanPerformAction, DATE_FORMAT, Models } from "../../const";
import { UrlUtil } from "../../utils";
import { ConfirmModal, DmModal } from "../../components/Modal";
import { useToggle } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { searchQuerySelector } from "../../store/selector";
import { Authenticate } from "../../hoc/Authenticate";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import _ from "lodash";
import fileDownload from "js-file-download";

export const FacebookListPage = () => {
  const dispatch = useDispatch();
  const facebookPageState = useSelector((state) => state.facebookPage);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRow, setActiveRow] = useState({});
  const [activeRowId, setActiveRowId] = useState(); // this is used only for hide and show ... options
  const [offset, setOffset] = useState({ limit: 10, skip: 0 });
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useToggle();
  const searchQuery = useSelector(searchQuerySelector);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseEnterRowHandler = (row) => {
    setActiveRow(row);
    setActiveRowId(row.id);
  };
  const onMouseLeaveRowHandler = () => {
    setAnchorEl(null);
    setActiveRowId(null);
  };

  const onSuccessfullyDelete = () => {
    setOffset({ limit: offset.limit, skip: offset.skip });
  };

  const onDelete = () => {
    dispatch(
      deleteFacebookPageRequest(
        { facebookPageIds: _.map(selectedRows, "id") },
        onSuccessfullyDelete
      )
    );
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const onClickMenuItem = (action, row) => {
    switch (action) {
      case Action.DELETE:
        setSelectedRows([row]);
        setOpenModal();
        break;
      case Action.EDIT:
        navigate(`/facebook-pages/edit/${row.slug}`);
        break;
      case Action.VIEW:
        navigate(`/facebook-pages/details/${row.slug}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchFacebookPagesRequest(offset, searchQuery));
  }, [dispatch, offset, searchQuery]);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    const skip = getSkip(currentPage);
    setOffset({ limit: currentRowsPerPage, skip: skip });
  };

  const onChangePage = (page) => {
    const skip = getSkip(page);
    setOffset({ ...offset, skip });
  };

  const getSkip = (page) => {
    const skip = page - 1 > 0 ? page - 1 : 0;
    const calculateSkip = offset.limit * skip;
    return calculateSkip;
  };

  const columns = [
    {
      name: <ColumnTitle title={"Id"} />,
      selector: (row) => row.id,
      minWidth: "210px",
    },

    {
      name: <ColumnTitle title={"Name"} />,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"slug"} />,
      selector: (row) => row.slug,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Enabled"} />,
      selector: (row) => <StatusChip status={row.isActive} />,
    },
    {
      name: <ColumnTitle title={"Image"} />,
      maxWidth: "150px",
      selector: (row) => (
        <Box
          component="img"
          sx={{ width: "50px", height: "50px", objectFit: "cover" }}
          src={
            row.image?.path
              ? UrlUtil.getFullImagePath(row.image.path)
              : "/images/default-thumbnail-image.gif"
          }
        />
      ),
    },
    {
      name: <ColumnTitle title={"Created"} />,
      selector: (row) => moment(row.createdAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Updated"} />,
      selector: (row) => moment(row.updatedAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={""} />,
      cell: (row) => (
        <>
          {activeRowId === row.id && (
            <ColumnOption
              row={row}
              open={open}
              model={Models.FACEBOOK_PAGE}
              anchorEl={anchorEl}
              handleClick={handleOptionClick}
              handleClose={handleOptionClose}
              onClickMenuItem={onClickMenuItem}
            />
          )}
        </>
      ),
    },
  ];

  const onSuccessfullyExported = (data) => {
    fileDownload(data, "facebook-list.csv");
  };

  return (
    <DmBox>
      <DmTable
        columns={columns}
        data={facebookPageState.facebookPages}
        title={"Facebook page List"}
        pending={facebookPageState.loading}
        totalRow={facebookPageState.totalFacebookPages}
        onRowMouseEnter={onMouseEnterRowHandler}
        onRowMouseLeave={onMouseLeaveRowHandler}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        actions={
          <Stack direction="row" spacing={2}>
            <Authenticate
              model={Models.FACEBOOK_PAGE}
              action={CanPerformAction.CAN_EXPORT}
            >
              <Button
                variant="contained"
                color="success"
                startIcon={<CloudDownloadIcon />}
                size="large"
                onClick={() =>
                  dispatch(exportFacebookPageRequest(onSuccessfullyExported))
                }
              >
                Export Csv
              </Button>
            </Authenticate>
            <Authenticate
              model={Models.FACEBOOK_PAGE}
              action={CanPerformAction.CAN_ADD}
            >
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate("/facebook-pages/add")}
              >
                Add
              </Button>
            </Authenticate>
          </Stack>
        }
        contextActions={
          <Authenticate
            model={Models.FACEBOOK_PAGE}
            action={CanPerformAction.CAN_DELETE}
          >
            <Button
              onClick={setOpenModal}
              sx={{
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
            >
              Delete
            </Button>
          </Authenticate>
        }
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
      <DmModal open={openModal} closeModal={setOpenModal}>
        <ConfirmModal
          title={
            selectedRows.length > 1
              ? `Are you sure want to delete ${selectedRows.length} facebook pagess ?`
              : `Are you sure you want to delete ${activeRow.name} ?`
          }
          subTitle="This action cannot be undone"
          btnConfirmText={"Delete"}
          btnCancelText="Cancel"
          onCancel={setOpenModal}
          onConfirm={onDelete}
        />
      </DmModal>
    </DmBox>
  );
};
