import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import { RedeemRewardService } from "../../services";
import { toast } from "react-toastify";

import {
  FETCH_REDEEM_REWARDS_REQUEST,
  FETCH_REDEEM_REWARD_REQUEST,
  FETCH_REDEEM_REWARD_STATUS_REQUEST,
  UPDATE_REDEEM_REWARD_REQUEST,
} from "./redeemRewardActionType";
import {
  fetchRedeemRewardFailure,
  fetchRedeemRewardStatusFailure,
  fetchRedeemRewardStatusSuccess,
  fetchRedeemRewardSuccess,
  fetchRedeemRewardsFailure,
  fetchRedeemRewardsSuccess,
  updateRedeemRewardFailure,
  updateRedeemRewardSuccess,
} from "./redeemRewardAction";

function* getRedeemRewardsSaga(action) {
  try {
    const { searchQuery, status } = action;
    const { limit, skip } = action.offset;
    const { data } = yield call(
      RedeemRewardService.getRedeemRewards,
      skip,
      limit,
      searchQuery,
      status
    );
    yield put(fetchRedeemRewardsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchRedeemRewardsFailure(error));
  }
}

function* getRedeemRewardSaga(action) {
  try {
    const { data } = yield call(
      RedeemRewardService.getRedeemReward,
      action.redeemRewardId
    );
    yield put(fetchRedeemRewardSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchRedeemRewardFailure(error));
  }
}

function* getRedeemRewardStatusSaga() {
  try {
    const { data } = yield call(RedeemRewardService.getRedeemRewardStatus);
    yield put(fetchRedeemRewardStatusSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchRedeemRewardStatusFailure(error));
  }
}

function* updateRedeemRewardSaga(action) {
  try {
    const {
      id,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(RedeemRewardService.updateRedeemReward, id, payload);
    yield put(updateRedeemRewardSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateRedeemRewardFailure(error));
  }
}

export function* redeemRewardWatcherSaga() {
  yield takeEvery(FETCH_REDEEM_REWARDS_REQUEST, getRedeemRewardsSaga);
  yield takeEvery(FETCH_REDEEM_REWARD_REQUEST, getRedeemRewardSaga);
  yield takeEvery(UPDATE_REDEEM_REWARD_REQUEST, updateRedeemRewardSaga);
  yield takeEvery(
    FETCH_REDEEM_REWARD_STATUS_REQUEST,
    getRedeemRewardStatusSaga
  );
}
