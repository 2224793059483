import {
  Drawer,
  ListItemIcon,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  Avatar,
  Stack,
  Typography,
  Collapse,
  Tooltip,
  IconButton,
  MenuItem,
  Box,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import DiscountIcon from "@mui/icons-material/Discount";
import BookIcon from "@mui/icons-material/Book";
import BadgeIcon from "@mui/icons-material/Badge";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CommentIcon from "@mui/icons-material/Comment";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import FacebookIcon from "@mui/icons-material/Facebook";
import PersonIcon from "@mui/icons-material/Person";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//import NotificationsIcon from "@mui/icons-material/Notifications";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ReportIcon from "@mui/icons-material/Report";
import StoreIcon from "@mui/icons-material/Store";
import { useToggle } from "../hooks";
import { NotificationBar, PopupMenu, Search } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { employeeLogOutRequest } from "../store/employee/employeeAction";
import { loggedEmployeeSelector } from "../store/selector";
import { UrlUtil } from "../utils";
import _ from "lodash";
import { Authenticate } from "../hoc/Authenticate";
import { CanPerformAction, Models } from "../const";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import HistoryIcon from "@mui/icons-material/History";
import PublicIcon from "@mui/icons-material/Public";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import RuleIcon from "@mui/icons-material/Rule";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import RedeemIcon from "@mui/icons-material/Redeem";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import StorefrontIcon from "@mui/icons-material/Storefront";

const NavbarChildItem = ({
  toggle,
  navigationPath,
  hideAddMenu = false,
  slug = "",
  model,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  slug = params[slug];
  const handleOnClick = (navigationPath) => {
    navigate(navigationPath);
  };
  const isMenuActive = (path) => {
    return location.pathname === path;
  };

  return (
    <Collapse in={toggle}>
      <List component="div" disablePadding>
        <Authenticate model={model} action={CanPerformAction.CAN_VIEW}>
          <ListItemButton
            sx={{
              textAlign: "center",
              bgcolor: (theme) =>
                isMenuActive(navigationPath) && theme.palette.drawerActiveMenu,
            }}
            onClick={() => handleOnClick(navigationPath)}
          >
            <ListItemText primary="List" />
          </ListItemButton>
        </Authenticate>
        <Authenticate model={model} action={CanPerformAction.CAN_ADD}>
          {!hideAddMenu && (
            <ListItemButton
              sx={{
                textAlign: "center",
                bgcolor: (theme) =>
                  (isMenuActive(navigationPath + "/add") ||
                    isMenuActive(navigationPath + `/edit/${slug}`)) &&
                  theme.palette.drawerActiveMenu,
              }}
              onClick={() => handleOnClick(navigationPath + "/add")}
            >
              <ListItemText primary="Add" />
            </ListItemButton>
          )}
        </Authenticate>
      </List>
    </Collapse>
  );
};

const NavbarItem = ({
  model,
  action,
  setToggle,
  toggle,
  toggleProperty,
  navigationPath,
  slug,
  getIcon,
  menuName,
  hideAddMenu = false,
}) => {
  return (
    <>
      <Authenticate model={model} action={action}>
        <ListItem>
          <ListItemButton
            onClick={() =>
              setToggle({
                ...toggle,
                [toggleProperty]: !toggle[toggleProperty],
              })
            }
          >
            <ListItemIcon>{getIcon()}</ListItemIcon>
            <ListItemText
              primary={`${
                menuName ? _.startCase(menuName) : _.capitalize(model)
              }`}
            />
            {toggle[toggleProperty] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
      </Authenticate>
      <NavbarChildItem
        toggle={toggle[toggleProperty]}
        navigationPath={`${navigationPath}`}
        slug={slug}
        model={model}
        hideAddMenu={hideAddMenu}
      />
    </>
  );
};
export const Navbar = () => {
  const [toggle, setToggle] = useState({
    client: false,
    category: false,
    comment: false,
    country: false,
    brand: false,
    banner: false,
    badge: false,
    store: false,
    storeReview: false,
    deal: false,
    dealAlert: false,
    employee: false,
    report: false,
    resource: false,
    role: false,
    user: false,
    searchLog: false,
    facebookPage: false,
    emailHistory: false,
    notification: false,
    rewardRule: false,
    reward: false,
    rewardRuleValidationLog: false,
    redeemReward: false,
  });

  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const iconColor = "rgb(158, 158, 158)";
  const accountRef = useRef();
  const loggedEmployee = useSelector(loggedEmployeeSelector);

  const [openAccountMenu, setToggleAccountMenu] = useToggle();

  const handleAccountMenuClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setToggleAccountMenu();
    switch (event.target.innerText) {
      case "Logout":
        dispatch(employeeLogOutRequest());
        break;
      case "Profile":
        navigation("/profile");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // used to set the active menu
    if (_.includes(location.pathname, "/clients"))
      setToggle((prev) => ({ ...prev, client: true }));

    if (_.includes(location.pathname, "/categories"))
      setToggle((prev) => ({ ...prev, category: true }));

    if (_.includes(location.pathname, "/badges"))
      setToggle((prev) => ({ ...prev, badge: true }));

    if (_.includes(location.pathname, "/banners"))
      setToggle((prev) => ({ ...prev, banner: true }));

    if (_.includes(location.pathname, "/brands"))
      setToggle((prev) => ({ ...prev, brand: true }));

    if (_.includes(location.pathname, "/comments"))
      setToggle((prev) => ({ ...prev, comment: true }));

    if (_.includes(location.pathname, "/countries"))
      setToggle((prev) => ({ ...prev, country: true }));

    if (_.includes(location.pathname, "/deals"))
      setToggle((prev) => ({ ...prev, deal: true }));

    if (_.includes(location.pathname, "/deal-alerts"))
      setToggle((prev) => ({ ...prev, dealAlert: true }));

    if (_.includes(location.pathname, "/employees"))
      setToggle((prev) => ({ ...prev, employee: true }));

    if (_.includes(location.pathname, "/reports"))
      setToggle((prev) => ({ ...prev, report: true }));

    if (_.includes(location.pathname, "/roles"))
      setToggle((prev) => ({ ...prev, role: true }));

    if (_.includes(location.pathname, "/resources"))
      setToggle((prev) => ({ ...prev, resource: true }));

    if (_.includes(location.pathname, "/stores"))
      setToggle((prev) => ({ ...prev, store: true }));

    if (_.includes(location.pathname, "/store-reviews"))
      setToggle((prev) => ({ ...prev, storeReview: true }));

    if (_.includes(location.pathname, "/users"))
      setToggle((prev) => ({ ...prev, user: true }));

    if (_.includes(location.pathname, "/search-logs"))
      setToggle((prev) => ({ ...prev, searchLog: true }));

    if (_.includes(location.pathname, "/facebook-pages"))
      setToggle((prev) => ({ ...prev, facebookPage: true }));

    if (_.includes(location.pathname, "/email-histories"))
      setToggle((prev) => ({ ...prev, emailHistory: true }));

    if (_.includes(location.pathname, "/notifications"))
      setToggle((prev) => ({ ...prev, notification: true }));

    if (_.includes(location.pathname, "/rewards"))
      setToggle((prev) => ({ ...prev, reward: true }));

    if (_.includes(location.pathname, "/reward-rules"))
      setToggle((prev) => ({ ...prev, rewardRule: true }));

    if (_.includes(location.pathname, "/reward-validation-logs"))
      setToggle((prev) => ({ ...prev, rewardRuleValidationLog: true }));

    if (_.includes(location.pathname, "/redeem-rewards"))
      setToggle((prev) => ({ ...prev, redeemReward: true }));
  }, [location.pathname]);

  const isMenuActive = (path) => {
    return location.pathname === path;
  };

  const navbarItems = [
    {
      model: Models.BADGE,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "badge",
      getIcon: () => (
        <MilitaryTechIcon
          sx={{ color: (theme) => theme.palette.icon.default }}
        />
      ),
      navigationPath: "/badges",
      slug: "slug",
    },
    {
      model: Models.BRAND,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "brand",
      getIcon: () => (
        <BrandingWatermarkIcon
          sx={{ color: (theme) => theme.palette.icon.default }}
        />
      ),
      navigationPath: "/brands",
      slug: "slug",
    },
    {
      model: Models.BANNER,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "banner",
      getIcon: () => (
        <BookIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/banners",
      slug: "slug",
    },
    {
      model: Models.CLIENT,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "client",
      getIcon: () => (
        <EscalatorWarningIcon
          sx={{ color: (theme) => theme.palette.icon.default }}
        />
      ),
      navigationPath: "/clients",
      slug: "email",
    },
    {
      model: Models.CATEGORY,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "category",
      getIcon: () => (
        <CategoryIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/categories",
      slug: "slug",
    },
    {
      model: Models.COMMENT,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "comment",
      getIcon: () => (
        <CommentIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/comments",
      hideAddMenu: true,
    },
    {
      model: Models.COUNTRY,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "country",
      getIcon: () => (
        <PublicIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/countries",
      slug: "slug",
    },
    {
      model: Models.DEAL,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "deal",
      getIcon: () => (
        <DiscountIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/deals",
      hideAddMenu: true,
    },
    {
      model: Models.DEAL_ALERT,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "dealAlert",
      getIcon: () => (
        <AddAlertIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/deal-alerts",
      menuName: "Deal Alerts",
      hideAddMenu: true,
    },
    {
      model: Models.EMAIL_HISTORY,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "emailHistory",
      getIcon: () => (
        <HistoryIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/email-histories",
      menuName: "Email History",
      hideAddMenu: true,
    },
    {
      model: Models.EMPLOYEE,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "employee",
      getIcon: () => (
        <BadgeIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/employees",
      slug: "email",
    },
    {
      model: Models.NOTIFICATION,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "notification",
      getIcon: () => (
        <NotificationsNoneIcon
          sx={{ color: (theme) => theme.palette.icon.default }}
        />
      ),
      navigationPath: "/notifications",
      hideAddMenu: true,
    },
    {
      model: Models.REDEEM_REWARD,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "redeemReward",
      getIcon: () => (
        <RedeemIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/redeem-rewards",
      hideAddMenu: true,
      menuName: "Redeem Reward",
    },
    {
      model: Models.REPORT,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "report",
      getIcon: () => (
        <ReportIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/reports",
      hideAddMenu: true,
    },
    {
      model: Models.RESOURCE,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "resource",
      getIcon: () => (
        <GroupsIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/resources",
      slug: "slug",
    },
    {
      model: Models.REWARD,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "reward",
      getIcon: () => (
        <EmojiEventsIcon
          sx={{ color: (theme) => theme.palette.icon.default }}
        />
      ),
      navigationPath: "/rewards",
      slug: "slug",
    },
    {
      model: Models.REWARD_RULE,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "rewardRule",
      getIcon: () => (
        <RuleIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/reward-rules",
      slug: "slug",
      menuName: "Reward Rules",
    },
    {
      model: Models.REWARD_VALIDATION_LOG,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "rewardRuleValidationLog",
      getIcon: () => (
        <YoutubeSearchedForIcon
          sx={{ color: (theme) => theme.palette.icon.default }}
        />
      ),
      navigationPath: "/reward-validation-logs",
      slug: "slug",
      menuName: "Validation Logs",
      hideAddMenu: true,
    },
    {
      model: Models.ROLE,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "role",
      getIcon: () => (
        <AccessibilityIcon
          sx={{ color: (theme) => theme.palette.icon.default }}
        />
      ),
      navigationPath: "/roles",
      slug: "slug",
    },
    {
      model: Models.STORE,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "store",
      getIcon: () => (
        <StoreIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/stores",
      slug: "slug",
    },
    {
      model: Models.STORE_REVIEW,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "storeReview",
      getIcon: () => (
        <StorefrontIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/store-reviews",
      hideAddMenu: true,
      menuName: "Store Review",
    },
    {
      model: Models.SEARCH_LOG,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "searchLog",
      getIcon: () => (
        <ManageSearchIcon
          sx={{ color: (theme) => theme.palette.icon.default }}
        />
      ),
      navigationPath: "/search-logs",
      menuName: "Search Log",
      hideAddMenu: true,
    },
    {
      model: Models.USER,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "user",
      getIcon: () => (
        <PersonIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/users",
      hideAddMenu: true,
    },
    {
      model: Models.FACEBOOK_PAGE,
      action: CanPerformAction.CAN_VIEW,
      setToggle,
      toggle,
      toggleProperty: "facebookPage",
      getIcon: () => (
        <FacebookIcon sx={{ color: (theme) => theme.palette.icon.default }} />
      ),
      navigationPath: "/facebook-pages",
    },
  ];

  return (
    <Stack direction="row">
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: "260px",

          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "260px",
            boxSizing: "border-box",
            backgroundColor: "rgb(35, 48, 68)",
            color: "rgb(238, 238, 238)",
            borderRadius: 0,
          },
        }}
      >
        <Stack alignItems="center" marginTop="1rem" spacing={2}>
          <Avatar
            alt="admin"
            src={UrlUtil.getFullImagePath(loggedEmployee.image.path)}
            sx={{ width: "80px", height: "80px" }}
          >
            {loggedEmployee.firstName.substring(0, 2)}
          </Avatar>
          <Typography>{`${loggedEmployee.firstName} ${loggedEmployee.lastName}`}</Typography>
        </Stack>

        <List
          sx={{
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              backgroundColor: "rgb(35, 48, 68)",
            },
            "&::-webkit-scrollbar-thumb": {
              WebkitBorderRadius: "10px",
              backgroundColor: "#aaa",
              outline: "1px solid slategrey",
            },
          }}
        >
          <Authenticate
            model={Models.DASHBOARD}
            action={CanPerformAction.CAN_VIEW}
          >
            <ListItem key="Dashboard">
              <ListItemButton
                onClick={() => navigation("/")}
                sx={{
                  bgcolor: (theme) =>
                    isMenuActive("/") && theme.palette.drawerActiveMenu,
                }}
              >
                <ListItemIcon>
                  <DashboardIcon
                    sx={{ color: (theme) => theme.palette.icon.default }}
                  />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </ListItem>
          </Authenticate>
          {navbarItems.map((eachNavbarItem) => (
            <NavbarItem
              key={eachNavbarItem.model}
              model={eachNavbarItem.model}
              action={eachNavbarItem.action}
              setToggle={eachNavbarItem.setToggle}
              toggle={eachNavbarItem.toggle}
              toggleProperty={eachNavbarItem.toggleProperty}
              navigationPath={eachNavbarItem.navigationPath}
              slug={eachNavbarItem.slug}
              getIcon={eachNavbarItem.getIcon}
              menuName={eachNavbarItem?.menuName}
              hideAddMenu={eachNavbarItem.hideAddMenu}
            />
          ))}
        </List>
      </Drawer>
      <Stack
        direction="row"
        padding="1rem"
        justifyContent="space-between"
        width="100%"
      >
        <Box sx={{ width: "30%" }}>
          <Search variant="standard" placeholder="Search topics..." />
        </Box>
        <Stack direction="row" spacing={1}>
          <NotificationBar />
          <Tooltip title="Account">
            <IconButton ref={accountRef} onClick={setToggleAccountMenu}>
              <PowerSettingsNewIcon sx={{ color: iconColor }} />
            </IconButton>
          </Tooltip>
          <PopupMenu
            anchorRef={accountRef}
            open={openAccountMenu}
            handleClose={handleAccountMenuClose}
            sx={{ zIndex: 1, boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px" }}
          >
            <MenuItem onClick={handleAccountMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleAccountMenuClose}>Logout</MenuItem>
          </PopupMenu>
        </Stack>
      </Stack>
    </Stack>
  );
};
