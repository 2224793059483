import {
  DELETE_STORE_REVIEW_FAILURE,
  DELETE_STORE_REVIEW_REQUEST,
  DELETE_STORE_REVIEW_SUCCESS,
  FETCH_STORE_REVIEWS_FAILURE,
  FETCH_STORE_REVIEWS_REQUEST,
  FETCH_STORE_REVIEWS_SUCCESS,
  TOGGLE_STORE_REVIEWS_STATUS_FAILURE,
  TOGGLE_STORE_REVIEWS_STATUS_REQUEST,
  TOGGLE_STORE_REVIEWS_STATUS_SUCCESS,
} from "./storeReviewActionType";

export const fetchStoreReviewsRequest = (offset, searchQuery) => {
  return {
    type: FETCH_STORE_REVIEWS_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchStoreReviewsSuccess = (data) => {
  return {
    type: FETCH_STORE_REVIEWS_SUCCESS,
    payload: data,
  };
};

export const fetchStoreReviewsFailure = (error) => {
  return {
    type: FETCH_STORE_REVIEWS_FAILURE,
    error,
  };
};

export const toggleStoreReviewsStatusRequest = (payload, onSuccess) => {
  return {
    type: TOGGLE_STORE_REVIEWS_STATUS_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const toggleStoreReviewsStatusSuccess = () => {
  return {
    type: TOGGLE_STORE_REVIEWS_STATUS_SUCCESS,
  };
};

export const toggleStoreReviewsStatusFailure = (error) => {
  return {
    type: TOGGLE_STORE_REVIEWS_STATUS_FAILURE,
    error,
  };
};

export const deleteStoreReviewRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_STORE_REVIEW_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteStoreReviewSuccess = () => {
  return {
    type: DELETE_STORE_REVIEW_SUCCESS,
  };
};

export const deleteStoreReviewFailure = (error) => {
  return {
    type: DELETE_STORE_REVIEW_FAILURE,
    error,
  };
};
