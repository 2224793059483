import {
  CREATE_BADGE_FAILURE,
  CREATE_BADGE_REQUEST,
  CREATE_BADGE_SUCCESS,
  DELETE_BADGE_FAILURE,
  DELETE_BADGE_REQUEST,
  DELETE_BADGE_SUCCESS,
  FETCH_BADGES_FAILURE,
  FETCH_BADGES_REQUEST,
  FETCH_BADGES_SUCCESS,
  FETCH_BADGE_FAILURE,
  FETCH_BADGE_REQUEST,
  FETCH_BADGE_SUCCESS,
  UPDATE_BADGE_FAILURE,
  UPDATE_BADGE_REQUEST,
  UPDATE_BADGE_SUCCESS,
} from "./badgeActionType";

export const fetchBadgesRequest = (offset, searchQuery) => {
  return {
    type: FETCH_BADGES_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchBadgesSuccess = (data) => {
  return {
    type: FETCH_BADGES_SUCCESS,
    payload: data,
  };
};

export const fetchBadgesFailure = (error) => {
  return {
    type: FETCH_BADGES_FAILURE,
    error,
  };
};

export const fetchBadgeRequest = (slug) => {
  return {
    type: FETCH_BADGE_REQUEST,
    slug,
  };
};

export const fetchBadgeSuccess = (data) => {
  return {
    type: FETCH_BADGE_SUCCESS,
    payload: data,
  };
};

export const fetchBadgeFailure = (error) => {
  return {
    type: FETCH_BADGE_FAILURE,
    error,
  };
};

export const createBadgeRequest = (payload, onSuccess) => {
  return {
    type: CREATE_BADGE_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};
export const createBadgeSuccess = (data) => {
  return {
    type: CREATE_BADGE_SUCCESS,
    payload: data,
  };
};
export const createBadgeFailure = (error) => {
  return {
    type: CREATE_BADGE_FAILURE,
    error,
  };
};

export const updateBadgeRequest = (slug, payload, onSuccess) => {
  return {
    type: UPDATE_BADGE_REQUEST,
    slug,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateBadgeSuccess = () => {
  return {
    type: UPDATE_BADGE_SUCCESS,
  };
};

export const updateBadgeFailure = (error) => {
  return {
    type: UPDATE_BADGE_FAILURE,
    error,
  };
};

export const deleteBadgeRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_BADGE_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteBadgeSuccess = () => {
  return {
    type: DELETE_BADGE_SUCCESS,
  };
};

export const deleteBadgeFailure = (error) => {
  return {
    type: DELETE_BADGE_FAILURE,
    error,
  };
};
