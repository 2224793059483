import {
  CREATE_BANNER_FAILURE,
  CREATE_BANNER_REQUEST,
  DELETE_BANNER_FAILURE,
  DELETE_BANNER_REQUEST,
  FETCH_BANNERS_FAILURE,
  FETCH_BANNERS_REQUEST,
  FETCH_BANNERS_SUCCESS,
  FETCH_BANNER_FAILURE,
  FETCH_BANNER_REQUEST,
  FETCH_BANNER_SUCCESS,
  UPDATE_BANNER_FAILURE,
  UPDATE_BANNER_REQUEST,
} from "./bannerActionType";

const initialState = {
  loading: false,
  banners: [],
  banner: {},
  error: "",
  totalBanners: 0,
};

export const BannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BANNERS_REQUEST:
    case FETCH_BANNER_REQUEST:
    case CREATE_BANNER_REQUEST:
    case UPDATE_BANNER_REQUEST:
    case DELETE_BANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BANNERS_SUCCESS:
      const { banners, totalBanners } = action.payload;
      return {
        ...state,
        loading: false,
        banners: banners,
        totalBanners: totalBanners,
      };
    case FETCH_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        banner: action.payload,
      };

    case FETCH_BANNERS_FAILURE:
    case FETCH_BANNER_FAILURE:
    case CREATE_BANNER_FAILURE:
    case UPDATE_BANNER_FAILURE:
    case DELETE_BANNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
