import { Box, Paper } from "@mui/material";
import React from "react";

export const DmBox = ({ children }) => {
  return (
    <Box p="2rem" bgcolor={(theme) => theme.palette.boxBgColor}>
      <Paper sx={{ padding: "1rem" }}>{children}</Paper>
    </Box>
  );
};
