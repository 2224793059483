export const FETCH_RESOURCES_REQUEST = "FETCH_RESOURCES_REQUEST";
export const FETCH_RESOURCES_SUCCESS = "FETCH_RESOURCES_SUCCESS";
export const FETCH_RESOURCES_FAILURE = "FETCH_RESOURCES_FAILURE";

export const FETCH_RESOURCE_REQUEST = "FETCH_RESOURCE_REQUEST";
export const FETCH_RESOURCE_SUCCESS = "FETCH_RESOURCE_SUCCESS";
export const FETCH_RESOURCE_FAILURE = "FETCH_RESOURCE_FAILURE";

export const FETCH_RESOURCE_OPTIONS_REQUEST = "FETCH_RESOURCE_OPTIONS_REQUEST";
export const FETCH_RESOURCE_OPTIONS_SUCCESS = "FETCH_RESOURCE_OPTIONS_SUCCESS";
export const FETCH_RESOURCE_OPTIONS_FAILURE = "FETCH_RESOURCE_OPTIONS_FAILURE";

export const CREATE_RESOURCE_REQUEST = "CREATE_RESOURCE_REQUEST";
export const CREATE_RESOURCE_SUCCESS = "CREATE_RESOURCE_SUCCESS";
export const CREATE_RESOURCE_FAILURE = "CREATE_RESOURCE_FAILURE";

export const UPDATE_RESOURCE_REQUEST = "UPDATE_RESOURCE_REQUEST";
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS";
export const UPDATE_RESOURCE_FAILURE = "UPDATE_RESOURCE_FAILURE";

export const DELETE_RESOURCE_REQUEST = "DELETE_RESOURCE_REQUEST";
export const DELETE_RESOURCE_SUCCESS = "DELETE_RESOURCE_SUCCESS";
export const DELETE_RESOURCE_FAILURE = "DELETE_RESOURCE_FAILURE";
