import React, { useState } from "react";
import {
  Switch,
  TextField,
  FormControlLabel,
  Box,
  Typography,
  Button,
  Stack,
  IconButton,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useDropzone } from "react-dropzone";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import Fade from "@mui/material/Fade";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { UrlUtil } from "../utils";

export const Input = ({ input, meta, onChange, ...props }) => {
  const hasError = () => {
    return meta.error && meta.touched;
  };
  return (
    <TextField
      {...input}
      {...props}
      // onChange={(e) => {
      //   input.onChange(e); //final-form's onChange
      //   if (onChange) {
      //     //props.onChange
      //     onChange(e.target.value);
      //   }
      // }}
      error={hasError()}
      helperText={hasError() && meta.error}
    ></TextField>
  );
};

export const AutoCompleteMultiSelectCheckBox = ({
  input,
  meta,
  handleOnChange,
  options,
  label,
  ...rest
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const hasError = () => {
    return meta.error && meta.touched;
  };

  const isOptionSelected = (option) => {
    return input.value.find((v) => v.value === option.value) ? true : false;
  };

  return (
    <Autocomplete
      multiple
      options={options}
      {...input}
      {...rest}
      onChange={(e, value) => {
        input.onChange(value); //final-form's onChange
        handleOnChange(value);
      }}
      disableCloseOnSelect
      filterSelectedOptions
      renderOption={(props, option, { selected }) => {
        return (
          <>
            {!isOptionSelected(option) && (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    input.value.find((v) => v.value === option.value)
                      ? true
                      : false
                  }
                />
                {option.label}
              </li>
            )}
          </>
        );
      }}
      //style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={hasError()}
          helperText={hasError() && meta.error}
        />
      )}
    />
  );
};

export const AutoCompleteSelect = ({
  input,
  meta,
  options,
  label,
  handleOnChange,
  ...rest
}) => {
  const hasError = () => {
    return meta.error && meta.touched;
  };

  return (
    <Autocomplete
      options={options}
      {...input}
      {...rest}
      onChange={(e, value) => {
        input.onChange(value); //final-form's onChange
        handleOnChange(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={hasError()}
          helperText={hasError() && meta.error}
          label={label}
        />
      )}
    />
  );
};

export const InputDate = ({ input, meta, ...props }) => {
  const hasError = () => {
    return meta.error && meta.touched;
  };
  const { name, value } = input;
  return (
    <DatePicker
      name={name}
      value={value}
      {...props}
      onChange={(value) => {
        if (input.onChange) {
          input.onChange(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={hasError()}
          helperText={hasError() && meta.error}
        />
      )}
    />
  );
};

export const InputSwitch = ({ input, meta, handleOnChange, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          {...input}
          onChange={(e, value) => {
            input.onChange(value); //final-form's onChange
            handleOnChange(value);
          }}
        />
      }
      {...props}
    />
  );
};

const textStyle = {
  color: "#2B2A2E",
  fontSize: "14px",
  lineHeight: "18px",
};

const errorTextStyle = {
  color: "#FF3737",
  fontFamily: "Inter",
  fontSize: "14px",
  lineHeight: "18px",
  marginTop: "24px",
};

export const InputFile = ({
  input,
  meta,
  files,
  onChangeHandler,
  label,
  isMultiple = false,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const [hoverPreview, setHoverPreview] = useState({});

  const hasError = () => {
    return meta.error && meta.touched;
  };

  const dragEnter = () => {
    setHover(true);
  };

  const dragLeave = () => {
    setHover(false);
  };

  const onDrop = (acceptedFiles) => {
    if (input.onChange) {
      input.onChange(acceptedFiles);
      onChangeHandler(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDragEnter: dragEnter,
    onDragLeave: dragLeave,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: isMultiple,
    onDrop: onDrop,
  });

  const removeFile = (file) => () => {
    const filterFiles = files.filter((eachFile) => eachFile !== file);
    if (input.onChange) {
      input.onChange(filterFiles);
      onChangeHandler(filterFiles);
    }
  };

  return (
    <Box>
      <Stack
        {...getRootProps()}
        {...props}
        spacing={1}
        bgcolor={hover ? "#F3F4FF" : "white"}
      >
        <input {...getInputProps()} />

        <FileUploadIcon />
        {label && <Typography sx={textStyle}> {label}</Typography>}
        <Typography sx={textStyle}>Drag & drop files here or</Typography>
        <Typography sx={textStyle}>click to select below.</Typography>
        {hasError() && (
          <Typography sx={errorTextStyle}>{meta.error}</Typography>
        )}
        <Button
          onClick={open}
          variant="outlined"
          startIcon={<AddIcon icon="Plus" size="16px" />}
        >
          Add Image
        </Button>
      </Stack>
      {files.length > 0 && (
        <Stack
          direction="row"
          spacing={1.8}
          sx={{ bgcolor: "whitesmoke", p: ".8rem 1rem", flexWrap: "wrap" }}
        >
          {files.map((file, index) => (
            <Box
              sx={{ position: "relative" }}
              key={file.name}
              onMouseEnter={() => setHoverPreview({ [index]: true })}
              onMouseLeave={() => setHoverPreview({ [index]: false })}
            >
              <Box
                src={
                  file.size // this meas it was uploaded
                    ? URL.createObjectURL(file)
                    : UrlUtil.getFullImagePath(file.path)
                }
                component="img"
                sx={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
              <Fade in={hoverPreview[index]}>
                <IconButton
                  sx={{ position: "absolute", top: "-5px", right: "-20px" }}
                  onClick={removeFile(file)}
                >
                  <ClearIcon />
                </IconButton>
              </Fade>
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
};
