import React, { useEffect } from "react";
import { EmployeeForm } from "../../components/forms";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DmBox } from "../../components";
import { fetchEmployeeRequest } from "../../store";

export const AddEmployeePage = () => {
  const { email } = useParams();
  const dispatch = useDispatch();
  const employeeState = useSelector((state) => state.employee);

  useEffect(() => {
    if (email) dispatch(fetchEmployeeRequest(email));
  }, [dispatch, email]);

  return (
    <DmBox>
      {employeeState.loading ? (
        <CircularProgress />
      ) : (
        <EmployeeForm employee={email && employeeState.employee} />
      )}
    </DmBox>
  );
};
