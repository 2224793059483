import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
} from "@mui/material";
import React from "react";
import { StringUtil, UrlUtil } from "../../utils";
import moment from "moment";
import { DATE_FORMAT, DATE_TIME_FORMAT, DealType } from "../../const";
import ReactQuill from "react-quill";

export const DealView = ({ deal, title = "Details" }) => {
  const isExpired = moment(deal?.expiredDate).isBefore(new Date(), "day");
  return (
    <Box>
      <Typography sx={{ fontWeight: 600, pl: "1.3rem", mb: "1rem" }}>
        {title}
      </Typography>
      <Divider />
      <List sx={{ pt: "0px" }}>
        <Box sx={{ pl: "1.3rem", display: "flex", flexWrap: "wrap" }}>
          {deal.images.map((eachImage) => (
            <Box
              component="img"
              key={eachImage.name}
              src={
                eachImage?.path
                  ? UrlUtil.getFullImagePath(eachImage.path)
                  : "/images/default-thumbnail-image.gif"
              }
              sx={{
                width: "250px",
                height: "250px",
                objectFit: "contain",
                border: "1px solid",
                borderColor: "divider",
                marginTop: "1.3rem",
                marginBottom: ".5rem",
                marginRight: ".8rem",
                padding: ".5rem",
              }}
            />
          ))}
        </Box>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Id
            </Typography>
            <Box>
              <Typography variant="value">{deal?.id}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Title
            </Typography>
            <Box>
              <Typography variant="value">{deal?.title}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Slug
            </Typography>
            <Box>
              <Typography variant="value">{deal?.slug}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Url
            </Typography>
            <Box>
              <Typography variant="value">
                <Link href={deal?.url} target="_blank" rel="noopener">
                  {deal.url}
                </Link>
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Price
            </Typography>
            <Box>
              <Typography variant="value">{deal?.price}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Regular Price
            </Typography>
            <Box>
              <Typography variant="value">{deal?.regularPrice}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Deal Type
            </Typography>
            <Box>
              <Typography variant="value">{deal?.dealType}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        {deal?.dealType === DealType.COUPON && (
          <ListItem variant="view">
            <ListItemText disableTypography variant="view">
              <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
                Coupon Code
              </Typography>
              <Box>
                <Typography variant="value">{deal?.couponCode}</Typography>
              </Box>
            </ListItemText>
          </ListItem>
        )}
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Description
            </Typography>
            <Box>
              <ReactQuill
                value={deal.description}
                readOnly={true}
                modules={{
                  toolbar: false,
                }}
              />
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Views
            </Typography>
            <Box>
              <Typography variant="value">{deal?.views}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Ip Addresses
            </Typography>
            <Box>
              <Typography variant="value">
                {deal?.ipAddresses?.join(", ")}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Total Likes
            </Typography>
            <Box>
              <Typography variant="value">{deal?.totalLikes}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Total Comments
            </Typography>
            <Box>
              <Typography variant="value">{deal?.totalComments}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Total Dislikes
            </Typography>
            <Box>
              <Typography variant="value">{deal?.totalDislikes}</Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Featured
            </Typography>
            <Box>
              <Typography variant="value">
                {StringUtil.getYesOrNo(deal?.isFeatured)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Featured Start Date
            </Typography>
            <Box>
              <Typography variant="value">
                {deal.featuredStartDate &&
                  moment(deal.featuredStartDate).format(DATE_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Featured End Date
            </Typography>
            <Box>
              <Typography variant="value">
                {deal.featuredEndDate &&
                  moment(deal.featuredEndDate).format(DATE_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Enabled
            </Typography>
            <Box>
              <Typography variant="value">
                {StringUtil.getYesOrNo(deal?.isActive)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem
          variant="view"
          sx={{
            bgcolor: isExpired && "#E05851",
            color: isExpired && "#F9F9F9",
          }}
        >
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Expired
            </Typography>
            <Box>
              <Typography
                variant="value"
                sx={{ color: isExpired && "#F9F9F9" }}
              >
                {moment(deal?.expiredDate).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Created
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(deal?.createdAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem variant="view">
          <ListItemText disableTypography variant="view">
            <Typography variant="subtitle2" sx={{ minWidth: "180px" }}>
              Updated
            </Typography>
            <Box>
              <Typography variant="value">
                {moment(deal?.updatedAt).format(DATE_TIME_FORMAT)}
              </Typography>
            </Box>
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );
};
