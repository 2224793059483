export const FETCH_FACEBOOK_PAGES_REQUEST = "FETCH_FACEBOOK_PAGES_REQUEST";
export const FETCH_FACEBOOK_PAGES_SUCCESS = "FETCH_FACEBOOK_PAGES_SUCCESS";
export const FETCH_FACEBOOK_PAGES_FAILURE = "FETCH_FACEBOOK_PAGES_FAILURE";

export const FETCH_FACEBOOK_PAGE_REQUEST = "FETCH_FACEBOOK_PAGE_REQUEST";
export const FETCH_FACEBOOK_PAGE_SUCCESS = "FETCH_FACEBOOK_PAGE_SUCCESS";
export const FETCH_FACEBOOK_PAGE_FAILURE = "FETCH_FACEBOOK_PAGE_FAILURE";

export const CREATE_FACEBOOK_PAGE_REQUEST = "CREATE_FACEBOOK_PAGE_REQUEST";
export const CREATE_FACEBOOK_PAGE_SUCCESS = "CREATE_FACEBOOK_PAGE_SUCCESS";
export const CREATE_FACEBOOK_PAGE_FAILURE = "CREATE_FACEBOOK_PAGE_FAILURE";

export const UPDATE_FACEBOOK_PAGE_REQUEST = "UPDATE_FACEBOOK_PAGE_REQUEST";
export const UPDATE_FACEBOOK_PAGE_SUCCESS = "UPDATE_FACEBOOK_PAGE_SUCCESS";
export const UPDATE_FACEBOOK_PAGE_FAILURE = "UPDATE_FACEBOOK_PAGE_FAILURE";

export const DELETE_FACEBOOK_PAGE_REQUEST = "DELETE_FACEBOOK_PAGE_REQUEST";
export const DELETE_FACEBOOK_PAGE_SUCCESS = "DELETE_FACEBOOK_PAGE_SUCCESS";
export const DELETE_FACEBOOK_PAGE_FAILURE = "DELETE_FACEBOOK_PAGE_FAILURE";

export const EXPORT_FACEBOOK_PAGES_REQUEST = "EXPORT_FACEBOOK_PAGES_REQUEST";
export const EXPORT_FACEBOOK_PAGES_SUCCESS = "EXPORT_FACEBOOK_PAGES_SUCCESS";
export const EXPORT_FACEBOOK_PAGES_FAILURE = "EXPORT_FACEBOOK_PAGES_FAILURE";
