import { Grid, Box, Typography, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Fallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();
  console.log(error);
  return (
    <Grid
      container
      justifyContent="center"
      textAlign="center"
      sx={{ padding: "40px 0" }}
    >
      <Grid item xs={12} md={8}>
        <Box
          component="img"
          src={"/images/something-went-wrong.png"}
          alt="not found"
          sx={{
            width: "100%",
            height: "400px",
            objectFit: "contain",
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography pt="1rem">{`${error}`}</Typography>
      </Grid>
      <Grid item xs={12} md={8} mt="1rem">
        <Button
          variant="contained"
          onClick={() => {
            resetErrorBoundary();
            navigate("/");
          }}
          color="success"
        >
          Go to Home
        </Button>
      </Grid>
    </Grid>
  );
};
