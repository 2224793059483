import _ from "lodash";
import { StringUtil } from ".";
import { MONTHS } from "../const";

export const ArrayUtil = {
  getCategoryOptions: function (categories, categoryList = []) {
    if (categories) {
      for (let category of categories) {
        categoryList.push({
          value: category.id,
          label: category.displayName ?? category.name,
        });
        this.getCategoryOptions(category.children, categoryList);
      }
    }
    return categoryList;
  },
  getReportStatusOption: (reports) => {
    let options = [];
    for (let report of reports) {
      options.push({
        label: _.capitalize(report),
        value: report,
      });
    }
    return options;
  },
  getOptions: (givenOptions) => {
    let options = [];
    for (let option of givenOptions) {
      options.push({
        label: option.displayName ?? option.name ?? option.title,
        value: option.id,
      });
    }
    return options;
  },
  getCanPerformActionOptions: (actions) => {
    let options = [];
    for (let action of actions) {
      options.push({
        label: StringUtil.convertCapUnderscore(action),
        value: action,
      });
    }
    return options;
  },
  getMonthlySummaryFormattedData: (data) => {
    let formattedData = [];
    let index = 0;
    if (data) {
      for (let eachData of data) {
        formattedData.push(eachData[MONTHS[index++]]);
      }
    }
    return formattedData;
  },
  getCoordinateAndAddresses: (addresses, coordinates) => {
    const result = [];
    let index = 0;
    let skip = 0;
    if (addresses) {
      for (const address of addresses) {
        const start = index === 0 ? 0 : skip;
        const end = start + 2;
        skip = end;
        result.push({
          address,
          coordinates: coordinates.slice(start, end),
        });
        index++;
      }
    }
    return result;
  },
};
