export const ValidationUtil = {
  requiredValidator: (value) => {
    if (Array.isArray(value) && value.length === 0) return "Required";
    return value || value === 0 ? undefined : "Required"; // allowing 0 to be valid
  },
  confirmPasswordValidator: (confirmPassword, newPassword) => {
    return confirmPassword === newPassword
      ? undefined
      : "Confirm password doesn't match with new password";
  },
  emailValidator: (email) => {
    return /\S+@\S+\.\S+/.test(email)
      ? undefined
      : "Please provide a valid email address";
  },

  isValidHttpUrl: (string) => {
    let url;
    const msg = "Invalid link";
    try {
      url = new URL(string);
    } catch (_) {
      return msg;
    }
    return url.protocol === "http:" || url.protocol === "https:"
      ? undefined
      : msg;
  },
  fileSizeValidator: (files) => {
    for (let file of files) {
      let fileMb = file.size / (1024 * 1024);
      if (fileMb > 5) return "File size not more than 5 mb";
    }
    return undefined;
  },
  fileExtensionValidator: (files) => {
    const allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/webp",
      "image/avif",
    ];
    for (let file of files) {
      if (!file.type) continue;
      if (allowedExtension.indexOf(file.type) === -1)
        return "only jpeg, jpg, png and avif file allowed";
    }
    return undefined;
  },
  numberOfFilesValidator: (files, counter) => {
    return files.length > counter
      ? `More than ${counter} files are not allowed`
      : undefined;
  },
  lengthValidator: (value, size) => {
    return value.length !== size
      ? `Length should be${size} characters`
      : undefined;
  },
  mobileNumberValidator: (value) => {
    //if (value === "undefined" || !value) return undefined;
    const mobileOperators = ["017", "013", "019", "014", "018", "015"];
    if (value?.length !== 11) return "Mobile number should be 11 digit";
    const startsWith = value.substring(0, 3);
    if (!mobileOperators.includes(startsWith))
      return "Please provide valid mobile operator number";
  },
  composeValidators:
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      ),
};
