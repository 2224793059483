import React from "react";
import { Typography } from "@mui/material";

export const ColumnTitle = ({ title, ...props }) => {
  return (
    <Typography
      variant="subtitle2"
      fontSize={".9rem"}
      fontWeight={600}
      {...props}
    >
      {title}
    </Typography>
  );
};
