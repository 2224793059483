import { Button, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DmBox } from "../../components";
import { Action, Models } from "../../const";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { Authenticate } from "../../hoc/Authenticate";
import { RewardRuleView } from "../../components/views";

export const ViewRewardRulePage = () => {
  const { slug } = useParams();
  const rewardRules = useSelector((state) => state.rewardRule.rewardRules);
  const rewardRule = rewardRules.find(
    (eachRewardRule) => eachRewardRule.slug === slug
  );
  const navigate = useNavigate();

  return (
    <DmBox>
      <Stack direction="row" spacing={5} mb="1rem">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate("/reward-rules")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Authenticate model={Models.REWARD_RULE} action={Action.EDIT}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(`/reward-rules/edit/${rewardRule.slug}`)}
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Authenticate>
      </Stack>
      <RewardRuleView rewardRule={rewardRule} />
    </DmBox>
  );
};
