import {
  DELETE_SEARCH_LOG_FAILURE,
  DELETE_SEARCH_LOG_REQUEST,
  FETCH_SEARCH_LOGS_FAILURE,
  FETCH_SEARCH_LOGS_REQUEST,
  FETCH_SEARCH_LOGS_SUCCESS,
} from "./searchLogActionType";

const initialState = {
  loading: false,
  searchLogs: [],
  totalSearchLogs: 0,
  error: "",
};

export const SearchLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCH_LOGS_REQUEST:
    case DELETE_SEARCH_LOG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SEARCH_LOGS_SUCCESS:
      const { searchLogs, totalSearchLogs } = action.payload;
      return {
        ...state,
        loading: false,
        searchLogs: searchLogs,
        totalSearchLogs: totalSearchLogs,
      };
    case FETCH_SEARCH_LOGS_FAILURE:
    case DELETE_SEARCH_LOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
