import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DmBox } from "../../components";
import { ClientForm } from "../../components/forms";
import { fetchClientRequest } from "../../store";

export const AddClientPage = () => {
  const { email } = useParams();
  const dispatch = useDispatch();
  const clientState = useSelector((state) => state.client);

  useEffect(() => {
    if (email) dispatch(fetchClientRequest(email));
  }, [dispatch, email]);

  return (
    <DmBox>
      {clientState.loading ? (
        <CircularProgress />
      ) : (
        <ClientForm client={email && clientState.client} />
      )}
    </DmBox>
  );
};
