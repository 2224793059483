import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { NotifyUtil } from "../../utils";
import {
  FETCH_CATEGORY_BRAND_STORE_SUMMARY_REQUEST,
  FETCH_SUMMARY_REQUEST,
  FETCH_TOP_CONTRIBUTORS_DASHBOARD_REQUEST,
  FETCH_TOP_MOST_REPORTED_DEALS_REQUEST,
  FETCH_TOP_MOST_SEARCHED_KEYWORDS_REQUEST,
  FETCH_TOP_MOST_VIEWED_DEALS_REQUEST,
  FETCH_MONTHLY_DEALS_SUMMARY_REQUEST,
  FETCH_MONTHLY_REPORTS_SUMMARY_REQUEST,
  FETCH_MONTHLY_USERS_SUMMARY_REQUEST,
  FETCH_ACTIVE_USER_SUMMARY_REQUEST,
} from "./dashboardActionType";
import {
  fetchCategoryBrandStoreSummaryFailure,
  fetchCategoryBrandStoreSummarySuccess,
  fetchSummaryFailure,
  fetchSummarySuccess,
  fetchTopContributorsDashboardFailure,
  fetchTopContributorsDashboardSuccess,
  fetchTopMostReportedDealsFailure,
  fetchTopMostReportedDealsSuccess,
  fetchTopMostSearchedKeywordsFailure,
  fetchTopMostSearchedKeywordsSuccess,
  fetchTopMostViewedDealsFailure,
  fetchTopMostViewedDealsSuccess,
  fetchMonthlyDealsSummaryFailure,
  fetchMonthlyDealsSummarySuccess,
  fetchMonthlyReportsSummaryFailure,
  fetchMonthlyReportsSummarySuccess,
  fetchMonthlyUsersSummarySuccess,
  fetchMonthlyUsersSummaryFailure,
  fetchActiveUserSummarySuccess,
  fetchActiveUserSummaryFailure,
} from "./dashboardAction";
import { DashboardService } from "../../services";

function* fetchTopContributorsSaga(action) {
  try {
    const { data } = yield call(
      DashboardService.getTopContributors,
      action.limit
    );
    yield put(fetchTopContributorsDashboardSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchTopContributorsDashboardFailure(error));
  }
}

function* fetchMonthlyDealsSummarySaga() {
  try {
    const { data } = yield call(DashboardService.getMonthlyDealsSummary);
    yield put(fetchMonthlyDealsSummarySuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchMonthlyDealsSummaryFailure(error));
  }
}

function* fetchMonthlyUsersSummarySaga() {
  try {
    const { data } = yield call(DashboardService.getMonthlyUsersSummary);
    yield put(fetchMonthlyUsersSummarySuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchMonthlyUsersSummaryFailure(error));
  }
}

function* fetchMonthlyReportsSummarySaga() {
  try {
    const { data } = yield call(DashboardService.getMonthlyReportsSummary);
    yield put(fetchMonthlyReportsSummarySuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchMonthlyReportsSummaryFailure(error));
  }
}

function* fetchSummarySaga() {
  try {
    const { data } = yield call(DashboardService.getSummary);
    yield put(fetchSummarySuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchSummaryFailure(error));
  }
}

function* fetchTopMostViewedDealsSaga(action) {
  try {
    const { data } = yield call(
      DashboardService.getTopMostViewedDeals,
      action.limit
    );
    yield put(fetchTopMostViewedDealsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchTopMostViewedDealsFailure(error));
  }
}

function* fetchTopMostReportedDealsSaga(action) {
  try {
    const { data } = yield call(
      DashboardService.getTopMostReportedDeals,
      action.limit
    );
    yield put(fetchTopMostReportedDealsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchTopMostReportedDealsFailure(error));
  }
}

function* fetchTopMostSearchedKeywordsSaga(action) {
  try {
    const { data } = yield call(
      DashboardService.getTopMostSearchedKeywords,
      action.limit
    );
    yield put(fetchTopMostSearchedKeywordsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchTopMostSearchedKeywordsFailure(error));
  }
}

function* fetchCategoryBrandStoreSummarySaga() {
  try {
    const { data } = yield call(DashboardService.getCategoryBrandStoreSummary);
    yield put(fetchCategoryBrandStoreSummarySuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchCategoryBrandStoreSummaryFailure(error));
  }
}

function* fetchActiveUserSummarySaga() {
  try {
    const { data } = yield call(DashboardService.getActiveUserSummary);
    yield put(fetchActiveUserSummarySuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchActiveUserSummaryFailure(error));
  }
}

export function* dashboardWatcherSaga() {
  yield takeEvery(
    FETCH_TOP_CONTRIBUTORS_DASHBOARD_REQUEST,
    fetchTopContributorsSaga
  );
  yield takeEvery(
    FETCH_MONTHLY_DEALS_SUMMARY_REQUEST,
    fetchMonthlyDealsSummarySaga
  );
  yield takeEvery(
    FETCH_MONTHLY_USERS_SUMMARY_REQUEST,
    fetchMonthlyUsersSummarySaga
  );
  yield takeEvery(
    FETCH_MONTHLY_REPORTS_SUMMARY_REQUEST,
    fetchMonthlyReportsSummarySaga
  );
  yield takeEvery(FETCH_SUMMARY_REQUEST, fetchSummarySaga);
  yield takeEvery(
    FETCH_TOP_MOST_VIEWED_DEALS_REQUEST,
    fetchTopMostViewedDealsSaga
  );
  yield takeEvery(
    FETCH_TOP_MOST_REPORTED_DEALS_REQUEST,
    fetchTopMostReportedDealsSaga
  );
  yield takeEvery(
    FETCH_TOP_MOST_SEARCHED_KEYWORDS_REQUEST,
    fetchTopMostSearchedKeywordsSaga
  );
  yield takeEvery(
    FETCH_CATEGORY_BRAND_STORE_SUMMARY_REQUEST,
    fetchCategoryBrandStoreSummarySaga
  );
  yield takeEvery(
    FETCH_ACTIVE_USER_SUMMARY_REQUEST,
    fetchActiveUserSummarySaga
  );
}
