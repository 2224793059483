import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import { toast } from "react-toastify";
import {
  DELETE_REWARD_VALIDATION_LOG_REQUEST,
  FETCH_REWARD_VALIDATION_LOGS_REQUEST,
} from "./rewardValidationLogActionType";
import { RewardValidationLogService } from "../../services";
import {
  deleteRewardValidationLogFailure,
  deleteRewardValidationLogSuccess,
  fetchRewardValidationLogsFailure,
  fetchRewardValidationLogsSuccess,
} from "./rewardValidationLogAction";

function* getRewardValidationLogsSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      RewardValidationLogService.getRewardValidationLogs,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchRewardValidationLogsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchRewardValidationLogsFailure(error));
  }
}

function* deleteRewardValidationLogSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(RewardValidationLogService.deleteRewardValidationLog, payload);
    yield put(deleteRewardValidationLogSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteRewardValidationLogFailure(error));
  }
}

export function* rewardValidationLogWatcherSaga() {
  yield takeEvery(
    FETCH_REWARD_VALIDATION_LOGS_REQUEST,
    getRewardValidationLogsSaga
  );
  yield takeEvery(
    DELETE_REWARD_VALIDATION_LOG_REQUEST,
    deleteRewardValidationLogSaga
  );
}
