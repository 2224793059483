import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import { RewardRuleService } from "../../services";
import { toast } from "react-toastify";
import {
  CREATE_REWARD_RULE_REQUEST,
  DELETE_REWARD_RULE_REQUEST,
  FETCH_ALL_REWARD_RULES_REQUEST,
  FETCH_REWARD_RULES_REQUEST,
  FETCH_REWARD_RULE_REQUEST,
  UPDATE_REWARD_RULE_REQUEST,
} from "./rewardRuleActionType";
import {
  createRewardRuleFailure,
  createRewardRuleSuccess,
  deleteRewardRuleFailure,
  deleteRewardRuleSuccess,
  fetchAllRewardRulesFailure,
  fetchAllRewardRulesSuccess,
  fetchRewardRuleFailure,
  fetchRewardRuleSuccess,
  fetchRewardRulesFailure,
  fetchRewardRulesSuccess,
  updateRewardRuleFailure,
  updateRewardRuleSuccess,
} from "./rewardRuleAction";

function* getRewardRulesSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      RewardRuleService.getRewardRules,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchRewardRulesSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchRewardRulesFailure(error));
  }
}

function* getRewardRuleSaga(action) {
  try {
    const { data } = yield call(RewardRuleService.getRewardRule, action.slug);
    yield put(fetchRewardRuleSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchRewardRuleFailure(error));
  }
}

function* getAllRewardRulesSaga() {
  try {
    const { data } = yield call(RewardRuleService.getAllRewardRules);
    yield put(fetchAllRewardRulesSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchAllRewardRulesFailure(error));
  }
}

function* createRewardRuleSaga(action) {
  try {
    const {
      meta: { onSuccess },
    } = action;
    const { data } = yield call(
      RewardRuleService.createRewardRule,
      action.payload
    );
    yield put(createRewardRuleSuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createRewardRuleFailure(error));
  }
}
function* updateRewardRuleSaga(action) {
  try {
    const {
      slug,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(RewardRuleService.updateRewardRule, slug, payload);
    yield put(updateRewardRuleSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateRewardRuleFailure(error));
  }
}

function* deleteRewardRuleSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(RewardRuleService.deleteRewardRule, payload);
    yield put(deleteRewardRuleSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteRewardRuleFailure(error));
  }
}

export function* rewardRuleWatcherSaga() {
  yield takeEvery(FETCH_REWARD_RULES_REQUEST, getRewardRulesSaga);
  yield takeEvery(FETCH_REWARD_RULE_REQUEST, getRewardRuleSaga);
  yield takeEvery(FETCH_ALL_REWARD_RULES_REQUEST, getAllRewardRulesSaga);
  yield takeEvery(CREATE_REWARD_RULE_REQUEST, createRewardRuleSaga);
  yield takeEvery(UPDATE_REWARD_RULE_REQUEST, updateRewardRuleSaga);
  yield takeEvery(DELETE_REWARD_RULE_REQUEST, deleteRewardRuleSaga);
}
