import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

export const ConfirmModal = ({
  onConfirm,
  onCancel,
  title,
  subTitle,
  btnConfirmText,
  btnCancelText,
  btnConfirmStyles,
  isEnableOrDisableOption,
}) => {
  const [reviewMessage, setReviewMessage] = useState();
  const [notificationOnly, setNotificationOnly] = useState();
  return (
    <Box mt="1rem">
      <Stack spacing={2} mb="1rem">
        <Typography variant="h5">{title}</Typography>
        {subTitle && <Typography>{subTitle}</Typography>}
      </Stack>
      {isEnableOrDisableOption && (
        <Stack mb="1rem" spacing={2}>
          <TextField
            onChange={(e) => setReviewMessage(e.target.value)}
            label="Review Message"
            helperText="This will be shown in user profile deal list"
          />
          <FormControlLabel
            control={
              <Switch onChange={(e, value) => setNotificationOnly(value)} />
            }
            label="Send Notification Only"
          />
        </Stack>
      )}
      <Stack direction="row" spacing={3}>
        <Button
          onClick={onCancel}
          variant="capsule"
          sx={{ border: "1px solid black" }}
        >
          {btnCancelText}
        </Button>
        <Button
          onClick={() => onConfirm(reviewMessage, notificationOnly)}
          variant="capsule"
          sx={
            btnConfirmStyles ?? {
              bgcolor: "red",
              color: "white",
              "&:hover": {
                backgroundColor: "red",
              },
            }
          }
        >
          {notificationOnly ? "Send" : btnConfirmText}
        </Button>
      </Stack>
    </Box>
  );
};
