import { call, put, takeEvery } from "redux-saga/effects";
import { NotifyUtil } from "../../utils";
import { toast } from "react-toastify";
import { SearchLogService } from "../../services";
import {
  DELETE_SEARCH_LOG_REQUEST,
  FETCH_SEARCH_LOGS_REQUEST,
} from "./searchLogActionType";
import {
  deleteSearchLogFailure,
  deleteSearchLogSuccess,
  fetchSearchLogsFailure,
  fetchSearchLogsSuccess,
} from "./searchLogAction";

function* getSearchLogsSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      SearchLogService.getSearchLogs,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchSearchLogsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchSearchLogsFailure(error));
  }
}

function* deleteSearchLogSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(SearchLogService.deleteSearchLog, payload);
    yield put(deleteSearchLogSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteSearchLogFailure(error));
  }
}

export function* searchLogWatcherSaga() {
  yield takeEvery(FETCH_SEARCH_LOGS_REQUEST, getSearchLogsSaga);
  yield takeEvery(DELETE_SEARCH_LOG_REQUEST, deleteSearchLogSaga);
}
