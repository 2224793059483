import { Routes, Route } from "react-router-dom";
import { CanPerformAction, Models } from "../const";
import { Authenticate, ProtectedRoute } from "../hoc";
import {
  AddBannerPage,
  AddBrandPage,
  AddCategoryPage,
  ViewCategoryPage,
  AddClientPage,
  AddEmployeePage,
  AddRolePage,
  BannerListPage,
  BrandListPage,
  CategoryListPage,
  ClientListPage,
  CommentListPage,
  DashboardPage,
  DealListPage,
  EmployeeListPage,
  LayoutPage,
  LoginPage,
  ReportListPage,
  ViewReportPage,
  ResourceListPage,
  ViewResourcePage,
  RoleListPage,
  ViewBannerPage,
  ViewBrandPage,
  ViewClientPage,
  ViewCommentPage,
  ViewDealPage,
  ViewEmployeePage,
  ViewRolePage,
  AddResourcePage,
  StoreListPage,
  AddStorePage,
  UserListPage,
  ViewUserPage,
  ViewStorePage,
  ProfilePage,
  SearchLogListPage,
  ViewSearchLogPage,
  AddFacebookPage,
  FacebookListPage,
  ViewFacebookPage,
  AddBadgePage,
  BadgeListPage,
  EmailHistoryListPage,
  AddCountryPage,
  CountryListPage,
  ViewCountryPage,
  NotificationListPage,
  ViewNotificationPage,
  RewardRuleListPage,
  AddRewardRulePage,
  ViewRewardRulePage,
  RewardListPage,
  AddRewardPage,
  ViewRewardPage,
  RewardValidationLogListPage,
  ViewRewardValidationLogPage,
  RedeemRewardListPage,
  ViewRedeemRewardPage,
  DealAlertListPage,
  ViewDealAlertPage,
  StoreReviewListPage,
  ViewStoreReviewPage,
} from "../pages";
import { ViewEmailHistoryPage } from "../pages/emailHistory/ViewEmailHistoryPage";

export const PageRoute = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <LayoutPage />
          </ProtectedRoute>
        }
      >
        <Route
          index
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.DASHBOARD}
                action={CanPerformAction.CAN_VIEW}
              >
                <DashboardPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* category routes */}

        <Route
          path="categories"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.CATEGORY}
                action={CanPerformAction.CAN_VIEW}
              >
                <CategoryListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="categories/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.CATEGORY}
                action={CanPerformAction.CAN_ADD}
              >
                <AddCategoryPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="categories/edit/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.CATEGORY}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddCategoryPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="categories/details/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.CATEGORY}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewCategoryPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* badge Routes */}
        <Route
          path="badges"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.BADGE}
                action={CanPerformAction.CAN_VIEW}
              >
                <BadgeListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="badges/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.BADGE}
                action={CanPerformAction.CAN_ADD}
              >
                <AddBadgePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="badges/edit/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.BADGE}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddBadgePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />

        {/* banner Routes */}
        <Route
          path="banners"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.BANNER}
                action={CanPerformAction.CAN_VIEW}
              >
                <BannerListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="banners/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.BANNER}
                action={CanPerformAction.CAN_ADD}
              >
                <AddBannerPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="banners/edit/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.BANNER}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddBannerPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="banners/details/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.BANNER}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewBannerPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />

        {/* brands routes */}
        <Route
          path="brands"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.BRAND}
                action={CanPerformAction.CAN_VIEW}
              >
                <BrandListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="brands/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.BRAND}
                action={CanPerformAction.CAN_ADD}
              >
                <AddBrandPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="brands/edit/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.BRAND}
                action={CanPerformAction.CAN_ADD}
              >
                <AddBrandPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="brands/details/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.BRAND}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewBrandPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />

        {/* clients routes */}
        <Route
          path="clients"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.CLIENT}
                action={CanPerformAction.CAN_VIEW}
              >
                <ClientListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="clients/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.CLIENT}
                action={CanPerformAction.CAN_ADD}
              >
                <AddClientPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="clients/edit/:email"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.CLIENT}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddClientPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="clients/details/:email"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.CLIENT}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewClientPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* comment routes */}
        <Route
          path="comments"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.COMMENT}
                action={CanPerformAction.CAN_VIEW}
              >
                <CommentListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="comments/details/:id"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.COMMENT}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewCommentPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* country Routes */}
        <Route
          path="countries"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.COUNTRY}
                action={CanPerformAction.CAN_VIEW}
              >
                <CountryListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="countries/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.COUNTRY}
                action={CanPerformAction.CAN_ADD}
              >
                <AddCountryPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="countries/edit/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.COUNTRY}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddCountryPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="countries/details/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.COUNTRY}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewCountryPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* deal routes */}
        <Route
          path="deals"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.DEAL}
                action={CanPerformAction.CAN_VIEW}
              >
                <DealListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="deals/details/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.DEAL}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewDealPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* deal alert routes */}
        <Route
          path="deal-alerts"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.DEAL_ALERT}
                action={CanPerformAction.CAN_VIEW}
              >
                <DealAlertListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="deal-alerts/details/:id"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.DEAL_ALERT}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewDealAlertPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* email history routes */}
        <Route
          path="email-histories"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.EMAIL_HISTORY}
                action={CanPerformAction.CAN_VIEW}
              >
                <EmailHistoryListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="email-histories/details/:id"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.EMAIL_HISTORY}
                action={CanPerformAction.CAN_EDIT}
              >
                <ViewEmailHistoryPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* employee routes */}
        <Route
          path="employees"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.EMPLOYEE}
                action={CanPerformAction.CAN_VIEW}
              >
                <EmployeeListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="employees/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.EMPLOYEE}
                action={CanPerformAction.CAN_ADD}
              >
                <AddEmployeePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="employees/edit/:email"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.EMPLOYEE}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddEmployeePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="employees/details/:email"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.EMPLOYEE}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewEmployeePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* notification routes */}
        <Route
          path="notifications"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.NOTIFICATION}
                action={CanPerformAction.CAN_VIEW}
              >
                <NotificationListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="notifications/details/:id"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.NOTIFICATION}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewNotificationPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* redeem reward */}
        <Route
          path="redeem-rewards"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REDEEM_REWARD}
                action={CanPerformAction.CAN_VIEW}
              >
                <RedeemRewardListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="redeem-rewards/details/:id"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REDEEM_REWARD}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewRedeemRewardPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* report routes */}
        <Route
          path="reports"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REPORT}
                action={CanPerformAction.CAN_VIEW}
              >
                <ReportListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="reports/details/:id"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REPORT}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewReportPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* role routes */}
        <Route
          path="roles"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.ROLE}
                action={CanPerformAction.CAN_VIEW}
              >
                <RoleListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="roles/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.ROLE}
                action={CanPerformAction.CAN_ADD}
              >
                <AddRolePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="roles/edit/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.ROLE}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddRolePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="roles/details/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.ROLE}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewRolePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* resource routes */}
        <Route
          path="resources"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.RESOURCE}
                action={CanPerformAction.CAN_VIEW}
              >
                <ResourceListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="resources/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.RESOURCE}
                action={CanPerformAction.CAN_ADD}
              >
                <AddResourcePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="resources/edit/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.RESOURCE}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddResourcePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="resources/details/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.RESOURCE}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewResourcePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* reward routes */}
        <Route
          path="rewards"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REWARD}
                action={CanPerformAction.CAN_VIEW}
              >
                <RewardListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="rewards/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REWARD}
                action={CanPerformAction.CAN_ADD}
              >
                <AddRewardPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="rewards/edit/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REWARD}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddRewardPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="rewards/details/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REWARD}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewRewardPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* reward rule routes */}
        <Route
          path="reward-rules"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REWARD_RULE}
                action={CanPerformAction.CAN_VIEW}
              >
                <RewardRuleListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="reward-rules/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REWARD_RULE}
                action={CanPerformAction.CAN_ADD}
              >
                <AddRewardRulePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="reward-rules/edit/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REWARD_RULE}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddRewardRulePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="reward-rules/details/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REWARD_RULE}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewRewardRulePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* reward validation logs */}
        <Route
          path="reward-validation-logs"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REWARD_VALIDATION_LOG}
                action={CanPerformAction.CAN_VIEW}
              >
                <RewardValidationLogListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="reward-validation-logs/details/:id"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.REWARD_VALIDATION_LOG}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewRewardValidationLogPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* store routes */}
        <Route
          path="stores"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.STORE}
                action={CanPerformAction.CAN_VIEW}
              >
                <StoreListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="stores/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.STORE}
                action={CanPerformAction.CAN_ADD}
              >
                <AddStorePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="stores/edit/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.STORE}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddStorePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="stores/details/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.STORE}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewStorePage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* store review routes */}
        <Route
          path="store-reviews"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.STORE_REVIEW}
                action={CanPerformAction.CAN_VIEW}
              >
                <StoreReviewListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="store-reviews/details/:id"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.STORE_REVIEW}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewStoreReviewPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* searchLog routes */}
        <Route
          path="search-logs"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.SEARCH_LOG}
                action={CanPerformAction.CAN_VIEW}
              >
                <SearchLogListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="search-logs/details/:id"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.SEARCH_LOG}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewSearchLogPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* user routes */}
        <Route
          path="users"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.USER}
                action={CanPerformAction.CAN_VIEW}
              >
                <UserListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="users/details/:email"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.USER}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewUserPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        {/* profile route */}
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        {/* facebook page routes */}
        <Route
          path="facebook-pages"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.FACEBOOK_PAGE}
                action={CanPerformAction.CAN_VIEW}
              >
                <FacebookListPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="facebook-pages/add"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.FACEBOOK_PAGE}
                action={CanPerformAction.CAN_ADD}
              >
                <AddFacebookPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="facebook-pages/edit/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.FACEBOOK_PAGE}
                action={CanPerformAction.CAN_EDIT}
              >
                <AddFacebookPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
        <Route
          path="facebook-pages/details/:slug"
          element={
            <ProtectedRoute>
              <Authenticate
                model={Models.FACEBOOK_PAGE}
                action={CanPerformAction.CAN_VIEW}
              >
                <ViewFacebookPage />
              </Authenticate>
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
};
