import { api } from "../config";

export const SearchLogService = {
  getSearchLogs: async (skip, limit, searchQuery) => {
    try {
      const { data } = await api.get(
        `api/admin/search-logs/?query=${searchQuery}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteSearchLog: async (payload) => {
    try {
      const { data } = await api.post("api/admin/search-logs/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
