import React from "react";
import Chart from "react-apexcharts";

export const PieChart = ({
  series,
  labels,
  type = "pie",
  title = "Summary",
  height,
}) => {
  const state = {
    series: series,
    options: {
      title: {
        text: title,
        align: "left",
      },
      labels: labels,
      plotOptions: {
        pie: {
          customScale: 0.9,
          offsetX: 40,
          offsetY: 50,
        },
      },
    },
  };
  return (
    <Chart
      options={state.options}
      type={type}
      series={state.series}
      height={height}
    />
  );
};
