import {
  CREATE_RESOURCE_FAILURE,
  CREATE_RESOURCE_REQUEST,
  CREATE_RESOURCE_SUCCESS,
  DELETE_RESOURCE_FAILURE,
  DELETE_RESOURCE_REQUEST,
  DELETE_RESOURCE_SUCCESS,
  FETCH_RESOURCES_FAILURE,
  FETCH_RESOURCES_REQUEST,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCE_FAILURE,
  FETCH_RESOURCE_OPTIONS_FAILURE,
  FETCH_RESOURCE_OPTIONS_REQUEST,
  FETCH_RESOURCE_OPTIONS_SUCCESS,
  FETCH_RESOURCE_REQUEST,
  FETCH_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_FAILURE,
  UPDATE_RESOURCE_REQUEST,
  UPDATE_RESOURCE_SUCCESS,
} from "./resourceActionType";

export const fetchResourcesRequest = (offset, searchQuery) => {
  return {
    type: FETCH_RESOURCES_REQUEST,
    offset,
    searchQuery,
  };
};

export const fetchResourcesSuccess = (data) => {
  return {
    type: FETCH_RESOURCES_SUCCESS,
    payload: data,
  };
};

export const fetchResourcesFailure = (error) => {
  return {
    type: FETCH_RESOURCES_FAILURE,
    error,
  };
};

export const fetchResourceRequest = (slug) => {
  return {
    type: FETCH_RESOURCE_REQUEST,
    slug,
  };
};
export const fetchResourceSuccess = (data) => {
  return {
    type: FETCH_RESOURCE_SUCCESS,
    payload: data,
  };
};
export const fetchResourceFailure = (error) => {
  return {
    type: FETCH_RESOURCE_FAILURE,
    error,
  };
};

export const fetchResourceOptionsRequest = () => {
  return {
    type: FETCH_RESOURCE_OPTIONS_REQUEST,
  };
};

export const fetchResourceOptionsSuccess = (data) => {
  return {
    type: FETCH_RESOURCE_OPTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchResourceOptionsFailure = (error) => {
  return {
    type: FETCH_RESOURCE_OPTIONS_FAILURE,
    error,
  };
};

export const createResourceRequest = (payload, onSuccess) => {
  return {
    type: CREATE_RESOURCE_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const createResourceSuccess = (data) => {
  return {
    type: CREATE_RESOURCE_SUCCESS,
    payload: data,
  };
};

export const createResourceFailure = (error) => {
  return {
    type: CREATE_RESOURCE_FAILURE,
    error,
  };
};

export const updateResourceRequest = (slug, payload, onSuccess) => {
  return {
    type: UPDATE_RESOURCE_REQUEST,
    slug,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateResourceSuccess = () => {
  return {
    type: UPDATE_RESOURCE_SUCCESS,
  };
};

export const updateResourceFailure = (error) => {
  return {
    type: UPDATE_RESOURCE_FAILURE,
    error,
  };
};

export const deleteResourceRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_RESOURCE_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteResourceSuccess = () => {
  return {
    type: DELETE_RESOURCE_SUCCESS,
  };
};

export const deleteResourceFailure = (error) => {
  return {
    type: DELETE_RESOURCE_FAILURE,
    error,
  };
};
