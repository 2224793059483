import { Chip } from "@mui/material";
import React from "react";
import { StringUtil } from "../utils";

export const StatusChip = ({ status }) => {
  return (
    <Chip
      variant="outlined"
      sx={{
        backgroundColor: (theme) =>
          status
            ? theme.palette.status.activeBg
            : theme.palette.status.inActiveBg,
        color: (theme) =>
          status
            ? theme.palette.status.activeColor
            : theme.palette.status.inactiveColor,
      }}
      label={StringUtil.getYesOrNo(status)}
    />
  );
};
