import { Paper, Stack, Box, Typography } from "@mui/material";
import React from "react";

export const SummaryItem = ({
  title,
  value,
  fontColor,
  icon,
  bgcolor,
  linearGradient,
}) => {
  return (
    <Paper
      sx={{
        width: "50%",
        maxWidth: "520px",
        pt: "2rem",
        pb: "2rem",
        bgcolor: bgcolor,
        borderRadius: "10px",
        boxShadow: (theme) => theme.palette.boxShadow,
      }}
    >
      <Stack alignItems="center">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "64px",
            height: "64px",
            borderRadius: "50%",
            color: "rgb(0, 123, 85)",
            backgroundImage: linearGradient,
          }}
        >
          {icon}
        </Box>
        <Typography
          variant="h4"
          sx={{ color: fontColor, fontWeight: 700, marginTop: "1rem" }}
        >
          {value}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ color: fontColor, opacity: ".72" }}
        >
          {title}
        </Typography>
      </Stack>
    </Paper>
  );
};
