"./bannerActionType";

import {
  DELETE_REPORT_FAILURE,
  DELETE_REPORT_REQUEST,
  FETCH_REPORTS_FAILURE,
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_STATUS_FAILURE,
  FETCH_REPORT_STATUS_REQUEST,
  FETCH_REPORT_STATUS_SUCCESS,
  FETCH_REPORT_SUCCESS,
  UPDATE_REPORT_FAILURE,
  UPDATE_REPORT_REQUEST,
} from "./reportActionType";

const initialState = {
  loading: false,
  reports: [],
  report: {},
  reportStatus: [],
  error: "",
  totalReports: 0,
};

export const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTS_REQUEST:
    case FETCH_REPORT_REQUEST:
    case FETCH_REPORT_STATUS_REQUEST:
    case UPDATE_REPORT_REQUEST:
    case DELETE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REPORTS_SUCCESS:
      const { reports, totalReports } = action.payload;
      return {
        ...state,
        loading: false,
        reports: reports,
        totalReports: totalReports,
      };
    case FETCH_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        report: action.payload,
      };
    case FETCH_REPORT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        reportStatus: action.payload,
      };
    case FETCH_REPORTS_FAILURE:
    case FETCH_REPORT_FAILURE:
    case FETCH_REPORT_STATUS_FAILURE:
    case UPDATE_REPORT_FAILURE:
    case DELETE_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
