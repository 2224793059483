import { api } from "../config";

export const RedeemRewardService = {
  updateRedeemReward: async (id, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/redeem-rewards/update/${id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getRedeemRewards: async (skip, limit, searchQuery, status) => {
    try {
      const { data } = await api.get(
        `api/admin/redeem-rewards/?query=${searchQuery}&status=${status}&skip=${skip}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getRedeemReward: async (redeemRewardId) => {
    try {
      const { data } = await api.get(
        `api/admin/redeem-rewards/${redeemRewardId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getRedeemRewardStatus: async () => {
    try {
      const { data } = await api.get("api/admin/redeem-rewards/status");
      return data;
    } catch (error) {
      throw error;
    }
  },
};
