import React, { useEffect, useState, useCallback } from "react";
import { Button } from "@mui/material";
import {
  ColumnOption,
  DmTable,
  ColumnTitle,
  DmBox,
  StatusChip,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { deleteResourceRequest, fetchResourcesRequest } from "../../store";
import moment from "moment";
import { Action, CanPerformAction, DATE_FORMAT, Models } from "../../const";
import { ConfirmModal, DmModal } from "../../components/Modal";
import { useToggle } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { searchQuerySelector } from "../../store/selector";
import _ from "lodash";
import { Authenticate } from "../../hoc/Authenticate";
import { StringUtil } from "../../utils";

export const ResourceListPage = () => {
  const dispatch = useDispatch();
  const resourceState = useSelector((state) => state.resource);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRowId, setActiveRowId] = useState(); // this is used only for hide and show ... options
  const [offset, setOffset] = useState({ limit: 10, skip: 0 });
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useToggle();
  const searchQuery = useSelector(searchQuerySelector);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseEnterRowHandler = (row) => {
    setActiveRowId(row.id);
  };
  const onMouseLeaveRowHandler = () => {
    setAnchorEl(null);
    setActiveRowId(null);
  };

  const onSuccessfullyDelete = () => {
    setOffset({ limit: offset.limit, skip: offset.skip });
  };

  const onDelete = () => {
    dispatch(
      deleteResourceRequest(
        { resourceIds: _.map(selectedRows, "id") },
        onSuccessfullyDelete
      )
    );
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const onClickMenuItem = (action, row) => {
    switch (action) {
      case Action.DELETE:
        setSelectedRows([row]);
        setOpenModal();
        break;
      case Action.EDIT:
        navigate(`/resources/edit/${row.slug}`);
        break;
      case Action.VIEW:
        navigate(`/resources/details/${row.slug}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchResourcesRequest(offset, searchQuery));
  }, [dispatch, offset, searchQuery]);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    const skip = getSkip(currentPage);
    setOffset({ limit: currentRowsPerPage, skip: skip });
  };

  const onChangePage = (page) => {
    const skip = getSkip(page);
    setOffset({ ...offset, skip });
  };

  const getSkip = (page) => {
    const skip = page - 1 > 0 ? page - 1 : 0;
    const calculateSkip = offset.limit * skip;
    return calculateSkip;
  };

  const columns = [
    {
      name: <ColumnTitle title={"Id"} />,
      selector: (row) => row.id,
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Name"} />,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Display Name"} />,
      selector: (row) => row.displayName,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Slug"} />,
      selector: (row) => row.slug,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Can Perform Action"} />,
      selector: (row) => StringUtil.convertCapUnderscore(row.canPerformAction),
      sortable: true,
      minWidth: "195px",
    },
    {
      name: <ColumnTitle title={"Enabled"} />,
      selector: (row) => <StatusChip status={row.isActive} />,
    },
    {
      name: <ColumnTitle title={"Created"} />,
      selector: (row) => moment(row.createdAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Updated"} />,
      selector: (row) => moment(row.updatedAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={""} />,
      cell: (row) => (
        <>
          {activeRowId === row.id && (
            <ColumnOption
              row={row}
              open={open}
              model={Models.RESOURCE}
              anchorEl={anchorEl}
              handleClick={handleOptionClick}
              handleClose={handleOptionClose}
              onClickMenuItem={onClickMenuItem}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <DmBox>
      <DmTable
        columns={columns}
        data={resourceState.resources}
        title={"Permission List"}
        pending={resourceState.loading}
        totalRow={resourceState.totalResources}
        onRowMouseEnter={onMouseEnterRowHandler}
        onRowMouseLeave={onMouseLeaveRowHandler}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        actions={
          <Authenticate
            model={Models.RESOURCE}
            action={CanPerformAction.CAN_ADD}
          >
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/resources/add")}
            >
              Add
            </Button>
          </Authenticate>
        }
        contextActions={
          <Authenticate
            model={Models.RESOURCE}
            action={CanPerformAction.CAN_DELETE}
          >
            <Button
              onClick={setOpenModal}
              sx={{
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
            >
              Delete
            </Button>
          </Authenticate>
        }
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
      <DmModal open={openModal} closeModal={setOpenModal}>
        <ConfirmModal
          title={
            selectedRows.length > 1
              ? `Are you sure want to delete ${selectedRows.length} resources ?`
              : "Are you sure you want to delete this resource ?"
          }
          subTitle="This action cannot be undone"
          btnConfirmText={"Delete"}
          btnCancelText="Cancel"
          onCancel={setOpenModal}
          onConfirm={onDelete}
        />
      </DmModal>
    </DmBox>
  );
};
