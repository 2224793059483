import { Avatar, Box, Stack, Typography, Tab } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { ProfileForm, ResetPasswordForm } from "../../components/forms";
import { loggedEmployeeSelector } from "../../store/selector";
import { UrlUtil } from "../../utils";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DmBox } from "../../components";
import KeyIcon from "@mui/icons-material/Key";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

export const ProfilePage = () => {
  const loggedEmployee = useSelector(loggedEmployeeSelector);
  const [value, setValue] = React.useState("1");
  const TabValue = {
    GENERAL: "1",
    PASSWORD: "2",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <DmBox>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              icon={<AccountBoxIcon />}
              iconPosition="start"
              label="General"
              value={TabValue.GENERAL}
            />
            <Tab
              icon={<KeyIcon />}
              iconPosition="start"
              label="Password"
              value={TabValue.PASSWORD}
            />
          </TabList>
        </Box>
        <TabPanel value={TabValue.GENERAL} sx={{ p: "1rem 0px" }}>
          <Stack direction="row" spacing={3} pt="2rem">
            <Box
              width="40%"
              height="350px"
              maxWidth="450px"
              pt="1.5rem"
              boxShadow={(theme) => theme.palette.boxShadow}
            >
              <Stack alignItems="center" spacing={1}>
                <Avatar
                  alt="admin"
                  src={UrlUtil.getFullImagePath(loggedEmployee.image.path)}
                  sx={{ width: "80px", height: "80px" }}
                >
                  {loggedEmployee.firstName.substring(0, 2)}
                </Avatar>
                <Typography
                  variant="h5"
                  fontWeight={600}
                >{`${loggedEmployee.firstName} ${loggedEmployee.lastName}`}</Typography>
                <Typography variant="value">{loggedEmployee.email}</Typography>
                <Typography variant="value">
                  {loggedEmployee.address}
                </Typography>
                <Typography variant="value">
                  {loggedEmployee.mobileNumber}
                </Typography>
              </Stack>
            </Box>
            <Box
              width="60%"
              maxWidth="950px"
              pt="1.5rem"
              pb="1.5rem"
              boxShadow={(theme) => theme.palette.boxShadow}
            >
              <ProfileForm employee={loggedEmployee} />
            </Box>
          </Stack>
        </TabPanel>
        <TabPanel value={TabValue.PASSWORD} sx={{ p: "1rem 0px" }}>
          <ResetPasswordForm employee={loggedEmployee} />
        </TabPanel>
      </TabContext>
    </DmBox>
  );
};
