import {
  CREATE_STORE_FAILURE,
  CREATE_STORE_REQUEST,
  DELETE_STORE_FAILURE,
  DELETE_STORE_REQUEST,
  FETCH_STORES_FAILURE,
  FETCH_STORES_REQUEST,
  FETCH_STORES_SUCCESS,
  FETCH_STORE_FAILURE,
  FETCH_STORE_REQUEST,
  FETCH_STORE_SUCCESS,
  UPDATE_STORE_FAILURE,
  UPDATE_STORE_REQUEST,
} from "./storeActionType";

const initialState = {
  loading: false,
  stores: [],
  store: {},
  error: "",
  totalStores: 0,
};

export const StoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORES_REQUEST:
    case FETCH_STORE_REQUEST:
    case CREATE_STORE_REQUEST:
    case UPDATE_STORE_REQUEST:
    case DELETE_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_STORES_SUCCESS:
      const { stores, totalStores } = action.payload;
      return {
        ...state,
        loading: false,
        stores: stores,
        totalStores: totalStores,
      };
    case FETCH_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        store: action.payload,
      };
    case FETCH_STORES_FAILURE:
    case FETCH_STORE_FAILURE:
    case CREATE_STORE_FAILURE:
    case UPDATE_STORE_FAILURE:
    case DELETE_STORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
