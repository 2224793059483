import React from "react";
import Chart from "react-apexcharts";

export const AreaChart = ({ seriesName, title, data, categories, height }) => {
  const state = {
    series: [
      {
        name: seriesName,
        data: data,
      },
    ],
    options: {
      chart: {
        type: "area",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: title,
        align: "left",
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        opposite: false,
      },
      legend: {
        horizontalAlign: "left",
      },
    },
  };
  return (
    <Chart
      options={state.options}
      series={state.series}
      type="area"
      height={height}
    />
  );
};
