import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { NotifyUtil } from "../../utils";
import {
  DELETE_STORE_REVIEW_REQUEST,
  FETCH_STORE_REVIEWS_REQUEST,
  TOGGLE_STORE_REVIEWS_STATUS_REQUEST,
} from "./storeReviewActionType";
import { StoreReviewService } from "../../services";
import {
  deleteStoreReviewFailure,
  deleteStoreReviewSuccess,
  fetchStoreReviewsFailure,
  fetchStoreReviewsSuccess,
  toggleStoreReviewsStatusFailure,
  toggleStoreReviewsStatusSuccess,
} from "./storeReviewAction";

function* fetchStoreReviewsSaga(action) {
  try {
    const { limit, skip } = action.offset;
    const { data } = yield call(
      StoreReviewService.getStoreReviews,
      skip,
      limit,
      action.searchQuery
    );
    yield put(fetchStoreReviewsSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchStoreReviewsFailure(error));
  }
}

function* toggleStoreReviewsStatusSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(StoreReviewService.toggleStoreReviewsStatus, payload);
    yield put(toggleStoreReviewsStatusSuccess());
    toast.success("Successfully status updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(toggleStoreReviewsStatusFailure(error));
  }
}

function* deleteStoreReviewSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(StoreReviewService.deleteStoreReview, payload);
    yield put(deleteStoreReviewSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteStoreReviewFailure(error));
  }
}

export function* storeReviewWatcherSaga() {
  yield takeEvery(FETCH_STORE_REVIEWS_REQUEST, fetchStoreReviewsSaga);
  yield takeEvery(
    TOGGLE_STORE_REVIEWS_STATUS_REQUEST,
    toggleStoreReviewsStatusSaga
  );
  yield takeEvery(DELETE_STORE_REVIEW_REQUEST, deleteStoreReviewSaga);
}
