import {
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_REQUEST,
  EMPLOYEE_LOGIN_FAILURE,
  EMPLOYEE_LOGIN_REQUEST,
  EMPLOYEE_LOGIN_SUCCESS,
  EMPLOYEE_LOGOUT_REQUEST,
  FETCH_EMPLOYEES_FAILURE,
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEE_OPTIONS_FAILURE,
  FETCH_EMPLOYEE_OPTIONS_REQUEST,
  FETCH_EMPLOYEE_OPTIONS_SUCCESS,
  FETCH_EMPLOYEE_REQUEST,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_UPDATED_EMPLOYEE_FAILURE,
  FETCH_UPDATED_EMPLOYEE_REQUEST,
  FETCH_UPDATED_EMPLOYEE_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  UPDATE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_FAILURE,
  UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_REQUEST,
  UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_SUCCESS,
  UPDATE_EMPLOYEE_PROFILE_FAILURE,
  UPDATE_EMPLOYEE_PROFILE_REQUEST,
  UPDATE_EMPLOYEE_PROFILE_SUCCESS,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_FAIlURE,
  UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_REQUEST,
  UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_SUCCESS,
} from "./employeeActionType";

const initialState = {
  loading: false,
  employeeOptionsLoading: false,
  employees: [],
  employee: {},
  loggedEmployee: {},
  employeeOptions: [],
  totalEmployees: 0,
  error: "",
};

export const EmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_LOGIN_REQUEST:
    case FETCH_EMPLOYEES_REQUEST:
    case FETCH_EMPLOYEE_REQUEST:
    case FETCH_UPDATED_EMPLOYEE_REQUEST:
    case CREATE_EMPLOYEE_REQUEST:
    case UPDATE_EMPLOYEE_REQUEST:
    case UPDATE_EMPLOYEE_PROFILE_REQUEST:
    case UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case DELETE_EMPLOYEE_REQUEST:
    case UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EMPLOYEE_OPTIONS_REQUEST:
      return {
        ...state,
        employeeOptionsLoading: true,
      };
    case EMPLOYEE_LOGOUT_REQUEST:
      return {
        ...state,
        loggedEmployee: {},
      };
    case EMPLOYEE_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedEmployee: action.payload,
      };
    case FETCH_EMPLOYEE_OPTIONS_SUCCESS:
      return {
        ...state,
        employeeOptionsLoading: false,
        employeeOptions: action.payload,
      };
    case FETCH_EMPLOYEES_SUCCESS:
      const { employees, totalEmployees } = action.payload;
      return {
        ...state,
        loading: false,
        employees: employees,
        totalEmployees: totalEmployees,
      };
    case FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_EMPLOYEE_PROFILE_SUCCESS: {
      const { token, token_expire_in, refresh_token, refresh_token_expire_in } =
        state.loggedEmployee;
      return {
        ...state,
        loading: false,
        loggedEmployee: {
          ...action.payload,
          token,
          token_expire_in,
          refresh_token,
          refresh_token_expire_in,
        },
      };
    }
    case FETCH_UPDATED_EMPLOYEE_SUCCESS:
      const { token, token_expire_in, refresh_token, refresh_token_expire_in } =
        state.loggedEmployee;
      return {
        ...state,
        loading: false,
        loggedEmployee: {
          ...action.payload,
          token,
          token_expire_in,
          refresh_token,
          refresh_token_expire_in,
        },
      };
    case FETCH_EMPLOYEE_OPTIONS_FAILURE:
      return {
        ...state,
        employeeOptionsLoading: false,
        error: action.error,
      };
    case EMPLOYEE_LOGIN_FAILURE:
    case FETCH_EMPLOYEES_FAILURE:
    case FETCH_EMPLOYEE_FAILURE:
    case FETCH_UPDATED_EMPLOYEE_FAILURE:
    case CREATE_EMPLOYEE_FAILURE:
    case UPDATE_EMPLOYEE_FAILURE:
    case UPDATE_MARK_EMPLOYEE_NOTIFICATION_READ_FAIlURE:
    case RESET_PASSWORD_FAILURE:
    case UPDATE_EMPLOYEE_PROFILE_FAILURE:
    case DELETE_EMPLOYEE_FAILURE:
    case UPDATE_EMPLOYEE_NOTIFICATION_SUMMARY_SEEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
